const siteConstants = {
    FETCH_SITE_REQUEST: 'FETCH_SITE_REQUEST',
    FETCH_SITE_SUCCESS: 'FETCH_SITE_SUCCESS',
    FETCH_SITE_REJECTED: 'FETCH_SITE_REJECTED',
    FETCH_SITES_REQUEST: 'FETCH_SITES_REQUEST',
    FETCH_SITES_SUCCESS: 'FETCH_SITES_SUCCESS',
    FETCH_SITES_REJECTED: 'FETCH_SITES_REJECTED',
    UPDATE_SITE_REQUEST: 'UPDATE_SITE_REQUEST',
    UPDATE_SITE_SUCCESS: 'UPDATE_SITE_SUCCESS',
    UPDATE_SITE_REJECTED: 'UPDATE_SITE_REJECTED',
    COPY_SITE_REQUEST: 'COPY_SITE_REQUEST',
    COPY_SITE_SUCCESS: 'COPY_SITE_SUCCESS',
    COPY_SITE_REJECTED: 'COPY_SITE_REJECTED',
    SEARCH_SITE_REQUEST: 'SEARCH_SITE_REQUEST',
    SEARCH_SITE_SUCCESS: 'SEARCH_SITE_SUCCESS',
    SEARCH_SITE_REJECTED: 'SEARCH_SITE_REJECTED',
    DELETE_SITE_REQUEST: 'DELETE_SITE_REQUEST',
    DELETE_SITE_SUCCESS: 'DELETE_SITE_SUCCESS',
    DELETE_SITE_REJECTED: 'DELETE_SITE_REJECTED',
    MULTI_COPY_SITE_REQUEST: 'MULTI_COPY_SITE_REQUEST',
    MULTI_COPY_SITE_SUCCESS: 'MULTI_COPY_SITE_SUCCESS',
    MULTI_COPY_SITE_REJECTED: 'MULTI_COPY_SITE_REJECTED',
    GET_SITE_TITLE_REQUEST: 'GET_SITE_TITLE_REQUEST',
    GET_SITE_TITLE_SUCCESS: 'GET_SITE_TITLE_SUCCESS',
    GET_SITE_TITLE_REJECTED: 'GET_SITE_TITLE_REJECTED',
    GET_SITE_BACKUP_STATUS_REQUEST: 'GET_SITE_BACKUP_STATUS_REQUEST',
    GET_SITE_BACKUP_STATUS_SUCCESS: 'GET_SITE_BACKUP_STATUS_SUCCESS',
    GET_SITE_BACKUP_STATUS_REJECTED: 'GET_SITE_BACKUP_STATUS_REJECTED',
    GENERATE_SSO_KEYS_FOR_SITE_REQUEST: 'GENERATE_SSO_KEYS_FOR_SITE_REQUEST',
    GENERATE_SSO_KEYS_FOR_SITE_SUCCESS: 'GENERATE_SSO_KEYS_FOR_SITE_SUCCESS',
    GENERATE_SSO_KEYS_FOR_SITE_REJECTED: 'GENERATE_SSO_KEYS_FOR_SITE_REJECTED',
    CLEAR_SSO_DATA: 'CLEAR_SSO_DATA'
};

export default siteConstants;
