import permissionConstants from '../constants/permissions.constants';

const initialState = {
    loading: false,
    permissions: {},
    roles: [],
};

const permissions = (state = initialState, action) => {
    switch (action.type) {
        case permissionConstants.FETCH_PERMISSIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case permissionConstants.FETCH_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                permissions: action.payload.permissions,
                roles: action.payload.role_permissions,
            };
        case permissionConstants.FETCH_PERMISSIONS_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case permissionConstants.GET_PERMISSIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case permissionConstants.GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                permissions: action.payload,
            };
        case permissionConstants.GET_PERMISSIONS_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case permissionConstants.FETCH_ROLES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case permissionConstants.FETCH_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.payload,
            };
        case permissionConstants.FETCH_ROLES_REJECTED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default permissions;
