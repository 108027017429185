/* eslint-disable import/prefer-default-export */
const learnerProgress = {
    FETCH_PATHWAYS_PROGRESS_REQUEST: 'FETCH_PATHWAYS_PROGRESS_REQUEST',
    FETCH_PATHWAYS_PROGRESS_SUCCESS: 'FETCH_PATHWAYS_PROGRESS_SUCCESS',
    FETCH_PATHWAYS_PROGRESS_FAIL: 'FETCH_PATHWAYS_PROGRESS_FAIL',

    FETCH_PATHWAYS_PROGRESS_REQUEST_V2: 'FETCH_PATHWAYS_PROGRESS_REQUEST_V2',
    FETCH_PATHWAYS_PROGRESS_SUCCESS_V2: 'FETCH_PATHWAYS_PROGRESS_SUCCESS_V2',
    FETCH_PATHWAYS_PROGRESS_FAIL_V2: 'FETCH_PATHWAYS_PROGRESS_FAIL_V2',
};

export default learnerProgress;
