export const learningFocusConstants = {
    FETCH_LEARNING_FOCUS_REQUEST: "FETCH_LEARNING_FOCUS_REQUEST",
    FETCH_LEARNING_FOCUS_SUCCESS: "FETCH_LEARNING_FOCUS_SUCCESS",
    FETCH_LEARNING_FOCUS_REJECTED: "FETCH_LEARNING_FOCUS_REJECTED",
    FETCH_FOCUS_REQUEST: "FETCH_FOCUS_REQUEST",
    FETCH_FOCUS_SUCCESS: "FETCH_FOCUS_SUCCESS",
    FETCH_FOCUS_REJECTED: "FETCH_FOCUS_REJECTED",
    UPDATE_LEARNING_FOCUS_REQUEST:"UPDATE_LEARNING_FOCUS_REQUEST",
    UPDATE_LEARNING_FOCUS_SUCCESS:"UPDATE_LEARNING_FOCUS_SUCCESS",
    UPDATE_LEARNING_FOCUS_REJECTED:"UPDATE_LEARNING_FOCUS_REJECTED"
};
