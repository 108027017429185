import { userConstants } from '../constants/user.constants';
import authenticationConstants from '../constants/authentication.constants';
import { retrieveDecryptedUserFromLocalStorage } from '../../utils/common';

const loggedInUser = retrieveDecryptedUserFromLocalStorage();

const initialState = {
    loginRequestLoading: false,
    user: loggedInUser || null,
    loginError: null,
    forgotPasswordError: null,
    loginPopUpMsg: null,
};

function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loginRequestLoading: true,
                user: action.user,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loginRequestLoading: false,
                user: action.user,
                loginPopUpMsg: action.loginPopUpMsg
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                loginRequestLoading: false,
                loginError: action.error,
            };
        case authenticationConstants.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                resetPasswordRequestLoading: true,
            };
        case authenticationConstants.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordRequestLoading: false,
                emailText: action.payload,
            };
        case authenticationConstants.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordRequestLoading: false,
                forgotPasswordError: action.resetPasswordError,
            };
        case userConstants.LOGOUT:
            return {};
        case authenticationConstants.RESET_PASSWORD_REQUEST_V2:
            return {
                ...state,
                resetPasswordRequestLoadingV2: true
            };
        case authenticationConstants.RESET_PASSWORD_SUCCESS_V2:
            return {
                ...state,
                resetPasswordRequestLoading: false
            };
        case authenticationConstants.RESET_PASSWORD_FAILURE_V2:
            return {
                ...state,
                resetPasswordRequestLoading: false,
                resetPasswordErrorV2: action.resetPasswordErrorV2,
            };
        default:
            return state;
    }
}

export default authentication;
