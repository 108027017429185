export const feedbackConstants = {
    FETCH_FEEDBACK_REQUEST: 'FETCH_FEEDBACK_REQUEST',
    FETCH_FEEDBACK_SUCCESS: 'FETCH_FEEDBACK_SUCCESS',
    FETCH_FEEDBACK_REJECTED: 'FETCH_FEEDBACK_REJECTED',
    GET_FEEDBACK_BY_ID_REQUEST: 'GET_FEEDBACK_BY_ID_REQUEST',
    GET_FEEDBACK_BY_ID_SUCCESS: 'GET_FEEDBACK_BY_ID_SUCCESS',
    GET_FEEDBACK_BY_ID_REJECTED: 'GET_FEEDBACK_BY_ID_REJECTED',
    STORE_NEW_FEEDBACK_REQUEST: 'STORE_NEW_FEEDBACK_REQUEST',
    STORE_NEW_FEEDBACK_SUCCESS: 'STORE_NEW_FEEDBACK_SUCCESS',
    STORE_NEW_FEEDBACK_REJECTED: 'STORE_NEW_FEEDBACK_REJECTED',
    UPDATED_FEEDBACK_REQUEST: 'UPDATED_FEEDBACK_REQUEST',
    UPDATED_FEEDBACK_SUCCESS: 'UPDATED_FEEDBACK_SUCCESS',
    UPDATED_FEEDBACK_REJECTED: 'DELETE_FEEDBACK_REJECTED',
    DELETE_FEEDBACK_REQUEST: 'DELETE_FEEDBACK_REQUEST',
    DELETE_FEEDBACK_SUCCESS: 'DELETE_FEEDBACK_SUCCESS',
    DELETE_FEEDBACK_REJECTED: 'DELETE_FEEDBACK_REJECTED',
    ADD_QUESTION_TO_LIST: 'ADD_QUESTION_TO_LIST',
    UPDATE_LIST_QUESTION: 'UPDATE_LIST_QUESTION',
    DELETE_QUESTION: 'DELETE_QUESTION',
    Clear_FEEDBACK: 'Clear_FEEDBACK'
}
