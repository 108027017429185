export const questionnaireConstants = {
    FETCH_QUESTIONNAIRES_REQUEST: 'FETCH_QUESTIONNAIRES_REQUEST',
    FETCH_QUESTIONNAIRES_SUCCESS: 'FETCH_QUESTIONNAIRES_SUCCESS',
    FETCH_QUESTIONNAIRES_REJECTED: 'FETCH_QUESTIONNAIRES_REJECTED',
    FETCH_QUESTIONNAIRE_REQUEST: 'FETCH_QUESTIONNAIRE_REQUEST',
    FETCH_QUESTIONNAIRE_SUCCESS: 'FETCH_QUESTIONNAIRE_SUCCESS',
    FETCH_QUESTIONNAIRE_REJECTED: 'FETCH_QUESTIONNAIRE_REJECTED',
    SEARCH_QUESTIONNAIRES_REQUEST: 'SEARCH_QUESTIONNAIRES_REQUEST',
    SEARCH_QUESTIONNAIRES_SUCCESS: 'SEARCH_QUESTIONNAIRES_SUCCESS',
    SEARCH_QUESTIONNAIRES_REJECTED: 'SEARCH_QUESTIONNAIRES_REJECTED',
    QUESTION_ANSWERED: 'QUESTION_ANSWERED',
    SUBMIT_ANSWERS: 'SUBMIT_ANSWERS',
    FIELDS_LOADED: 'FIELDS_LOADED',
    DELETE_QUESTIONNAIRE_REQUEST: 'DELETE_QUESTIONNAIRE_REQUEST',
    DELETE_QUESTIONNAIRE_SUCCESS: 'DELETE_QUESTIONNAIRE_SUCCESS',
    DELETE_QUESTIONNAIRE_REJECTED: 'DELETE_QUESTIONNAIRE_REJECTED',
    CLEAR_QUESTIONNAIRE: 'CLEAR_QUESTIONNAIRE',
};
