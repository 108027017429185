export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    COMPLETE_MODULES: 'USERS_COMPLETE_MODULES',
    COMPLETE_MODULES_SUCCESS: 'COMPLETE_MODULES_SUCCESS',
    COMPLETE_MODULES_REJECTED: 'COMPLETE_MODULES_REJECTED',

    PRIMARY_SKILLS: 'PRIMARY_SKILLS',
    PRIMARY_SKILLS_SUCCESS: 'PRIMARY_SKILLS_SUCCESS',
    PRIMARY_SKILLS_REJECTED: 'PRIMARY_SKILLS_REJECTED',

    LOGOUT: 'USERS_LOGOUT',

    FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
    FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
    FETCH_USERS_REJECTED: 'FETCH_USERS_REJECTED',

    FETCH_USERS_LEARNERS_REQUEST: 'FETCH_USERS_LEARNERS_REQUEST',
    FETCH_USERS_LEARNERS_SUCCESS: 'FETCH_USERS_LEARNERS_SUCCESS',
    FETCH_USERS_LEARNERS_REJECTED: 'FETCH_USERS_LEARNERS_REJECTED',

    FETCH_USERS_TRAINERS_REQUEST: 'FETCH_USERS_TRAINERS_REQUEST',
    FETCH_USERS_TRAINERS_SUCCESS: 'FETCH_USERS_TRAINERS_SUCCESS',
    FETCH_USERS_TRAINERS_REJECTED: 'FETCH_USERS_TRAINERS_REJECTED',

    FETCH_USERS_HRMANAGERS_REQUEST: 'FETCH_USERS_HRMANAGERS_REQUEST',
    FETCH_USERS_HRMANAGERS_SUCCESS: 'FETCH_USERS_HRMANAGERS_SUCCESS',
    FETCH_USERS_HRMANAGERS_REJECTED: 'FETCH_USERS_HRMANAGERS_REJECTED',

    FIRST_LOGIN_DATA_REQUEST: 'FIRST_LOGIN_DATA_REQUEST',
    FIRST_LOGIN_DATA_SUCCESS: 'FIRST_LOGIN_DATA_SUCCESS',

    USERS_SEARCH_REQUEST: 'USERS_SEARCH_REQUEST',
    USERS_SEARCH_SUCCESS: 'USERS_SEARCH_SUCCESS',
    USERS_SEARCH_REJECTED: 'USERS_SEARCH_REJECTED',

    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_REJECTED: 'GET_USER_REJECTED',

    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_REJECTED: 'CREATE_USER_REJECTED',

    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_REJECTED: 'UPDATE_USER_REJECTED',

    UPDATE_TRAINER_PROFILE_REQUEST: 'UPDATE_TRAINER_PROFILE_REQUEST',
    UPDATE_TRAINER_PROFILE_SUCCESS: 'UPDATE_TRAINER_PROFILE_SUCCESS',
    UPDATE_TRAINER_PROFILE_REJECTED: 'UPDATE_TRAINER_PROFILE_REJECTED',

    PRIVACY_AGREE_REQUEST: 'PRIVACY_AGREE_REQUEST',
    PRIVACY_AGREE_SUCCESS: 'PRIVACY_AGREE_SUCCESS',
    PRIVACY_AGREE_REJECTED: 'PRIVACY_AGREE_REJECTED',

    WELCOME_MASSAGES: 'WELCOME_MASSAGES',
    WELCOME_MASSAGES_SUCCESS: 'WELCOME_MASSAGES_SUCCESS',
    WELCOME_MASSAGES_REJECTED: 'WELCOME_MASSAGES_REJECTED',
    UPDATE_USER_FIRST_LOGIN_SUCCESS: 'UPDATE_USER_FIRST_LOGIN_SUCCESS',
    CLEAR_USER: 'CLEAR_USER',
};
