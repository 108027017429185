const initialState = {
    buttonsVisible: false,
};

const visibility = (state = initialState, action) => {
    switch (action.type) {
        case 'BUTTONS_VISIBLE':
            return {
                ...state,
                buttonsVisible: true,
            };
        case 'BUTTONS_INVISIBLE':
            return {
                ...state,
                buttonsVisible: false,
            };
        default:
            return state;
    }
};

export default visibility;
