import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { learningLevelActions } from '../actions/learningLevel.actions';
import { retrieveDecryptedSiteFromLocalStorage } from '../../utils/common';

const useLearningLevels = () => {
    const dispatch = useDispatch();
    const site = retrieveDecryptedSiteFromLocalStorage();

    useEffect(() => {
        if (site) dispatch(learningLevelActions.fetchLearningLevels());
        else dispatch(learningLevelActions.fetchLearningLevels());
    }, []);

    const learningLevels = useSelector(
        state => state.learningLevels.learningLevels,
    );

    return learningLevels;
};

export default useLearningLevels;
