import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { BackTop, Layout } from 'antd';
import Echo from 'laravel-echo';
import Socketio from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import Navigation from './Navigation/Navigation';
import SiteFooter from './Footer/SiteFooter';
import MobileNavigation from './Navigation/MobileNavigation';
import StickyNavBar from './Navigation/StickyNavBar';
import HeaderMenuDesktop from './HeaderMenuDesktop';
import HeaderMenuMobile from './HeaderMenuMobile';
import notificationsActions from '../../redux/actions/notification.actions';
import LearnerDashboardPage from '../../pages/LearnerDashboardPage';
import { retrieveDecryptedUserFromLocalStorage } from '../../utils/common';

const { Header, Content } = Layout;

const SiteLayout = props => {
    const handleOnIdle = event => {
    };

    const handleOnActive = event => {
     
    };

    const handleOnAction = e => {
    };

    const { getLastActiveTime, getRemainingTime } = useIdleTimer({
        timeout: 1000 * 3,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500,
    });

    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const downSize = useMediaQuery({ maxWidth: 1297 });
    const { component: Component, ...rest } = props;
    const { history, user } = props;
    const notifications = useSelector(
        state => state.notifications.notifications,
    );
    const [unreadCount, setUnreadCount] = useState(0);
    const [notificationsList, setNotificationsList] = useState([]);

    const getContentPadding = () => {
        return isMobile ? '10px 10px' : '50px 50px';
    };

    const userType = retrieveDecryptedUserFromLocalStorage().role;

    return userType === 'learner' ? (
        <LearnerDashboardPage {...props} component={Component} />
    ) : (
        <Layout
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header
                style={{
                    padding: '10px 0px',
                    height: downSize ? '86px' : 'max-content',
                    background: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20
                    // justifyContent: 'center',
                    // height: 'max-content',
                }}
            >
                {isMobile ? (
                    <HeaderMenuMobile
                        user={user}
                        notifications={notificationsList}
                        unreadCount={unreadCount}
                        history={props.history}
                    />
                ) : (
                    <HeaderMenuDesktop
                        user={user}
                        notifications={notificationsList}
                        unreadCount={unreadCount}
                        history={props.history}
                    />
                )}
                {!isMobile && !downSize && user && <Navigation user={user} />}
            </Header>
            {/* {downSize && !isMobile && user && <MobileNavigation user={user} />} */}
            {(downSize || isMobile) && user && <MobileNavigation user={user} />}
            <div
                style={{
                    backgroundColor: '#f1f5f9',
                    display: isMobile ? 'block' : 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: '100%',
                }}
            >
                <Content
                    style={{
                        padding: getContentPadding(),
                        background: '#f1f5f9',
                        maxWidth: '100%',
                        margin: '0 auto',
                    }}
                >
                    <Component {...rest} />
                </Content>
            </div>
            {/* {isMobile && user.role !== 'learner' && (
                <MobileNavigation user={user} />
            )}
            <BackTop /> */}
            <SiteFooter
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            />
            {isMobile && user.role === 'learner' && (
                <StickyNavBar user={user} />
            )}
        </Layout>
    );
};

export default SiteLayout;
