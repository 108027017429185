import { learningTypeActions } from '../actions/learningType.actions';
import learningTypesConstants from '../constants/learningTypes.constants';

const initialState = {
    loading: false,
    learningTypes: [],
    learningType:{},
    fetchLoading:false,
};

const learningTypes = (state = initialState, action) => {
    switch (action.type) {
        case learningTypesConstants.FETCH_LEARNING_TYPES_REQUEST:
        case learningTypesConstants.FETCH_LEARNING_TYPE_REQUEST:
            return{
                ...state,
               fetchLoading: true,
                learningType:{}
            }
            case learningTypesConstants.FETCH_LEARNING_TYPE_SUCCESS:
                return{
                    ...state,
                    fetchLoading: false,
                    learningType:action.payload


                }
        case learningTypesConstants.CLEARDATA :
            return{
                ...state,
                learningType:{}
            }
        case learningTypesConstants.FETCH_LEARNING_TYPES_PAGINATED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learningTypesConstants.FETCH_LEARNING_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                learningTypes: action.payload.data,
            };
        case learningTypesConstants.FETCH_LEARNING_TYPES_PAGINATED_SUCCESS:
            return {
                ...state,
                loading: false,
                learningTypes: action.payload,
            };
        case learningTypesConstants.UPDATE_LEARNING_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learningTypesConstants.UPDATE_LEARNING_TYPE_SUCCESS:

        case learningTypesConstants.UPDATE_LEARNING_TYPE_REJECTED:
            
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default learningTypes;
