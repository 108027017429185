import React, { useEffect, useState } from 'react';
import {
    Col,
    Divider,
    Modal,
    notification,
    Row,
    Switch,
    Table,
    Tag
} from 'antd';
import { useParams } from 'react-router-dom';
import { axiosDefaultClient } from '../settings/axios.settings';

const CustomerPackages = () => {
    const [packages, setPackages] = useState([]);
    const [metaData, setMetaData] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingPackages, setLoadingPackages] = useState(false);
    const [deletedPackage, setDeletedPackage] = useState(false);
    let { customerId } = useParams();

    const getCustomerPackages = () => {
        setLoadingPackages(true);
        axiosDefaultClient
            .get(`/api/customer/${customerId}/scorm`)
            .then(response => {
                setPackages(response.data?.data);
                setMetaData(response.data.meta);
            })
            .catch(e => {
                notification.error({ message: e.data?.message });
            })
            .finally(() => {
                setLoadingPackages(false);
            });
    };

    useEffect(() => {
        getCustomerPackages();
    }, [customerId]);

    const deletePackage = () => {
        setLoadingDelete(true);
        axiosDefaultClient
            .delete(`/api/customer/scorm/${deletedPackage.id}`)
            .then(response => {
                setOpenDelete(false);
                notification.success({
                    message: 'customer deleted successfully',
                });
                getCustomerPackages();
                setDeletedPackage(null);
            })
            .catch(e => {
                console.log('eeeee', e);
                notification.error({ message: e.data?.message ?? 'error' });
            })
            .finally(() => {
                setLoadingDelete(false);
            });
    };

    const switchStatus = (record, checked) => {
        axiosDefaultClient
            .put(`/api/customer/scorm/${record.id}`, {
                status: checked,
            })
            .then(() => {
                getCustomerPackages();
            })
            .catch(e => {
                console.log('eeeee', e);
                notification.error({ message: e.data?.message ?? 'error' });
            });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '33.33%',
        },
        {
            title: 'Status',
            key: 'status',
            width: '33.33%',
            render: (_, record) =>
                record.status ? (
                    <Tag color="green">active</Tag>
                ) : (
                    <Tag color="red">not active</Tag>
                ),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: '33.33%',
            render: (_, record) => (
                <Row>
                    <Switch
                        checkedChildren="active"
                        unCheckedChildren="not active"
                        checked={record.status}
                        onChange={checked => switchStatus(record, checked)}
                    />
                    <Divider type="vertical" />
                    <a
                        onClick={() => {
                            setDeletedPackage(record);
                            setOpenDelete(true);
                        }}
                    >
                        delete
                    </a>
                </Row>
            ),
        },
    ];

    return (
        <Row justify="center">
            <Col span={22}>
                <Table
                    style={{ width: '100%' }}
                    pagination={false}
                    dataSource={packages}
                    columns={columns}
                    loading={loadingPackages}
                />
            </Col>
            <Modal
                onOk={deletePackage}
                onCancel={() => setOpenDelete(false)}
                visible={openDelete}
                confirmLoading={loadingDelete}
                okButtonProps={{ disabled: loadingDelete }}
            >
                <p>Are you sure want to delete the customer</p>
            </Modal>
        </Row>
    );
};

export default CustomerPackages;
