/* eslint-disable prettier/prettier */
import { learningLevelsConstants } from '../constants/learningLevels.constants';

const initialState = {
    loading: false,
    learningLevels: [],
};

// eslint-disable-next-line import/prefer-default-export
export function learningLevels(state = initialState, action) {
    switch (action.type) {
        case learningLevelsConstants.FETCH_LEARNING_LEVELS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learningLevelsConstants.FETCH_LEARNING_LEVELS_SUCCESS:
            return {
                ...state,
                loading: false,
                learningLevels: action.payload.data,
            };
        default:
            return state;
    }
}
