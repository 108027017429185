const learnerFeedbackConstants = {
    // GET Copies For Learner
    GET_COPIES_FOR_LEARNER_REQUEST: 'GET_COPIES_FOR_LEARNER_REQUEST',
    GET_COPIES_FOR_LEARNER_SUCCESS: 'GET_COPIES_FOR_LEARNER_SUCCESS',
    GET_COPIES_FOR_LEARNER_REJECTED: 'GET_COPIES_FOR_LEARNER_REJECTED',

    // GET Copy Details For Learner
    GET_COPY_DETAILS_FOR_LEARNER_REQUEST: 'GET_COPY_DETAILS_FOR_LEARNER_REQUEST',
    GET_COPY_DETAILS_FOR_LEARNER_SUCCESS: 'GET_COPY_DETAILS_FOR_LEARNER_SUCCESS',
    GET_COPY_DETAILS_FOR_LEARNER_REJECTED: 'GET_COPY_DETAILS_FOR_LEARNER_REJECTED',

    // POST Add Learner Comments
    ADD_LEARNER_COMMENTS_REQUEST: 'ADD_LEARNER_COMMENTS_REQUEST',
    ADD_LEARNER_COMMENTS_SUCCESS: 'ADD_LEARNER_COMMENTS_SUCCESS',
    ADD_LEARNER_COMMENTS_REJECTED: 'ADD_LEARNER_COMMENTS_REJECTED',

    // Generate PDF Report
    GENERATE_PDF_REPORT_REQUEST: 'GENERATE_PDF_REPORT_REQUEST',
    GENERATE_PDF_REPORT_SUCCESS: 'GENERATE_PDF_REPORT_SUCCESS',
    GENERATE_PDF_REPORT_REJECTED: 'GENERATE_PDF_REPORT_REJECTED',
}

export default learnerFeedbackConstants
