import { combineReducers } from 'redux';

import authentication from './authentication.reducer';
import { activities } from './activities.reducer';
import { feedbacks } from './feedback.reducer';
import { feedbackTrainerProgressReducer } from './feedbackTrainerProgress.reducers';
import { learnerFeedbackReducer } from './learnerFeedback.reducer'
import { firstLoginData } from './firstLoginReducer';
import { learningLevels } from './learningLevels.reducer';
import { learningFocuses } from './learningFocuses.reducer';
import { activityBuilder } from './activityBuilder.reducer';
import { wishlist } from './wishlist.reducer';
import { formBuilder } from './formBuilder.reducer';
import { library } from './library.reducer';
import { onboardingPreferences } from './onboardingPreferences.reducer';
import sharedFiles from './sharedFiles.reducer';
import learningPathways from './learningPathways.reducer';
import libraryBundles from './libraryBundles.reducer';
import modules from './modules.reducer';
import users from './users.reducer';
import search from './search.reducer';
import activityMarking from './activityMarking.reducer';
import learningTypes from './learningTypes.reducer';
import languageLevelTypes from './languageLevelTypes.reducer';
import sites from './sites.reducer';
import courseBundles from './courseBundles.reducer';
import courses from './courses.reducer';
import notifications from './notifications.reducer';
import visibility from './visibility.reducer';
// import questionnaires from './questionnaires.reducer';
import {questionnaires} from "./Questionnaires/questionnaire.reducer";
import userGroups from './userGroups.reducer';
import permissions from './permissions.reducer';
import colorPicker from './colorPicker.reducer';
import userReporting from './user.reporting.reducer';
import userGroupReporting from './userGroup.reporting.reducer';
import auditLogs from './auditLogs.reducer';
import tracking from './tracking.reducer';
import savedContent from './savedContent.reducer';

import formats from './formateData.reducer';
import csv_options from './csv.reducer';
import dateFormats from './dateFormate.reducer';
import {
    learnerDashboardPathways,
    learnerDashboardModules,
    learnerDashboardLibrary,
    learnerDashboardCourses,
} from './learnerDashboard.reducer';
import { learnerPathwaysProgress } from './learnerProgress.reducer';
import { languagesContent } from './languages.reducer';
import {
    allCountriesApi,
    updateProfileInfo,
    updateLanguageInterface,
    updateLearnerSkills,
    updateLearnerTarget,
    updateLearnerPassword,
    allInterfaceLanguages,
    fetchAllSettings,
    fetchAllAvatars,
    updatedProfileImage,
    fetchProfileInfo,
} from './learnerProfile.reducer';
import {
    fetchAllDbPictures,
    deleteDbPictures,
    uploadDbPictures,
    selectDbPictures,
} from './picturesDb.reducer';

import messages from './messages.reducer'
import {FeedbackBuilder} from "./Feedback/FeedbcakBuilder.reducer";
import contacts from "./Contacts/contacts.reducer";
import contactGroups from "./ContactGroups/contactGroups.reducer";
import utils from "./UtilsState/utils.reducer";

const rootReducer = combineReducers({
    utils,
    contactGroups,
    contacts,
    FeedbackBuilder,
    savedContent,
    authentication,
    activities,
    feedbacks,
    feedbackTrainerProgressReducer,
    learnerFeedbackReducer,
    firstLoginData,
    modules,
    learningTypes,
    learningLevels,
    learningFocuses,
    library,
    activityBuilder,
    formBuilder,
    wishlist,
    search,
    sharedFiles,
    onboardingPreferences,
    activityMarking,
    learningPathways,
    libraryBundles,
    languageLevelTypes,
    sites,
    courseBundles,
    courses,
    users,
    notifications,
    visibility,
    questionnaires,
    userGroups,
    permissions,
    colorPicker,
    userReporting,
    userGroupReporting,
    formats,
    csv_options,
    dateFormats,
    learnerDashboardPathways,
    learnerDashboardModules,
    learnerDashboardLibrary,
    learnerDashboardCourses,
    languagesContent,
    auditLogs,
    tracking,
    learnerPathwaysProgress,
    allCountriesApi,
    updateProfileInfo,
    updateLanguageInterface,
    updateLearnerSkills,
    updateLearnerTarget,
    updateLearnerPassword,
    allInterfaceLanguages,
    fetchAllSettings,
    fetchAllAvatars,
    updatedProfileImage,
    fetchProfileInfo,
    fetchAllDbPictures,
    deleteDbPictures,
    uploadDbPictures,
    selectDbPictures,
    messages
});

export default rootReducer;
