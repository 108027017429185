export const feedbackTrainerProgressConstants = {
    GET_FEEDBACK_COPIES_REQUEST: 'GET_FEEDBACK_COPIES_REQUEST',
    GET_FEEDBACK_COPIES_SUCCESS: 'GET_FEEDBACK_COPIES_SUCCESS',
    GET_FEEDBACK_COPIES_REJECTED: 'GET_FEEDBACK_COPIES_REJECTED',

    GET_ARCHIVED_FEEDBACK_COPIES_REQUEST: 'GET_ARCHIVED_FEEDBACK_COPIES_REQUEST',
    GET_ARCHIVED_FEEDBACK_COPIES_SUCCESS: 'GET_GET_ARCHIVED_FEEDBACK_COPIES_SUCCESS',
    GET_ARCHIVED_FEEDBACK_COPIES_REJECTED: 'GET_GET_ARCHIVED_FEEDBACK_COPIES_REJECTED',

    GET_TRAINER_LEARNERS_REQUEST: 'GET_TRAINER_LEARNERS_REQUEST',
    GET_TRAINER_LEARNERS_SUCCESS: 'GET_TRAINER_LEARNERS_SUCCESS',
    GET_TRAINER_LEARNERS_REJECTED: 'GET_TRAINER_LEARNERS_REJECTED',

    GET_TRAINER_LEARNER_GROUP_REQUEST: 'GET_TRAINER_LEARNER_GROUP_REQUEST',
    GET_TRAINER_LEARNER_GROUP_SUCCESS: 'GET_TRAINER_LEARNER_GROUP_SUCCESS',
    GET_TRAINER_LEARNER_GROUP_REJECTED: 'GET_TRAINER_LEARNER_GROUP_REJECTED',

    GET_COPY_FEEDBACK_INFORMATION_REQUEST: 'GET_COPY_FEEDBACK_INFORMATION_REQUEST',
    GET_COPY_FEEDBACK_INFORMATION_SUCCESS: 'GET_COPY_FEEDBACK_INFORMATION_SUCCESS',
    GET_COPY_FEEDBACK_INFORMATION_REJECTED: 'GET_COPY_FEEDBACK_INFORMATION_REJECTED',

    GET_COPY_FEEDBACK_QUESTIONS_REQUEST: 'GET_COPY_QUESTIONS_REQUEST',
    GET_COPY_FEEDBACK_QUESTIONS_SUCCESS: 'GET_COPY_QUESTIONS_SUCCESS',
    GET_COPY_FEEDBACK_QUESTIONS_REJECTED: 'GET_COPY_QUESTIONS_REJECTED',

    GET_ASSIGNED_ITEM_FOR_COPY_REQUEST: 'GET_ASSIGNED_ITEM_FOR_COPY_REQUEST',
    GET_ASSIGNED_ITEM_FOR_COPY_SUCCESS: 'GET_ASSIGNED_ITEM_FOR_COPY_SUCCESS',
    GET_ASSIGNED_ITEM_FOR_COPY_REJECTED: 'GET_ASSIGNED_ITEM_FOR_COPY_REJECTED',

    GET_COPY_LEARNER_LIST_REQUEST: 'GET_COPY_LEARNER_LIST_REQUEST',
    GET_COPY_LEARNER_LIST_SUCCESS: 'GET_COPY_LEARNER_LIST_SUCCESS',
    GET_COPY_LEARNER_LIST_REJECTED: 'GET_COPY_LEARNER_LIST_REJECTED',

    GET_COPY_LEARNER_REQUEST: 'GET_COPY_LEARNER_REQUEST',
    GET_COPY_LEARNER_SUCCESS: 'GET_COPY_LEARNER_SUCCESS',
    GET_COPY_LEARNER_REJECTED: 'GET_COPY_LEARNER_REJECTED',

    ARCHIVE_COPY_FEEDBACK_REQUEST: 'ARCHIVE_COPY_FEEDBACK_REQUEST',
    ARCHIVE_COPY_FEEDBACK_SUCCESS: 'ARCHIVE_COPY_FEEDBACK_SUCCESS',
    ARCHIVE_COPY_FEEDBACK_REJECTED: 'ARCHIVE_COPY_FEEDBACK_REJECTED',

    ALLOCATE_LEARNERS_OR_LEARNER_GROUPS_REQUEST: 'ALLOCATE_LEARNERS_OR_LEARNER_GROUPS_REQUEST',
    ALLOCATE_LEARNERS_OR_LEARNER_GROUPS_SUCCESS: 'ALLOCATE_LEARNERS_OR_LEARNER_GROUPS_SUCCESS',
    ALLOCATE_LEARNERS_OR_LEARNER_GROUPS_REJECTED: 'ALLOCATE_LEARNERS_OR_LEARNER_GROUPS_REJECTED',

    STORE_TRAINER_ANSWER_REQUEST: 'STORE_TRAINER_ANSWER_REQUEST',
    STORE_TRAINER_ANSWER_SUCCESS: 'STORE_TRAINER_ANSWER_SUCCESS',
    STORE_TRAINER_ANSWER_REJECTED: 'STORE_TRAINER_ANSWER_REJECTED',

    DELETE_COPY_FEEDBACK_REQUEST: 'DELETE_COPY_FEEDBACK_REQUEST',
    DELETE_COPY_FEEDBACK_SUCCESS: 'DELETE_COPY_FEEDBACK_SUCCESS',
    DELETE_COPY_FEEDBACK_REJECTED: 'DELETE_COPY_FEEDBACK_REJECTED',


    GET_ORIGINAL_FEEDBACK_INFO_WHEN_CLICK_ALLOCATE_ICON: 'GET_ORIGINAL_FEEDBACK_INFO_WHEN_CLICK_ALLOCATE_ICON',
    STORE_TRAINER_FEEDBACK_ANSWERS: 'STORE_TRAINER_FEEDBACK_ANSWERS',
    CHANGE_CURRENT_STATUS: 'CHANGE_CURRENT_STATUS',
    RESTORE_TO_INITIAL: 'RESTORE_TO_INITIAL',
    ANSWER_FOR_WHOLE_GROUP: 'ANSWER_FOR_WHOLE_GROUP',
    ARRAY_OF_SAME_QUESTION_BASED_ON_LEARNER_LIST_LENGTH: 'ARRAY_OF_SAME_QUESTION_BASED_ON_LEARNER_LIST_LENGTH',
    WHOLE_GROUP: 'WHOLE_GROUP'

}
