import {sharedFilesConstants} from '../constants/sharedFiles.constants';

const initialState = {
    files: [],
    folderChain: [],
    sharedFilesLoading: false,
    directoryNameModalVisible: false,
    fileViewModalVisible: false,
    lastUploadedPath: '',
    fileLoading: false,
    tempActivityID: null,
    specificTempID: null,
};

const sharedFiles = (state = initialState, action) => {
    switch (action.type) {
        case sharedFilesConstants.SHARED_FILES_REQUEST:
            return {
                ...state,
                sharedFilesLoading: true,
            };
        case sharedFilesConstants.SHARED_FILES_SUCCESS:
            const files = [];
            const actionFiles = action.files;
            if (action.files) {
                actionFiles.map(file => {
                    const newFile = file;
                    newFile.modDate = new Date(file.modDate);
                    files.push(newFile);
                });
            }
            return {
                ...state,
                files: actionFiles ? files : state.files,
                folderChain: action.folderChain
                    ? action.folderChain
                    : state.folderChain,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_REJECTED:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_FILE_UPLOAD_REQUEST:
            return {
                ...state,
                sharedFilesLoading: true,
            };
        case sharedFilesConstants.SHARED_FILES_FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                lastUploadedPath: action.file,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_FILE_UPLOAD_REJECTED:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_FILE_DELETE_REQUEST:
            return {
                ...state,
                sharedFilesLoading: true,
            };
        case sharedFilesConstants.SHARED_FILES_FILE_DELETE_SUCCESS:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_FILE_DELETE_REJECTED:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_CREATE_FOLDER_REQUEST:
            return {
                ...state,
                sharedFilesLoading: true,
            };
        case sharedFilesConstants.SHARED_FILES_CREATE_FOLDER_SUCCESS:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_CREATE_FOLDER_REJECTED:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_DUPLICATE_REQUEST:
            return {
                ...state,
                sharedFilesLoading: true,
            };
        case sharedFilesConstants.SHARED_FILES_DUPLICATE_SUCCESS:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_DUPLICATE_REJECTED:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_RENAME_REQUEST:
            return {
                ...state,
                sharedFilesLoading: true,
            };
        case sharedFilesConstants.SHARED_FILES_RENAME_SUCCESS:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_RENAME_REJECTED:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_MOVE_REQUEST:
            return {
                ...state,
                sharedFilesLoading: true,
            };
        case sharedFilesConstants.SHARED_FILES_MOVE_SUCCESS:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_MOVE_REJECTED:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_DOWNLOAD_FILE_REQUEST:
            return {
                ...state,
                sharedFilesLoading: true,
            };
        case sharedFilesConstants.SHARED_FILES_DOWNLOAD_FILE_SUCCESS:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_DOWNLOAD_FILE_REJECTED:
            return {
                ...state,
                sharedFilesLoading: false,
            };
        case sharedFilesConstants.SHARED_FILES_DIRECTORY_NAME_MODAL_OPEN:
            return {
                ...state,
                directoryNameModalVisible: true,
            };
        case sharedFilesConstants.SHARED_FILES_DIRECTORY_NAME_MODAL_CLOSE:
            return {
                ...state,
                directoryNameModalVisible: false,
            };
        case sharedFilesConstants.SHARED_FILES_FILE_OPEN:
            return {
                ...state,
                fileViewModalVisible: true,
            };
        case sharedFilesConstants.SHARED_FILES_FILE_CLOSE:
            return {
                ...state,
                fileViewModalVisible: false,
            };
        case sharedFilesConstants.SHARED_FILES_FILE_LOADING:
            return {
                ...state,
                fileLoading: true,
            };
        case sharedFilesConstants.SHARED_FILES_FILE_LOADED:
            return {
                ...state,
                fileLoading: false,
            };
        case sharedFilesConstants.SET_TEMP_ACTIVITY_ID:
            return {
                ...state,
                tempActivityID: action.tempActivityID,
            };
        case sharedFilesConstants.SET_SPECIFIC_TEMP_ID:
            return {
                ...state,
                specificTempID: action.specificTempID,
            };
        case sharedFilesConstants.CLEAR_SHARE_FILE:
            return {
                files: [],
                folderChain: [],
                sharedFilesLoading: false,
                directoryNameModalVisible: false,
                fileViewModalVisible: false,
                lastUploadedPath: '',
                fileLoading: false,
                tempActivityID: null,
                specificTempID: null,
            };
        default:
            return state;
    }
};

export default sharedFiles;
