import * as Sentry from "@sentry/react";
import { VITE_SENTRY_ENV } from './constants/apiConstants';

Sentry.init({
    dsn: 'https://83ff6ffa194838959028fadfe458db33@o4507407114895360.ingest.de.sentry.io/4507412424622160',
    environment: VITE_SENTRY_ENV || 'local',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    beforeSend(event, hint) {
      // Check if the event is a warning
      if (event.level === 'warning') {
        // Discard the warning event
        return null;
      }
      // Allow other events to be sent
      return event;
    },
  
    // Performance Monitoring
    tracesSampleRate: 0.5, //  Capture 50% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^\//],
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 5%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
