/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { ApiBaseURL } from '../../constants/apiConstants';
import Loader from './../Shared/Loader/Loader';
import { storeEncryptedSiteInLocalStorage } from '../../utils/common';
const SiteInfoProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    // Global  language function
    // return the value for key from language
    // if key not fount retun the key with replace _ and Capitalize
    window['__lang'] = function (word) {
        const language = JSON.parse(localStorage.getItem('language'));
        const titleCase = (str) => {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                // You do not need to check if i is larger than splitStr length, as your for does that for you
                // Assign it back to the array
                splitStr[i] =
                    (i == 0
                        ? splitStr[i].charAt(0).toUpperCase()
                        : splitStr[i].charAt(0)) + splitStr[i].substring(1);
            }
            // Directly return the joined string
            return splitStr.join(' ');
        };
        if (language) {
            if (language['' + word]) {
                return language['' + word];
            }
        }
        return titleCase(word.replaceAll('_', ' '));
    };

    const storeSiteIdFromURL = () => {
        const params = new URLSearchParams(window.location.search);
        const siteId = params.get('site_id');
        if (siteId) {
            localStorage.setItem('site_url', siteId);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                storeSiteIdFromURL();
                const siteUrl = window.location.host.indexOf('localhost') > -1 || localStorage.getItem('site_url') ? localStorage.getItem('site_url') : window.location.host;
                const response = await fetch(
                    `${ApiBaseURL}/sites/info?url=${siteUrl}`,
                );
                const result = await response.json();
                // Store the result in local storage
                storeEncryptedSiteInLocalStorage(result);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            {loading ? (
                <div
                    style={{
                        height: '100vh',
                        paddingTop: '300px',
                        backgroundColor: '#f0f2f5',
                    }}
                >
                    <Loader />
                </div>
            ) : (
                children
            )}
        </div>
    );
};
export default SiteInfoProvider;
