import React from 'react';

const checkedSvg = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.598"
        height="16.598"
        viewBox="0 0 16.598 16.598"
        className="learner-svg-icons"
    >
        <path
            className="fill-primary"
            id="Icon_awesome-check-circle"
            data-name="Icon awesome-check-circle"
            d="M17.161,8.862a8.3,8.3,0,1,1-8.3-8.3A8.3,8.3,0,0,1,17.161,8.862ZM7.9,13.256,14.059,7.1a.535.535,0,0,0,0-.757L13.3,5.584a.535.535,0,0,0-.757,0L7.523,10.606,5.179,8.261a.535.535,0,0,0-.757,0l-.757.757a.535.535,0,0,0,0,.757l3.48,3.48a.535.535,0,0,0,.757,0Z"
            transform="translate(-0.563 -0.563)"
            fill="#02a1fa"
        />
    </svg>
);

const defaultAvatar = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="102"
        height="102"
        viewBox="0 0 102 102"
    >
        <g id="Asset_1" data-name="Asset 1">
            <g id="Layer_2" data-name="Layer 2">
                <g id="connected_people" data-name="connected people">
                    <g id="Group_13247" data-name="Group 13247">
                        <circle
                            id="Ellipse_95"
                            data-name="Ellipse 95"
                            cx="51"
                            cy="51"
                            r="51"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
            <g
                id="user-5"
                transform="translate(-19.925 15)"
                className="learner-svg-icons"
            >
                <path
                    className="fill-primary"
                    id="Path_6218"
                    data-name="Path 6218"
                    d="M100.369,218.944v.025a43.246,43.246,0,0,1-58.444,0v-.025A29.491,29.491,0,0,1,58.6,192.32c3.706,3.728,8.045,5.737,12.546,5.737s8.841-2.009,12.548-5.737A29.487,29.487,0,0,1,100.369,218.944Z"
                    transform="translate(0 -155.211)"
                    fill="#1aaafa"
                />
                <path
                    id="Path_6219"
                    data-name="Path 6219"
                    d="M126.237,0a16.178,16.178,0,0,1,16.052,16.3c0,9-7.187,21.32-16.052,21.32S110.185,25.306,110.185,16.3A16.178,16.178,0,0,1,126.237,0Z"
                    transform="translate(-55.089)"
                    fill="#fcd09f"
                />
            </g>
        </g>
    </svg>
);

const myProfile = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12.818"
        height="19.312"
        viewBox="0 0 12.818 19.312"
    >
        <g id="man-4" transform="translate(0 0)" className="learner-svg-icons">
            <path
                id="Path_3127"
                data-name="Path 3127"
                d="M90.846,17.345v1.634a.333.333,0,0,1-.333.333H78.361a.333.333,0,0,1-.333-.333V17.345a3.1,3.1,0,0,1,3.1-3.1,1.723,1.723,0,0,0,1.447-.785,1.956,1.956,0,0,0,.277-1.22l.006-.015a4.12,4.12,0,0,1-2.545-3.808v-.13a1.026,1.026,0,0,1-1.04-1.021V5.533a5.76,5.76,0,0,1,1.548-4.2C82.608-.391,85.435-.25,87.482.67a.083.083,0,0,1,0,.153l-.534.22a.083.083,0,0,0,.023.16l.259.028a4.2,4.2,0,0,1,3.11,1.929.089.089,0,0,1-.011.1,2.518,2.518,0,0,0-.687,1.73V7.262a1.021,1.021,0,0,1-1.021,1.021h-.061v.13a4.12,4.12,0,0,1-2.545,3.808l.006.015a1.955,1.955,0,0,0,.277,1.22,1.723,1.723,0,0,0,1.447.785A3.1,3.1,0,0,1,90.846,17.345Z"
                transform="translate(-78.028 0)"
                fill="#ffdfba"
            />
            <path
                className="fill-primary"
                id="Path_3128"
                data-name="Path 3128"
                d="M90.846,327.254v1.634a.333.333,0,0,1-.333.333H78.361a.333.333,0,0,1-.333-.333v-1.634a3.1,3.1,0,0,1,3.1-3.1,1.723,1.723,0,0,0,1.447-.785,2.857,2.857,0,0,0,3.716,0,1.723,1.723,0,0,0,1.447.785A3.1,3.1,0,0,1,90.846,327.254Z"
                transform="translate(-78.028 -309.909)"
                fill="#02a1fa"
            />
            <path
                id="Path_3129"
                data-name="Path 3129"
                d="M119.049,3.16a.088.088,0,0,1-.011.1,2.519,2.519,0,0,0-.687,1.73V7.263a1.023,1.023,0,0,1-1.082,1.021V5.714a2,2,0,0,0-2-2h-4.245a2,2,0,0,0-2,2V8.284a1.026,1.026,0,0,1-1.04-1.021V5.534a5.76,5.76,0,0,1,1.548-4.2c1.784-1.719,4.61-1.578,6.658-.659a.083.083,0,0,1,0,.153l-.534.22a.083.083,0,0,0,.023.16l.259.028a4.2,4.2,0,0,1,3.109,1.929Z"
                transform="translate(-106.74 0)"
                fill="#42434d"
            />
            <path
                id="Path_3130"
                data-name="Path 3130"
                d="M141.268,93.984a4.121,4.121,0,1,1-8.24-.13V91.284a2,2,0,0,1,2-2h4.245a2,2,0,0,1,2,2Z"
                transform="translate(-130.739 -85.57)"
                fill="#ffebd2"
            />
            <path
                id="Path_3131"
                data-name="Path 3131"
                d="M282.36,431.722h-1a.333.333,0,0,1,0-.666h1a.333.333,0,0,1,0,.666Z"
                transform="translate(-272.58 -413.117)"
                fill="#fff"
            />
            <path
                id="Path_3132"
                data-name="Path 3132"
                d="M136.215,111.646a.658.658,0,0,1-.6,1.165,4.121,4.121,0,0,1-2.589-3.826v-2.7a1.989,1.989,0,0,1,.35-1.131.492.492,0,0,1,.9.3v2.7A4.09,4.09,0,0,0,136.215,111.646Z"
                transform="translate(-130.739 -100.572)"
                fill="#fff3e4"
            />
            <path
                id="Path_3133"
                data-name="Path 3133"
                d="M196.155,294.385a4.131,4.131,0,0,1-3.257,0,2.578,2.578,0,0,0,.047-.683l.006-.015a4.128,4.128,0,0,0,3.15,0l.006.015A2.578,2.578,0,0,0,196.155,294.385Z"
                transform="translate(-188.118 -281.464)"
                fill="#ffd6a6"
            />
            <path
                className="fill-primary"
                id="Path_3134"
                data-name="Path 3134"
                d="M82.809,325.156a1.692,1.692,0,0,1-.5.077,3.112,3.112,0,0,0-3.12,3.093v.313a.583.583,0,0,1-.583.583h-.25a.333.333,0,0,1-.333-.333v-1.634a3.1,3.1,0,0,1,3.1-3.1,1.723,1.723,0,0,0,1.447-.785,2.877,2.877,0,0,0,.4.284A.831.831,0,0,1,82.809,325.156Z"
                transform="translate(-78.028 -309.909)"
                fill="#1299e5"
            />
            <path
                className="fill-primary"
                id="Path_3135"
                data-name="Path 3135"
                d="M257.934,325.156a1.692,1.692,0,0,0,.5.077,3.112,3.112,0,0,1,3.12,3.093v.313a.583.583,0,0,0,.583.583h.25a.333.333,0,0,0,.333-.333v-1.634a3.1,3.1,0,0,0-3.1-3.1,1.723,1.723,0,0,1-1.447-.785,2.872,2.872,0,0,1-.4.284A.831.831,0,0,0,257.934,325.156Z"
                transform="translate(-249.898 -309.909)"
                fill="#1299e5"
            />
            <path
                id="Path_3136"
                data-name="Path 3136"
                d="M116.192.823c-.489.2-.559.246-.729.219-2.733-.424-5.131.707-5.945,3.362h0a1.988,1.988,0,0,0-.489,1.308V7.275a.52.52,0,1,1-1.04-.012V5.533a5.76,5.76,0,0,1,1.548-4.2c1.784-1.719,4.61-1.578,6.658-.659a.083.083,0,0,1,0,.153Z"
                transform="translate(-106.74 0)"
                fill="#4d4e59"
            />
        </g>
    </svg>
);

const settingsIcon = (
    <svg
        id="settings"
        xmlns="http://www.w3.org/2000/svg"
        width="19.326"
        height="19.326"
        viewBox="0 0 19.326 19.326"
        className="learner-svg-icons"
    >
        <path
            className="fill-primary"
            id="Path_3137"
            data-name="Path 3137"
            d="M10.933,6.631a4.555,4.555,0,0,0,0-1.359l.77-.555a.511.511,0,0,0,.159-.6l-.43-1.053a.516.516,0,0,0-.544-.306l-.94.147A4.791,4.791,0,0,0,9,1.943l.136-.929A.5.5,0,0,0,8.839.471L7.785.041a.511.511,0,0,0-.6.159L6.63.969A4.275,4.275,0,0,0,5.781.924a3.871,3.871,0,0,0-.51.045L4.717.2a.511.511,0,0,0-.6-.159L3.063.471a.525.525,0,0,0-.306.544l.147.94a4.789,4.789,0,0,0-.963.951l-.929-.147a.525.525,0,0,0-.544.306L.04,4.118a.511.511,0,0,0,.159.6l.77.555a4.555,4.555,0,0,0,0,1.359L.2,7.186a.511.511,0,0,0-.159.6L.47,8.84a.5.5,0,0,0,.544.294L1.954,9a4.789,4.789,0,0,0,.951.963l-.147.929a.525.525,0,0,0,.306.544l1.053.43a.507.507,0,0,0,.6-.159l.555-.77c.17.023.34.045.51.057a5.352,5.352,0,0,0,.849-.057l.555.77a.485.485,0,0,0,.6.159l1.053-.43a.5.5,0,0,0,.294-.544L9,9.949A5.1,5.1,0,0,0,9.96,9l.929.136a.5.5,0,0,0,.544-.294l.43-1.053a.511.511,0,0,0-.159-.6Zm-2.367.408A2.825,2.825,0,0,1,5.951,8.783a.863.863,0,0,1-.17-.011A2.824,2.824,0,0,1,3.335,4.865,2.884,2.884,0,0,1,4.864,3.336a2.582,2.582,0,0,1,.917-.215,2.836,2.836,0,0,1,1.257.215A2.885,2.885,0,0,1,8.567,4.865,2.787,2.787,0,0,1,8.567,7.039Z"
            transform="translate(0 -0.001)"
            fill="#17ace8"
        />
        <path
            className="fill-primary"
            id="Path_3138"
            data-name="Path 3138"
            d="M159.081,7.186a.511.511,0,0,1,.159.6l-.43,1.053a.5.5,0,0,1-.544.294L157.338,9a5.1,5.1,0,0,1-.962.951l.136.94a.5.5,0,0,1-.294.544l-1.053.43a.485.485,0,0,1-.6-.159l-.555-.77a5.356,5.356,0,0,1-.849.057V8.772a.863.863,0,0,0,.17.011,2.825,2.825,0,0,0,2.616-1.744,2.787,2.787,0,0,0,0-2.174,2.885,2.885,0,0,0-1.529-1.529,2.835,2.835,0,0,0-1.257-.215V.924a4.278,4.278,0,0,1,.849.045l.555-.77a.511.511,0,0,1,.6-.159l1.053.43a.5.5,0,0,1,.294.544l-.136.929a4.79,4.79,0,0,1,.951.963l.94-.147a.516.516,0,0,1,.544.306l.43,1.053a.511.511,0,0,1-.159.6l-.77.555a4.557,4.557,0,0,1,0,1.359Z"
            transform="translate(-147.378 -0.001)"
            fill="#1689fc"
        />
        <path
            className="fill-opacity-primary"
            id="Path_3139"
            data-name="Path 3139"
            d="M197.93,191.094l-.929-.226a5.051,5.051,0,0,0-.51-1.257l.487-.8a.5.5,0,0,0-.079-.623l-.793-.793a.5.5,0,0,0-.623-.079l-.8.487a5.051,5.051,0,0,0-1.257-.51l-.226-.928a.484.484,0,0,0-.487-.359h-1.132a.484.484,0,0,0-.487.359l-.226.928a5.052,5.052,0,0,0-1.257.51l-.8-.487a.5.5,0,0,0-.623.079l-.793.793a.5.5,0,0,0-.079.623l.487.8a5.051,5.051,0,0,0-.51,1.257l-.929.226a.484.484,0,0,0-.359.487v1.132a.484.484,0,0,0,.359.487l.929.226a5.052,5.052,0,0,0,.51,1.257l-.487.8a.5.5,0,0,0,.079.623l.793.793a.5.5,0,0,0,.623.079l.8-.487a5.047,5.047,0,0,0,1.257.51l.226.929a.5.5,0,0,0,.487.374h1.132a.5.5,0,0,0,.487-.374l.226-.929a5.048,5.048,0,0,0,1.257-.51l.8.487a.5.5,0,0,0,.623-.079l.793-.793a.5.5,0,0,0,.079-.623l-.487-.8a5.051,5.051,0,0,0,.51-1.257l.929-.226a.5.5,0,0,0,.374-.487v-1.132A.5.5,0,0,0,197.93,191.094Zm-5.786,3.884a2.831,2.831,0,1,1,2.831-2.831A2.832,2.832,0,0,1,192.144,194.978Z"
            transform="translate(-178.978 -178.981)"
            fill="#b7e0f6"
        />
        <path
            className="fill-opacity-primary"
            id="Path_3140"
            data-name="Path 3140"
            d="M354.96,191.58v1.132a.5.5,0,0,1-.374.487l-.929.226a5.049,5.049,0,0,1-.51,1.257l.487.8a.5.5,0,0,1-.079.623l-.793.793a.5.5,0,0,1-.623.079l-.8-.487a5.049,5.049,0,0,1-1.257.51l-.226.929a.5.5,0,0,1-.487.374H348.8v-3.329a2.831,2.831,0,1,0,0-5.662V186h.566a.484.484,0,0,1,.487.359l.226.928a5.052,5.052,0,0,1,1.257.51l.8-.487a.5.5,0,0,1,.623.079l.793.793a.5.5,0,0,1,.079.623l-.487.8a5.053,5.053,0,0,1,.51,1.257l.928.226A.5.5,0,0,1,354.96,191.58Z"
            transform="translate(-335.634 -178.98)"
            fill="#a4d9f5"
        />
    </svg>
);

const progressIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.623"
        height="13.053"
        viewBox="0 0 16.623 13.053"
    >
        <g id="rising" transform="translate(0 0)" className="learner-svg-icons">
            <path
                className="fill-primary"
                id="Path_3147"
                data-name="Path 3147"
                d="M2.147,406H.885a.885.885,0,0,0-.885.885v1.925a.885.885,0,0,0,.885.885H2.147a.885.885,0,0,0,.885-.885v-1.925A.885.885,0,0,0,2.147,406Zm0,0"
                transform="translate(0 -396.641)"
                fill="#02a1fa"
            />
            <path
                className="fill-primary"
                id="Path_3148"
                data-name="Path 3148"
                d="M1.728,409.694H.885A.885.885,0,0,1,0,408.81v-1.925A.885.885,0,0,1,.885,406h.843a.885.885,0,0,0-.885.885v1.925A.885.885,0,0,0,1.728,409.694Zm0,0"
                transform="translate(0 -396.641)"
                fill="#1299e5"
            />
            <path
                className="fill-primary"
                id="Path_3149"
                data-name="Path 3149"
                d="M132.127,344.465h-1.262a.885.885,0,0,0-.885.885v4.069a.885.885,0,0,0,.885.885h1.262a.885.885,0,0,0,.885-.885V345.35A.885.885,0,0,0,132.127,344.465Zm0,0"
                transform="translate(-125.45 -337.251)"
                fill="#02a1fa"
            />
            <path
                className="fill-primary"
                id="Path_3150"
                data-name="Path 3150"
                d="M131.7,350.3h-.843a.885.885,0,0,1-.885-.885V345.35a.885.885,0,0,1,.885-.885h.843a.885.885,0,0,0-.885.885v4.069A.885.885,0,0,0,131.7,350.3Zm0,0"
                transform="translate(-125.446 -337.251)"
                fill="#1299e5"
            />
            <path
                className="fill-primary"
                id="Path_3151"
                data-name="Path 3151"
                d="M262.108,269.109h-1.262a.885.885,0,0,0-.885.885v6.7a.885.885,0,0,0,.885.885h1.262a.885.885,0,0,0,.885-.885v-6.7A.885.885,0,0,0,262.108,269.109Zm0,0"
                transform="translate(-250.9 -264.522)"
                fill="#02a1fa"
            />
            <path
                className="fill-primary"
                id="Path_3152"
                data-name="Path 3152"
                d="M392.092,137.488H390.83a.885.885,0,0,0-.885.885v11.283a.885.885,0,0,0,.885.885h1.262a.885.885,0,0,0,.885-.885V138.373A.885.885,0,0,0,392.092,137.488Zm0,0"
                transform="translate(-376.354 -137.488)"
                fill="#02a1fa"
            />
            <path
                className="fill-primary"
                id="Path_3153"
                data-name="Path 3153"
                d="M261.689,277.575h-.843a.885.885,0,0,1-.885-.885v-6.7a.885.885,0,0,1,.885-.885h.843a.885.885,0,0,0-.885.885v6.7A.885.885,0,0,0,261.689,277.575Zm0,0"
                transform="translate(-250.9 -264.522)"
                fill="#1299e5"
            />
            <path
                className="fill-primary"
                id="Path_3154"
                data-name="Path 3154"
                d="M391.666,150.541h-.843a.885.885,0,0,1-.885-.885V138.373a.885.885,0,0,1,.885-.885h.843a.885.885,0,0,0-.885.885v11.283A.885.885,0,0,0,391.666,150.541Zm0,0"
                transform="translate(-376.347 -137.488)"
                fill="#1299e5"
            />
        </g>
    </svg>
);

const logoutIcon = (
    <svg
        id="ss3177407"
        xmlns="http://www.w3.org/2000/svg"
        width="16.768"
        height="16.768"
        viewBox="0 0 16.768 16.768"
    >
        <g id="_4192fdff" data-name="#4192fdff" className="learner-svg-icons">
            <path
                className="fill-primary"
                id="Path_3144"
                data-name="Path 3144"
                d="M5.951,0h.16a.318.318,0,0,1,.176.26c.006.438,0,.875,0,1.313,0,2.784,0,5.567,0,8.351.008,1.168-.008,2.336,0,3.5-.006.589-.005,1.179,0,1.768,0,.438,0,.875,0,1.312a.324.324,0,0,1-.172.26h-.16c-.539-.2-1.095-.365-1.64-.553L.207,14.86a.314.314,0,0,1-.207-.2V2.141A.278.278,0,0,1,.2,1.91Q2.964,1,5.726.086C5.8.062,5.877.032,5.951,0Z"
                fill="#02a1fa"
            />
            <path
                className="fill-primary"
                id="Path_3145"
                data-name="Path 3145"
                d="M253.593,176.176a.413.413,0,0,1,.237-.155.291.291,0,0,1,.249.105q1.039,1.041,2.079,2.08a3.009,3.009,0,0,1,.322.346v.166a2.749,2.749,0,0,1-.322.349q-1.04,1.04-2.08,2.08a.292.292,0,0,1-.249.106.348.348,0,0,1-.2-.123c-.234-.236-.469-.471-.7-.705a.269.269,0,0,1-.046-.384c.194-.212.414-.4.6-.617-.572-.007-1.144,0-1.715,0q-1.819,0-3.638,0a.263.263,0,0,1-.288-.228c-.021-.25,0-.5,0-.753a2.211,2.211,0,0,1,.016-.414.263.263,0,0,1,.275-.174c1.212-.008,2.424.005,3.637,0,.572,0,1.144,0,1.716,0-.191-.216-.41-.405-.6-.617a.258.258,0,0,1,.015-.35C253.122,176.645,253.361,176.414,253.593,176.176Z"
                transform="translate(-239.713 -170.254)"
                fill="#02a1fa"
            />
        </g>
        <g
            id="_e4eafdff"
            data-name="#e4eafdff"
            transform="translate(6.287 1.571)"
        >
            <path
                id="Path_3146"
                data-name="Path 3146"
                d="M191.961,47.968q2.735,0,5.47,0a.263.263,0,0,1,.287.229c.01.141,0,.284.005.426q0,2.685,0,5.37c-1.212.008-2.425-.006-3.637,0a.263.263,0,0,0-.275.174,2.208,2.208,0,0,0-.016.414c0,.251-.017.5,0,.753a.263.263,0,0,0,.288.228q1.819,0,3.638,0,0,2.8,0,5.6a.555.555,0,0,1-.048.314.275.275,0,0,1-.244.112H191.96c0-.59,0-1.179,0-1.768-.013-1.168,0-2.336,0-3.5C191.962,53.536,191.957,50.752,191.961,47.968Z"
                transform="translate(-191.958 -47.967)"
                fill="#e4eafd"
            />
        </g>
    </svg>
);

const timeIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.401"
        height="22.401"
        viewBox="0 0 22.401 22.401"
        className="learner-svg-icons"
    >
        <path
            className="fill-primary"
            id="Icon_ionic-ios-time"
            data-name="Icon ionic-ios-time"
            d="M14.575,3.375a11.2,11.2,0,1,0,11.2,11.2A11.2,11.2,0,0,0,14.575,3.375Zm.754,12.17a.756.756,0,0,1-.754.754H9.406a.754.754,0,0,1,0-1.508h4.415V7.683a.754.754,0,0,1,1.508,0Z"
            transform="translate(-3.375 -3.375)"
            fill="#02a1fa"
        />
    </svg>
);

const streakIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.359"
        height="23.268"
        viewBox="0 0 20.359 23.268"
        className="learner-svg-icons"
    >
        <path
            className="fill-primary"
            id="Icon_open-fire"
            data-name="Icon open-fire"
            d="M5.817,0C8.725,5.817,0,8.725,0,14.542s5.817,8.725,5.817,8.725c-2.85-5.759,5.817-8.725,5.817-14.542S5.817,0,5.817,0Zm8.725,8.725c2.908,5.817-5.817,8.725-5.817,14.542h8.725c1.163,0,2.908-1.454,2.908-5.817C20.359,11.634,14.542,8.725,14.542,8.725Z"
            fill="#02a1fa"
        />
    </svg>
);

const dotIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        viewBox="0 0 8 8"
        className="learner-svg-icons"
    >
        <circle
            className="fill-primary"
            id="Ellipse_86"
            data-name="Ellipse 86"
            cx="4"
            cy="4"
            r="4"
            fill="#02a1fa"
        />
    </svg>
);

const pieChart = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.18"
        height="20.201"
        viewBox="0 0 20.18 20.201"
    >
        <g
            id="pie-chart"
            transform="translate(-0.274)"
            className="learner-svg-icons"
        >
            <path
                id="Path_2924"
                data-name="Path 2924"
                d="M256.694,0a.42.42,0,0,0-.42.42V9.669a.42.42,0,0,0,.42.42h9.249a.42.42,0,0,0,.42-.42A9.681,9.681,0,0,0,256.694,0Z"
                transform="translate(-245.91)"
                fill="#ffc107"
            />
            <path
                className="fill-primary"
                id="Path_2925"
                data-name="Path 2925"
                d="M15.321,38.312l-5.8-7.453V21.753a.42.42,0,0,0-.42-.42C4.234,21.333.274,25.671.274,31a9.68,9.68,0,0,0,9.669,9.669,7.183,7.183,0,0,0,5.311-1.778A.42.42,0,0,0,15.321,38.312Z"
                transform="translate(0 -20.492)"
                fill="#2196f3"
            />
            <path
                id="Path_2926"
                data-name="Path 2926"
                d="M286.438,277.333H278.03a.42.42,0,0,0-.327.684l5.465,6.766a.42.42,0,0,0,.3.156h.029a.421.421,0,0,0,.287-.113,9.707,9.707,0,0,0,3.077-7.073A.421.421,0,0,0,286.438,277.333Z"
                transform="translate(-266.405 -266.402)"
                fill="#4caf50"
            />
        </g>
    </svg>
);

const searchPage = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="500"
        height="400"
        viewBox="0 0 193.014 214.323"
    >
        <g
            id="undraw_Wishlist_re_m7tv"
            transform="translate(-89.477 0)"
            className="learner-svg-icons"
        >
            <path
                id="Path_3307"
                data-name="Path 3307"
                d="M679.221,402.528H500.14a6.974,6.974,0,0,1-6.966-6.967V195.171a6.974,6.974,0,0,1,6.967-6.967H679.221a6.974,6.974,0,0,1,6.967,6.967v200.39A6.974,6.974,0,0,1,679.221,402.528ZM500.14,189.024a6.154,6.154,0,0,0-6.147,6.147v200.39a6.154,6.154,0,0,0,6.147,6.147H679.221a6.154,6.154,0,0,0,6.147-6.147V195.171a6.154,6.154,0,0,0-6.147-6.147Z"
                transform="translate(-403.696 -188.205)"
                fill="#3f3d56"
            />
            <rect
                id="Rectangle_3513"
                data-name="Rectangle 3513"
                width="192.194"
                height="0.82"
                transform="translate(89.887 17.044)"
                fill="#3f3d56"
            />
            <circle
                className="fill-primary"
                id="Ellipse_92"
                data-name="Ellipse 92"
                cx="3.278"
                cy="3.278"
                r="3.278"
                transform="translate(96.034 5.737)"
                fill="#02a1fa"
            />
            <circle
                className="fill-primary"
                id="Ellipse_93"
                data-name="Ellipse 93"
                cx="3.278"
                cy="3.278"
                r="3.278"
                transform="translate(106.279 5.737)"
                fill="#02a1fa"
            />
            <circle
                className="fill-primary"
                id="Ellipse_94"
                data-name="Ellipse 94"
                cx="3.278"
                cy="3.278"
                r="3.278"
                transform="translate(116.524 5.737)"
                fill="#02a1fa"
            />
            <path
                id="Path_3308"
                data-name="Path 3308"
                d="M692.857,287.859H565a5.327,5.327,0,1,1,0-10.655H692.857a5.327,5.327,0,0,1,0,10.655ZM565,278.024a4.508,4.508,0,1,0,0,9.016H692.857a4.508,4.508,0,0,0,0-9.016Z"
                transform="translate(-442.945 -240.733)"
                fill="#3f3d56"
            />
            <path
                id="Path_3309"
                data-name="Path 3309"
                d="M719,396.352H660.806a3.278,3.278,0,0,1,0-6.557H719a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -307.184)"
                fill="#ccc"
            />
            <path
                id="Path_3310"
                data-name="Path 3310"
                d="M752.6,423.352H660.806a3.278,3.278,0,0,1,0-6.557H752.6a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -323.12)"
                fill="#ccc"
            />
            <path
                id="Path_3311"
                data-name="Path 3311"
                d="M581.333,407.146H563.712a3.9,3.9,0,0,1-3.893-3.893V392.188a3.9,3.9,0,0,1,3.893-3.893h17.621a3.9,3.9,0,0,1,3.893,3.893v11.064A3.9,3.9,0,0,1,581.333,407.146Zm-17.621-18.031a3.077,3.077,0,0,0-3.073,3.073v11.064a3.077,3.077,0,0,0,3.073,3.074h17.621a3.077,3.077,0,0,0,3.073-3.074V392.188a3.077,3.077,0,0,0-3.073-3.073Z"
                transform="translate(-443.031 -306.299)"
                fill="#ccc"
            />
            <path
                id="Path_3312"
                data-name="Path 3312"
                d="M719,491.352H660.806a3.278,3.278,0,0,1,0-6.557H719a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -363.254)"
                fill="#ccc"
            />
            <path
                id="Path_3313"
                data-name="Path 3313"
                d="M752.6,518.352H660.806a3.278,3.278,0,0,1,0-6.557H752.6a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -379.189)"
                fill="#ccc"
            />
            <path
                id="Path_3314"
                data-name="Path 3314"
                d="M581.333,502.146H563.712a3.9,3.9,0,0,1-3.893-3.893V487.188a3.9,3.9,0,0,1,3.893-3.893h17.621a3.9,3.9,0,0,1,3.893,3.893v11.064A3.9,3.9,0,0,1,581.333,502.146Zm-17.621-18.031a3.077,3.077,0,0,0-3.073,3.073v11.064a3.077,3.077,0,0,0,3.073,3.073h17.621a3.077,3.077,0,0,0,3.073-3.073V487.188a3.077,3.077,0,0,0-3.073-3.073Z"
                transform="translate(-443.031 -362.368)"
                fill="#ccc"
            />
            <path
                id="Path_3315"
                data-name="Path 3315"
                d="M719,586.352H660.806a3.278,3.278,0,0,1,0-6.557H719a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -419.323)"
                fill="#f2f2f2"
            />
            <path
                id="Path_3316"
                data-name="Path 3316"
                d="M752.6,613.352H660.806a3.278,3.278,0,0,1,0-6.557H752.6a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -435.259)"
                fill="#f2f2f2"
            />
            <path
                id="Path_3317"
                data-name="Path 3317"
                d="M581.333,597.146H563.712a3.9,3.9,0,0,1-3.893-3.893V582.188a3.9,3.9,0,0,1,3.893-3.893h17.621a3.9,3.9,0,0,1,3.893,3.893v11.064A3.9,3.9,0,0,1,581.333,597.146Z"
                transform="translate(-443.031 -418.438)"
                fill="#f2f2f2"
            />
        </g>
    </svg>
);

const noResult = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="undraw_searching_p5ux"
        width="500"
        height="400"
        viewBox="0 0 238.341 172.101"
        className="learner-svg-icons"
    >
        <rect
            id="Rectangle_695"
            data-name="Rectangle 695"
            width="2.122"
            height="83.804"
            transform="translate(206.96 83.829)"
            fill="#3f3d56"
        />
        <path
            id="Path_3047"
            data-name="Path 3047"
            d="M956.738,296.725c.135,29.3-20.482,53.155-20.482,53.155s-20.836-23.66-20.971-52.964,20.482-53.155,20.482-53.155S956.6,267.421,956.738,296.725Z"
            transform="translate(-728.077 -202.216)"
            fill="#3f3d56"
        />
        <rect
            id="Rectangle_696"
            data-name="Rectangle 696"
            width="1.091"
            height="43.102"
            transform="translate(165.569 123.26)"
            fill="#3f3d56"
        />
        <path
            id="Path_3048"
            data-name="Path 3048"
            d="M789.254,548.89c.069,15.072-10.534,27.339-10.534,27.339S768,564.06,767.934,548.988s10.534-27.339,10.534-27.339S789.185,533.818,789.254,548.89Z"
            transform="translate(-612.524 -420.137)"
            fill="#3f3d56"
        />
        <ellipse
            id="Ellipse_39"
            data-name="Ellipse 39"
            cx="29.564"
            cy="4.532"
            rx="29.564"
            ry="4.532"
            transform="translate(158.604 163.038)"
            fill="#3f3d56"
        />
        <path
            id="Path_3049"
            data-name="Path 3049"
            d="M860.8,322.169a9.73,9.73,0,0,0-7.46-4.429s-4.829-.617-7.926,5.828c-2.887,6.008-6.872,11.809-.641,13.215l1.125-3.5.7,3.764a24.377,24.377,0,0,0,2.666.046c6.672-.215,13.027.063,12.822-2.331C861.808,331.575,863.207,326.241,860.8,322.169Z"
            transform="translate(-670.191 -260.218)"
            fill="#2f2e41"
        />
        <path
            id="Path_3050"
            data-name="Path 3050"
            d="M889.234,368.984s3.237,4.532,1.295,8.2,4.532,7.553,4.532,7.553l4.748-10.358s-5.611-3.669-4.1-7.121Z"
            transform="translate(-707.648 -299.063)"
            fill="#fbbebe"
        />
        <circle
            id="Ellipse_40"
            data-name="Ellipse 40"
            cx="5.611"
            cy="5.611"
            r="5.611"
            transform="translate(178.133 60.642)"
            fill="#fbbebe"
        />
        <path
            id="Path_3051"
            data-name="Path 3051"
            d="M809.255,344.411l4.689-6.05s1.651-6.341,4.042-6.02-.944,7.6-.944,7.6l-4.659,6.7Z"
            transform="translate(-644.928 -271.672)"
            fill="#fbbebe"
        />
        <path
            id="Path_3052"
            data-name="Path 3052"
            d="M871.469,707.662l1.511,7.984,3.237.863-.863-9.5Z"
            transform="translate(-683.409 -554.443)"
            fill="#fbbebe"
        />
        <path
            id="Path_3053"
            data-name="Path 3053"
            d="M1022.469,663.741l5.827,7.984,2.374,1.079,1.942-3.237-5.827-7.553Z"
            transform="translate(-801.824 -519.154)"
            fill="#fbbebe"
        />
        <path
            className="fill-primary"
            id="Path_3054"
            data-name="Path 3054"
            d="M872.049,400.889l-3.6-4.739s-6.974,1.286-7.622,1.933,1.726,12.516,1.726,12.516.432,3.237,2.374,4.963l1.942,1.295,15.969-3.237.6-7.105a18.647,18.647,0,0,0-4.916-14.259h0l-2.779.38Z"
            transform="translate(-685.284 -318.668)"
            fill="#02a1fa"
        />
        <path
            className="fill-primary"
            id="Path_3055"
            data-name="Path 3055"
            d="M807.983,386.753l-1.079-.432-4.963-.432s-1.726-.432-1.295-1.295.863-1.079,0-1.295-1.079-.432-.863-1.079a8.59,8.59,0,0,1,1.511-1.942l-3.669-3.021s-.2.14-.519.4c-1.821,1.475-7.585,6.8-3.365,12.116,4.963,6.258,10.79,9.927,15.537,8.632Z"
            transform="translate(-631.576 -306.905)"
            fill="#02a1fa"
        />
        <path
            id="Path_3056"
            data-name="Path 3056"
            d="M882.049,493.416v3.021s-1.942,3.669-1.295,7.121.863,5.179.863,5.179a29.462,29.462,0,0,0,1.511,8.632c1.511,4.316-3.453,32.585,2.805,33.017s9.711.863,11.653-1.295-3.237-39.275-3.237-39.275,17.7,36.9,21.364,35.391,12.948-4.963,11.869-6.905-28.269-45.1-28.269-45.1l-.863-1.942Z"
            transform="translate(-700.894 -396.304)"
            fill="#2f2e41"
        />
        <path
            id="Path_3057"
            data-name="Path 3057"
            d="M915.549,791.194s-3.453-.216-3.453.863-1.726,4.748-1.726,4.748-1.295,4.316,2.158,3.884,5.611-4.316,5.611-4.316l-.863-4.1Z"
            transform="translate(-724.036 -631.501)"
            fill="#2f2e41"
        />
        <path
            id="Path_3058"
            data-name="Path 3058"
            d="M1092,748.377s-3.021-1.942-2.805-.647.432,4.748,1.942,4.963,6.042,1.511,6.258,1.942,5.4,2.158,5.4-.647-3.237-4.963-3.237-4.963l-3.669-2.805s-2.374-.216-2.805,1.295S1092,748.377,1092,748.377Z"
            transform="translate(-864.449 -596.237)"
            fill="#2f2e41"
        />
        <circle
            id="Ellipse_41"
            data-name="Ellipse 41"
            cx="3.583"
            cy="3.583"
            r="3.583"
            transform="translate(180.17 53.96)"
            fill="#2f2e41"
        />
        <path
            id="Path_3059"
            data-name="Path 3059"
            d="M878.181,300.507a3.583,3.583,0,0,1,3.206-3.563,3.623,3.623,0,0,0-.377-.02,3.583,3.583,0,1,0,.377,7.146,3.583,3.583,0,0,1-3.206-3.563Z"
            transform="translate(-698.389 -243.907)"
            fill="#2f2e41"
        />
        <path
            id="Path_3060"
            data-name="Path 3060"
            d="M830.693,275.872l-4.9-2.565-6.761,1.049-1.4,6.178,3.482-.134.973-2.27v2.232l1.607-.062.932-3.614.583,3.847,5.712-.117Z"
            transform="translate(-641.194 -214.328)"
            fill="#2f2e41"
        />
        <path
            id="Path_3061"
            data-name="Path 3061"
            d="M874.955,489.464l-7.55.2s-6.442,1.254-6.081-1.347,6.646-1.691,6.646-1.691l6.88-1.049Z"
            transform="translate(-685.749 -391.852)"
            fill="#fbbebe"
        />
        <path
            className="fill-primary"
            id="Path_3062"
            data-name="Path 3062"
            d="M923.1,390.5a1.611,1.611,0,0,1,1.777,1.107c1.478,4.658,6.032,20.245,1.507,22.383a22.294,22.294,0,0,1-9.218,2.408l-2.081-5.125,1.895-1.778,2.023-13.007.969-5.514Z"
            transform="translate(-727.922 -317.272)"
            fill="#02a1fa"
        />
        <path
            id="Path_3063"
            data-name="Path 3063"
            d="M867.385,379.515l-.863,8.632-7.553,3.237,8.416-1.942Z"
            transform="translate(-673.606 -297.617)"
            opacity="0.4"
        />
        <path
            id="Path_3064"
            data-name="Path 3064"
            d="M745.319,298.705h0a10.108,10.108,0,0,1,5.9,4.288l.706,1.093.036.045,12.383,3.624a.9.9,0,0,1,.617,1.1L764,312.384a.9.9,0,0,1-1.114.632L750.5,309.553h0l-1.244.47a11.117,11.117,0,0,1-7.148.243h0Z"
            transform="translate(-592.273 -245.304)"
            fill="#3f3d56"
        />
        <ellipse
            id="Ellipse_42"
            data-name="Ellipse 42"
            cx="0.756"
            cy="1.418"
            rx="0.756"
            ry="1.418"
            transform="translate(163.153 63.553) rotate(-73.69)"
            fill="#575a89"
        />
        <circle
            id="Ellipse_43"
            data-name="Ellipse 43"
            cx="0.52"
            cy="0.52"
            r="0.52"
            transform="translate(164.935 62.955)"
            fill="#3f3d56"
        />
        <ellipse
            className="fill-primary"
            id="Ellipse_44"
            data-name="Ellipse 44"
            cx="6.062"
            cy="2.741"
            rx="6.062"
            ry="2.741"
            transform="matrix(0.308, -0.951, 0.951, 0.308, 146.997, 64.154)"
            fill="#0f85c7"
        />
        <path
            id="Path_3065"
            data-name="Path 3065"
            d="M744.567,305.319c.995-3.069.724-5.928-.585-6.551a1.268,1.268,0,0,1,.232.051c1.44.467,1.77,3.427.738,6.612s-3.037,5.388-4.477,4.921a1.266,1.266,0,0,1-.153-.061C741.738,310.472,743.588,308.336,744.567,305.319Z"
            transform="translate(-590.87 -245.353)"
            opacity="0.4"
        />
        <path
            className="fill-primary"
            id="Path_3066"
            data-name="Path 3066"
            d="M200.975,104.655l-28.1-21.8a186.007,186.007,0,0,1-14.289-12.384,71.571,71.571,0,0,0-31.55-16.993C96.267,45.58,71.9,59.584,60.81,80.146,43.871,111.567,30.89,162.554,106,135.414c16.426-5.936,33.052-6.567,45.66-9.693l45.65-9.412Z"
            transform="translate(-47.766 -51.242)"
            fill="#02a1fa"
        />
        <circle
            id="Ellipse_45"
            data-name="Ellipse 45"
            cx="1.511"
            cy="1.511"
            r="1.511"
            transform="translate(67.322 13.706)"
            fill="#f2f2f2"
        />
        <path
            id="Path_3067"
            data-name="Path 3067"
            d="M451.812,85.266h-.393v-.393h-.077v.393h-.393v.077h.393v.393h.077v-.393h.393Z"
            transform="translate(-353.636 -66.558)"
            fill="#f2f2f2"
        />
        <path
            id="Path_3068"
            data-name="Path 3068"
            d="M634.812,237.266h-.393v-.393h-.077v.393h-.393v.077h.393v.393h.077v-.393h.393Z"
            transform="translate(-497.145 -185.757)"
            fill="#f2f2f2"
        />
        <path
            id="Path_3069"
            data-name="Path 3069"
            d="M621.812,284.266h-.393v-.393h-.077v.393h-.393v.077h.393v.393h.077v-.393h.393Z"
            transform="translate(-486.95 -222.614)"
            fill="#f2f2f2"
        />
        <path
            id="Path_3070"
            data-name="Path 3070"
            d="M62.812,324.266h-.393v-.393h-.077v.393h-.393v.077h.393v.393h.077v-.393h.393Z"
            transform="translate(-48.581 -253.982)"
            fill="#f2f2f2"
        />
        <path
            id="Path_3071"
            data-name="Path 3071"
            d="M142.812,106.266h-.393v-.393h-.077v.393h-.393v.077h.393v.393h.077v-.393h.393Z"
            transform="translate(-111.317 -83.026)"
            fill="#f2f2f2"
        />
        <path
            id="Path_3072"
            data-name="Path 3072"
            d="M453.812,292.266h-.393v-.393h-.077v.393h-.393v.077h.393v.393h.077v-.393h.393Z"
            transform="translate(-355.204 -228.888)"
            fill="#f2f2f2"
        />
        <path
            id="Path_3073"
            data-name="Path 3073"
            d="M262.812,42.266h-.393v-.393h-.077v.393h-.393v.077h.393v.393h.077v-.393h.393Z"
            transform="translate(-205.421 -32.837)"
            fill="#f2f2f2"
        />
        <path
            id="Path_3074"
            data-name="Path 3074"
            d="M312.635,238.915l-.49.076c.153.98.274,1.981.363,2.972l.494-.043C312.913,240.916,312.79,239.906,312.635,238.915Z"
            transform="translate(-255.093 -198.416)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3075"
            data-name="Path 3075"
            d="M306.48,211.575l-.475.145c.287.95.544,1.923.762,2.892l.486-.11C307.03,213.522,306.769,212.538,306.48,211.575Z"
            transform="translate(-250.278 -176.976)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3076"
            data-name="Path 3076"
            d="M296.656,185.355l-.451.207c.414.9.8,1.832,1.155,2.76l.464-.175C297.467,187.209,297.077,186.268,296.656,185.355Z"
            transform="translate(-242.593 -156.414)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3077"
            data-name="Path 3077"
            d="M283.374,160.775l-.419.265c.533.837,1.044,1.7,1.519,2.574l.436-.237C284.431,162.5,283.913,161.621,283.374,160.775Z"
            transform="translate(-232.202 -137.139)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3078"
            data-name="Path 3078"
            d="M266.863,138.245l-.378.322c.643.755,1.269,1.545,1.86,2.344l.4-.3C268.147,139.808,267.514,139.009,266.863,138.245Z"
            transform="translate(-219.286 -119.471)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3079"
            data-name="Path 3079"
            d="M247.455,118.185l-.33.369c.742.662,1.47,1.36,2.162,2.069l.356-.347C248.942,119.558,248.206,118.854,247.455,118.185Z"
            transform="translate(-204.104 -103.739)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3080"
            data-name="Path 3080"
            d="M225.523,100.965l-.278.41c.824.555,1.64,1.146,2.423,1.757l.306-.391C227.181,102.124,226.356,101.527,225.523,100.965Z"
            transform="translate(-186.946 -90.235)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3081"
            data-name="Path 3081"
            d="M206.4,89.005v.561c.516.27,1.027.552,1.528.842l.248-.429Q207.307,89.474,206.4,89.005Z"
            transform="translate(-172.171 -80.856)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3082"
            data-name="Path 3082"
            d="M314.57,267.188l.5-.009c.009.5.01,1,0,1.5l-.5-.008C314.579,268.182,314.579,267.684,314.57,267.188Z"
            transform="translate(-256.994 -220.581)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3083"
            data-name="Path 3083"
            d="M177.635,435.915l-.49.076c.153.98.274,1.981.363,2.972l.494-.043C177.913,437.916,177.79,436.906,177.635,435.915Z"
            transform="translate(-149.225 -352.904)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3084"
            data-name="Path 3084"
            d="M171.48,408.575l-.475.145c.287.95.544,1.923.762,2.892l.486-.11C172.03,410.522,171.769,409.538,171.48,408.575Z"
            transform="translate(-144.41 -331.464)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3085"
            data-name="Path 3085"
            d="M161.656,382.355l-.451.207c.414.9.8,1.832,1.155,2.76l.464-.175C162.467,384.209,162.077,383.268,161.656,382.355Z"
            transform="translate(-136.725 -310.902)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3086"
            data-name="Path 3086"
            d="M148.374,357.775l-.419.265c.533.837,1.044,1.7,1.519,2.574l.436-.237C149.431,359.5,148.913,358.621,148.374,357.775Z"
            transform="translate(-126.334 -291.627)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3087"
            data-name="Path 3087"
            d="M131.863,335.246l-.378.322c.643.755,1.269,1.545,1.86,2.344l.4-.3C133.147,336.808,132.514,336.009,131.863,335.246Z"
            transform="translate(-113.419 -273.959)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3088"
            data-name="Path 3088"
            d="M112.455,315.185l-.33.369c.742.662,1.47,1.359,2.162,2.069l.356-.347C113.942,316.558,113.206,315.854,112.455,315.185Z"
            transform="translate(-98.236 -258.227)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3089"
            data-name="Path 3089"
            d="M90.523,297.965l-.278.41c.824.555,1.64,1.146,2.423,1.757l.306-.391C92.181,299.124,91.356,298.527,90.523,297.965Z"
            transform="translate(-81.078 -244.723)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3090"
            data-name="Path 3090"
            d="M71.4,286.005v.561c.516.27,1.027.552,1.528.842l.248-.429Q72.307,286.474,71.4,286.005Z"
            transform="translate(-66.304 -235.344)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3091"
            data-name="Path 3091"
            d="M179.57,464.188l.5-.009c.009.5.01,1,0,1.5l-.5-.008C179.58,465.182,179.579,464.684,179.57,464.188Z"
            transform="translate(-151.127 -375.069)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3092"
            data-name="Path 3092"
            d="M639.635,336.915l-.49.076c.153.98.274,1.981.363,2.972l.494-.043C639.913,338.916,639.79,337.906,639.635,336.915Z"
            transform="translate(-511.527 -275.268)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3093"
            data-name="Path 3093"
            d="M633.48,309.575l-.475.145c.287.949.544,1.923.762,2.892l.486-.11C634.03,311.522,633.769,310.538,633.48,309.575Z"
            transform="translate(-506.712 -253.828)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3094"
            data-name="Path 3094"
            d="M623.656,283.355l-.451.207c.414.9.8,1.832,1.155,2.76l.464-.175C624.467,285.209,624.077,284.268,623.656,283.355Z"
            transform="translate(-499.027 -233.266)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3095"
            data-name="Path 3095"
            d="M610.374,258.775l-.419.265c.533.837,1.044,1.7,1.519,2.574l.436-.237C611.431,260.5,610.913,259.621,610.374,258.775Z"
            transform="translate(-488.636 -213.991)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3096"
            data-name="Path 3096"
            d="M593.863,236.245l-.378.322c.643.755,1.269,1.545,1.86,2.344l.4-.3C595.147,237.808,594.514,237.009,593.863,236.245Z"
            transform="translate(-475.721 -196.322)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3097"
            data-name="Path 3097"
            d="M574.455,216.185l-.33.369c.742.662,1.47,1.36,2.162,2.069l.356-.347C575.942,217.558,575.206,216.854,574.455,216.185Z"
            transform="translate(-460.538 -180.591)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3098"
            data-name="Path 3098"
            d="M552.523,198.965l-.278.41c.824.555,1.64,1.146,2.423,1.757l.306-.391C554.181,200.124,553.356,199.527,552.523,198.965Z"
            transform="translate(-443.38 -167.087)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3099"
            data-name="Path 3099"
            d="M533.4,187.005v.561c.516.27,1.027.552,1.528.842l.248-.429Q534.307,187.474,533.4,187.005Z"
            transform="translate(-428.606 -157.708)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3100"
            data-name="Path 3100"
            d="M641.57,365.188l.5-.009c.009.5.01,1,0,1.5l-.5-.008C641.58,366.182,641.579,365.684,641.57,365.188Z"
            transform="translate(-513.429 -297.433)"
            fill="#e6e6e6"
        />
        <path
            id="Path_3101"
            data-name="Path 3101"
            d="M146.376,198.565h-.366V198.2h-.071v.366h-.366v.071h.366V199h.071v-.366h.366Z"
            transform="translate(-114.159 -155.428)"
            fill="#f2f2f2"
        />
        <path
            id="Path_3102"
            data-name="Path 3102"
            d="M126.835,300.922h-.366v-.366H126.4v.366h-.366v.071h.366v.366h.071v-.366h.366Z"
            transform="translate(-98.835 -235.697)"
            fill="#f2f2f2"
        />
        <path
            id="Path_3103"
            data-name="Path 3103"
            d="M69.5,390.63h-.366v-.366h-.071v.366h-.366v.071h.366v.366h.071V390.7H69.5Z"
            transform="translate(-53.871 -306.046)"
            fill="#f2f2f2"
        />
        <path
            id="Path_3104"
            data-name="Path 3104"
            d="M263.215,179.358a26.5,26.5,0,1,1,12.3,9.873,8.573,8.573,0,1,1-12.3-9.873Z"
            transform="translate(-213.168 -119.327)"
            fill="#fff"
        />
        <path
            className="fill-primary"
            id="Path_3105"
            data-name="Path 3105"
            d="M345.322,190.679c6.076,0,10.256,3.37,10.256,8.214,0,3.208-1.555,5.427-4.553,7.193-2.819,1.636-3.775,2.835-3.775,4.909h0a1.28,1.28,0,0,1-1.28,1.28h-3.106a1.28,1.28,0,0,1-1.279-1.235l-.006-.158c-.275-3.338.891-5.411,3.824-7.129,2.738-1.636,3.888-2.673,3.888-4.682s-1.944-3.483-4.358-3.483a4.151,4.151,0,0,0-3.953,2.351,2.86,2.86,0,0,1-2.614,1.634h0a2.811,2.811,0,0,1-2.51-4.1C337.413,192.533,340.7,190.679,345.322,190.679Zm-4.261,27.769a3.478,3.478,0,1,1,3.467,3.338A3.393,3.393,0,0,1,341.061,218.449Z"
            transform="translate(-273.434 -160.589)"
            fill="#02a1fa"
        />
    </svg>
);

const wishlistPage = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="500"
        height="400"
        viewBox="0 0 193.014 214.323"
    >
        <g
            id="undraw_Wishlist_re_m7tv"
            transform="translate(-89.477 0)"
            className="learner-svg-icons"
        >
            <path
                id="Path_3307"
                data-name="Path 3307"
                d="M679.221,402.528H500.14a6.974,6.974,0,0,1-6.966-6.967V195.171a6.974,6.974,0,0,1,6.967-6.967H679.221a6.974,6.974,0,0,1,6.967,6.967v200.39A6.974,6.974,0,0,1,679.221,402.528ZM500.14,189.024a6.154,6.154,0,0,0-6.147,6.147v200.39a6.154,6.154,0,0,0,6.147,6.147H679.221a6.154,6.154,0,0,0,6.147-6.147V195.171a6.154,6.154,0,0,0-6.147-6.147Z"
                transform="translate(-403.696 -188.205)"
                fill="#3f3d56"
            />
            <rect
                id="Rectangle_3513"
                data-name="Rectangle 3513"
                width="192.194"
                height="0.82"
                transform="translate(89.887 17.044)"
                fill="#3f3d56"
            />
            <circle
                className="fill-primary"
                id="Ellipse_92"
                data-name="Ellipse 92"
                cx="3.278"
                cy="3.278"
                r="3.278"
                transform="translate(96.034 5.737)"
                fill="#02a1fa"
            />
            <circle
                className="fill-primary"
                id="Ellipse_93"
                data-name="Ellipse 93"
                cx="3.278"
                cy="3.278"
                r="3.278"
                transform="translate(106.279 5.737)"
                fill="#02a1fa"
            />
            <circle
                className="fill-primary"
                id="Ellipse_94"
                data-name="Ellipse 94"
                cx="3.278"
                cy="3.278"
                r="3.278"
                transform="translate(116.524 5.737)"
                fill="#02a1fa"
            />
            <path
                id="Path_3308"
                data-name="Path 3308"
                d="M692.857,287.859H565a5.327,5.327,0,1,1,0-10.655H692.857a5.327,5.327,0,0,1,0,10.655ZM565,278.024a4.508,4.508,0,1,0,0,9.016H692.857a4.508,4.508,0,0,0,0-9.016Z"
                transform="translate(-442.945 -240.733)"
                fill="#3f3d56"
            />
            <path
                id="Path_3309"
                data-name="Path 3309"
                d="M719,396.352H660.806a3.278,3.278,0,0,1,0-6.557H719a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -307.184)"
                fill="#ccc"
            />
            <path
                id="Path_3310"
                data-name="Path 3310"
                d="M752.6,423.352H660.806a3.278,3.278,0,0,1,0-6.557H752.6a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -323.12)"
                fill="#ccc"
            />
            <path
                id="Path_3311"
                data-name="Path 3311"
                d="M581.333,407.146H563.712a3.9,3.9,0,0,1-3.893-3.893V392.188a3.9,3.9,0,0,1,3.893-3.893h17.621a3.9,3.9,0,0,1,3.893,3.893v11.064A3.9,3.9,0,0,1,581.333,407.146Zm-17.621-18.031a3.077,3.077,0,0,0-3.073,3.073v11.064a3.077,3.077,0,0,0,3.073,3.074h17.621a3.077,3.077,0,0,0,3.073-3.074V392.188a3.077,3.077,0,0,0-3.073-3.073Z"
                transform="translate(-443.031 -306.299)"
                fill="#ccc"
            />
            <path
                id="Path_3312"
                data-name="Path 3312"
                d="M719,491.352H660.806a3.278,3.278,0,0,1,0-6.557H719a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -363.254)"
                fill="#ccc"
            />
            <path
                id="Path_3313"
                data-name="Path 3313"
                d="M752.6,518.352H660.806a3.278,3.278,0,0,1,0-6.557H752.6a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -379.189)"
                fill="#ccc"
            />
            <path
                id="Path_3314"
                data-name="Path 3314"
                d="M581.333,502.146H563.712a3.9,3.9,0,0,1-3.893-3.893V487.188a3.9,3.9,0,0,1,3.893-3.893h17.621a3.9,3.9,0,0,1,3.893,3.893v11.064A3.9,3.9,0,0,1,581.333,502.146Zm-17.621-18.031a3.077,3.077,0,0,0-3.073,3.073v11.064a3.077,3.077,0,0,0,3.073,3.073h17.621a3.077,3.077,0,0,0,3.073-3.073V487.188a3.077,3.077,0,0,0-3.073-3.073Z"
                transform="translate(-443.031 -362.368)"
                fill="#ccc"
            />
            <path
                id="Path_3315"
                data-name="Path 3315"
                d="M719,586.352H660.806a3.278,3.278,0,0,1,0-6.557H719a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -419.323)"
                fill="#f2f2f2"
            />
            <path
                id="Path_3316"
                data-name="Path 3316"
                d="M752.6,613.352H660.806a3.278,3.278,0,0,1,0-6.557H752.6a3.278,3.278,0,1,1,0,6.557Z"
                transform="translate(-500.699 -435.259)"
                fill="#f2f2f2"
            />
            <path
                id="Path_3317"
                data-name="Path 3317"
                d="M581.333,597.146H563.712a3.9,3.9,0,0,1-3.893-3.893V582.188a3.9,3.9,0,0,1,3.893-3.893h17.621a3.9,3.9,0,0,1,3.893,3.893v11.064A3.9,3.9,0,0,1,581.333,597.146Z"
                transform="translate(-443.031 -418.438)"
                fill="#f2f2f2"
            />
            <path
                className="fill-primary"
                id="Path_3318"
                data-name="Path 3318"
                d="M581.395,408.636l-.949-.856c-3.37-3.094-5.594-5.1-5.594-7.6a3.558,3.558,0,0,1,3.6-3.621,3.871,3.871,0,0,1,2.944,1.382,3.871,3.871,0,0,1,2.944-1.382,3.558,3.558,0,0,1,3.6,3.621c0,2.5-2.225,4.509-5.594,7.6Z"
                transform="translate(-451.903 -311.175)"
                fill="#02a1fa"
            />
            <path
                className="fill-primary"
                id="Path_3319"
                data-name="Path 3319"
                d="M581.395,503.636l-.949-.856c-3.37-3.094-5.594-5.1-5.594-7.6a3.558,3.558,0,0,1,3.6-3.621,3.871,3.871,0,0,1,2.944,1.382,3.87,3.87,0,0,1,2.944-1.382,3.558,3.558,0,0,1,3.6,3.621c0,2.5-2.225,4.509-5.594,7.6Z"
                transform="translate(-451.903 -367.244)"
                fill="#02a1fa"
            />
        </g>
    </svg>
);

const showMoreIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        className="learner-svg-icons"
    >
        <path
            className="fill-primary"
            id="Icon_ionic-ios-arrow-dropdown-circle"
            data-name="Icon ionic-ios-arrow-dropdown-circle"
            d="M3.375,14.375a11,11,0,1,0,11-11A11,11,0,0,0,3.375,14.375Zm15.289-2.3a1.025,1.025,0,0,1,1.444,0,1.008,1.008,0,0,1,.3.719,1.026,1.026,0,0,1-.3.725l-4.987,4.971a1.019,1.019,0,0,1-1.407-.032L8.648,13.418a1.021,1.021,0,0,1,1.444-1.444l4.289,4.331Z"
            transform="translate(-3.375 -3.375)"
            fill="#02a1fa"
        />
    </svg>
);

export {
    checkedSvg,
    defaultAvatar,
    myProfile,
    settingsIcon,
    progressIcon,
    logoutIcon,
    timeIcon,
    streakIcon,
    dotIcon,
    pieChart,
    searchPage,
    wishlistPage,
    showMoreIcon,
    noResult,
};
