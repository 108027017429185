export const activityBuilderConstants = {
    ACTIVITY_BUILDER_ACTIVITY_SUBMITTED: "ACTIVITY_BUILDER_ACTIVITY_SUBMITTED",
    ACTIVITY_BUILDER_ACTIVITY_SAVE_SUCCESS: "ACTIVITY_BUILDER_ACTIVITY_SAVE_SUCCESS",
    ACTIVITY_BUILDER_ACTIVITY_SAVE_FAILURE: 'ACTIVITY_BUILDER_ACTIVITY_SAVE_FAILURE',
    ACTIVITY_BUILDER_CANCEL_SAVE_ACTIVITY: "ACTIVITY_BUILDER_CANCEL_SAVE_ACTIVITY",
    ACTIVITY_NAME_CHANGED: "ACTIVITY_NAME_CHANGED",
    ACTIVITY_BUILDER_TYPE_CHANGED: "ACTIVITY_BUILDER_TYPE_CHANGED",
    ACTIVITY_BUILDER_ACTIVITY_LOADING: "ACTIVITY_BUILDER_ACTIVITY_LOADING",
    ACTIVITY_BUILDER_ACTIVITY_LOADED: "ACTIVITY_BUILDER_ACTIVITY_LOADED",
};
