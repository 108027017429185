import courseConstants from '../constants/course.constants';

const initialState = {
    loading: false,
    updateLoading: false,
    courses: [],
};

const courses = (state = initialState, action) => {
    switch (action.type) {
        case courseConstants.FETCH_COURSES_REQUEST:
        case courseConstants.SEARCH_COURSE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case courseConstants.FETCH_COURSES_SUCCESS:
        case courseConstants.SEARCH_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                courses: action.payload,
            };
        case courseConstants.FETCH_COURSES_REJECTED:
        case courseConstants.SEARCH_COURSE_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case courseConstants.SAVE_COURSE_REQUEST:
            return {
                ...state,
                updateLoading: true,
            };
        case courseConstants.SAVE_COURSE_SUCCESS:
            return {
                ...state,
                updateLoading: false,
            };
        case courseConstants.SAVE_COURSE_REJECTED:
            return {
                ...state,
                updateLoading: false,
            };
        default:
            return state;
    }
};

export default courses;
