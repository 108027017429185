import { axiosDefaultClient } from '../../settings/axios.settings';
import languageLevelTypesConstants from '../constants/languageLevelTypes.constants';
import { notification } from 'antd';
import siteConstants from '../constants/site.constants';
import { retrieveDecryptedUserFromLocalStorage } from '../../utils/common';

const client = axiosDefaultClient;

const fetchLanguageLevelTypes = () => {
    return dispatch => {
        dispatch({
            type:
                languageLevelTypesConstants.FETCH_LANGUAGE_LEVEL_TYPES_REQUEST,
        });
        const url = '/api/language-level-types';
        client
            .get(url)
            .then(response => {
                dispatch({
                    type:
                        languageLevelTypesConstants.FETCH_LANGUAGE_LEVEL_TYPES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error =>
                dispatch({
                    type:
                        languageLevelTypesConstants.FETCH_LANGUAGE_LEVEL_TYPES_REJECTED,
                    payload: error,
                }),
            );
    };
};

const fetchLanguageLevels = (languageLevelTypeID, siteId) => {
    return dispatch => {
        dispatch({
            type: languageLevelTypesConstants.FETCH_LANGUAGE_LEVELS_REQUEST,
        });
        let url = `/api/language-level-types/${languageLevelTypeID}/language-levels?`;
        if (siteId) url = url + `siteID=${siteId}`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type:
                        languageLevelTypesConstants.FETCH_LANGUAGE_LEVELS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error =>
                dispatch({
                    type:
                        languageLevelTypesConstants.FETCH_LANGUAGE_LEVELS_REJECTED,
                    payload: error,
                }),
            );
    };
};

const fetchLanguageWithLevels = () => {
    return dispatch => {
        // dispatch({
        //     type: languageLevelTypesConstants.FETCH_LANGUAGE_LEVELS_REQUEST,
        // });
        let url = `/api/language-types-with-levels`;
        client.get(url).then(response => {
            dispatch({
                type:
                    languageLevelTypesConstants.FETCH_TYPES_WITH_LEVELS_SUCCESS,
                payload: response.data,
            });
        });
    };
};

const updateLanguageLevelType = (languageLevelTypeID, data, history) => {
    return dispatch => {
        dispatch({
            type:
                languageLevelTypesConstants.UPDATE_LANGUAGE_LEVEL_TYPES_REQUEST,
        });
        const url = `/api/language-level-types/update/${languageLevelTypeID}`;
        client
            .post(url, data)
            .then(() => {
                dispatch({
                    type:
                        languageLevelTypesConstants.UPDATE_LANGUAGE_LEVEL_TYPES_SUCCESS,
                });
                history.push('/admin/language-level-types');
            })
            .catch(error => {
                notification.error({
                    message: 'Error!',
                    description: error?.data?.message,
                });
            });
    };
};

const createLanguageLevelType = (data, history) => {
    return dispatch => {
        dispatch({
            type:
                languageLevelTypesConstants.CREATE_LANGUAGE_LEVEL_TYPES_REQUEST,
        });
        const url = '/api/language-level-types/create';
        client
            .post(url, data)
            .then(() => {
                dispatch({
                    type:
                        languageLevelTypesConstants.CREATE_LANGUAGE_LEVEL_TYPES_SUCCESS,
                });
                history.push('/admin/language-level-types');
            })
            .catch(error => {
                notification.error({
                    message: 'Error!',
                    description: error?.data?.message,
                });
            });
    };
};

const fetchAllLanguages = (type, siteId, forSite) => {
    let url = '';
    let user = retrieveDecryptedUserFromLocalStorage();
    if (type === 'interface') url = '/api/interface-languages?';
    if (type === 'content') url = '/api/content-languages?';
    if (siteId) url = url + `&siteID=${siteId}`;
    if (user?.role === 'trainer')
        url = url + `&userID=${user.userID}`;
    if (forSite) url = url + `forSite=${forSite}`;
    return dispatch => {
        dispatch({
            type: languageLevelTypesConstants.FETCH_ALL_LANGUAGES_REQUEST,
        });

        client
            .get(url)
            .then(response => {
                dispatch({
                    type:
                        languageLevelTypesConstants.FETCH_ALL_LANGUAGES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error =>
                dispatch({
                    type:
                        languageLevelTypesConstants.FETCH_ALL_LANGUAGES_REJECTED,
                    payload: error,
                }),
            );
    };
};
const createLanguage = (data, type, history) => {
    return dispatch => {
        dispatch({
            type: languageLevelTypesConstants.CREATE_LANGUAGE_REQUEST,
        });
        const url = '/api/languages';
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: languageLevelTypesConstants.CREATE_LANGUAGE_SUCCESS,
                });
                notification.success({
                    message: response.data.message,
                });
                if (type === 'interface') {
                    history.push('/admin/interface-languages');
                }
                if (type === 'content') {
                    history.push('/admin/content-languages');
                }
            })
            .catch(error => {
                // console.log(error, 'error');
                notification.error({
                    message: error?.data?.message,
                });
            });
    };
};
const updateLanguageImage = data => {
    return dispatch => {
        dispatch({
            type: languageLevelTypesConstants.UPDATE_LANGUAGE_IMAGE,
        });
        const url = '/api/languages/update-image';
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type:
                        languageLevelTypesConstants.UPDATE_LANGUAGE_IMAGE_SUCCESS,
                });
                notification.success({
                    message: 'Language Icon Uplodade Successfully',
                });
            })
            .catch(error => {
                // console.log(error, 'error');
                notification.error({
                    message: 'Erroe Icon Not Update',
                });
            });
    };
};
const fetchLearningLanguage = id => {
    return dispatch => {
        dispatch({
            type: languageLevelTypesConstants.FETCH_LANGUAGE_REQUEST,
        });
        const url = `/api/languages/${id}`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: languageLevelTypesConstants.FETCH_LANGUAGE_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch(error =>
                dispatch({
                    type:
                        languageLevelTypesConstants.FETCH_ALL_LANGUAGES_REJECTED,
                    payload: error,
                }),
            );
    };
};
const getLanguageData = (code, callback) => {
    return dispatch => {
        dispatch({
            type: languageLevelTypesConstants.LANGUAGE_DATA,
        });
        const url = `/api/language-data/${code}`;
        client
            .get(url)
            .then(response => {
                callback && callback(response.data.lang);
                dispatch({
                    type: languageLevelTypesConstants.LANGUAGE_DATA_SUCCESS,
                    payload: response.data.lang,
                });
            })
            .catch(error =>
                dispatch({
                    type: languageLevelTypesConstants.LANGUAGE_DATA_REJECTED,
                    payload: error,
                }),
            );
    };
};
const updateLanguage = (id, data, history) => {
    return dispatch => {
        dispatch({
            type: languageLevelTypesConstants.CREATE_LANGUAGE_REQUEST,
        });
        const url = `/api/languages/${id}?_method=PUT`;
        client
            .post(url, data)
            .then(() => {
                dispatch({
                    type: languageLevelTypesConstants.CREATE_LANGUAGE_SUCCESS,
                });
                history.push('/admin/learning-languages');
                notification.success({
                    message: 'Success',
                });
            })
            .catch(error =>
                notification.error({
                    message: 'Error',
                }),
            );
    };
};
const updateLanguageData = data => {
    return dispatch => {
        dispatch({
            type: languageLevelTypesConstants.UPDATE_LANGUAGE_DATA,
        });
        const url = `/api/update-language-data`;
        client
            .post(url, data)
            .then(() => {
                dispatch({
                    type:
                        languageLevelTypesConstants.UPDATE_LANGUAGE_DATA_SUCCESS,
                });
                notification.success({
                    message: 'Success Update Language',
                });
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Error Language not Update ',
                });
            });
    };
};
const languageLevelTypesActions = {
    fetchLanguageLevelTypes,
    fetchLanguageLevels,
    fetchLanguageWithLevels,
    updateLanguageLevelType,
    createLanguageLevelType,
    fetchAllLanguages,
    createLanguage,
    fetchLearningLanguage,
    updateLanguage,
    getLanguageData,
    updateLanguageData,
    updateLanguageImage,
};

export default languageLevelTypesActions;
