import React from 'react';
import { history } from '../utils/history';

const ProtectedRoute = React.lazy(() =>
    import('../components/Layout/ProtectedRoute'),
);
const MainLearnerPage = React.lazy(() =>
    import('../pages/MainLearnerPage'),
);
const PathwayPage = React.lazy(() =>
    import('../pages/PathwayPage'),
);
const SectionPage = React.lazy(() =>
    import('../pages/SectionPage'),
);
const LearnerSearch = React.lazy(() =>
    import('../pages/LearnerSearch'),
);

const LearnerWishlist = React.lazy(() =>
    import('../pages/LearnerWishlist'),
);
const LearnerLibrary = React.lazy(() =>
    import('../pages/LearnerLibrary'),
);
const LearnerModulePage = React.lazy(() =>
    import('../pages/Learner/ModulePage'),
);
const MainProfilePage = React.lazy(() =>
    import('../pages/MainProfilePage'),
);
const MainProfilePageV2 = React.lazy(() =>
    import('../pages/MainProfilePageV2'),
);

const ChangePassword = React.lazy(() =>
    import('../pages/changePassword'),
);

const MyContent = React.lazy(() =>
    import('../pages/MyContent'),
);

const Messages = React.lazy(() => import('../pages/messages'))
const LearnerFeedbackReport = React.lazy(() => import('../pages/LearnerFeedbackReport'))


// testing merging
const LearnerRoutes = [
    <ProtectedRoute
        key="/learner/progress/feedback-report/:id"
        exact
        path="/learner/progress/feedback-report/:id"
        component={LearnerFeedbackReport}
    />,
    <ProtectedRoute
        key="/learner/messages"
        exact
        path="/learner/messages"
        component={Messages}
    />,
    <ProtectedRoute
        key="/auth/change-password"
        path="/auth/change-password"
        component={ChangePassword}
        history={history}
    />,
    <ProtectedRoute
        key="/learner/dashboard"
        path="/learner/dashboard"
        component={MainLearnerPage}
        history={history}
    />,
    <ProtectedRoute
        key="/learner/pathway-page"
        path="/learner/pathway-page/:pathwayID"
        component={PathwayPage}
        history={history}
    />,
    <ProtectedRoute
        key="/learner/section-page"
        path="/learner/section-page/:sectionID"
        component={SectionPage}
        history={history}
    />,
    <ProtectedRoute
        key="/learner/search"
        path="/learner/search"
        component={LearnerSearch}
        history={history}
    />,
    <ProtectedRoute
        key="/learner/wishlist"
        path="/learner/wishlist"
        component={LearnerWishlist}
        history={history}
    />,
    <ProtectedRoute
        key="/learner/library"
        path="/learner/library"
        component={LearnerLibrary}
        history={history}
    />,
    <ProtectedRoute
        key="/learner/module-page"
        path="/learner/module-page/:moduleID"
        component={LearnerModulePage}
        history={history}
    />,
    <ProtectedRoute
        key="/learner/profile"
        path="/learner/profile"
        component={MainProfilePageV2}
        history={history}
    />,
    // <ProtectedRoute
    //     key="/learner/profile"
    //     path="/learner/profile"
    //     component={MainProfilePage}
    //     history={history}
    // />,
    <ProtectedRoute
        key="/learner/content"
        path="/learner/content"
        component={MyContent}
        history={history}
    />,
];

export default LearnerRoutes;
