const courseConstants = {
    FETCH_COURSES_REQUEST: 'FETCH_COURSES_REQUEST',
    FETCH_COURSES_SUCCESS: 'FETCH_COURSES_SUCCESS',
    FETCH_COURSES_REJECTED: 'FETCH_COURSES_REJECTED',
    SAVE_COURSE_REQUEST: 'SAVE_COURSE_REQUEST',
    SAVE_COURSE_SUCCESS: 'SAVE_COURSE_SUCCESS',
    SAVE_COURSE_REJECTED: 'SAVE_COURSE_REJECTED',
    SEARCH_COURSE_REQUEST: 'SEARCH_COURSE_REQUEST',
    SEARCH_COURSE_SUCCESS: 'SEARCH_COURSE_SUCCESS',
    SEARCH_COURSE_REJECTED: 'SEARCH_COURSE_REJECTED',
};

export default courseConstants;
