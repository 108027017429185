/* eslint-disable import/prefer-default-export */
import languagesConstant from '../constants/languages.constant';

const initialState = {};

function languagesContent(state = initialState, action) {
    switch (action.type) {
        case languagesConstant.FETCH_LANGUAGES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case languagesConstant.FETCH_LANGUAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                languagesContent: action.payload,
            };
        case languagesConstant.FETCH_LANGUAGES_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export { languagesContent };
