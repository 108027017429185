import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faHome,
    faUpload,
    faFolderPlus,
    faFileTimes,
    faFileAlt,
    faFolder,
    faList,
    faThLarge,
    faCopy,
    faFileSignature,
    faEye,
    faImagePolaroid,
    faUsers,
    faUser,
    faGraduationCap as faGraduationCapLight,
    faChartPieAlt,
    faListAlt,
    faListUl,
    faSearch,
    faClipboardListCheck,
    faChalkboardTeacher,
    faUserChart,
    faEnvelope,
    faCog,
    faInfoSquare,
    faWindowAlt,
    faMoneyCheckEdit,
    faDownload,
    faBars,
    faBell,
    faMapMarker,
    faPlanetMoon,
    faSuitcase,
    faMapMarked,
    faConciergeBell,
    faCogs,
    faTruck,
    faFileCertificate,
    faHeadphonesAlt,
    faHourglassHalf,
    faChevronCircleRight,
    faSignal,
    faBullseye,
    faBrain,
    faArrowAltFromLeft,
    faCheckCircle,
    faTimesCircle,
    faInfoCircle,
    faTurtle,
    faForward as faForwardLight,
    faPlay,
    faAngleDoubleRight,
    faAngleRight,
    faBookOpen,
    faVolumeUp,
    faHeart as faHeartLight,
} from '@fortawesome/pro-light-svg-icons';

import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as faCircleSolid } from '@fortawesome/free-solid-svg-icons';

import {
    faGraduationCap as faGraduationCapSolid,
    faBookmark,
    faForward as faForwardSolid,
    faAngleRight as faAngleRightSolid,
    faHeart as faHeartSolid,
    faAdjust,
    faArrowAltCircleUp,
    faArrowAltCircleDown,
    faBug,
    faCheckDouble,
    faEye as faEyeSolid,
    faEdit as faEditSolid,
    faFile as faFileSolid,
    faFileImage as faFileImageSolid,
    faFileAudio as faFileAudioSolid,
    faFileVideo as faFileVideoSolid,
    faFilePdf as faFilePdfSolid,
    faDiceD20,
    faTasks,
    faRandom,
    faQuestion,
    faBolt,
    faSort,
    faKeyboard,
    faLightbulbOn,
    faCheckCircle as faCheckCircleSolid,
    faTimesCircle as faTimesCircleSolid,
    faPause,
    faPlay as faPlaySolid,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faSignalAlt1 as faSignalAlt1Duotone,
    faSignalAlt2 as faSignalAlt2Duotone,
    faSignalAlt3 as faSignalAlt3Duotone,
    faStepForward,
    faCheckCircle as faCheckCircleDuotone,
    faFolder as faFolderDuotone,
    faFile as faFileDuotone,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
    faHome,
    faUpload,
    faFolderPlus,
    faFileTimes,
    faFileAlt,
    faFolder,
    faList,
    faThLarge,
    faCopy,
    faFileSignature,
    faEye,
    faImagePolaroid,
    faUsers,
    faUser,
    faGraduationCapLight,
    faChartPieAlt,
    faListAlt,
    faListUl,
    faSearch,
    faClipboardListCheck,
    faChalkboardTeacher,
    faUserChart,
    faEnvelope,
    faCog,
    faInfoSquare,
    faWindowAlt,
    faMoneyCheckEdit,
    faDownload,
    faBars,
    faBell,
    faMapMarker,
    faGraduationCapSolid,
    faPlanetMoon,
    faSuitcase,
    faMapMarked,
    faConciergeBell,
    faCogs,
    faTruck,
    faFileCertificate,
    faSignalAlt1Duotone,
    faSignalAlt2Duotone,
    faSignalAlt3Duotone,
    faHeadphonesAlt,
    faHourglassHalf,
    faChevronCircleRight,
    faBookmark,
    faSignal,
    faBullseye,
    faBrain,
    faStepForward,
    faArrowAltFromLeft,
    faCheckCircle,
    faTimesCircle,
    faInfoCircle,
    faTurtle,
    faForwardLight,
    faPlay,
    faAngleDoubleRight,
    faAngleRight,
    faBookOpen,
    faPlay,
    faAngleDoubleRight,
    faHeartLight,
    faVolumeUp,
    faForwardSolid,
    faAngleRightSolid,
    faHeartSolid,
    faAdjust,
    faCircle,
    faCircleSolid,
    faCheckCircleDuotone,
    faFolderDuotone,
    faFileDuotone,
    faArrowAltCircleUp,
    faArrowAltCircleDown,
    faBug,
    faCheckDouble,
    faEyeSolid,
    faEditSolid,
    faFileSolid,
    faFileImageSolid,
    faFileAudioSolid,
    faFileVideoSolid,
    faFilePdfSolid,
    faDiceD20,
    faTasks,
    faRandom,
    faQuestion,
    faBolt,
    faSort,
    faKeyboard,
    faLightbulbOn,
    faCheckCircleSolid,
    faTimesCircleSolid,
    faPause,
    faPlaySolid,
);
