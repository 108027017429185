import React from 'react';
import './loader.scss';

const Loader = () => {
    return (
        <div className="main-loader">
            <div className="loading">
                <div className="loading-text">
                    <span className="loading-text-words">{window.__lang("loading")}</span>
                    {/* <span className="loading-text-words">o</span>
                    <span className="loading-text-words">a</span>
                    <span className="loading-text-words">d</span>
                    <span className="loading-text-words">i</span>
                    <span className="loading-text-words">n</span>
                    <span className="loading-text-words">g</span> */}
                </div>
            </div>
            <div className="spinner">
                <div className="quadrant"></div>
                <div className="quadrant"></div>
                <div className="quadrant"></div>
            </div>
        </div>
    );
};

export default Loader;
