const libraryBundlesConstants = {
    FETCH_LIBRARY_BUNDLES_REQUEST: 'FETCH_LIBRARY_BUNDLES_REQUEST',
    FETCH_LIBRARY_BUNDLES_SUCCESS: 'FETCH_LIBRARY_BUNDLES_SUCCESS',
    FETCH_LIBRARY_BUNDLES_REJECTED: 'FETCH_LIBRARY_BUNDLES_REJECTED',

    FETCH_LIBRARY_BUNDLES_FOR_USER_REQUEST: 'FETCH_LIBRARY_BUNDLES_FOR_USER_REQUEST',
    FETCH_LIBRARY_BUNDLES_FOR_USER_SUCCESS: 'FETCH_LIBRARY_BUNDLES_FOR_USER_SUCCESS',
    FETCH_LIBRARY_BUNDLES_FOR_USER_REJECTED: 'FETCH_LIBRARY_BUNDLES_FOR_USER_REJECTED',

    FETCH_LIBRARY_BUNDLES_FOR_USER_GROUPS_REQUEST: 'FETCH_LIBRARY_BUNDLES_FOR_USER_GROUPS_REQUEST',
    FETCH_LIBRARY_BUNDLES_FOR_USER_GROUPS_SUCCESS: 'FETCH_LIBRARY_BUNDLES_FOR_USER_GROUPS_SUCCESS',
    FETCH_LIBRARY_BUNDLES_FOR_USER_GROUPS_REJECTED: 'FETCH_LIBRARY_BUNDLES_FOR_USER_GROUPS_REJECTED',

    FETCH_LIBRARY_BUNDLE_REQUEST: 'FETCH_LIBRARY_BUNDLE_REQUEST',
    FETCH_LIBRARY_BUNDLE_SUCCESS: 'FETCH_LIBRARY_BUNDLE_SUCCESS',
    FETCH_LIBRARY_BUNDLE_REJECTED: 'FETCH_LIBRARY_BUNDLE_REJECTED',

    RESET_LIBRARY_BUNDLE: 'RESET_LIBRARY_BUNDLE',

    SAVE_LIBRARY_BUNDLE_REQUEST: 'SAVE_LIBRARY_BUNDLE_REQUEST',
    SAVE_LIBRARY_BUNDLE_SUCCESS: 'SAVE_LIBRARY_BUNDLE_SUCCESS',
    SAVE_LIBRARY_BUNDLE_REJECTED: 'SAVE_LIBRARY_BUNDLE_REJECTED',

    SEARCH_LIBRARY_BUNDLE_REQUEST: 'SEARCH_LIBRARY_BUNDLE_REQUEST',
    SEARCH_LIBRARY_BUNDLE_SUCCESS: 'SEARCH_LIBRARY_BUNDLE_SUCCESS',
    SEARCH_LIBRARY_BUNDLE_REJECTED: 'SEARCH_LIBRARY_BUNDLE_REJECTED',

    SWITCH_LIBRARY_BUNDLE_REQUEST: 'SWITCH_LIBRARY_BUNDLE_REQUEST',
    SWITCH_LIBRARY_BUNDLE_SUCCESS: 'SWITCH_LIBRARY_BUNDLE_SUCCESS',
    SWITCH_LIBRARY_BUNDLE_REJECTED: 'SWITCH_LIBRARY_BUNDLE_REJECTED',

    SWITCH_LIBRARY_BUNDLE_FOR_USER_GROUPS_REQUEST: 'SWITCH_LIBRARY_BUNDLE_FOR_USER_GROUPS_REQUEST',
    SWITCH_LIBRARY_BUNDLE_FOR_USER_GROUPS_SUCCESS: 'SWITCH_LIBRARY_BUNDLE_FOR_USER_GROUPS_SUCCESS',
    SWITCH_LIBRARY_BUNDLE_FOR_USER_GROUPS_REJECTED: 'SWITCH_LIBRARY_BUNDLE_FOR_USER_GROUPS_REJECTED',

    SAVE_EXTRA_CONTENT_REQUEST: 'SAVE_EXTRA_CONTENT_REQUEST',
    SAVE_EXTRA_CONTENT_SUCCESS: 'SAVE_EXTRA_CONTENT_SUCCESS',
    SAVE_EXTRA_CONTENT_REJECTED: 'SAVE_EXTRA_CONTENT_REJECTED',

    SAVE_CANCELLED: 'SAVE_CANCELLED',

    FETCH_LEARNER_LIBRARY_BUNDLES_REQUEST: 'FETCH_LEARNER_LIBRARY_BUNDLES_REQUEST',
    FETCH_LEARNER_LIBRARY_BUNDLES_SUCCESS: 'FETCH_LEARNER_LIBRARY_BUNDLES_SUCCESS',
    FETCH_LEARNER_LIBRARY_BUNDLES_REJECTED: 'FETCH_LEARNER_LIBRARY_BUNDLES_REJECTED',

    CLEARDATA: 'CLEARDATA',

    CLEAR_LIBRARY_BUNDLE: 'CLEAR_LIBRARY_BUNDLE',

    FETCH_LEARNING_LIBRARY_BUNDLE_BY_ID_REQUEST:
        'FETCH_LEARNING_LIBRARY_BUNDLE_BY_ID_REQUEST',
};

export default libraryBundlesConstants;
