import languageLevelTypesActions from '../actions/languageLevelTypes.actions';
import languageLevelTypesConstants from '../constants/languageLevelTypes.constants';

const initialState = {
    loading: false,
    updateLoading: false,
    languageLevelTypes: [],
    languageLevels: [],
    allLanguages: [],
    language:{},
    fetchLanguageLoading:false,
 
};

function languageLevelTypes(state = initialState, action) {
    switch (action.type) {
        case languageLevelTypesConstants.FETCH_LANGUAGE_LEVEL_TYPES_REQUEST:
        case languageLevelTypesConstants.FETCH_ALL_LANGUAGES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case languageLevelTypesConstants.FETCH_LANGUAGE_LEVEL_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                languageLevelTypes: action.payload,
            };
        case languageLevelTypesConstants.FETCH_LANGUAGE_LEVEL_TYPES_REJECTED:
        case languageLevelTypesConstants.FETCH_ALL_LANGUAGES_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case languageLevelTypesConstants.UPDATE_LANGUAGE_LEVEL_TYPES_REQUEST:
        case languageLevelTypesConstants.CREATE_LANGUAGE_LEVEL_TYPES_REQUEST:
            return {
                ...state,
                updateLoading: true,
            };
        case languageLevelTypesConstants.UPDATE_LANGUAGE_LEVEL_TYPES_SUCCESS:
        case languageLevelTypesConstants.CREATE_LANGUAGE_LEVEL_TYPES_SUCCESS:
            return {
                ...state,
                updateLoading: false,
            };
        case languageLevelTypesConstants.FETCH_LANGUAGE_LEVELS_SUCCESS:
            return {
                ...state,
                languageLevels: action.payload,
            };
        case languageLevelTypesConstants.FETCH_TYPES_WITH_LEVELS_SUCCESS:
            return {
                ...state,
                allLevels: action.payload,
            };
        case languageLevelTypesConstants.LANGUAGE_DATA_SUCCESS:
            return {
                ...state,
                languageData: action.payload,
            };
        case languageLevelTypesConstants.FETCH_ALL_LANGUAGES_SUCCESS:
            return {
                ...state,
                allLanguages: action.payload,
                loading:false,
            };
            case languageLevelTypesConstants.CREATE_LANGUAGE_REQUEST :
                return{
                    ...state , 
                    loading:true,

                };
                case languageLevelTypesConstants.CREATE_LANGUAGE_SUCCESS :
                    return{
                        ...state , 
                        loading:false,
                        language:action.payload
                    }
                case languageLevelTypesConstants.FETCH_LANGUAGE_REQUEST:
                    return{
                        ...state,
                        fetchLanguageLoading:true,
                        language:{},
                        loading:false,
                    }
                    case languageLevelTypesConstants.FETCH_LANGUAGE_SUCCESS:
                        return{
                            ...state,
                            fetchLanguageLoading:false,
                            language:action.payload,
                            loading:false,
                        }
        default:
            return state;
    }
}

export default languageLevelTypes;
