import { userConstants } from '../constants/user.constants';

// TODO: multi-tenant
// Test with Passport

// TODO: Validation
// Email required
// Email unique
// Email not default
// Name required
// Username required
// Username unique

const initialState = {
    steps: [],
    siteData: '',
    schoolData: '',
    showPrivacyPolicy: true,
    showUsernameField: true,
    showPasswordField: true,
    showEmailField: true,
    loading: false,
    privacyRequestLoading: false,
    privacyConfirmed: false,
};

export function firstLoginData(state = initialState, action) {
    switch (action.type) {
        case userConstants.FIRST_LOGIN_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.FIRST_LOGIN_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                steps: action.loginData.steps,
                showPrivacyPolicy: action.loginData.showPrivacyPolicy,
                showUsernameField: action.loginData.showUsernameField,
                showPasswordField: action.loginData.showPasswordField,
                showEmailField: action.loginData.showEmailField,
            };
        case userConstants.PRIVACY_AGREE_REQUEST:
            return {
                ...state,
                privacyRequestLoading: true,
            };
        case userConstants.PRIVACY_AGREE_SUCCESS:
            return {
                ...state,
                privacyRequestLoading: false,
                privacyConfirmed: true,
            };
        case userConstants.PRIVACY_AGREE_REJECTED:
            return {
                ...state,
                privacyRequestLoading: false,
            };
        default:
            return state;
    }
}
