import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select, Table, Layout, Divider ,notification, Radio} from 'antd';

import { learningFocusActions } from '../redux/actions/learningFocus.actions';
import TableFooter from '../components/Shared/TableFooter';
import { axiosDefaultClient } from '../settings/axios.settings';
import languagesActions from "../redux/actions/languageLevelTypes.actions";
import './ConfigurationTable.scss'
import useSkeletonLoadingForTables from '../redux/Hooks/useSkeletonLoadingForTables';
import { retrieveDecryptedSiteFromLocalStorage } from '../utils/common';

const client =axiosDefaultClient
const { Option } = Select;

const LearningFocusesListPage = () => {
    const dispatch = useDispatch();
    const learningFocuses = useSelector(
        state => state.learningFocuses.learningFocuses,
    );
    const {meta} = useSelector(state => state.learningFocuses)
    const loading = useSelector(state => state.learningFocuses.loading);
    const [pageSize, setPageSize] = useState(10);
    const user = retrieveDecryptedSiteFromLocalStorage();

    const allLanguages = useSelector(
        state => state.languageLevelTypes.allLanguages,
    );

    useEffect(() => {
        dispatch(languagesActions.fetchAllLanguages('content', user?.siteID));
    }, []);

    const [langID, setLangID] = useState()

    const handleChange = (value) => {
        setLangID(value)
    }

    const [sortValue, setSortValue] = useState('asc');

    const handleSortChange = (e) => {
        setSortValue(e.target.value);
    };

    useEffect(() => {
        dispatch(learningFocusActions.fetchLearningFocus(null, null, 1, pageSize, langID, sortValue));
    }, [langID, sortValue]);

    const onPageChange = (page, size) => {
        dispatch(learningFocusActions.fetchLearningFocus(null, null, page, size));
        setPageSize(size);
    };

    const onPageSizeChange = value => {
        setPageSize(value);
        dispatch(learningFocusActions.fetchLearningFocus(null, null, 1, value));
    };

    const renderFooter = () => {
        return (
            <TableFooter
                meta={meta}
                pageSize={pageSize}
                onPageChange={onPageChange}
            />
        );
    };

    const columns = [
        {
            title: 'Learning Focus',
            dataIndex: 'learningCategory',
            key: 'Learning Fouces',
            width: '50%',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'Actions',
            width: '50%',
            render: (_, record) => (
                loading ? record.actions :
                <span>
                    <Link
                        to={{
                            pathname: `/admin/learning-focuses/manage/${record.learningCategoryID}`,
                        }}
                    >
                        Edit
                    </Link>
                    <Divider type="vertical" />
                    <Link
                    onClick={()=>handleDelete(record?.learningCategoryID)}
                    >
                        Delete
                    </Link>
                    <Divider type="vertical" />
                    <Link
                       onClick={()=> handleDisabled({
                        status:record?.status === "enabled" ?"disabled" :"enabled",
                        configuration:"focus",
                        configurationID:record.learningCategoryID
                    } )}

                    >
                         {record?.status === "enabled" ? "Disabled" : "Enabled"}
                    </Link>
                </span>
            ),
        },
    ];

    const skeletonLoading = useSkeletonLoadingForTables(columns)

    const handleDelete =(Id) =>{
        let url = `/api/learning-focuses/${Id}`
        client
        .delete(url)
        .then(response =>{
            notification.success({
                message:response.data.message
            })
            dispatch(learningFocusActions.fetchLearningFocus());
        }).catch(error=>{
            notification.error({
                message:error.data.message
            })

        })
    }
    const handleDisabled =(data) =>{
        let url = `/api/update-status`
        client
        .post(url , data
        )
        .then(response =>{
            notification.success({
                message:response.data.message
            })
            dispatch(learningFocusActions.fetchLearningFocus());
        }).catch(error=>{
            notification.error({
                message:error.data.message
            })

        })
    }
    return (
        <div className='configuration-table-container'>
            <Layout>
                <Row>
                    <Col span={12}>
                        <Link
                            to="/admin/learning-focuses/manage">
                            <Button type="primary" icon={<PlusOutlined />}>
                                Create New Learning Focus
                            </Button>
                        </Link>
                    </Col>
                    <Col span={12}>
                        <Row justify='space-between'>
                            <Col span={6} style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                                <Select style={{width: '100%'}} onChange={handleChange}>
                                    {allLanguages.payload?.map((language, index) => (
                                        <Option key={index} value={language.langID}>
                                            {language.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Radio.Group onChange={handleSortChange} value={sortValue} defaultValue={'asc'}>
                                    <Radio value={'asc'}>Alphabetical Order</Radio>
                                    <Radio value={'desc'}>Reverse Alphabetical Order</Radio>
                                </Radio.Group>
                            </Col>
                            <Col span={6} style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                                <span>
                            Show&nbsp;
                                    <Select
                                        defaultValue="10"
                                        onChange={onPageSizeChange}
                                    >
                                <Option value="10">10</Option>
                                <Option value="20">20</Option>
                                <Option value="50">50</Option>
                            </Select>
                                    &nbsp;entries
                        </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Table
                    dataSource={loading ? skeletonLoading : learningFocuses}
                    columns={columns}
                    rowKey={record => record.learningCategoryID}
                    style={{
                        backgroundColor: 'white',
                        marginTop: '10px',
                    }}
                    footer={() => renderFooter(learningFocuses)}
                    pagination={false}
                />
            </Layout>
        </div>
    );
};
export default LearningFocusesListPage;
