import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Menu, Badge, Divider } from 'antd';
// import './HeaderNotificationMenu.scss';
// moment import
import moment from 'moment';
import './Header.scss';

const HeaderNotificationMenu = ({ notifications }) => {
    return (
        <Menu className="headerNotificationMenu">
            <Menu.Item key="0">
                <Link to="/Notifications">
                    <span style={{ fontWeight: 600 }}>Notifications</span>
                </Link>
            </Menu.Item>
            <Menu.Divider />
            {notifications.map((notification, index) => {
                if (index < 5) {
                    return (
                        <Menu.Item
                            key={notification.data.id}
                            className="headerNotificationMenuItem"
                        >
                            <div>
                                <Col span={20}>
                                    <span>
                                        <Link
                                            to={`/moduleID/${notification.data.moduleID}`}
                                        >
                                            {notification.data.message}
                                        </Link>
                                    </span>
                                </Col>

                                <span className="headerNotificationDate">
                                    {moment(notification.created_at).fromNow()}
                                </span>

                                <Badge
                                    className="notificationRead"
                                    status={
                                        notification.read_at
                                            ? 'default'
                                            : 'success'
                                    }
                                />
                            </div>
                            <Divider dashed={true} />
                        </Menu.Item>
                    );
                }
            })}
            {notifications.length > 5 && (
                <Menu.Item>
                    <Link to="/notifications">
                        <strong>See All Notifications</strong>
                    </Link>
                </Menu.Item>
            )}
            {notifications.length === 0 && (
                <Menu.Item disabled>You have no notifications.</Menu.Item>
            )}
        </Menu>
    );
};

export default HeaderNotificationMenu;
