export const wishlistConstants = {
    WISHLIST_CREATING: 'WISHLIST_CREATING',
    WISHLIST_CREATE_SUCCESS: 'WISHLIST_CREATE_SUCCESS',
    WISHLIST_CREATE_REJECTED: 'WISHLIST_CREATE_REJECTED',
    WISHLIST_DELETE_SUCCESS: 'WISHLIST_DELETE_SUCCESS',
    WISHLIST_DELETE_REJECTED: 'WISHLIST_DELETE_REJECTED',
    WISHLIST_FETCHING: 'WISHLIST_FETCHING',
    WISHLIST_FETCH_SUCCESS: 'WISHLIST_FETCH_SUCCESS',
    WISHLIST_FETCH_REJECTED: 'WISHLIST_FETCH_REJECTED',
    FETCH_MORE_WISHLIST_MODULES_REQUEST: 'FETCH_MORE_WISHLIST_MODULES_REQUEST',
    FETCH_MORE_WISHLIST_MODULES_SUCCESS: 'FETCH_MORE_WISHLIST_MODULES_SUCCESS',
    FETCH_MORE_WISHLIST_MODULES_FAILED: 'FETCH_MORE_WISHLIST_MODULES_FAILED'
};
