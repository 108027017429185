import learnerProgress from '../constants/learnerProgress.constants';
import { ApiBaseURL } from '../../constants/apiConstants'
import { forEach } from 'lodash'
const initialState = {};

function learnerPathwaysProgress(state = initialState, action) {
    switch (action.type) {
        case learnerProgress.FETCH_PATHWAYS_PROGRESS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProgress.FETCH_PATHWAYS_PROGRESS_SUCCESS:
            for (let pathway of action.payload.pathwhays) {
                forEach(pathway.new_sections, (section) => {
                    section.imagePath = `${ApiBaseURL}${section.imagePath}`
                });
           }
            return {
                ...state,
                loading: false,
                pathwaysProgress: action.payload,
            };
        case learnerProgress.FETCH_PATHWAYS_PROGRESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                pathwaysProgress: [],
            };
        case learnerProgress.FETCH_PATHWAYS_PROGRESS_REQUEST_V2:
            return {
                ...state,
                loading: true,
            };
        case learnerProgress.FETCH_PATHWAYS_PROGRESS_SUCCESS_V2:
            for (let pathway of action.payload.pathways) {
                forEach(pathway.new_sections, (section) => {
                    section.imagePath = `${ApiBaseURL}${section.imagePath}`
                });
            }
            return {
                ...state,
                loading: false,
                pathwaysProgress: action.payload,
            };
        case learnerProgress.FETCH_PATHWAYS_PROGRESS_FAIL_V2:
            return {
                ...state,
                loading: false,
                error: action.payload,
                pathwaysProgress: [],
            };
        default:
            return state;
    }
}

export { learnerPathwaysProgress };
