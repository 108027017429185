import {contactGroupsConstants} from "../../constants/ContactGroups/contactGroups.contants";


const initialState = {
    loading: false,
    contactGroups: {}
}

function contactGroups(state = initialState, action) {
    switch (action.type) {
        case contactGroupsConstants.FETCH_CONTACT_GROUPS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case contactGroupsConstants.FETCH_CONTACT_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                contactGroups: action.payload
            }

        case contactGroupsConstants.FETCH_CONTACT_GROUPS_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }


        case contactGroupsConstants.DELETE_CONTACT_GROUPS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case contactGroupsConstants.DELETE_CONTACT_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                contactGroups: {
                    ...state.contactGroups,
                    payload: {
                        ...state.contactGroups.payload,
                        data: state.contactGroups.payload.data.filter(group => group.contactGroupID !== action.payload)
                    }
                }
            }

        case contactGroupsConstants.DELETE_CONTACT_GROUPS_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default: return state
    }
}

export default contactGroups;
