import React from 'react';
import { Form, Divider, Button, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../redux/actions/user.actions';

const FirstLoginPrivacyForm = props => {
    const dispatch = useDispatch();
    const { step, setCurrent, user } = props;
    const { content } = step;

    const { privacyConfirmed, privacyRequestLoading } = useSelector(
        state => state.firstLoginData,
    );

    const clickedPrivacyPolicy = () => {
        dispatch(userActions.privacyAgreed(user.userID)).then(() => {
            return setCurrent(1);
        });
    };

    return (
        <div>
            <div
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
            <Divider />
            <Form.Item name="privacyAgree" valuePropName="checked">
                <>
                    <p>To use our platform please accept our privacy policy</p>
                    <Button
                        disabled={privacyConfirmed}
                        style={{
                            backgroundColor: '#23ad1e',
                            borderRadius: 30,
                            color: 'white',
                        }}
                        onClick={clickedPrivacyPolicy}
                    >
                        {privacyRequestLoading ? (
                            <Spin spinning />
                        ) : (
                            'Click here to Accept'
                        )}
                    </Button>
                    <Divider />
                    <div style={{ marginTop: 10 }}>
                        Click{' '}
                        <a
                            href="http://www.eflextraining.com/privacy-and-cookie-policy/"
                            target="_blank"
                        >
                            here
                        </a>{' '}
                        to view the privacy policy in a new tab
                    </div>
                </>
            </Form.Item>
            {privacyConfirmed && (
                <p>
                    <em>You have already confirmed to the privacy policy.</em>
                </p>
            )}
        </div>
    );
};

export default FirstLoginPrivacyForm;
