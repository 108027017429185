import colorPickerConstants from '../constants/colorPicker.constants';

const initialState = {
    primaryColor: '#5371d3',
    secondaryColor: '#54af38',
    redColor: '#f92c2c',
    antDesignPrimaryColor: '#1890ff',
    antDesignDefaultColor: '#ffffff',
    antDesignPrimaryHoverColor: '#40a9ff',
    font: 'Raleway',
};

const colorPicker = (state = initialState, action) => {
    switch (action.type) {
        case colorPickerConstants.SET_COLORS:
            return {
                ...state,
                ...action.payload,
            };
        case colorPickerConstants.RESET_COLORS:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};

export default colorPicker;
