import formatConstants from '../constants/dateFormat.constant';

const initialState = {
    loading: false,
    dateFormats:[]
};

const dateFormats= (state = initialState, action) => {
    switch (action.type) {
        case formatConstants.FETCH_DATE_FORMAT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case formatConstants.FETCH_DATE_FORMAT_SUCCESS:
            return {
                ...state,
                loading: false,
                dateFormats: action.payload,
            };
        case formatConstants.FETCH_DATE_FORMAT_REJECTED:
            return {
                ...state,
                loading: false,
            };
               default:
            return state;
    }
};

export default dateFormats
