import React, { useEffect, useState } from 'react';
import { Table, Tag, Row, Col, Button, Modal, Form, notification, Space, Divider } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { axiosDefaultClient } from '../settings/axios.settings';
import CustomersForm from '../components/Admin/ScormCustomers/CustomersForm';
import { Link } from 'react-router-dom';
import useSkeletonLoadingForTables from "../redux/Hooks/useSkeletonLoadingForTables";
import './ConfigurationTable.scss'
const ScormCustomers = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(null);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deletedCustomer, setDeletedCustomer] = useState();
    const [editedCustomer, setEditedCustomer] = useState();
    const [form] = Form.useForm();

    const getCustomers = () => {
        setLoading(true)
        axiosDefaultClient.get('/api/customer?').then(response => {
            setCustomers(response.data.data ?? []);
            setLoading(false)
        });
    };

    useEffect(() => {
        getCustomers();
    }, []);

    const deleteCustomer = () => {
        axiosDefaultClient
            .delete(`/api/customer/${deletedCustomer.id}`)
            .then(response => {
                setOpenDelete(false);
                notification.success({
                    message: 'customer deleted successfully',
                });
                getCustomers();
                setDeletedCustomer(null);
            })
            .catch(e => {
                notification.error({ message: e.data?.message ?? 'error' });
            });
    };

    let columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '25%',
        },
        {
            title: 'Path',
            dataIndex: 'path',
            key: 'path',
            width: '25%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '25%',
            render: (_, record) =>
                record.status ? (
                    loading ? record.status : <Tag color="green">active</Tag>
                ) : (
                    loading ? record.status : <Tag color="red">not active</Tag>
                ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '25%',
            render: (_, record) => (
                loading ? record.actions :
                <Space
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        flexWrap: 'wrap',
                    }}
                >
                    <Link to={`/admin/scorm-cursomers/${record.id}`}>
                        customer packages
                    </Link>
                    <Divider type={isMobile ? '' : 'vertical'} />
                    <Link
                        onClick={() => {
                            setEditedCustomer(record);
                            setOpen(true);
                            form.setFieldsValue({
                                name: record.name,
                                path: record.path,
                                status: record.status,
                            });
                        }}
                    >
                        edit
                    </Link>
                    <Divider type={isMobile ? '' : 'vertical'} />
                    <Link
                        onClick={() => {
                            setDeletedCustomer(record);
                            setOpenDelete(true);
                        }}
                        style={{ cursor: 'pointer' }}
                        color="red"
                    >
                        delete
                    </Link>
                </Space>
            ),
        },
    ];

    const skeletonLoading = useSkeletonLoadingForTables(columns)

    const submitCustomer = () => {
        axiosDefaultClient
            .post('/api/customer', {
                ...form.getFieldsValue(),
            })
            .then(reponse => {
                setOpen(false);
                notification.success({
                    message: 'customer added successfully',
                });
                getCustomers();
            })
            .catch(e => {
                notification.error({ message: e.data?.message ?? 'error' });
            });
    };

    const editeCustomer = () => {
        axiosDefaultClient
            .put(`/api/customer/${editedCustomer.id}`, {
                ...form.getFieldsValue(),
            })
            .then(reponse => {
                setOpen(false);
                setEditedCustomer(null);
                form.setFieldsValue({ name: '', path: '', status: '' });
                notification.success({
                    message: 'customer updated successfully',
                });
                getCustomers();
            })
            .catch(e => {
                notification.error({ message: e.data?.message ?? 'error' });
            });
    };

    return (
        <div className='configuration-table-container'>
            <Row justify="start" gutter={16}>
                <Col>
                    <Button
                        onClick={() => {
                            setOpen(true);
                        }}
                        type="primary"
                    >
                        Add Customer
                    </Button>
                </Col>
                <Col span={24}>
                    <Table
                        pagination={false}
                        dataSource={loading ? skeletonLoading : customers}
                        columns={columns}
                    />
                </Col>
                <Modal
                    onOk={editedCustomer ? editeCustomer : submitCustomer}
                    onCancel={() => {
                        setOpen(false);
                    }}
                    afterClose={() => {
                        setEditedCustomer(null);
                        form.setFieldsValue({ name: '', path: '', status: '' });
                    }}
                    visible={open}
                    destroyOnClose={true}
                >
                    <CustomersForm editedCustomer={editedCustomer} form={form} />
                </Modal>
                <Modal
                    onOk={deleteCustomer}
                    onCancel={() => setOpenDelete(false)}
                    visible={openDelete}
                >
                    <p>Are you sure want to delete the customer</p>
                </Modal>
            </Row>
        </div>
    );
};

export default ScormCustomers;
