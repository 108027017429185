import React, { useState, useEffect } from 'react';
import {
    Button,
    Col,
    Divider,
    Row,
    Steps,
    Form,
    Layout,
    Spin,
    Modal,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import AdditionalInfoForm from '../components/AdditionalInfoForm';
import FirstLoginPrivacyForm from '../components/FirstLoginProcess/FirstLoginPrivacyForm';
import { userActions } from '../redux/actions/user.actions';
import PreferenceSettings from '../components/PreferenceSettings/PreferenceSettings';
import './FirstLoginPage.scss';
import './PreferenceSettings.scss';
import ProfileForm from '../components/ProfileForm';

const { Step } = Steps;

const FirstLoginPage = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {
        steps,
        showPrivacyPolicy,
        showUsernameField,
        showPasswordField,
        showEmailField,
    } = useSelector(state => state.firstLoginData);

    const loading = useSelector(state => state.firstLoginData.loading);
    const user = useSelector(state => state.authentication.user);
    const [current, setCurrent] = useState(0);
    const [preferencesVisible, setPreferencesVisible] = useState(false);

    useEffect(() => {
        if (user.role === 'learner') {
            dispatch(userActions.getFirstLoginData(user.userID));
        }
    }, []);

    const profileFormHelpContent = (
        <div>
            <p style={{ fontSize: '24px' }}>
                <QuestionCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ fontSize: '24px' }}
                />{' '}
                Help
            </p>
            <p>
                You can change your name, username, email and password in this
                step.
            </p>
            <p>
                If you skip this step now, you can change these details later.
            </p>
        </div>
    );

    const mappings = {
        privacyForm: (
            <FirstLoginPrivacyForm
                step={steps[current]}
                setCurrent={setCurrent}
                user={user}
            />
        ),
        personalDetailsForm: (
            <ProfileForm
                canChangeName
                showUsernameField={showUsernameField}
                showEmailField={showEmailField}
                showPasswordField={showPasswordField}
                helpContent={profileFormHelpContent}
            />
        ),
        additionalInfoDataForm: <AdditionalInfoForm />,
    };

    const getCurrentStep = () => {
        if (steps.length > 0) {
            return mappings[steps[current].type];
        }
        return '';
    };

    if (loading) {
        return (
            <div style={{ textAlign: 'center' }}>
                <Spin spinning />
            </div>
        );
    }

    const prev = () => {
        return setCurrent(current - 1);
    };

    const next = () => {
        return setCurrent(current + 1);
    };

    const handleSubmit = values => {
        // setPreferencesVisible(true);
        alert('ss')
    };

    const showNextButton = () => {
        if (showPrivacyPolicy) {
            if (current !== 0) {
                if (current < steps.length - 1) {
                    return true;
                }
                return false;
            }
            return false;
        }
        if (current < steps.length - 1) {
            return true;
        }
        return false;
    };

    const showPreviousButton = () => {
        if (showPrivacyPolicy) {
            return current > 1;
        }
        return current > 0;
    };

    return (
        <Layout style={{ backgroundColor: 'white', padding: 20 }}>
            <Form
                form={form}
                onFinish={handleSubmit}
                className="first-login-form"
            >
                <Steps current={current}>
                    {steps.map(item => (
                        <Step
                            key={item.title}
                            title={item.title}
                            description={item.description}
                        />
                    ))}
                </Steps>
                <Divider dashed />
                <div className="steps-content" style={{ marginTop: '20px' }}>
                    {getCurrentStep()}
                </div>
                <Divider dashed />
                <div className="steps-action">
                    <Row style={{ marginTop: '20px' }}>
                        <Col flex={1} style={{ textAlign: 'right' }}>
                            {showNextButton() && (
                                <Button type="primary" onClick={() => next()}>
                                    Next
                                </Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                                    Done
                                </Button>
                            )}
                            {showPreviousButton() && (
                                <Button
                                    style={{ marginLeft: 8 }}
                                    onClick={() => prev()}
                                >
                                    Previous
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
            </Form>
            {/*<Modal*/}
            {/*    width={850}*/}
            {/*    centered*/}
            {/*    visible={preferencesVisible}*/}
            {/*    className="preferenceSettings"*/}
            {/*>*/}
            {/*    <PreferenceSettings />*/}
            {/*</Modal>*/}
        </Layout>
    );
};

export default FirstLoginPage;
