import React, { useState } from 'react';
import { Drawer, Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import links from './NavigationLinks';
import './MobileNavigation.scss';
import { useSelector } from 'react-redux';

const { Panel } = Collapse;

const MobileNavigation = props => {
    const { user } = props;
    const [isVisible, setIsVisible] = useState(false);
    const userauth = useSelector(state => state.authentication.user);
    const navMobileMenus = links(user)(
        user.permissions.map(permission => permission.name),
    )[user.role];

    const MenuBar = () => {
        return (
            <div
                className="menuBar"
                type="primary"
                onClick={() => setIsVisible(!isVisible)}
            >
                <FontAwesomeIcon
                    icon={['fal', 'bars']}
                    style={{ marginRight: 5 }}
                />
                MENU
            </div>
        );
    };

    return (
        <div>
            <MenuBar />
            <Drawer
                className="mobileDrawer"
                placement="right"
                closable={false}
                onClose={() => setIsVisible(!isVisible)}
                visible={isVisible}
            >
                {navMobileMenus.map((menuItem, i) =>
                    menuItem.items ? (
                        <Collapse
                            key={i}
                            bordered={false}
                            className="mobileCollapse"
                        >
                            <Panel
                                header={menuItem.label}
                                style={{ border: 'none' }}
                                className="mobileDrawerItems"
                            >
                                <div>
                                    <Link
                                        to={menuItem.url}
                                        key={i}
                                        style={{ display: menuItem?.display }}
                                    />
                                </div>

                                {menuItem.items
                                    ? menuItem.items.map((item, i) => {
                                          if (item.items) {
                                              return (
                                                  <Collapse
                                                      key={i}
                                                      bordered={false}
                                                      className="mobileCollapse"
                                                  >
                                                      <Panel
                                                          header={item.label}
                                                          style={{
                                                              border: 'none',
                                                          }}
                                                          className="mobilePanelItem"
                                                          // showArrow={false}
                                                      >
                                                          <div>
                                                              <Link
                                                                  to={
                                                                      menuItem
                                                                          .items
                                                                          .url
                                                                  }
                                                                  key={i}
                                                              />
                                                          </div>
                                                          {item?.items?.map(
                                                              (subItem) => (
                                                                  <div
                                                                      className="mobilePanelItem"
                                                                      style={{
                                                                          padding: 6,
                                                                      }}
                                                                      key={
                                                                          item.url
                                                                      }
                                                                  >
                                                                      <Link
                                                                          to={
                                                                              subItem.url
                                                                          }
                                                                          key={
                                                                              i
                                                                          }
                                                                          className="mobilePanelItem"
                                                                          style={{
                                                                              display:
                                                                                  menuItem?.display,
                                                                          }}
                                                                      >
                                                                          {
                                                                              subItem.label
                                                                          }
                                                                      </Link>
                                                                  </div>
                                                              ),
                                                          )}
                                                      </Panel>
                                                  </Collapse>
                                              );
                                          } else
                                              return (
                                                  <div
                                                      className="mobilePanelItem"
                                                      style={{ padding: 6 }}
                                                      key={item.url}
                                                  >
                                                      <Link
                                                          to={item.url}
                                                          key={i}
                                                          className="mobilePanelItem"
                                                          style={{
                                                              display:
                                                                  menuItem?.display,
                                                          }}
                                                      >
                                                          {item.label}
                                                      </Link>
                                                  </div>
                                              );
                                      })
                                    : ''}
                            </Panel>
                        </Collapse>
                    ) : (
                        <div key={menuItem.url} className="mobileDrawerItems">
                            <Link
                                to={menuItem.url}
                                key={i}
                                className="mobileDrawerItem"
                                style={{ display: menuItem?.display }}
                            >
                                {menuItem.label}
                            </Link>
                        </div>
                    ),
                )}
            </Drawer>
        </div>
    );
};

export default MobileNavigation;
