import libraryBundlesConstants from "../constants/LibraryBundles.constants";

const initialState = {
    loading: false,
    updatingLoading: false,
    validationErrors: [],
    learnerLibraryBundles: [],
    libraryBundlesForUser: [],
    libraryBundlesForUserGroups: [],
    learnerLibraryBundle: {},
    learningLibraryBundle: {},
};

const libraryBundles = (state = initialState, action) => {
    switch (action.type) {
        case libraryBundlesConstants.FETCH_LIBRARY_BUNDLES_REQUEST:
        case libraryBundlesConstants.FETCH_LEARNER_LIBRARY_BUNDLES_REQUEST:
            return {
                ...state,
                loading: true,
                learnerLibraryBundles: [],
            };
        case libraryBundlesConstants.FETCH_LIBRARY_BUNDLES_SUCCESS:
            return {
                ...state,
                loading: false,
                learnerLibraryBundles: action.payload,
            };
        case libraryBundlesConstants.FETCH_LIBRARY_BUNDLES_REJECTED:
            return {
                ...state,
                loading: false,
                validationErrors: action.payload,
            };

        case libraryBundlesConstants.FETCH_LIBRARY_BUNDLES_FOR_USER_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case libraryBundlesConstants.FETCH_LIBRARY_BUNDLES_FOR_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                libraryBundlesForUser: action.payload,
            }
        case libraryBundlesConstants.FETCH_LIBRARY_BUNDLES_FOR_USER_REJECTED:
            return {
                ...state,
                loading: false,
                validationErrors: action.payload,
            }

        case libraryBundlesConstants.FETCH_LIBRARY_BUNDLES_FOR_USER_GROUPS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case libraryBundlesConstants.FETCH_LIBRARY_BUNDLES_FOR_USER_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                libraryBundlesForUserGroups: action.payload,
            }
        case libraryBundlesConstants.FETCH_LIBRARY_BUNDLES_FOR_USER_GROUPS_REJECTED:
            return {
                ...state,
                loading: false,
                validationErrors: action.payload,
            }
        default: return state;
    }
}

export default libraryBundles
