import {utilsConstants} from "../../constants/UtilsState/utils.constants";


const initialState = {
    logoutClicked: false,
    logoutActionClick: false,
    // state for showing alert if learner answer any question or not
    isAnsweringQuestions: false,
}

function utils(state = initialState, action) {
    switch (action.type) {
        case utilsConstants.LOGOUT_BEFORE_REQUEST:
            return {
                ...state,
                logoutClicked: action.payload
            }
        case utilsConstants.LOGOUT_AFTER_REQUEST:
            return {
                ...state,
                // logoutClicked: action.payload,
                logoutActionClick: action.payload
            }
        case utilsConstants.IS_ANSWERING_QUESTIONS:
            return {
                ...state,
                isAnsweringQuestions: action.payload
            }

        default: return state
    }
}

export default utils
