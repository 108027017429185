const messagesConstants = {
    CREATE_MESSAGES_REQUEST: 'CREATE_MESSAGES_START',
    CREATE_MESSAGES_SUCCESS: 'CREATE_MESSAGES_SUCCESS',
    CREATE_MESSAGES_REJECTED: 'CREATE_MESSAGES_REJECTED',

    FETCH_SENT_THREADS_REQUEST: 'FETCH_SENT_THREADS_REQUEST',
    FETCH_SENT_THREADS_SUCCESS: 'FETCH_SENT_THREADS_SUCCESS',
    FETCH_SENT_THREADS_REJECTED: 'FETCH_SENT_THREADS_REJECTED',

    CONVERSATION_REPLAY_REQUEST: 'CONVERSATION_REPLAY_REQUEST',
    CONVERSATION_REPLAY_SUCCESS: 'CONVERSATION_REPLAY_SUCCESS',
    CONVERSATION_REPLAY_REJECTED: 'CONVERSATION_REPLAY_REJECTED',

    FETCH_INBOX_THREADS_REQUEST: 'FETCH_INBOX_THREADS_REQUEST',
    FETCH_INBOX_THREADS_SUCCESS: 'FETCH_INBOX_THREADS_SUCCESS',
    FETCH_INBOX_THREADS_REJECTED: 'FETCH_INBOX_THREADS_REJECTED',

    FETCH_CONVERSATION_REQUEST: 'FETCH_CONVERSATION_REQUEST',
    FETCH_CONVERSATION_SUCCESS: 'FETCH_CONVERSATION_SUCCESS',
    FETCH_CONVERSATION_REJECTED: 'FETCH_CONVERSATION_REJECTED',

    FETCH_FILTERED_INBOX_MESSAGES_REQUEST: 'FETCH_FILTERED_INBOX_MESSAGES_REQUEST',
    FETCH_FILTERED_INBOX_MESSAGES_SUCCESS: 'FETCH_FILTERED_INBOX_MESSAGES_SUCCESS',
    FETCH_FILTERED_INBOX_MESSAGES_REJECTED: 'FETCH_FILTERED_INBOX_MESSAGES_REJECTED',

    FETCH_FILTERED_SENT_MESSAGES_REQUEST: 'FETCH_FILTERED_SENT_MESSAGES_REQUEST',
    FETCH_FILTERED_SENT_MESSAGES_SUCCESS: 'FETCH_FILTERED_SENT_MESSAGES_SUCCESS',
    FETCH_FILTERED_SENT_MESSAGES_REJECTED: 'FETCH_FILTERED_SENT_MESSAGES_REJECTED',

    FETCH_FILTERED_ARCHIVED_MESSAGES_REQUEST: 'FETCH_FILTERED_ARCHIVED_MESSAGES_REQUEST',
    FETCH_FILTERED_ARCHIVED_MESSAGES_SUCCESS: 'FETCH_FILTERED_ARCHIVED_MESSAGES_SUCCESS',
    FETCH_FILTERED_ARCHIVED_MESSAGES_REJECTED: 'FETCH_FILTERED_ARCHIVED_MESSAGES_REJECTED',

    FETCH_USERS_LINKED_TRAINERS_REQUEST: 'FETCH_USERS_LINKED_TRAINERS_REQUEST',
    FETCH_USERS_LINKED_TRAINERS_SUCCESS: 'FETCH_USERS_LINKED_TRAINERS_SUCCESS',
    FETCH_USERS_LINKED_TRAINERS_REJECTED: 'FETCH_USERS_LINKED_TRAINERS_REJECTED',

    FETCH_ARCHIVED_THREAD_REQUEST: 'FETCH_ARCHIVED_THREAD_REQUEST',
    FETCH_ARCHIVED_THREAD_SUCCESS: 'FETCH_ARCHIVED_THREAD_SUCCESS',
    FETCH_ARCHIVED_THREAD_REJECTED: 'FETCH_ARCHIVED_THREAD_REJECTED',

    MOVE_INBOX_THREAD_TO_ARCHIVED_REQUEST: 'MOVE_INBOX_THREAD_TO_ARCHIVED_REQUEST',
    MOVE_INBOX_THREAD_TO_ARCHIVED_SUCCESS: 'MOVE_INBOX_THREAD_TO_ARCHIVED_SUCCESS',
    MOVE_INBOX_THREAD_TO_ARCHIVED_REJECTED: 'MOVE_INBOX_THREAD_TO_ARCHIVED_REJECTED',

    CHANGE_TAB_KEY_STATE: 'CHANGE_TAB_KEY_STATE',
    CHANGE_SELECTED_USER: 'CHANGE_SELECTED_USER',

    FETCH_LATEST_MESSAGES_REQUEST: "FETCH_LATEST_MESSAGES_REQUEST",
    FETCH_LATEST_MESSAGES_SUCCESS: "FETCH_LATEST_MESSAGES_SUCCESS",
    FETCH_LATEST_MESSAGES_REJECTED: "FETCH_LATEST_MESSAGES_REJECTED",

    GET_TOTAL_INBOX_THREADS: 'GET_TOTAL_INBOX_THREADS',
    GET_TOTAL_SENT_THREADS: 'GET_TOTAL_SENT_THREADS',
    GET_TOTAL_ARCHIVED_THREADS: 'GET_TOTAL_ARCHIVED_THREADS',

    FETCH_LOAD_MORE_SENT_REQUEST: 'FETCH_LOAD_MORE_SENT_REQUEST',
    FETCH_LOAD_MORE_SENT_SUCCESS: 'FETCH_LOAD_MORE_SENT_SUCCESS',
    FETCH_LOAD_MORE_SENT_REJECTED: 'FETCH_LOAD_MORE_SENT_REJECTED',

    FETCH_LOAD_MORE_INBOX_REQUEST: 'FETCH_LOAD_MORE_INBOX_REQUEST',
    FETCH_LOAD_MORE_INBOX_SUCCESS: 'FETCH_LOAD_MORE_INBOX_SUCCESS',
    FETCH_LOAD_MORE_INBOX_REJECTED: 'FETCH_LOAD_MORE_INBOX_REJECTED',

    FETCH_LOAD_MORE_ARCHIVED_REQUEST: 'FETCH_LOAD_MORE_ARCHIVED_REQUEST',
    FETCH_LOAD_MORE_ARCHIVED_SUCCESS: 'FETCH_LOAD_MORE_ARCHIVED_SUCCESS',
    FETCH_LOAD_MORE_ARCHIVED_REJECTED: 'FETCH_LOAD_MORE_ARCHIVED_REJECTED',
}

export default messagesConstants;
