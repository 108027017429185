const authenticationConstants = {
    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
    RESET_PASSWORD_REQUEST_V2: 'RESET_PASSWORD_REQUEST_V2',
    RESET_PASSWORD_SUCCESS_V2: 'RESET_PASSWORD_SUCCESS_V2',
    RESET_PASSWORD_FAILURE_V2: 'RESET_PASSWORD_FAILURE_V2',
};

export default authenticationConstants;
