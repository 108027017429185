export const searchConstants = {
    SEARCH_SEARCHING: 'SEARCH_SEARCHING',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS',
    SEARCH_REJECTED: 'SEARCH_REJECTED',
    SEARCH_RESET: 'SEARCH_RESET',
    SEARCH_TOGGLE_KEYWORD_SEARCH: 'SEARCH_TOGGLE_KEYWORD_SEARCH',
    SEARCH_SET_LEARNING_LEVEL: 'SEARCH_SET_LEARNING_LEVEL',
    SEARCH_SET_LEARNING_SKILL: 'SEARCH_SET_LEARNING_SKILL',
    SEARCH_SET_LEARNING_FOCUS: 'SEARCH_SET_LEARNING_FOCUS',
    SEARCH_SET_KEYWORD: 'SEARCH_SET_KEYWORD'
};
