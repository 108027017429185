import picturesDbConstants from '../constants/picturesDb.constants';

const initialState = {};

function fetchAllDbPictures(state = initialState, action) {
    switch (action.type) {
        case picturesDbConstants.FETCH_DB_PICTURES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case picturesDbConstants.FETCH_DB_PICTURES_SUCCESS:
            return {
                ...state,
                loading: false,
                DbPictures: action.payload,
            };
        case picturesDbConstants.FETCH_DB_PICTURES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function deleteDbPictures(state = initialState, action) {
    switch (action.type) {
        case picturesDbConstants.DELETE_DB_PICTURES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case picturesDbConstants.DELETE_DB_PICTURES_SUCCESS:
            return {
                ...state,
                loading: false,
                deletePicture: action.payload,
            };
        case picturesDbConstants.DELETE_DB_PICTURES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function uploadDbPictures(state = initialState, action) {
    switch (action.type) {
        case picturesDbConstants.UPLOAD_DB_PICTURES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case picturesDbConstants.UPLOAD_DB_PICTURES_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadPicture: action.payload,
            };
        case picturesDbConstants.UPLOAD_DB_PICTURES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function selectDbPictures(state = initialState, action) {
    switch (action.type) {
        case picturesDbConstants.SELECT_DB_PICTURES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case picturesDbConstants.SELECT_DB_PICTURES_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedPicture: action.payload,
            };
        case picturesDbConstants.SELECT_DB_PICTURES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function moveDbPictures(state = initialState, action) {
    switch (action.type) {
        case picturesDbConstants.MOVE_DB_PICTURES_REQUEST:
            return {
                ...state,
            };
        case picturesDbConstants.MOVE_DB_PICTURES_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export {
    fetchAllDbPictures,
    deleteDbPictures,
    uploadDbPictures,
    selectDbPictures,
    moveDbPictures
};
