const languageLevelTypesConstants = {
    FETCH_LANGUAGE_LEVEL_TYPES_REQUEST: 'FETCH_LANGUAGE_LEVEL_TYPES_REQUEST',
    FETCH_LANGUAGE_LEVEL_TYPES_SUCCESS: 'FETCH_LANGUAGE_LEVEL_TYPES_SUCCESS',
    FETCH_LANGUAGE_LEVEL_TYPES_REJECTED: 'FETCH_LANGUAGE_LEVEL_TYPES_REJECTED',
    UPDATE_LANGUAGE_LEVEL_TYPES_REQUEST: 'UPDATE_LANGUAGE_LEVEL_TYPES_REQUEST',
    UPDATE_LANGUAGE_LEVEL_TYPES_SUCCESS: 'UPDATE_LANGUAGE_LEVEL_TYPES_SUCCESS',
    CREATE_LANGUAGE_LEVEL_TYPES_REQUEST: 'CREATE_LANGUAGE_LEVEL_TYPES_REQUEST',
    CREATE_LANGUAGE_LEVEL_TYPES_SUCCESS: 'CREATE_LANGUAGE_LEVEL_TYPES_SUCCESS',
    FETCH_LANGUAGE_LEVELS_REQUEST: 'FETCH_LANGUAGE_LEVELS_REQUEST',
    FETCH_LANGUAGE_LEVELS_SUCCESS: 'FETCH_LANGUAGE_LEVELS_SUCCESS',
    FETCH_TYPES_WITH_LEVELS_SUCCESS: 'FETCH_TYPES_WITH_LEVELS_SUCCESS',
    FETCH_LANGUAGE_LEVELS_REJECTED: 'FETCH_LANGUAGE_LEVELS_REJECTED',
    FETCH_ALL_LANGUAGES_REQUEST: 'FETCH_ALL_LANGUAGES_REQUEST',
    FETCH_ALL_LANGUAGES_SUCCESS: 'FETCH_ALL_LANGUAGES_SUCCESS',
    FETCH_ALL_LANGUAGES_REJECTED: 'FETCH_ALL_LANGUAGES_REJECTED',
    CREATE_LANGUAGE_REQUEST:'CREATE_LANGUAGE_REQUEST',
    CREATE_LANGUAGE_SUCCESS:'CREATE_LANGUAGE_SUCCESS',
    FETCH_LANGUAGE_REQUEST:'FETCH_LANGUAGE_REQUEST',
    FETCH_LANGUAGE_SUCCESS:'FETCH_LANGUAGE_SUCCESS',

    LANGUAGE_DATA:'LANGUAGE_DATA',
    LANGUAGE_DATA_SUCCESS:'LANGUAGE_DATA_SUCCESS',
    LANGUAGE_DATA_REJECTED:'LANGUAGE_DATA_REJECTED',

    UPDATE_LANGUAGE_DATA:'UPDATE_LANGUAGE_DATA',
    UPDATE_LANGUAGE_DATA_SUCCESS:'UPDATE_LANGUAGE_DATA_SUCCESS',
    UPDATE_LANGUAGE_DATA_REJECTED:'UPDATE_LANGUAGE_DATA_REJECTED',

    UPDATE_LANGUAGE_IMAGE:'UPDATE_LANGUAGE_IMAGE',
    UPDATE_LANGUAGE_IMAGE_SUCCESS:'UPDATE_LANGUAGE_IMAGE_SUCCESS',
    UPDATE_LANGUAGE_IMAGE_REJECTED:'UPDATE_LANGUAGE_IMAGE_REJECTED',
};

export default languageLevelTypesConstants;
