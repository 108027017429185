import activityMarkingConstants from '../constants/activityMarking.constants';
import { forEach } from 'lodash';

const initialState = {
    answers: JSON.parse(localStorage.getItem('lastCorrectAnswers')) ?? {},
    checkingAnswers: false,
    markedAnswers: [],
    tryAgain: false,
    showAnswers: false,
    questionCount: 0,
    openDrafts: [],
    questionsWithHints: {},
    tryAgainButtonOnClickStatus: false
};

const activityMarking = (state = initialState, action) => {
    const existingBlankAnswers = state.answers[action.questionID] || {};
    const existingAnswers = state.answers;
    switch (action.type) {
        case activityMarkingConstants.ACTIVITY_QUESTION_ANSWER_GIVEN:
            return {
                ...state,
                answers: state.tryAgain
                    ? {}
                    : {
                          ...state.answers,
                          [`${action.questionID}`]: action.answerID,
                      },
                tryAgain: false,
            };
        // case activityMarkingConstants.ACTIVITY_QUESTION_MULTIPLE_ANSWER_GIVEN:
        //     const answers = state.answers.hasOwnProperty(action.questionID)
        //         ? state.answers[action.questionID]
        //         : [];

        //     answers.push(action.answerID);
        //     let newMultipleAnswers = [...new Set(answers)];
        //     return {
        //         ...state,
        //         answers: state.tryAgain
        //             ? {}
        //             : {
        //                   ...state.answers,
        //                   [`${action.questionID}`]: [...answers],
        //               },
        //         tryAgain: false,
        //     };
        case activityMarkingConstants.ACTIVITY_QUESTION_MULTIPLE_ANSWER_GIVEN:
            let answers = Array.isArray(action.answers) ? action.answers : [];
            return {
                ...state,
                answers: state.tryAgain
                    ? {}
                    : {
                          ...state.answers,
                          [`${action.questionID}`]: [...answers],
                      },
                tryAgain: false,
            };
        case activityMarkingConstants.ACTIVITY_QUESTION_MULTIPLE_ANSWER_REMOVED:
            const newAnswers = state.answers[action.questionID].filter(
                ans => ans !== action.answerID,
            );
            return {
                ...state,
                answers: state.tryAgain
                    ? {}
                    : {
                          ...state.answers,
                          [`${action.questionID}`]: newAnswers,
                      },
                tryAgain: false,
            };
        case activityMarkingConstants.ACTIVITY_QUESTION_PAIRED_ANSWER_GIVEN:
            return {
                ...state,
                answers: state.tryAgain
                    ? {}
                    : {
                          ...state.answers,
                          [`${action.questionID}`]: {
                              ...state.answers[action.questionID],
                              [`${action.answerID}`]: action.pair,
                          },
                      },
                tryAgain: false,
            };
        case activityMarkingConstants.ACTIVITY_BLANK_ANSWER_GIVEN:
            existingBlankAnswers[action.answerKey] = action.value;
            existingAnswers[action.questionID] = existingBlankAnswers;
            return {
                ...state,
                answers: { ...existingAnswers },
            };
        case activityMarkingConstants.ACTIVITY_QUESTION_MIXED_SENTENCE_ANSWER_GIVEN:
            const newA = state.answers;
            const { questionID, answerID, words } = action;
            if (!newA?.hasOwnProperty(questionID)) {
                newA[questionID] = {};
            }
            newA[questionID][answerID] = words;
            return {
                ...state,
                answers: { ...newA },
            };
        case activityMarkingConstants.ACTIVITY_QUESTION_REORDER:
            const newAns = state.answers;
            if (!newAns?.hasOwnProperty(action.questionID)) {
                newAns[action.questionID] = {};
            }
            newAns[action.questionID] = action.answers.filter(ans => ans !== '');
            return {
                ...state,
                answers: {...newAns},
            };
        case activityMarkingConstants.ACTIVITY_QUESTION_WHAT_IS_THIS_ANSWER_GIVEN:
            const newAnswer = state.answers;
            if (!newAnswer?.hasOwnProperty(action.questionID)) {
                newAnswer[action.questionID] = {};
            }
            newAnswer[action.questionID] = action.answer.replace(/ /g, ' ');
            return {
                ...state,
                answers: { ...newAnswer },
            };
        case 'ACTIVITY_OPEN_ANSWER_GIVEN':
            const newAn = state.answers;
            const { answer } = action;
            const qID = action.questionID;
            if (!newAn?.hasOwnProperty(qID)) {
                newAn[qID] = {};
            }
            newAn[qID] = answer;
            return {
                ...state,
                answers: { ...newAn },
            };
        case activityMarkingConstants.ACTIVITY_CHECK_ANSWERS:
            return {
                ...state,
                checkingAnswers: true,
                tryAgain: false,
                tryAgainButtonOnClickStatus: false
            };
        case activityMarkingConstants.ACTIVITY_ANSWERS_CHECKED:
            return {
                ...state,
                checkingAnswers: false,
                showAnswers: false,
                markedAnswers: action.data,
                tryAgain: true,
                responseOfCheckAnswer: action.data ?? [],
            };
        case activityMarkingConstants.ACTIVITY_ANSWERS_SHOW_CORRECT:
            return {
                ...state,
                showAnswers: true,
                correctAnswers: action.data,
                answers: {},
                markedAnswers: [],
                tryAgainButtonOnClickStatus: false
                //  responseOfCheckAnswer : action.data??[]
            };
        case activityMarkingConstants.ACTIVITY_SHOW_ANSWERS:
            return {
                ...state,
                //  markedAnswers: [],
            };
        case activityMarkingConstants.ACTIVITY_TRY_AGAIN:
            return {
                ...state,
                tryAgain: false,
                showAnswers: false,
                answers: {},
                markedAnswers: [],
                correctAnswers: [],
                tryAgainButtonOnClickStatus: true
            };

        case activityMarkingConstants.ACTIVITY_QUESTION_COUNT:
            return {
                ...state,
                questionCount: action.questionCount,
            };
        case 'ACTIVITY_BACK_BUTTON':
            return {
                ...initialState,
            };
        case 'ACTIVITY_CLEAR_MARKED':
            return {
                ...initialState,
                answers: {},
            };
        case 'ACTIVITY_DND_ANSWER_REMOVED':
            const answerRemoved = state.answers;
            forEach(state.answers, (question, questionID) => {
                return forEach(question, (ans, answerID) => {
                    if (answerID === action.answerID) {
                        delete answerRemoved[questionID][answerID];
                    }
                });
            });
            return {
                ...state,
                answers: { ...answerRemoved },
            };
        case 'ACTIVITY_CHECK_DRAFT':
            if (action.drafts) {
                return {
                    ...state,
                    openDrafts: action.drafts,
                };
            } else {
                return {
                    ...state,
                    openDrafts: [],
                };
            }
        case 'HINT_USED':
            let hint = state.questionsWithHints;
            hint[action.questionID] = action.answerIndex;
            return {
                ...state,
                questionsWithHints: hint,
            };
        default:
            return state;
    }
};

export default activityMarking;
