import { axiosDefaultClient } from '../../settings/axios.settings';
import languagesConstant from '../constants/languages.constant';

const client = axiosDefaultClient;

const fetchLanguagesContent = userId => {
    return dispatch => {
        dispatch({
            type: languagesConstant.FETCH_LANGUAGES_REQUEST,
        });
        const url = `/api/user/${userId}/languages`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: languagesConstant.FETCH_LANGUAGES_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch(error =>
                dispatch({
                    type: languagesConstant.FETCH_LANGUAGES_REJECTED,
                    payload: error,
                }),
            );
    };
};

const languagesActions = {
    fetchLanguagesContent,
};

export default languagesActions;
