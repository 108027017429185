import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';
import './apiDocumentation.scss';
import ApiTable from './apiComponent/ApiTable';

const { Header, Content, Footer } = Layout;
const { TabPane } = Tabs;

import details from '../../utilities/api-details.json';
import { retrieveDecryptedSiteFromLocalStorage } from '../../utils/common';
import pdfFile from '../../assets/sso-doc.pdf';

const apiDocumentation = () => {
    let site = retrieveDecryptedSiteFromLocalStorage();
    const [header, setHeader] = useState('Getting Started');

    const matchKeys = {
        2: 'getUsers',
        3: 'storeUser',
        4: 'getGroups',
        5: 'storeGroup',
        6: 'getBundles',
        7: 'getPathways',
        8: 'userReport',
        15: 'deleteUserGroups',
        10: 'deleteUsers',
        11: 'getCourses',
        12: 'ssoIntegration',
    };

    const handleClickTab = (key, event) => {
        let __details = details[matchKeys[key]];
        if (__details === undefined) setHeader('Getting Started');
        else setHeader(__details['header']);
    };

    return (
        <div className="apis-documentation">
            <Layout className="layout">
                <Header theme="light">
                    <div className="container" style={{ height: '100%' }}>
                        <div className="logo" style={{ height: '100%' }}>
                            <img
                                style={{ width: '100%' }}
                                src={
                                    site?.smallLogo_s3 ??
                                    '/images/user/icons/logo.svg'
                                }
                                alt="eflex"
                            />
                        </div>
                    </div>
                </Header>
                <Content>
                    <h2>API Documentation</h2>
                    <p>Show user info and other info</p>
                    <div className="content-heading">
                        <h4>API Calls</h4>
                        <h4>{header}</h4>
                    </div>
                    <Tabs tabPosition="left" onTabClick={handleClickTab}>
                        <TabPane
                            tab={
                                <div className="heading-tabs">
                                    <img
                                        src={'/images/user/icons/api/housess.svg'}
                                        alt="Icon"
                                    />
                                    Getting Started
                                </div>
                            }
                            key="1"
                        >
                            <div className="get-started">
                                <h2>API Details</h2>
                                <p>
                                    The API works using http POST, passing in a
                                    JSON encoded object, and returns a JSON
                                    encoded object.
                                    <br />
                                    <br />
                                    Before you can access the API you will need
                                    your API key that will be provided by
                                    eflexlanguages. Please contact eflex
                                    international for the key directly.
                                    <br />
                                    <br />
                                    Mis-use of the API will resort in the
                                    removal of your API key.
                                </p>
                            </div>
                        </TabPane>
                        {site?.apiSettings?.get_users && (
                            <TabPane
                                tab={
                                    <div className="heading-tabs">
                                        <img
                                            src={'/images/user/icons/api/Group 13054.svg'}
                                            alt="Icon"
                                        />
                                        Fetch Users
                                    </div>
                                }
                                key="2"
                            >
                                <ApiTable subject={'getUsers'} />
                            </TabPane>
                        )}
                        {site?.apiSettings?.update_or_create_users && (
                            <TabPane
                                tab={
                                    <div className="heading-tabs">
                                        <img
                                            src={'/images/user/icons/api/Icons - Navigation icon (12 states).svg'}
                                            alt="Icon"
                                        />
                                        New User
                                    </div>
                                }
                                key="3"
                            >
                                <ApiTable subject={'storeUser'} />
                            </TabPane>
                        )}
                        {site?.apiSettings?.delete_users && (
                            <TabPane
                                tab={
                                    <div className="heading-tabs">
                                        <img
                                            src={'/images/user/icons/api/Icons - Navigation icon (12 states).svg'}
                                            alt="Icon"
                                        />
                                        Delete Users
                                    </div>
                                }
                                key="10"
                            >
                                <ApiTable subject={'deleteUsers'} />
                            </TabPane>
                        )}
                        {site?.apiSettings?.get_user_groups && (
                            <TabPane
                                tab={
                                    <div className="heading-tabs">
                                        <img
                                            src={'/images/user/icons/api/driving-instructor.svg'}
                                            alt="Icon"
                                        />
                                        Fetch Groups
                                    </div>
                                }
                                key="4"
                            >
                                <ApiTable subject={'getGroups'} />
                            </TabPane>
                        )}
                        {site?.apiSettings?.update_or_create_user_groups && (
                            <TabPane
                                tab={
                                    <div className="heading-tabs">
                                        <img
                                            src={'/images/user/icons/api/driving-instructora.svg'}
                                            alt="Icon"
                                        />
                                        New Group
                                    </div>
                                }
                                key="5"
                            >
                                <ApiTable subject={'storeGroup'} />
                            </TabPane>
                        )}
                        {site?.apiSettings?.delete_user_groups && (
                            <TabPane
                                tab={
                                    <div className="heading-tabs">
                                        <img
                                            src={'/images/user/icons/api/driving-instructora.svg'}
                                            alt="Icon"
                                        />
                                        Delete Groups
                                    </div>
                                }
                                key="15"
                            >
                                <ApiTable subject={'deleteUserGroups'} />
                            </TabPane>
                        )}
                        {site?.apiSettings?.get_course_bundles && (
                            <TabPane
                                tab={
                                    <div className="heading-tabs">
                                        <img
                                            src={'/images/user/icons/api/book.svg'}
                                            alt="Icon"
                                        />
                                        Fetch Course Bundles
                                    </div>
                                }
                                key="6"
                            >
                                <ApiTable subject={'getBundles'} />
                            </TabPane>
                        )}
                        {site?.apiSettings?.get_courses && (
                            <TabPane
                                tab={
                                    <div className="heading-tabs">
                                        <img
                                            src={'/images/user/icons/api/book.svg'}
                                            alt="Icon"
                                        />
                                        Fetch Courses
                                    </div>
                                }
                                key="11"
                            >
                                <ApiTable subject={'getCourses'} />
                            </TabPane>
                        )}
                        {site?.apiSettings?.get_pathways && (
                            <TabPane
                                tab={
                                    <div className="heading-tabs">
                                        <img
                                            src={'/images/user/icons/api/options.svg'}
                                            alt="Icon"
                                        />
                                        Fetch Learning Pathways
                                    </div>
                                }
                                key="7"
                            >
                                <ApiTable subject={'getPathways'} />
                            </TabPane>
                        )}
                        {site?.apiSettings?.report_user && (
                            <TabPane
                                tab={
                                    <div className="heading-tabs">
                                        <img
                                            src={'/images/user/icons/api/Icons - Navigation icon (12 states)aa.svg'}
                                            alt="Icon"
                                        />
                                        User Reports
                                    </div>
                                }
                                key="8"
                            >
                                <ApiTable subject={'userReport'} />
                            </TabPane>
                        )}
                        <TabPane
                            tab={
                                <div className="heading-tabs">
                                    <img
                                        src={'/images/user/icons/api/sso.svg'}
                                        alt="Icon"
                                    />
                                    SSO Integration
                                </div>
                            }
                            key="12"
                        >
                            <div className="pdf-container">
                                <iframe
                                    src={pdfFile}
                                    title="API Documentation PDF"
                                    width="100%"
                                    height="600px"
                                />
                            </div>
                        </TabPane>
                    </Tabs>
                </Content>
                <Footer>
                    <div className="container">
                        Copyright &copy;{' '}
                        {site?.copyright ??
                            '2024 All Rights Reserved Eflexlanguages'}
                    </div>
                </Footer>
            </Layout>
        </div>
    );
};

export default apiDocumentation;
