import { axiosDefaultClient } from '../../settings/axios.settings';
import { learningLevelsConstants } from '../constants/learningLevels.constants';

export const learningLevelActions = {
    fetchLearningLevels,
};

const client = axiosDefaultClient;

function fetchLearningLevels(siteId) {
    return function(dispatch) {
        let url = `/api/learning-levels?`;
        if (siteId) url = url + `siteID=${siteId}`;
        dispatch({
            type: learningLevelsConstants.FETCH_LEARNING_LEVELS_REQUEST,
        });
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: learningLevelsConstants.FETCH_LEARNING_LEVELS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type:
                        learningLevelsConstants.FETCH_LEARNING_LEVELS_REJECTED,
                    payload: error,
                });
            });
    };
}
