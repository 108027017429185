const permissionsConstants = {
    FETCH_PERMISSIONS_REQUEST: 'FETCH_PERMISSIONS_REQUEST',
    FETCH_PERMISSIONS_SUCCESS: 'FETCH_PERMISSIONS_SUCCESS',
    FETCH_PERMISSIONS_REJECTED: 'FETCH_PERMISSIONS_REJECTED',
    GET_PERMISSIONS_REQUEST: 'GET_PERMISSIONS_REQUEST',
    GET_PERMISSIONS_SUCCESS: 'GET_PERMISSIONS_SUCCESS',
    GET_PERMISSIONS_REJECTED: 'GET_PERMISSIONS_REJECTED',
    FETCH_ROLES_REQUEST: 'FETCH_ROLES_REQUEST',
    FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
    FETCH_ROLES_REJECTED: 'FETCH_ROLES_REJECTED',
    SAVE_ROLE_REQUEST: 'SAVE_ROLE_REQUEST',
    SAVE_ROLE_SUCCESS: 'SAVE_ROLE_SUCCESS',
    SAVE_ROLE_REJECTED: 'SAVE_ROLE_REJECTED',
};

export default permissionsConstants;
