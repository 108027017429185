import {feedbackConstants} from "../constants/feedback.constants";


const initialState = {
    loading: false,
    feedbacks: {
        data: [],
        meta: {}
    },
    feedback: {
        questions_list: [],
    },
    error: null
};

export function feedbacks(state = initialState, action) {
    switch (action.type) {
        // Fetch Feedbacks List
        case feedbackConstants.FETCH_FEEDBACK_REQUEST:
            return {
                ...state,
                loading: true
            }
        case feedbackConstants.FETCH_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: false,
                feedbacks: {
                    ...state.feedbacks,
                    data: action.payload.data,
                    meta: action.payload.meta
                }
            }
        case feedbackConstants.FETCH_FEEDBACK_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // Get Feedback By ID
        case feedbackConstants.GET_FEEDBACK_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case feedbackConstants.GET_FEEDBACK_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                feedback: action.payload
            }
        case feedbackConstants.GET_FEEDBACK_BY_ID_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        // Clear Feedback
        case feedbackConstants.Clear_FEEDBACK:
            return {
                ...state,
                feedback: {},
                loading: false
            }


        // Store New Feedback
        case feedbackConstants.STORE_NEW_FEEDBACK_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case feedbackConstants.STORE_NEW_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: false,
                feedbacks: [...state.feedbacks?.data, action.payload]
            }
        case feedbackConstants.STORE_NEW_FEEDBACK_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // Update Feedback
        case feedbackConstants.UPDATED_FEEDBACK_REQUEST:
            return {
                ...state,
                loading: true
            }
        case feedbackConstants.UPDATED_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case feedbackConstants.UPDATED_FEEDBACK_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // Delete Feedback
        case feedbackConstants.DELETE_FEEDBACK_REQUEST:
            return {
                ...state,
                loading: true
            }
        case feedbackConstants.DELETE_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbacks: {
                    ...state.feedbacks,
                    data: state.feedbacks.data.filter(feedback => feedback.id !== action.payload)
                },
                loading: false,
            }
        case feedbackConstants.DELETE_FEEDBACK_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }


        // Add Question
        case feedbackConstants.ADD_QUESTION_TO_LIST:
            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    questions_list: [...state.feedback.questions_list, action.payload]
                }
            }

        case feedbackConstants.UPDATE_LIST_QUESTION:
            const newArr = [...state.feedback.questions_list]
            const updatedQuestionObject = action.payload
            const index = newArr.indexOf(newArr.find(question => question.question_id === updatedQuestionObject.question_id))
            if (index !== -1) {
                newArr[index] = updatedQuestionObject
            }
            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    questions_list: [...newArr]
                }
            }
        // Delete Question
        case feedbackConstants.DELETE_QUESTION:
            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    questions_list: state.feedback.questions_list.filter(question => question.question_id !== action.payload)
                }
            }
        default :
            return state
    }
}
