import savedContentConstants from '../constants/savedContent.constants';

const initialState = {
    loading: false,
    loadingMark: false,
    loadingActivities: false,
    content: [],
    metaData: {},
    activities: [],
};

const savedContent = (state = initialState, action) => {
    switch (action.type) {
        case savedContentConstants.Get_SAVE_CONTENT:
            return {
                ...state,
                loading: true,
                content: [],
            };
        case savedContentConstants.Get_SAVE_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                content: action?.payload?.content ?? [],
                metaData: action?.payload?.metaData ?? {},
            };
        case savedContentConstants.MARK_CONTENT:
            return {
                ...state,
                loadingMark: true,
            };
        case savedContentConstants.MARK_CONTENT_SUCCESS:
            let content = state.content;
            let contentId = action.payload?.savedContentId;
            let item = action.payload?.item;
            let contentIndex = state.content.findIndex(x => x.id == contentId);
            if (contentIndex != -1) {
                content[contentIndex] = item;
            }
            return {
                ...state,
                loadingMark: false,
                content: [...content],
            };
            case savedContentConstants.FETCH_ACTIVITY_CONTENT_ACTIVITIES_REQUEST:
                return {
                    ...state,
                    loadingActivities: true,
                };
            case savedContentConstants.FETCH_ACTIVITY_CONTENT_ACTIVITIES_SUCCESS:
                return {
                    ...state,
                    activities: action?.payload,
                    loadingActivities: false,
                };
            case savedContentConstants.FETCH_ACTIVITY_CONTENT_ACTIVITIES_REJECTED:
                return {
                    ...state,
                    loadingActivities: false,
                };
        default:
            return state;
    }
};

export default savedContent;
