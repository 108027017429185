const questionnaireMarkingConstants = {
    QUESTIONNAIRE_QUESTION_ANSWER_GIVEN: 'QUESTIONNAIRE_QUESTION_ANSWERED',
    QUESTIONNAIRE_QUESTION_MULTIPLE_ANSWER_GIVEN:
        'QUESTIONNAIRE_QUESTION_MULTIPLE_ANSWER_GIVEN',
    QUESTIONNAIRE_QUESTION_MULTIPLE_ANSWER_REMOVED:
        'QUESTIONNAIRE_QUESTION_MULTIPLE_ANSWER_REMOVED',
    QUESTIONNAIRE_QUESTION_MIXED_SENTENCE_ANSWER_GIVEN:
        'QUESTIONNAIRE_QUESTION_MIXED_SENTENCE_ANSWER_GIVEN',
    QUESTIONNAIRE_QUESTION_WHAT_IS_THIS_ANSWER_GIVEN:
        'QUESTIONNAIRE_QUESTION_WHAT_IS_THIS_ANSWER_GIVEN',
    QUESTIONNAIRE_QUESTION_REORDER: 'QUESTIONNAIRE_QUESTION_REORDER',
    QUESTIONNAIRE_QUESTION_PAIRED_ANSWER_GIVEN: 'QUESTIONNAIRE_QUESTION_PAIRED_ANSWERED',
    QUESTIONNAIRE_BLANK_ANSWER_GIVEN: 'QUESTIONNAIRE_BLANK_ANSWERED',
    QUESTIONNAIRE_CHECK_ANSWERS: 'QUESTIONNAIRE_CHECK_ANSWERS',
    QUESTIONNAIRE_ANSWERS_CHECKED: 'QUESTIONNAIRE_ANSWERS_CHECKED',
    QUESTIONNAIRE_ANSWERS_SHOW_CORRECT: 'QUESTIONNAIRE_ANSWERS_SHOW_CORRECT',
    QUESTIONNAIRE_SHOW_ANSWERS: 'QUESTIONNAIRE_SHOW_ANSWERS',
    QUESTIONNAIRE_ANSWERS_SHOWN: 'QUESTIONNAIRE_ANSWERS_SHOWN',
    QUESTIONNAIRE_TRY_AGAIN: 'QUESTIONNAIRE_TRY_AGAIN',
    QUESTIONNAIRE_QUESTION_COUNT: 'QUESTIONNAIRE_QUESTION_COUNT',
    CLEAR_CURRENT_QUESTIONNAIRE: 'CLEAR_CURRENT_QUESTIONNAIRE',
};

export default questionnaireMarkingConstants;
