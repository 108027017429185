/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Layout, Menu, Avatar, Dropdown, Badge, Button, Modal, Col, Row} from 'antd';
import ReactAvatar from 'react-avatar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faLinkedinIn,
    faFacebookF,
    faTwitter,
    faBloggerB,
    faYoutube,
    faFlickr,
    faPinterest,
    faGooglePlus,
    faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import {faBuilding} from '@fortawesome/free-solid-svg-icons';

import {faUserGraduate} from '@fortawesome/free-solid-svg-icons';
import languagesActions from '../redux/actions/languages.actions';
import learnerProfileActions from '../redux/actions/learnerProfile.actions';
import {userActions} from '../redux/actions/user.actions';
import {
    checkedSvg,
    defaultAvatar,
    logoutIcon,
    myProfile,
    progressIcon,
    settingsIcon,
} from '../components/Shared/SVG/learnerIcons';
import '../../public/fontello/css/fontello.css';
import './LearnerDashboardPage.scss';
import ModalUsernameAndPassword from '../components/Learner/welcome-messages/usernameAndPassword';
import {
    AppstoreOutlined,
    HeartOutlined,
    SearchOutlined,
    SnippetsOutlined,
} from '@ant-design/icons';
import dashboard from '../../public/images/user/icons/dashboard.svg';
import settingsActions from '../redux/actions/learnerProfile.actions';
import {messagesActions} from "../redux/actions/messages.actions";
import notifications from "../redux/reducers/notifications.reducer";
import notificationActions from "../redux/actions/notification.actions";
import {utilsConstants} from "../redux/constants/UtilsState/utils.constants";
import { getSiteFlag, retrieveDecryptedSiteFromLocalStorage, retrieveDecryptedUserFromLocalStorage } from '../utils/common';
import version from '../utils/version'
import { printUrl } from '../utils/common';
import { useHistory } from 'react-router-dom';
import LoginPopUpMessageModal from '../components/LoginPopUpMessageModal';
import * as Sentry from "@sentry/react";
import ConfirmLogOutModal from '../components/ConfirmLogOutModal';
import SsoButton from '../components/SsoButton';
const {Header, Footer} = Layout;

const LearnerDashboardPage = props => {
    const [dimensions, setDimensions] = React.useState({
        width: window.innerWidth,
    });
    const [confirmLogoutModalVisibility, setConfirmLogoutModalVisibility] = React.useState(false);

    const user = localStorage.getItem('user')
        ? retrieveDecryptedUserFromLocalStorage()
        : {};
    const site = localStorage.getItem('site')
        ? retrieveDecryptedSiteFromLocalStorage()
        : {};
    const language = JSON.parse(localStorage.getItem('language') ?? '{}');
    const lang = localStorage.getItem('lang')
        ? JSON.parse(localStorage.getItem('lang'))
        : {};

    const {languagesContent} = useSelector(state => state.languagesContent);
    const userWelcomeMassages = useSelector(
        state => state.users.welcomeMassages,
    );
    const {latestMessages} = useSelector(state => state.messages)
    const {learnerNotifications} = useSelector(state => state.notifications)
    const {pathways} = useSelector(state => state.learnerDashboardPathways)
    const {logoutClicked} = useSelector(state => state.utils)
    const dashPath = useSelector((state) => state.learnerDashboardPathways);

    const dispatch = useDispatch();
    const history = useHistory();
    const [loginPopUpMsg, setLoginPopUpMsg] = useState(
        localStorage.getItem('loginPopUpMsg')
            ? localStorage.getItem('loginPopUpMsg')
            : null,
    );
    // Global  language function
    // return the value for key from language
    // if key not fount retun the key with replace _ and Capitalize
    window['__lang'] = function (word) {
        const language = JSON.parse(localStorage.getItem('language') ?? '{}');
        const titleCase = str => {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                // You do not need to check if i is larger than splitStr length, as your for does that for you
                // Assign it back to the array
                splitStr[i] =
                    (i === 0
                        ? splitStr[i].charAt(0).toUpperCase()
                        : splitStr[i].charAt(0)) + splitStr[i].substring(1);
            }
            // Directly return the joined string
            return splitStr.join(' ');
        };
        if (language) {
            if (language['' + word]) {
                return language['' + word];
            }
        }
        return titleCase(word.replaceAll('_', ' '));
    };
    // return the flag of showing profile @author Amr
    const hasShowProfile = getSiteFlag('showMyProfile');

    const flagsIcons = {
        es: 'https://hatscripts.github.io/circle-flags/flags/es.svg',
        en: 'https://hatscripts.github.io/circle-flags/flags/gb.svg',
        pt: 'https://hatscripts.github.io/circle-flags/flags/pt.svg',
        de: 'https://hatscripts.github.io/circle-flags/flags/de.svg',
        fr: 'https://hatscripts.github.io/circle-flags/flags/fr.svg',
    };

     // Set Sentry user details
     Sentry.setUser({
        id: user.userID,
        username: user.username,
    });

    useEffect(() => {
        dispatch(languagesActions.fetchLanguagesContent(user.userID));
        dispatch(learnerProfileActions.fetchProfileInfo(user.userID));
        dispatch(userActions.welcomMassages(user.userID));
        dispatch(messagesActions.fetchLatestMessages())
        dispatch(notificationActions.fetchLearnerNotifications())
    }, []);

    const privacyAgreed = () => {
        const user = localStorage.getItem('user')
            ? retrieveDecryptedUserFromLocalStorage()
            : {};
        dispatch(userActions.privacyAgreed(user.userID));
        dispatch(userActions.welcomMassages(user.userID));
    };

    useEffect(() => {
        if (!localStorage.getItem('langId') && languagesContent) {
            const defaultLang = languagesContent.find(
                lang => lang.langCode === 'en',
            );

            if (defaultLang)
                localStorage.setItem('langId', defaultLang?.langID);
            else !defaultLang && languagesContent.length > 0;
            localStorage.setItem('langId', languagesContent[0].langID);
        }
    }, [languagesContent]);

    //useEffect for width
    useEffect(() => {
        function handleResize() {
            setDimensions({
                width: window.innerWidth,
            });
        }

        window.addEventListener('resize', handleResize);

        return _ => {
            window.removeEventListener('resize', handleResize);
        };
    });

    const profileInfo = useSelector(state => state.fetchProfileInfo);

    const logout = () => {
        userActions.logout(history);
    };
    const handleOk = () => {
        if (logoutClicked) {
            dispatch({
                type: utilsConstants.LOGOUT_AFTER_REQUEST,
                payload: true,
            });
        } else {
            logout();
        }
    };
    const handleCancel = () => {
        setConfirmLogoutModalVisibility(false);
    };
    const messagesStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        //align-items: space-between;

        border: "1px solid #f5f6fa",
        marginBottom: "10px",
        borderRadius: "5px",
        padding: "7px",
        cursor: "pointer",
    }
    const messages = (
        <div style={{width: 300, minHeight: 120, backgroundColor: "#fff"}} className="learner-messages">
            <div className="msg-header">
                <h3>{window.__lang('messages')}</h3>
                <Link to={'/learner/messages'}>{window.__lang('see_all')}</Link>
            </div>
            <div style={messagesStyle}>
                {latestMessages.length > 0 && latestMessages.map(item => (
                    item.user_type === "receiver" && (
                        <Link to={'/learner/messages'} style={{color: "#131523", fontSize: 12, fontWeight: 500}}>
                            <div style={{
                                minHeight: 60,
                                padding: 7,
                                borderRadius: 7,
                                display: "flex",
                                backgroundColor: item.is_read === false ? "#f0f1fc" : "#fafafa",
                            }}>
                                {item?.user?.image === null ? (
                                    <ReactAvatar
                                        color={ReactAvatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
                                        name={item?.user?.name}
                                        size={45}
                                        className={'contact-avatar'}
                                    />
                                ) : (
                                    <ReactAvatar
                                        color={ReactAvatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
                                        src={item?.user?.image}
                                        size={45}
                                        className={'contact-avatar'}
                                    />
                                )}
                                <div>
                                    {item.is_read === false ? <h3>{item?.user?.name}</h3> : <h4>{item?.user?.name}</h4>}
                                    {item.is_read === false ?
                                        <h4>{window.__lang(`${item?.message.replace(/ /g, "_")}`)}</h4> :
                                        <h5>{window.__lang(`${item?.message.replace(/ /g, "_")}`)}</h5>}
                                </div>
                            </div>
                        </Link>
                    )))}
                {!latestMessages.length && (
                        <div style={{height: 30, padding: 7, display: "flex", backgroundColor: "#f5f6fa"}}>
                            <h5>There are no messages</h5>
                        </div>
                )}
            </div>
        </div>
    );
    const userNotification = (
        <div style={{width: 300, minHeight: 120, backgroundColor: "#fff"}} className="learner-messages">
            <div className="msg-header">
                <h3>{window.__lang('notifications')}</h3>
                <Link
                    to={'/learner/profile/progress'}
                    onClick={() => dispatch(notificationActions.markLearnerNotificationRead(learnerNotifications && learnerNotifications[0].id))}
                >
                    {window.__lang('see_all')}
                </Link>
            </div>
            <div style={messagesStyle}>
                {learnerNotifications.length > 0 && learnerNotifications.map(item => (
                    <Link
                        onClick={() => dispatch(notificationActions.markLearnerNotificationRead(item.id))}
                        to={'/learner/profile/progress'}
                        style={{color: "#131523", fontSize: 12, fontWeight: 500}}
                    >
                        <div style={{
                            minHeight: 60,
                            padding: 7,
                            borderRadius: 7,
                            display: "flex",
                            backgroundColor:
                            item.read_at === null ? "#f5f6fa" :
                                "#fbfbff",
                        }}>
                            <div>
                                <h3>{window.__lang(`${item?.title.replace(/ /g, "_")}`)}</h3>
                                <h4>{window.__lang(`${item?.body.replace(/ /g, "_")}`)}</h4>
                            </div>
                        </div>
                    </Link>
                ))}
                {!learnerNotifications.length && (
                    <div style={{height: 30, padding: 7, display: "flex", backgroundColor: "#f5f6fa"}}>
                        {window.__lang('no_notifications')}
                    </div>
                )}
            </div>
        </div>
        // <Menu className="learner-notification">
        //     <div className="notification-header">
        //         <h3>{window.__lang('notifications')}</h3>
        //     </div>
        //     {learnerNotifications.length ? learnerNotifications.map(notification => (
        //             // <Menu.Item>
        //                 <Col span={24} className="notification-item-container" key={notification.id}>
        //                     <Col span={24} style={{display: "flex", justifyContent: 'space-between'}}>
        //                         <h4>{window.__lang(`${notification.title.toLowerCase().split(" ").join("_")}`)}</h4>
        //                         <p style={{fontSize: 10}}>{window.__lang(`${notification.human_created_at}`)}</p>
        //                     </Col>
        //                     <Col span={24} style={{width: '100%'}}>
        //                         <h5>{window.__lang(`${notification.body.toLowerCase().split(" ").join("_")}`)}asdasdsadasfjfdjg asndgjdsf asjfdgnsdf adsfgn</h5>
        //                     </Col>
        //                 </Col>
        //             // </Menu.Item>
        //         )) :
        //         <Menu.Item>{window.__lang('no_notifications')}</Menu.Item>
        //     }
        // </Menu>
    );
    const languages = (
        <Menu className="learner-languages">
            <h3>{window.__lang('content_language')} </h3>
            {languagesContent?.map(lang => {
                return (
                    <Menu.Item
                        key={lang.langID}
                        onClick={() => {
                            localStorage.setItem('langId', lang.langID);
                            localStorage.setItem('langFlag', lang.flag);
                            localStorage.setItem(
                                'lang',
                                JSON.stringify(lang ?? {}),
                            );
                            dispatch(
                                settingsActions.updateLanguageInterface(
                                    user.userID,
                                    {contentLangID: lang.langID},
                                    null,
                                    // () => window.location.reload(),
                                    false,
                                    lang,
                                ),
                            );
                            dispatch(
                                settingsActions.changeLanguageInterface(
                                    lang.langCode,
                                    lang.langID,
                                    dashPath.pathways?.pathway,
                                ),
                            );
                            // window.location.reload();
                        }}
                    >
                        <Avatar
                            size={30}
                            // src={'/images/user/icons/english.svg'}
                            src={flagsIcons[lang.langCode] ?? lang.flag}
                        />
                        <p>
                            <span>{lang.name}</span>
                            <Avatar
                                size={
                                    Number(localStorage.getItem('langId')) ===
                                    Number(lang.langID)
                                        ? 16
                                        : 0
                                }
                                src={checkedSvg}
                            />
                        </p>
                    </Menu.Item>
                );
            })}
            <h3></h3>
            {/*<h3>View Language</h3>*/}
            {/*<Menu.Item>*/}
            {/*    <Avatar*/}
            {/*        size={30}*/}
            {/*        src={'/images/user/icons/english.svg'}*/}
            {/*    />*/}
            {/*    <p>*/}
            {/*        <span>English</span>*/}
            {/*        <Avatar size={16} src={checkedSvg} />*/}
            {/*    </p>*/}
            {/*</Menu.Item>*/}
        </Menu>
    );

    const userInfo = (
        <Menu className="user-info">
            <div className="info-header">
                <div className="user-avatar">
                    <Avatar
                        src={profileInfo?.profileInfo?.image || defaultAvatar}
                        size={100}
                    />
                </div>
                <div className="user-type-name">
                    <h3>{`${profileInfo?.profileInfo?.first_name} ${profileInfo?.profileInfo?.last_name}`}</h3>
                    <p className="user-type">
                        <FontAwesomeIcon icon={faUserGraduate}/>{' '}
                        {profileInfo?.profileInfo?.role}
                    </p>
                </div>
            </div>
            {hasShowProfile ? (
                <Menu.Item>
                    <Link
                        to="/learner/profile/editing"
                        className="menu-item-link"
                    >
                        <Avatar size={30} src={myProfile} />
                        <p>{window.__lang('my_profile')}</p>
                    </Link>
                </Menu.Item>
            ) : (
                ''
            )}

            <Menu.Item>
                <Link to="/learner/profile/settings" className="menu-item-link">
                    <Avatar size={30} src={settingsIcon} />
                    <p>{window.__lang('settings')} </p>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/learner/profile/progress" className="menu-item-link">
                    <Avatar size={30} src={progressIcon} />
                    <p>{window.__lang('Progress')} </p>
                </Link>
            </Menu.Item>
            {user.site_relation.canSeeSavedContent !== 0 && (
                <Menu.Item>
                    <Link to="/learner/content" className="menu-item-link">
                        <SnippetsOutlined
                            className="saved-content-icon"
                            style={{ fontSize: '18px' }}
                        />
                        <p>{window.__lang('Saved_Content')} </p>
                    </Link>
                </Menu.Item>
            )}
            <Menu.Item
                className="menu-item-link"
                onClick={() => setConfirmLogoutModalVisibility(true)}
            >
                <Avatar size={30} src={logoutIcon} />
                <p>{window.__lang('Logout')} </p>
            </Menu.Item>
        </Menu>
    );
    
    return (
        <div className="learner-dashboard-page">
            <LoginPopUpMessageModal
                loginPopUpMsg={loginPopUpMsg}
                setLoginPopUpMsg={setLoginPopUpMsg}
            />
            <ConfirmLogOutModal
                visible={confirmLogoutModalVisibility}
                handleCancel={handleCancel}
                handleOk={handleOk}
            />
            <Layout className="layout">
                <Header theme="light">
                    <div className="learner-container">               
                        <div className="logo">
                            {/* <img src={user?.site_logo} alt="eflex" /> */}
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                    alignItems: 'start',
                                }}
                            >
                                {pathways?.user_groups?.length === 0 && (
                                    <div
                                        style={{
                                            backgroundImage: `url(${site.smallLogo_s3})`,
                                            height: '85px',
                                            width: '210px',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            backgroundSize: 'contain',
                                        }}
                                    ></div>
                                )}
                                {pathways?.user_groups?.map(userGroup => userGroup.displayLogoForLearners === 1 && (
                                    <div key={userGroup.userGroupID}
                                        style={{
                                            backgroundImage: `url(${userGroup.logo_s3})`,
                                            height: '85px',
                                            width: '210px',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            backgroundSize: 'contain',
                                        }}
                                    ></div>
                                ))}
                                {site.poweredByText && (
                                    <div className="powred-by-p">
                                        {window.__lang('Powered_by')}
                                        {site.poweredByText}
                                    </div>
                                )}{' '}
                            </div>
                            {/* <p className="powred-by-p">
                                    {window.__lang('Powered_by')}{' '}
                                    {site.poweredByText}
                                </p> */}
                        </div>
                        <div className="custom-nav">
                            <div className="links">
                                <Menu
                                    theme="light"
                                    mode="horizontal"
                                    defaultSelectedKeys={['2']}
                                >
                                    <Menu.Item
                                        className={
                                            props.path ===
                                            '/learner/dashboard' ||
                                            props.path === '/'
                                                ? 'ant-menu-item-selected'
                                                : ''
                                        }
                                    >
                                        {dimensions.width < 955 && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <rect
                                                    x="3"
                                                    y="3"
                                                    width="7"
                                                    height="9"
                                                />
                                                <rect
                                                    x="14"
                                                    y="3"
                                                    width="7"
                                                    height="5"
                                                />
                                                <rect
                                                    x="14"
                                                    y="12"
                                                    width="7"
                                                    height="9"
                                                />
                                                <rect
                                                    x="3"
                                                    y="16"
                                                    width="7"
                                                    height="5"
                                                />
                                            </svg>
                                        )}

                                        <Link to="/learner/dashboard">
                                            {window.__lang('dashboard')}
                                        </Link>
                                    </Menu.Item>

                                    {site?.showSearchTab !== 0 ? (
                                        <Menu.Item
                                            className={
                                                props.path === '/learner/search'
                                                    ? 'ant-menu-item-selected'
                                                    : ''
                                            }
                                        >
                                            {dimensions.width < 955 && (
                                                <svg
                                                    width="24px"
                                                    height="24px"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                >
                                                    <path
                                                        stroke="currentColor"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="m20 20-4.05-4.05m0 0a7 7 0 1 0-9.9-9.9 7 7 0 0 0 9.9 9.9z"
                                                    />
                                                </svg>
                                            )}

                                            <Link to="/learner/search">
                                                {window.__lang('search')}
                                            </Link>
                                        </Menu.Item>
                                    ) : (
                                        <></>
                                    )}
                                    {site?.showWishListTab !== 0 ? (
                                        <Menu.Item
                                            className={
                                                props.path ===
                                                '/learner/wishlist'
                                                    ? 'ant-menu-item-selected'
                                                    : ''
                                            }
                                        >
                                            {dimensions.width < 955 && (
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="far"
                                                    data-icon="heart"
                                                    class="svg-inline--fa fa-heart fa-w-16 "
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"
                                                    ></path>
                                                </svg>
                                            )}

                                            <Link to="/learner/wishlist">
                                                {window.__lang('wishlist')}
                                            </Link>
                                        </Menu.Item>
                                    ) : (
                                        <></>
                                    )}
                                    {site?.showLibraryTab !== 0 &&
                                    profileInfo?.profileInfo?.bundles_count >
                                    0 ? (
                                        // {site?.showLibraryTab !== 0  ? (
                                        <Menu.Item
                                            className={
                                                props.path ===
                                                '/learner/library'
                                                    ? 'ant-menu-item-selected'
                                                    : ''
                                            }
                                            style={{position: 'relative !important'}}
                                        >
                                            {dimensions.width < 955 && (
                                                <svg
                                                    width="256px"
                                                    height="256px"
                                                    viewBox="0 0 256 256"
                                                    id="Flat"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M137.11377,152.19092a12.00023,12.00023,0,0,0-18.22266-.00049l-48.00195,56A11.99948,11.99948,0,0,0,79.99951,228h96a11.99969,11.99969,0,0,0,9.11133-19.80908ZM106.09131,204l21.91015-25.561L149.90967,204ZM235.99951,64V176a28.03146,28.03146,0,0,1-28,28,12,12,0,0,1,0-24,4.00426,4.00426,0,0,0,4-4V64a4.00426,4.00426,0,0,0-4-4h-160a4.00427,4.00427,0,0,0-4,4V176a4.00427,4.00427,0,0,0,4,4,12,12,0,0,1,0,24,28.03146,28.03146,0,0,1-28-28V64a28.03146,28.03146,0,0,1,28-28h160A28.03146,28.03146,0,0,1,235.99951,64Z"/>
                                                </svg>
                                            )}

                                            <Link to="/learner/library">
                                                {window.__lang('library')}
                                            </Link>
                                        </Menu.Item>
                                    ) : (
                                        <></>
                                    )}
                                    {/* <Menu.Item
                                        className={
                                            props.path === '/learner/content' ||
                                            props.path === '/'
                                                ? 'ant-menu-item-selected'
                                                : ''
                                        }
                                    >
                                        {dimensions.width < 955 && (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <rect
                                                    x="3"
                                                    y="3"
                                                    width="7"
                                                    height="9"
                                                />
                                                <rect
                                                    x="14"
                                                    y="3"
                                                    width="7"
                                                    height="5"
                                                />
                                                <rect
                                                    x="14"
                                                    y="12"
                                                    width="7"
                                                    height="9"
                                                />
                                                <rect
                                                    x="3"
                                                    y="16"
                                                    width="7"
                                                    height="5"
                                                />
                                            </svg>
                                        )}

                                        <Link to="/learner/content">
                                            {window.__lang('saved_content')}
                                        </Link>
                                    </Menu.Item> */}
                                </Menu>
                            </div>
                            <div className="user-options">
                                <ul>
                                    {/* <li>
                                        <img
                                            src={'/images/user/icons/help.svg'}
                                        />
                                    </li> */}
                                    {localStorage.getItem('ssoUser') && site?.ssoReturnUrl &&
                                        <SsoButton></SsoButton>
                                    }
                                    {latestMessages.find(msg => msg.is_read === false)?.is_read === false ? (
                                        <span
                                            style={{
                                                height: 11,
                                                width: 11,
                                                backgroundColor: "red",
                                                borderRadius: "50%",
                                                display: "inline-block",
                                                transform: "translate(25px, 16px)"
                                            }}
                                        />
                                    ) : null}
                                    <li>
                                        <Badge
                                            count={0}
                                        >
                                            <Dropdown.Button
                                                overlay={messages}
                                                placement="bottomCenter"
                                                icon={
                                                    <img
                                                        src={'/images/user/icons/message.svg'}
                                                        style={{
                                                            transform: latestMessages.find(msg => msg.is_read === false)?.is_read === false ? "translate(-10px, 0px)" : "translate(0px, 0px)"
                                                        }}
                                                    />
                                                }
                                            />
                                        </Badge>
                                    </li>

                                    {/* <li>
                                        <Badge dot={learnerNotifications.find(msg => msg.read_at === null)}>
                                            <Dropdown.Button
                                                overlay={userNotification}
                                                placement="bottomCenter"
                                                icon={
                                                    <img
                                                        src={'/images/user/icons/notifications.svg'}
                                                    />
                                                }
                                            />
                                        </Badge>
                                    </li> */}
                                    {((dashPath?.pathways?.pathway) || location.pathname !== '/learner/dashboard') && <li className="lang-flag">
                                        <Dropdown.Button
                                            className="lang"
                                            overlay={languages}
                                            placement="bottomCenter"
                                            icon={
                                                <Avatar
                                                    size={22}
                                                    src={
                                                        flagsIcons[
                                                            lang?.langCode
                                                            ]
                                                    }
                                                />
                                            }
                                        />
                                    </li>}
                                    <li className="profile-dropdown">
                                        <Dropdown
                                            className="custom-avatar"
                                            overlay={userInfo}
                                            placement="bottomCenter"
                                            trigger={['click', 'hover']}
                                        >
                                            <Button>
                                                {dimensions.width > 767 && (
                                                    <>
                                                        <span className="avatar-name">
                                                            {window.__lang(
                                                                'Hi',
                                                            )}
                                                        </span>
                                                        ,{' '}
                                                        {
                                                            profileInfo
                                                                ?.profileInfo
                                                                ?.first_name
                                                        }
                                                    </>
                                                )}

                                                <Avatar
                                                    size={35}
                                                    src={
                                                        profileInfo?.profileInfo
                                                            ?.image ||
                                                        defaultAvatar
                                                    }
                                                />
                                            </Button>
                                        </Dropdown>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>   
                </Header>
                <props.component {...props} />
                <Footer>
                    <div className="learner-container">
                        {site.copyright}
                        {site.poweredBy ? ' | Powered by Eflex Languages' : ''}
                        <code>
                            {window.__lang('version')} {version}
                        </code>
                        <div className="social-media">
                            {site.twitter ? (
                                <a
                                    href={printUrl(site.twitter)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faTwitter}/>
                                </a>
                            ) : (
                                ''
                            )}
                            {site.facebook ? (
                                <a
                                    href={printUrl(site.facebook)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faFacebookF}/>
                                </a>
                            ) : (
                                ''
                            )}
                            {site.instagram ? (
                                <a
                                    href={printUrl(site.instagram)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faInstagram}/>
                                </a>
                            ) : (
                                ''
                            )}
                            {site.youTube ? (
                                <a
                                    href={printUrl(site.youTube)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faYoutube}/>
                                </a>
                            ) : (
                                ''
                            )}
                            {site.linkedin ? (
                                <a
                                    href={printUrl(site.linkedin)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faLinkedinIn}/>
                                </a>
                            ) : (
                                ''
                            )}
                            {site.homepage ? (
                                <a
                                    href={printUrl(site.homepage)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faBuilding}/>
                                </a>
                            ) : (
                                ''
                            )}

                            {site.googlePlus ? (
                                <a
                                    href={printUrl(site.googlePlus)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faGooglePlus}/>
                                </a>
                            ) : (
                                ''
                            )}
                            {site.pinterest ? (
                                <a
                                    href={printUrl(site.pinterest)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faPinterest}/>
                                </a>
                            ) : (
                                ''
                            )}
                            {site.flickr ? (
                                <a
                                    href={printUrl(site.flickr)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faFlickr}/>
                                </a>
                            ) : (
                                ''
                            )}

                            {site.blog ? (
                                <a
                                    href={printUrl(site.blog)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon icon={faBloggerB}/>
                                </a>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </Footer>
            </Layout>
            {userWelcomeMassages?.showPrivacyPolicy ? (
                <Modal
                    title="TERMS AND CONDITIONS"
                    visible={userWelcomeMassages?.showPrivacyPolicy}
                    onCancel={logout}
                    onOk={privacyAgreed}
                    okText={'Accept'}
                    width={600}
                    closable={false}
                    cancelText={'Decline'}
                >
                    <div style={{height: '200px', 'overflow-y': 'scroll'}}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: userWelcomeMassages?.privacyPolicy,
                            }}
                        />
                    </div>
                </Modal>
            ) : (
                <ModalUsernameAndPassword/>
            )}
        </div>
    );
};

export default LearnerDashboardPage;
