import React from 'react';
import Quill from 'react-quill';
import QuestionType from '../../QuestionType/QuestionType';
const LearnerAnswersContainer = ({ selectedContent }) => {
    return (
        <>
            <div className="answers-container">
                <p>{window.__lang('Question')}</p>
                <QuestionType content={selectedContent} />
            </div>
            <div className="answers-container">
                <p>{window.__lang('Trainer notes')}</p>
                <Quill
                    value={selectedContent?.notes ?? ''}
                    readOnly={true}
                    theme={'bubble'}
                    className="answer-box"
                    onChange={() => {}}
                />
            </div>
        </>
    );
};

export default LearnerAnswersContainer;
