import React, { useState } from 'react';
import { Button, Col, Divider, Input, Row } from 'antd';
import MyQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import savedContentActions from '../../../../../redux/actions/saveContent.actions';
import QuestionType from '../../QuestionType/QuestionType';
import './trainer-answers-container.scss';

const TrainerAnswersContainer = ({ selectedContent, cancel }) => {
    const dispatch = useDispatch();
    const [mark, setMark] = useState(selectedContent.score);
    const [notes, setNotes] = useState(selectedContent.notes);

    const markContent = () => {
        dispatch(
            savedContentActions.trainerMarkContent(
                selectedContent.id,
                mark,
                notes,
            ),
        );
        cancel();
    };
    return (
        <div className="trainer-answers">
            <div className="answers-container trainer-answers-container">
                <p>{window.__lang('Question')}</p>
                <QuestionType content={selectedContent} />
            </div>
            <div className="answers-container trainer-answers-container">
                <p>{window.__lang('Trainer notes')}</p>
                <MyQuill
                    value={notes ?? ''}
                    onChange={content => setNotes(content)}
                />
            </div>
            <div className="answers-container trainer-answers-container">
                <p>{window.__lang('Mark')}</p>
                <Input
                    value={mark}
                    className="mark-input"
                    type="text"
                    width="100%"
                    onChange={e => setMark(e.target.value)}
                />
            </div>
            <Divider />
            <Row justify="start" className="footer">
                <Col span={2}>
                    <Button onClick={markContent} className="popup-button">
                        {window.__lang('Save')}
                    </Button>
                </Col>
                <Col span={2}>
                    <Button className="cancel" onClick={cancel}>
                        {window.__lang('Cancel')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default TrainerAnswersContainer;
