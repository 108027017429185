import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { learningFocusActions } from '../actions/learningFocus.actions';
import rolesConstant from '../constants/roles.constants';
import { retrieveDecryptedSiteFromLocalStorage, retrieveDecryptedUserFromLocalStorage } from '../../utils/common';

const useLearningFocuses = () => {
    const dispatch = useDispatch();
    const site = retrieveDecryptedSiteFromLocalStorage();
    const user = retrieveDecryptedUserFromLocalStorage();

    useEffect(() => {
        if (site && user.role != rolesConstant.Admin)
            dispatch(learningFocusActions.fetchLearningFocus(site.siteID, null, 1, 50));
        else dispatch(learningFocusActions.fetchLearningFocus(null, null, 1, 50));
    }, []);

    const learningFocuses = useSelector(
        state => state.learningFocuses.learningFocuses,
    );

    return learningFocuses;
};

export default useLearningFocuses;
