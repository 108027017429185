const SiteLayoutStyles = {
    navBarContainerStyles: {
        backgroundColor: 'var(--primary-color, #5371d3)',
        color: 'white',
        fontSize: '16px',
        textAlign: 'center',
        marginTop: 15,
    },
    navBarStyles: {
        color: 'white',
    },
    mobileNavStyle: {
        backgroundColor: 'var(--primary-color, #5371d3)',
        color: 'white',
        textAlign: 'center',
    },
    linkStyles: {
        fontWeight: 'bold',
    },
    userMenuStyles: {
        float: 'right',
        paddingLeft: '10px',
        marginLeft: '15px',
    },
    userMenuBorder: {
        borderLeft: '2px solid #f3f3f3',
    },
    userMenuIcon: {
        float: 'right',
        fontSize: '36px',
        color: '#f3f3f3',
    },
};

export { SiteLayoutStyles };
