export const formBuilderFieldConstants = {
    FIELD_DROPPED: "FORM_BUILDER_FIELD_DROPPED",
    FIELD_ADDED: "FORM_BUILDER_FIELD_ADDED",
    FIELD_REMOVED: "FORM_BUILDER_FIELD_REMOVED",
    FIELD_EDITING: "FORM_BUILDER_FIELD_EDITING",
    FIELD_EDITED: "FORM_BUILDER_FIELD_EDITED",
    FIELD_EDITING_CANCELLED: "FORM_BUILDER_FIELD_EDIT_CANCELLED",
    FIELD_ADD_CANCELLED: "FORM_BUILDER_FIELD_ADD_CANCELLED",
    FIELD_BULK_ACTIONS_ON: "FORM_BUILDER_FIELD_BULK_ACTIONS_ON",
    FIELD_BULK_ACTIONS_OFF: "FORM_BUILDER_FIELD_BULK_ACTIONS_OFF",
    TOGGLE_FIELD_SELECT: "TOGGLE_FIELD_SELECT",
    COPY_FIELDS: "FORM_BUILDER_COPY_FIELDS",
    MOVE_FIELDS: "FORM_BUILDER_MOVE_FIELDS",
    DELETE_FIELDS: "FORM_BUILDER_DELETE_FIELDS",
    SORT_FIELDS: "FORM_BUILDER_SORT_FIELDS",
}
