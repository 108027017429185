import React from 'react';
import ReactDOM from 'react-dom/client';
import './libraries/fontawesome';
import App from './App.jsx';
import { store } from './redux/store.js';
import { Provider } from 'react-redux';
import './index.css'
import SiteInfoProvider from './components/SiteInfoProvider';
// /**
//  * @Todo: displaying loading spinner until app.js is loaded
//  * @Author: Hamdi
//  */
const rootElement = document.getElementById("root");
const loadingSpinner = document.getElementById("loading-spinner");
const loading = document.getElementById("loading");
// const loadingText = document.getElementById("loading-text");

function showApp() {
    rootElement.style.display = "block";
    // loadingSpinner.style.display = "none";
    // loading.style.display = "none";
    // loadingText.style.display = "none";
}

window.addEventListener("load", showApp); // This event is triggered when all resources (including app.js) are loaded.

ReactDOM.createRoot(document.getElementById('root')).render(
        <Provider store={store}>
            <SiteInfoProvider >
                <App />
            </SiteInfoProvider>
        </Provider>
);
