import React, {useEffect} from 'react';
import { Col, Row } from 'antd';
import ContentList from '../components/Learner/MyContent/ContentList/ContentList';
import './my-content.scss';
import { useHistory } from 'react-router-dom';
import { retrieveDecryptedUserFromLocalStorage } from '../utils/common';

const MyContent = () => {
    const history = useHistory()
    const user = retrieveDecryptedUserFromLocalStorage();

    useEffect(() => {
        if (user?.site_relation?.canSeeSavedContent === 0) {
            history.push('/dashboard')
        }
    }, [])

    return (
        <Row className="learner-content" justify="center">
            <Col className="title" span={22}>
                {user.role === 'learner'
                    ? window.__lang('My_Content')
                    : window.__lang('Learners_Content')}
            </Col>
            <Col span={22}>
                <ContentList />
            </Col>
        </Row>
    );
};

export default MyContent;
