import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Popover, Radio, Row, Select, Space } from 'antd';
import GroupIcon from '../../../../../../public/svg/Group 15915.svg';
import { useDispatch, useSelector } from 'react-redux';
import userGroupsAction from '../../../../../redux/actions/userGroup.actions';
import { userActions } from '../../../../../redux/actions/user.actions';
import savedContentActions from '../../../../../redux/actions/saveContent.actions';

const { Option } = Select;
import './filters.scss';
import { retrieveDecryptedUserFromLocalStorage } from '../../../../../utils/common';

const Filters = ({ filters, setFilters }) => {
    const dispatch = useDispatch();
    const [filtersVisible, setFiltersVisible] = useState(false);
    const userGroups = useSelector(state => state.userGroups.userGroups);
    const user = retrieveDecryptedUserFromLocalStorage();
    const trainerUsers = useSelector(state => state.users.users);
    const trainerActivities = useSelector(
        (state) => state.savedContent.activities,
    );
    const [values, setValues] = useState(filters);

    useEffect(() => {
        dispatch(
            userGroupsAction.fetchAllUserGroups(null, user.siteID, 'select'),
        );

        if (user.role != 'learner') {
            dispatch(userActions.fetchAllUsers(null, null, user.userID));
            dispatch(
                savedContentActions.getTrainerActivityContentActivities(
                    user.userID,
                ),
            );
        }
    }, []);

    useEffect(() => {
        if (userGroups.meta?.current_page !== userGroups.meta?.last_page) {
            dispatch(
                userGroupsAction.fetchAllUserGroups(
                    null,
                    user.siteID,
                    'select',
                    userGroups.meta?.total,
                ),
            );
        };
    }, [userGroups]);

    useEffect(() => {
        if (trainerUsers.meta?.current_page !== trainerUsers.meta?.last_page) {
            user.role != 'learner' &&
                dispatch(
                    userActions.fetchAllUsers(
                        null,
                        null,
                        user.userID,
                        trainerUsers.meta?.total,
                    ),
                );
        }
    }, [trainerUsers]);

    const apply = () => {
        setFilters({ ...values });
        setFiltersVisible(false);
    };

    const reset = () => {
        setFilters({ userID: null, groupID: null, status: null, type: 'all' });
        setValues({ userID: null, groupID: null, status: null, type: 'all' });
        setFiltersVisible(false);
    };

    return (
        <Popover
            onVisibleChange={visisble => setFiltersVisible(visisble)}
            trigger="click"
            placement="bottom"
            visible={filtersVisible}
            content={
                <Row gutter={8} className="filters-container">
                    <Col className="col" span={24}>
                        <p className="title">{window.__lang('Filters')}</p>
                    </Col>
                    <Divider />
                    {user.role !== 'learner' && (
                        <Col className="col" span={24}>
                            <Select
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={window.__lang('Group')}
                                className="select"
                                style={{ width: '100%' }}
                                value={values.groupID}
                                onChange={(value) =>
                                    setValues((prev) => ({
                                        ...prev,
                                        groupID: value,
                                    }))
                                }
                            >
                                {userGroups?.data?.map((group, index) => (
                                    <Option value={group.userGroupID} key={index}>
                                        {group.userGroup}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    )}
                    {user.role != 'learner' && (
                        <Col className="col" span={24}>
                            <Select
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={window.__lang('User')}
                                className="select"
                                style={{ width: '100%' }}
                                value={values.userID}
                                onChange={value =>
                                    setValues(prev => ({
                                        ...prev,
                                        userID: value,
                                    }))
                                }
                            >
                                {trainerUsers?.data?.map((user, index) => (
                                    <Option value={user.userID} key={index}>
                                        {user.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    )}
                    {user.role != 'learner' && (
                        <Col className="col" span={24}>
                            <Select
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={window.__lang('Activity')}
                                className="select"
                                style={{ width: '100%' }}
                                value={values.activityID}
                                onChange={(value) =>
                                    setValues((prev) => ({
                                        ...prev,
                                        activityID: value,
                                    }))
                                }
                            >
                                {trainerActivities?.map((activity, index) => (
                                    <Option
                                        value={activity.activityID}
                                        key={index}
                                    >
                                        {activity.activityName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    )}
                    <Col className="col" span={24}>
                        <Select
                            placeholder="Status"
                            className="select"
                            style={{ width: '100%' }}
                            value={values.status}
                            onChange={value =>
                                setValues(prev => ({
                                    ...prev,
                                    status: value,
                                }))
                            }
                        >
                            <Option value={1}>{window.__lang('marked')}</Option>
                            <Option value={0}>
                                {window.__lang('unmarked')}
                            </Option>
                        </Select>
                    </Col>
                    <Col className="col" span={24}>
                        <Radio.Group
                            value={values.type}
                            onChange={e =>
                                setValues(prev => ({
                                    ...prev,
                                    type: e.target.value,
                                }))
                            }
                            defaultValue={'all'}
                        >
                            <Space direction="vertical">
                                <Radio value={'all'}>
                                    {window.__lang('All')}
                                </Radio>
                                <Radio value={'answer'}>
                                    {window.__lang('open_answer')}
                                </Radio>
                                <Radio value={'file'}>
                                    {window.__lang('pronunciation')}
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Col>
                    <Divider />
                    <Col className="col" span={24}>
                        <Row justify="end">
                            <Space>
                                <Button
                                    onClick={reset}
                                    className="reset-button"
                                >
                                    {window.__lang('Reset')}
                                </Button>

                                <Button
                                    onClick={apply}
                                    className="apply-button"
                                >
                                    {window.__lang('Apply')}
                                </Button>
                            </Space>
                        </Row>
                    </Col>
                </Row>
            }
        >
            <img className="group-icon" src={GroupIcon} />
        </Popover>
    );
};

export default Filters;
