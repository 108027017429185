/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Row } from 'antd';
import { userActions } from '../../redux/actions/user.actions';
import { useDispatch } from 'react-redux';

const LoginPopUpMessageModal = ({ loginPopUpMsg, setLoginPopUpMsg }) => {
    const dispatch = useDispatch();

    const dontShowAgain = () => {
        setLoginPopUpMsg(null);
        localStorage.removeItem('loginPopUpMsg');
        dispatch(userActions.dontShowDashboardMessageToUser());
    };

    const onOk = () => {
        setLoginPopUpMsg(null);
        localStorage.removeItem('loginPopUpMsg');
    };
    return (
        <Modal
            open={loginPopUpMsg ? true : false}
            onCancel={dontShowAgain}
            cancelText={window.__lang('dont_show_again')}
            onOk={onOk}
            okText={window.__lang('OK')}
        >
            <Row justify="center">
                <div
                    dangerouslySetInnerHTML={{
                        __html: loginPopUpMsg,
                    }}
                ></div>
            </Row>
        </Modal>
    );
};
export default LoginPopUpMessageModal;
