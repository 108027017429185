import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Carousel } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PreferenceSettings.scss';
import PropTypes from 'prop-types';
import useLearningFocuses from '../../redux/Hooks/useLearningFocuses';
import { responsiveDefaults } from '../../settings/responsive.settings';
import './LearningFocusesPreference.scss';

const LearningCategoryComponent = ({
    category,
    selectedCategoryID,
    selected,
    setSelectedCategoryID,
}) => {
    return (
        <div
            className="focusButtonItem"
            style={{
                backgroundColor: selected ? '#506EDA' : 'white',
            }}
            key={category.learningCategoryID}
            onClick={() =>
                setSelectedCategoryID(
                    category.learningCategoryID === selectedCategoryID
                        ? 0
                        : category.learningCategoryID,
                )
            }
        >
            <div className="buttonInnerText">
                <FontAwesomeIcon
                    icon={['fal', 'file-certificate']}
                    style={{
                        color: selected ? 'white' : '#506EDA',
                    }}
                />
                <p
                    className="iconText"
                    style={{
                        color: selected ? 'white' : '#506EDA',
                    }}
                >
                    {category.learningCategory}
                </p>
            </div>
        </div>
    );
};

const LearningFocusesPreference = ({
    selectedCategoryID,
    setSelectedCategoryID,
}) => {
    const learningCategories = useLearningFocuses();
    const [firstFour, setFirstFour] = useState([]);
    const [remainCategories, setRemainCategories] = useState([]);

    useEffect(() => {
        const cat = learningCategories;
        setFirstFour(cat.slice(0, 4));
        setRemainCategories(cat.slice(4));
    }, [learningCategories]);

    const carousel = useRef();

    const handlePrevious = () => {
        carousel.current.prev();
    };

    return (
        <div className="learningFocusContainer">
            <p className="learningFocusTitle">Why are you studying English?</p>
            <Row gutter={[16, 16]} justify="center">
                {firstFour.map(category => (
                    <div className="fixed" key={category.learningCategoryID}>
                        <LearningCategoryComponent
                            category={category}
                            selectedCategoryID={selectedCategoryID}
                            selected={
                                category.learningCategoryID ===
                                selectedCategoryID
                            }
                            setSelectedCategoryID={setSelectedCategoryID}
                        />
                    </div>
                ))}
            </Row>
            <Row>
                <Col {...responsiveDefaults}>
                    <div>
                        {remainCategories.length > 4 ? (
                            <Carousel
                                ref={carousel}
                                style={{
                                    marginBottom: 20,
                                }}
                                slidesToShow={4}
                                slidesToScroll={1}
                                dots={false}
                                arrows
                                onChange={handlePrevious}
                                slickNext
                                swipe
                            >
                                {remainCategories.map(category => (
                                    <div key={category.learningCategoryID}>
                                        <LearningCategoryComponent
                                            category={category}
                                            setSelectedCategoryID={
                                                setSelectedCategoryID
                                            }
                                            selected={
                                                category.learningCategoryID ===
                                                selectedCategoryID
                                            }
                                            selectedCategoryID={
                                                selectedCategoryID
                                            }
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        ) : (
                            <div>
                                {learningCategories.map(category => (
                                    <LearningCategoryComponent
                                        category={category}
                                        selectedCategoryID={selectedCategoryID}
                                        selected={
                                            category.learningCategoryID ===
                                            selectedCategoryID
                                        }
                                        setSelectedCategoryID={
                                            setSelectedCategoryID
                                        }
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

LearningCategoryComponent.propTypes = {
    category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedCategoryID: PropTypes.number,
    selected: PropTypes.bool,
    setSelectedCategoryID: PropTypes.func,
};
LearningCategoryComponent.defaultProps = {
    category: {
        learningCategoryID: 11,
        learningCategory: 'Medical',
        modified: '2020-01-12 18:59:00',
        created: '2020-01-12 18:58:58',
    },
    selectedCategoryID: 2,
    selected: false,
    setSelectedCategoryID: () => {},
};

LearningFocusesPreference.propTypes = {
    selectedCategoryID: PropTypes.number,
    setSelectedCategoryID: PropTypes.func,
};

LearningFocusesPreference.defaultProps = {
    selectedCategoryID: 2,
    setSelectedCategoryID: () => {},
};
export default LearningFocusesPreference;
