import React from 'react';
import { Card, Col, Row, Typography } from 'antd';
import Detailes from './Detailes/Detailes';
import LearnerAnswersContainer from './LearnerAnswersContainer';
import TrainerAnswersContainer from './TrainerAnswersContainer';
import { useSelector } from 'react-redux';
import './content-popup.scss';
const ContentPopUp = ({ selectedContent, cancel }) => {
    const user = useSelector(state => state.authentication.user);

    return (
        <Row className="content-popup">
            <Col className="feedback" span={24}>
                <Typography>{window.__lang('Feedback')}</Typography>
            </Col>
            <Col span={24}>
                <Card>
                    <Detailes selectedContent={selectedContent} />
                    {user.role === 'learner' ? (
                        <LearnerAnswersContainer
                            selectedContent={selectedContent}
                        />
                    ) : (
                        <TrainerAnswersContainer
                            cancel={cancel}
                            selectedContent={selectedContent}
                        />
                    )}
                </Card>
            </Col>
        </Row>
    );
};

export default ContentPopUp;
