export const activityConstants = {
    FETCH_ACTIVITIES_REQUEST: 'FETCH_ACTIVITIES_REQUEST',
    FETCH_ACTIVITIES_SUCCESS: 'FETCH_ACTIVITIES_SUCCESS',
    FETCH_ACTIVITIES_REJECTED: 'FETCH_ACTIVITIES_REJECTED',
    FETCH_ACTIVITY_REQUEST: 'FETCH_ACTIVITY_REQUEST',
    FETCH_ACTIVITY_SUCCESS: 'FETCH_ACTIVITY_SUCCESS',
    FETCH_ACTIVITY_REJECTED: 'FETCH_ACTIVITY_REJECTED',
    SEARCH_ACTIVITIES_REQUEST: 'SEARCH_ACTIVITIES_REQUEST',
    SEARCH_ACTIVITIES_SUCCESS: 'SEARCH_ACTIVITIES_SUCCESS',
    SEARCH_ACTIVITIES_REJECTED: 'SEARCH_ACTIVITIES_REJECTED',
    QUESTION_ANSWERED: 'QUESTION_ANSWERED',
    SUBMIT_ANSWERS: 'SUBMIT_ANSWERS',
    FIELDS_LOADED: 'FIELDS_LOADED',
    DELETE_ACTIVITY_REQUEST: 'DELETE_ACTIVITY_REQUEST',
    DELETE_ACTIVITY_SUCCESS: 'DELETE_ACTIVITY_SUCCESS',
    DELETE_ACTIVITY_REJECTED: 'DELETE_ACTIVITY_REJECTED',
    CLEAR_ACTIVITY: 'CLEAR_ACTIVITY',
};
