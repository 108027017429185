import learnerProfile from '../constants/learnerProfile.constants';
import { ApiBaseURL } from '../../constants/apiConstants'

const initialState = {};

function allCountriesApi(state = initialState, action) {
    switch (action.type) {
        case learnerProfile.FETCH_ALL_COUNTRIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProfile.FETCH_ALL_COUNTRIES_SUCCESS:
            return {
                ...state,
                loading: false,
                allCountriesApi: action.payload,
            };
        case learnerProfile.FETCH_ALL_COUNTRIES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function fetchAllSettings(state = initialState, action) {
    switch (action.type) {
        case learnerProfile.FETCH_ALL_SETTINGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProfile.FETCH_ALL_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.payload,
            };
        case learnerProfile.FETCH_ALL_SETTINGS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function fetchAllAvatars(state = initialState, action) {
    switch (action.type) {
        case learnerProfile.FETCH_ALL_AVATARS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProfile.FETCH_ALL_AVATARS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: action.payload,
            };
        case learnerProfile.FETCH_ALL_AVATARS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function allInterfaceLanguages(state = initialState, action) {
    switch (action.type) {
        case learnerProfile.FETCH_INTERFACE_LANGUAGES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProfile.FETCH_INTERFACE_LANGUAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                interfaceLanguages: action.payload,
            };
        case learnerProfile.FETCH_INTERFACE_LANGUAGES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function fetchProfileInfo(state = initialState, action) {
    switch (action.type) {
        case learnerProfile.FETCH_PROFILE_INFO_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProfile.FETCH_PROFILE_INFO_SUCCESS:
            if (
                action.payload.image &&
                !isFullUrl(action.payload.image)
            ) {
                action.payload.image = `${ApiBaseURL}${action.payload.image}`;
              }
            return {
                ...state,
                loading: false,
                profileInfo: action.payload,
            };
        case learnerProfile.FETCH_PROFILE_INFO_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function updateProfileInfo(state = initialState, action) {
    switch (action.type) {
        case learnerProfile.UPDATE_PROFILE_INFO_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProfile.UPDATE_PROFILE_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                profileInfo: action.payload,
            };
        case learnerProfile.UPDATE_PROFILE_INFO_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case learnerProfile.WEEKLY_TARGET_SUCCESS:
            return {
                ...state,
                loading: false,
                userWeeklyTarget: action.payload,
            };
        case learnerProfile.UPDATE_WEEKLY_TARGET_SUCCESS:
            return {
                ...state,
                loading: false,
                userWeeklyTarget: action.payload,
            };
        default:
            return state;
    }
}

function updateLanguageInterface(state = initialState, action) {
    switch (action.type) {
        case learnerProfile.UPDATE_SETTINGS_LANGUAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProfile.UPDATE_SETTINGS_LANGUAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                langInterface: action.payload,
            };
        case learnerProfile.UPDATE_SETTINGS_LANGUAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function updateLearnerSkills(state = initialState, action) {
    switch (action.type) {
        case learnerProfile.UPDATE_SETTINGS_SKILLS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProfile.UPDATE_SETTINGS_SKILLS_SUCCESS:
            return {
                ...state,
                loading: false,
                skills: action.payload,
            };
        case learnerProfile.UPDATE_SETTINGS_SKILLS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function updateLearnerTarget(state = initialState, action) {
    switch (action.type) {
        case learnerProfile.UPDATE_SETTINGS_TARGET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProfile.UPDATE_SETTINGS_TARGET_SUCCESS:
            return {
                ...state,
                loading: false,
                target: action.payload,
            };
        case learnerProfile.UPDATE_SETTINGS_TARGET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function updateLearnerPassword(state = initialState, action) {
    switch (action.type) {
        case learnerProfile.UPDATE_SETTINGS_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProfile.UPDATE_SETTINGS_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                password: action.payload,
            };
        case learnerProfile.UPDATE_SETTINGS_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function updatedProfileImage(state = initialState, action) {
    switch (action.type) {
        case learnerProfile.UPDATE_PROFILE_IMAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerProfile.UPDATE_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                password: action.payload,
            };
        case learnerProfile.UPDATE_PROFILE_IMAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
    
}

function isFullUrl(url) { 
 if(url.startsWith('https') || url.startsWith('http'))
    return true;
else
    return false;
} 

export {
    allCountriesApi,
    updateProfileInfo,
    updateLanguageInterface,
    updateLearnerSkills,
    updateLearnerTarget,
    updateLearnerPassword,
    allInterfaceLanguages,
    fetchAllSettings,
    fetchAllAvatars,
    updatedProfileImage,
    fetchProfileInfo,
};
