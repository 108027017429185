import React from 'react';
import { Row, Col, Form, Input, DatePicker } from 'antd';
// moment import
import moment from 'moment';
import './AdditionalInfoForm.scss';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

const { TextArea } = Input;

const AdditionalInfoForm = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const onFinish = values => {
    };

    const onChange = (value, dateString) => {
    };

    const onOk = value => {
    };

    const disabledDate = current => {
        return current && current < moment().endOf('day');
    };

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };
    return (
        <div>
            <Form
                name="basic"
                initialValues={{}}
                onFinish={onFinish}
                key="1"
                {...layout}
                className="additionalInfoForm"
            >
                <Row gutter={16} justify="center">
                    <Col lg={14} md={16} sm={18}>
                        <Form.Item
                            label="Nationality"
                            name="nationality"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your nationality',
                                },
                            ]}
                        >
                            {!isMobile ? (
                                <Input placeholder="Please input your nationality" />
                            ) : (
                                <>
                                    <Input />
                                    <p className="help-text">
                                        Please input your nationality
                                    </p>
                                </>
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Arrival Date"
                            name="attivalDate"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please select date and time of your arrival',
                                },
                            ]}
                        >
                            <div>
                                <DatePicker
                                    showTime
                                    onChange={onChange}
                                    onOk={onOk}
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD HH:mm"
                                    disabledDate={disabledDate}
                                />
                                {isMobile ? (
                                    <p className="help-text">
                                        Please select date and time of your
                                        arrival
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="Airport"
                            name="airport"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your airport',
                                },
                            ]}
                        >
                            {!isMobile ? (
                                <Input placeholder="Please input your airport" />
                            ) : (
                                <>
                                    <Input />
                                    <p className="help-text">
                                        Please input your airport
                                    </p>
                                </>
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Airline"
                            name="airline"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your airline',
                                },
                            ]}
                        >
                            {!isMobile ? (
                                <Input placeholder="Please input your airline" />
                            ) : (
                                <>
                                    <Input />
                                    <p className="help-text">
                                        Please input your airline
                                    </p>
                                </>
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Notes"
                            name="notes"
                            rules={[{ required: false }]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                    <Col
                        lg={10}
                        md={8}
                        sm={6}
                        style={{
                            borderLeft: isMobile ? '' : '1px solid #c1c1c1',
                        }}
                    >
                        <div style={{ padding: 20 }}>
                            <p style={{ fontSize: '24px' }}>
                                <QuestionCircleTwoTone
                                    twoToneColor="#52c41a"
                                    style={{
                                        fontSize: '24px',
                                        marginRight: 5,
                                    }}
                                />
                                Help
                            </p>
                            <p>
                                Please enter the additional data that is
                                required by your school.
                            </p>
                            <p>
                                If you do not have access to all of these
                                details now, you can complete the missing fields
                                later.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default AdditionalInfoForm;
