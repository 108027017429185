const initialState = {
    bundles: [],
    workbooks: [],
    pathways: [],
    standardPathways: [],
    user: {},
    loading: false,
    users: [],
    userGroups: [],
};

const userReporting = (state = initialState, action) => {
    switch (action.type) {
        case 'REPORTING_DATA_REQUEST':
        case 'REPORTING_DATA_TRAINER_USERS_REQUEST':
        case 'REPORTING_DATA_TRAINER_USER_GROUPS_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'REPORTING_DATA_RECEIVED':
            return {
                ...state,
                bundles: action.payload.hasOwnProperty('bundles')
                    ? action.payload.bundles
                    : [],
                workbooks: action.payload.hasOwnProperty('workbooks')
                    ? action.payload.workbooks
                    : [],
                pathways: action.payload.hasOwnProperty('pathways')
                    ? action.payload.pathways
                    : [],
                standardPathways: action.payload.hasOwnProperty('standardPathways')
                    ? action.payload.standardPathways
                    : [],
                timeReport: action.payload.hasOwnProperty('timeReport')
                    ? action.payload.timeReport
                    : [],
                user: action.payload.user,
                loading: false,
            };
        case 'REPORTING_DATA_TRAINER_USERS_SUCCESS':
            return {
                ...state,
                users: action.payload.users,
                loading: false,
            };
        case 'REPORTING_DATA_TRAINER_USER_GROUPS_SUCCESS':
            return {
                ...state,
                userGroups: action.payload.userGroups,
                loading: false,
            };
        default:
            return state;
    }
};

export default userReporting;
