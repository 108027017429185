import {libraryConstants} from '../constants/library.constants';

const initialState = {
    loading: false,
    loadingSub: false,
    items: [],
    subItems: [],
};

export function library(state = initialState, action) {
    switch (action.type) {
        case libraryConstants.LIBRARY_FETCHING:
            return {
                ...state,
                loading: true,
            };
        case libraryConstants.LIBRARY_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.data
            };
        case libraryConstants.LIBRARY_SUB_FETCHING:
            return {
                ...state,
                loadingSub: true,
            };
        case libraryConstants.LIBRARY_SUB_FETCH_SUCCESS:
            return {
                ...state,
                loadingSub: false,
                subItems: action.payload.data
            };
        default:
            return state;
    }
}
