import React from 'react';
import { Col, Row } from 'antd';
import noContentIcon from '../../../../../public/svg/undraw_Wishlist_re_m7tv.svg';
import { SmileOutlined } from '@ant-design/icons';
import './no-content.scss';
import { useSelector } from 'react-redux';
const NoContent = () => {
    const user = useSelector(state => state.authentication.user);
    return (
        <Row className="no-content" justify="center">
            <Col className="column" span={24}>
                <img src={noContentIcon} />
            </Col>
            <Col className="column" span={24}>
                <p className="message">
                    {window.__lang('No_saved_content')} <SmileOutlined />
                </p>
            </Col>
        </Row>
    );
};

export default NoContent;
