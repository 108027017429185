import messagesConstants from "../constants/messages.constants";

const initialState = {
    loading: false,
    conversationLoading: false,
    messages: [],
    filteredInboxContact: [],
    filteredSentContact: [],
    filteredArchivedContact: [],
    filterInboxStatus: false,
    filterSentStatus: false,
    filterArchivedStatus: false,
    sentThreads: [],
    inboxThreads: [],
    selectedUser: {},
    linkedTrainers: [],
    archivedThread: [],
    latestMessages: [],
    inboxTotalThreads: 0,
    sentTotalThreads: 0,
    archivedTotalThreads: 0,
    latestMessagesLoading: false,
    latestMessagesError: null,
    tabKey: "1",
    hasMoreSent: true,
    hasMoreInbox: true,
    hasMoreArchived: true,
    error: null
}

const messages = (state = initialState, action) => {
    switch (action.type) {
        // ------------ CREATE MESSAGE (THREAD) ------------
        case messagesConstants.CREATE_MESSAGES_REQUEST:
            return {
                ...state,
                // loading: true
            }
        case messagesConstants.CREATE_MESSAGES_SUCCESS:
            return {
                ...state,
                // loading: false,
            }
        case messagesConstants.CREATE_MESSAGES_REJECTED:
            return {
                // ...state,
                // loading: false,
                error: action.payload
            }

        // ------------ FETCH SENT THREADS ------------
        case messagesConstants.FETCH_SENT_THREADS_REQUEST:
            return {
                ...state,
                // loading: true,
            }
        case messagesConstants.FETCH_SENT_THREADS_SUCCESS:
            return {
                ...state,
                // loading: false,
                filterSentStatus: false,
                sentThreads: action.payload
            }
        case messagesConstants.FETCH_SENT_THREADS_REJECTED:
            return {
                ...state,
                // loading: false,
                error: action.payload
            }
        case messagesConstants.FETCH_LOAD_MORE_SENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case messagesConstants.FETCH_LOAD_MORE_SENT_SUCCESS:
            return {
                ...state,
                loading: false,
                sentThreads: state.sentThreads.concat(action.payload),
                hasMoreSent: action.payload.length > 0
            }
        case messagesConstants.FETCH_LOAD_MORE_SENT_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // ------------ CONVERSATION REPLAY ------------
        case messagesConstants.CONVERSATION_REPLAY_REQUEST:
            return {
                ...state,
                // loading: true
            }
        case messagesConstants.CONVERSATION_REPLAY_SUCCESS:
            return {
                ...state,
                // loading: false,
                messages: action.payload.reverse()
            }
        case messagesConstants.CONVERSATION_REPLAY_REJECTED:
            return {
                ...state,
                // loading: false,
                error: action.payload
            }

        // ------------ FETCH INBOX THREADS ------------
        case messagesConstants.FETCH_INBOX_THREADS_REQUEST:
            return {
                ...state,
                // loading: true
            }
        case messagesConstants.FETCH_INBOX_THREADS_SUCCESS:
            return {
                ...state,
                // loading: false,
                filterInboxStatus: false,
                inboxThreads: action.payload,
            }
        case messagesConstants.FETCH_INBOX_THREADS_REJECTED:
            return {
                ...state,
                // loading: false,
                error: action.payload
            }

        case messagesConstants.FETCH_LOAD_MORE_INBOX_REQUEST:
            return {
                ...state,
                loading: true
            }
        case messagesConstants.FETCH_LOAD_MORE_INBOX_SUCCESS:
            return {
                ...state,
                loading: false,
                inboxThreads: state.inboxThreads.concat(action.payload),
                hasMoreInbox: action.payload.length > 0
            }
        case messagesConstants.FETCH_LOAD_MORE_INBOX_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // ------------ FETCH CONVERSATIONS ------------
        case messagesConstants.FETCH_CONVERSATION_REQUEST:
            return {
                ...state,
                conversationLoading: true,
            }
        case messagesConstants.FETCH_CONVERSATION_SUCCESS:
            return {
                ...state,
                conversationLoading: false,
                messages: action.payload,
            }
        case messagesConstants.FETCH_CONVERSATION_REJECTED:
            return {
                ...state,
                conversationLoading: false,
                error: action.payload
            }

        // ------------ FETCH FILTERED INBOX MESSAGES ------------
        case messagesConstants.FETCH_FILTERED_INBOX_MESSAGES_REQUEST:
            return {
                ...state,
            }
        case messagesConstants.FETCH_FILTERED_INBOX_MESSAGES_SUCCESS:
            return {
                ...state,
                filterInboxStatus: true,
                filteredInboxContact: action.payload,
            }
        case messagesConstants.FETCH_FILTERED_INBOX_MESSAGES_REJECTED:
            return {
                ...state,
                error: action.payload
            }

        // ------------ FETCH FILTERED SENT MESSAGES ------------
        case messagesConstants.FETCH_FILTERED_SENT_MESSAGES_REQUEST:
            return {
                ...state,
            }
        case messagesConstants.FETCH_FILTERED_SENT_MESSAGES_SUCCESS:
            return {
                ...state,
                filterSentStatus: true,
                filteredSentContact: action.payload,
            }
        case messagesConstants.FETCH_FILTERED_SENT_MESSAGES_REJECTED:
            return {
                ...state,
                error: action.payload
            }

        // ------------ FETCH FILTERED ARCHIVED MESSAGES ------------
        case messagesConstants.FETCH_FILTERED_ARCHIVED_MESSAGES_REQUEST:
            return {
                ...state,
            }
        case messagesConstants.FETCH_FILTERED_ARCHIVED_MESSAGES_SUCCESS:
            return {
                ...state,
                filterArchivedStatus: true,
                filteredArchivedContact: action.payload,
            }
        case messagesConstants.FETCH_FILTERED_ARCHIVED_MESSAGES_REJECTED:
            return {
                ...state,
                error: action.payload
            }

        // ------------ FETCH USERS LINKED TRAINERS ------------
        case messagesConstants.FETCH_USERS_LINKED_TRAINERS_REQUEST:
            return {
                ...state,
            }
        case messagesConstants.FETCH_USERS_LINKED_TRAINERS_SUCCESS:
            return {
                ...state,
                linkedTrainers: action.payload,
            }
        case messagesConstants.FETCH_USERS_LINKED_TRAINERS_REJECTED:
            return {
                ...state,
                error: action.payload
            }

        // ------------ FETCH ARCHIVED THREAD REQUEST ------------
        case messagesConstants.FETCH_ARCHIVED_THREAD_REQUEST:
            return {
                ...state,
            }
        case messagesConstants.FETCH_ARCHIVED_THREAD_SUCCESS:
            return {
                ...state,
                filterArchivedStatus: false,
                archivedThread: action.payload,
            }
        case messagesConstants.FETCH_ARCHIVED_THREAD_REJECTED:
            return {
                ...state,
                error: action.payload
            }

        case messagesConstants.FETCH_LOAD_MORE_ARCHIVED_REQUEST:
            return {
                ...state,
                loading: true
            }
        case messagesConstants.FETCH_LOAD_MORE_ARCHIVED_SUCCESS:
            return {
                ...state,
                loading: false,
                archivedThread: state.archivedThread.concat(action.payload),
                hasMoreArchived: action.payload.length > 0
            }
        case messagesConstants.FETCH_LOAD_MORE_ARCHIVED_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // ------------ MOVE INBOX THREAD TO ARCHIVED REQUEST ------------
        case messagesConstants.MOVE_INBOX_THREAD_TO_ARCHIVED_REQUEST:
            return {
                ...state,
            }
        case messagesConstants.MOVE_INBOX_THREAD_TO_ARCHIVED_SUCCESS:
            return {
                ...state,
            }
        case messagesConstants.MOVE_INBOX_THREAD_TO_ARCHIVED_REJECTED:
            return {
                ...state,
                error: action.payload
            }

        // ------------ CHANGE TAB KEY STATE ------------
        case messagesConstants.CHANGE_TAB_KEY_STATE:
            return {
                ...state,
                tabKey: action.payload
            }

        // ------------ CHANGE SELECTED USER ------------
        case messagesConstants.CHANGE_SELECTED_USER:
            return {
                ...state,
                selectedUser: action.payload
            }

        // ------------ FETCH LATEST MESSAGES REQUEST ------------
        case messagesConstants.FETCH_LATEST_MESSAGES_REQUEST:
            return {
                ...state,
                latestMessagesLoading: true
            }
        case messagesConstants.FETCH_LATEST_MESSAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                latestMessages: action.payload
            }
        case messagesConstants.FETCH_LATEST_MESSAGES_REJECTED:
            return {
                ...state,
                loading: false,
                latestMessagesError: action.payload
            }

        case messagesConstants.GET_TOTAL_INBOX_THREADS:
            return {
                ...state,
                inboxTotalThreads: action.payload
            }

        case messagesConstants.GET_TOTAL_SENT_THREADS:
            return {
                ...state,
                sentTotalThreads: action.payload
            }

        case messagesConstants.GET_TOTAL_ARCHIVED_THREADS:
            return {
                ...state,
                archivedTotalThreads: action.payload
            }
        default:
            return state;
    }
}

export default messages;
