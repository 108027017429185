import {feedbackTrainerProgressConstants} from "../constants/feedbackTrainerProgress.constants";


const initialState = {
    loading: false,
    copies: [],
    archivedCopies: [],
    trainerLearners: [],
    trainerLearnerGroup: [],
    feedbackCopy: {},
    getFeedbackCopyLoading: false,
    originalFeedbackInfo: {},
    copyFeedbackInformation: {},
    copyFeedbackQuestions: [],
    trainerAnsweredQuestion: [],
    switchAnswerLoading: false,
    current: 0,
    assignedItemForCopy: {
        assigned_item: [],
        assigned_type: '',
    },
    getAssignedItemForCopyLoading: false,
    copyLearnerListLoading: false,
    copyLearnerList: [],
    answerForWholeGroup: true,
    sameQuestion: [],
    copyLearnerLoading: false,
    copyLearner: [],
    assignedCopyDetailsLoading: false,
    error: null
}

export function feedbackTrainerProgressReducer(state = initialState, action) {
    switch (action.type) {
        // GET feedback copies
        case feedbackTrainerProgressConstants.GET_FEEDBACK_COPIES_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case feedbackTrainerProgressConstants.GET_FEEDBACK_COPIES_SUCCESS:
            return {
                ...state,
                copies: action.payload,
                loading: false
            }
        case feedbackTrainerProgressConstants.GET_FEEDBACK_COPIES_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // GET archived copies
        case feedbackTrainerProgressConstants.GET_ARCHIVED_FEEDBACK_COPIES_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case feedbackTrainerProgressConstants.GET_ARCHIVED_FEEDBACK_COPIES_SUCCESS:
            return {
                ...state,
                archivedCopies: action.payload,
                loading: false,
            }
        case feedbackTrainerProgressConstants.GET_ARCHIVED_FEEDBACK_COPIES_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // GET trainer Learners
        case feedbackTrainerProgressConstants.GET_TRAINER_LEARNERS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case feedbackTrainerProgressConstants.GET_TRAINER_LEARNERS_SUCCESS:
            return {
                ...state,
                trainerLearners: action.payload,
                loading: false
            }
        case feedbackTrainerProgressConstants.GET_TRAINER_LEARNERS_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // GET trainer Learner group
        case feedbackTrainerProgressConstants.GET_TRAINER_LEARNER_GROUP_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case feedbackTrainerProgressConstants.GET_TRAINER_LEARNER_GROUP_SUCCESS:
            return {
                ...state,
                trainerLearnerGroup: action.payload,
                loading: false
            }
        case feedbackTrainerProgressConstants.GET_TRAINER_LEARNER_GROUP_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // GET copy feedback information
        case feedbackTrainerProgressConstants.GET_COPY_FEEDBACK_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case feedbackTrainerProgressConstants.GET_COPY_FEEDBACK_INFORMATION_SUCCESS:
            return {
                ...state,
                copyFeedbackInformation: action.payload,
                loading: false,
            }
        case feedbackTrainerProgressConstants.GET_COPY_FEEDBACK_INFORMATION_REJECTED:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        // GET copy feedback questions
        case feedbackTrainerProgressConstants.GET_COPY_FEEDBACK_QUESTIONS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case feedbackTrainerProgressConstants.GET_COPY_FEEDBACK_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                copyFeedbackQuestions: action.payload,
            }
        case feedbackTrainerProgressConstants.GET_COPY_FEEDBACK_QUESTIONS_REJECTED:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        // GET assigned item for copy
        case feedbackTrainerProgressConstants.GET_ASSIGNED_ITEM_FOR_COPY_REQUEST:
            return {
                ...state,
                getAssignedItemForCopyLoading: true,
            }
        case feedbackTrainerProgressConstants.GET_ASSIGNED_ITEM_FOR_COPY_SUCCESS:
            return {
                ...state,
                getAssignedItemForCopyLoading: false,
                assignedItemForCopy: {
                    ...state.assignedItemForCopy,
                    assigned_item: action.payload.assigned_item,
                    assigned_type: action.payload.assigned_type
                }
            }
        case feedbackTrainerProgressConstants.GET_ASSIGNED_ITEM_FOR_COPY_REJECTED:
            return {
                ...state,
                error: action.payload,
                getAssignedItemForCopyLoading: false,
            }

        // GET copy learner list:
        case feedbackTrainerProgressConstants.GET_COPY_LEARNER_LIST_REQUEST:
            return {
                ...state,
                copyLearnerListLoading: true,
            }
        case feedbackTrainerProgressConstants.GET_COPY_LEARNER_LIST_SUCCESS:
            return {
                ...state,
                copyLearnerListLoading: false,
                copyLearnerList: action.payload
            }
        case feedbackTrainerProgressConstants.GET_COPY_LEARNER_LIST_REJECTED:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        // GET copy learner
        case feedbackTrainerProgressConstants.GET_COPY_LEARNER_REQUEST:
            return {
                ...state,
                copyLearnerLoading: true,
            }
        case feedbackTrainerProgressConstants.GET_COPY_LEARNER_SUCCESS:
            return {
                ...state,
                copyLearnerLoading: false,
                copyLearner: action.payload
            }
        case feedbackTrainerProgressConstants.GET_COPY_LEARNER_REJECTED:
            return {
                ...state,
                copyLearnerLoading: false,
                error: action.payload
            }

        // DELETE copy feedback
        case feedbackTrainerProgressConstants.DELETE_COPY_FEEDBACK_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case feedbackTrainerProgressConstants.DELETE_COPY_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: false,
                copies: state.copies.filter(copy => copy.feedback_copy_id !== action.payload)
            }
        case feedbackTrainerProgressConstants.DELETE_COPY_FEEDBACK_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // POST archive copy feedback
        case feedbackTrainerProgressConstants.ARCHIVE_COPY_FEEDBACK_REQUEST:
            return {
                ...state,
                loading: true
            }
        case feedbackTrainerProgressConstants.ARCHIVE_COPY_FEEDBACK_SUCCESS:
            return {
                ...state,
                copies: state.copies.filter(doc => doc.feedback_copy_id !== action.payload),
                loading: false
            }
        case feedbackTrainerProgressConstants.ARCHIVE_COPY_FEEDBACK_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // POST allocate learners or learner group
        case feedbackTrainerProgressConstants.ALLOCATE_LEARNERS_OR_LEARNER_GROUPS_REQUEST:
            return {
                ...state,
                getFeedbackCopyLoading: true
            }
        case feedbackTrainerProgressConstants.ALLOCATE_LEARNERS_OR_LEARNER_GROUPS_SUCCESS:
            return {
                ...state,
                getFeedbackCopyLoading: false,
                feedbackCopy: {...action.payload},
            }
        case feedbackTrainerProgressConstants.ALLOCATE_LEARNERS_OR_LEARNER_GROUPS_REJECTED:
            return {
                ...state,
                getFeedbackCopyLoading: false,
                error: action.payload
            }

        // POST store trainer answer
        case feedbackTrainerProgressConstants.STORE_TRAINER_ANSWER_REQUEST:
            return {
                ...state,
                switchAnswerLoading: true,
            }
        case feedbackTrainerProgressConstants.STORE_TRAINER_ANSWER_SUCCESS:
            return {
                ...state,
                switchAnswerLoading: false,
                current: state.current + 1,
                answerForWholeGroup: true
            }
        case feedbackTrainerProgressConstants.STORE_TRAINER_ANSWER_REJECTED:
            return {
                ...state,
                switchAnswerLoading: false,
                error: action.payload
            }

        // GET_ORIGINAL_FEEDBACK_ID_WHEN_CLICK_ALLOCATE_ICON
        case feedbackTrainerProgressConstants.GET_ORIGINAL_FEEDBACK_INFO_WHEN_CLICK_ALLOCATE_ICON:
            return {
                ...state,
                originalFeedbackInfo: action.payload
            }

        // STORE_TRAINER_FEEDBACK_ANSWERS
        case feedbackTrainerProgressConstants.STORE_TRAINER_FEEDBACK_ANSWERS:
            const newList = state.trainerAnsweredQuestion.filter(question => question.question_id !== action.payload.question_id)
            return {
                ...state,
                trainerAnsweredQuestion: [...newList, action.payload]
            }

        // CHANGE STEPPER CURRENT STEP
        case feedbackTrainerProgressConstants.CHANGE_CURRENT_STATUS:
            return {
                ...state,
                current: state.current - 1
            }

        // CLEAR TRAINER ANSWERS
        case feedbackTrainerProgressConstants.RESTORE_TO_INITIAL:
            return {
                ...state,
                trainerAnsweredQuestion: [],
                copyFeedbackQuestions: [],
                current: 0
            }

        // ANSWER FOR WHOLE GROUP
        case feedbackTrainerProgressConstants.ANSWER_FOR_WHOLE_GROUP:
            return {
                ...state,
                answerForWholeGroup: action.payload
            }

        // ARRAY OF SAME QUESTION BASED ON LEARNER LIST LENGTH
        case feedbackTrainerProgressConstants.ARRAY_OF_SAME_QUESTION_BASED_ON_LEARNER_LIST_LENGTH:
            const currentQuestion = [{...state.copyFeedbackQuestions[action.payload]}]
            const newCopyLearnerList = [...state.copyLearnerList]
            const newArr = newCopyLearnerList.map(
                (_, i) => (
                    currentQuestion[i % currentQuestion.length]
                )
            );
            const combineLearnerWithNewArr = newArr.map((x, i) => (
                {
                    ...x,
                    learnerID: newCopyLearnerList[i].userID
                }
            ))

            return {
                ...state,
                sameQuestion: [...combineLearnerWithNewArr]
            }

        // WHOLE GROUP
        case feedbackTrainerProgressConstants.WHOLE_GROUP:
            return {
                ...state,
                sameQuestion: []
            }

        default :
            return state
    }
}
