import moduleConstants from '../constants/module.constants';

const initialState = {
    loading: false,
    updateLoading: false,
    modules: [],
    module: {},
    allocateLoading: false,
    sendingFeedback: false,
    sendingFeedbackSuccess: false,
};

const modules = (state = initialState, action) => {
    switch (action.type) {
        case moduleConstants.FETCH_MODULES_REQUEST:
        case moduleConstants.SEARCH_MODULE_REQUEST:
            return {
                ...state,
                loading: true,
                modules: [],
            };
        case moduleConstants.FETCH_MODULES_SUCCESS:
        case moduleConstants.SEARCH_MODULE_SUCCESS:
            return {
                ...state,
                loading: false,
                modules: action.payload,
            };
        case moduleConstants.CLEAR_MODULE:
            return {
                ...state,
                module: {},
            };
        case moduleConstants.FETCH_ALL_MODULES_SUCCESS:
            return {
                ...state,
                loading: false,
                allModules: action.payload,
            };
        case moduleConstants.FETCH_MODULE_REQUEST:
            return {
                ...state,
                loading: true,
                module: {},
            };
        case moduleConstants.FETCH_MODULE_SUCCESS:
            return {
                ...state,
                loading: false,
                module: action.payload,
            };
        case moduleConstants.FETCH_MODULE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                moduleData: action.payload,
            };
        case moduleConstants.FETCH_MODULE_ACTIVITIES_REQUEST:
            return {
                ...state,
                loading: true,
                activities: [],
            };
        case moduleConstants.FETCH_MODULE_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                activities: action.payload,
            };
        case moduleConstants.ALLOCATE_MODULE_TO_USERS_REQUEST:
            return {
                ...state,
                allocateLoading: true,
            };
        case moduleConstants.ALLOCATE_MODULE_TO_USERS_SUCCESS:
        case moduleConstants.ALLOCATE_MODULE_TO_USERS_REJECTED:
            return {
                ...state,
                allocateLoading: false,
            };
        case moduleConstants.SAVE_MODULE_REQUEST:
            return {
                ...state,
                updateLoading: true,
            };
        case moduleConstants.SAVE_MODULE_SUCCESS:
            return {
                ...state,
                updateLoading: false,
            };
        case moduleConstants.SAVE_MODULE_REJECTED:
            return {
                ...state,
                updateLoading: false,
            };
        case moduleConstants.RESET_MODULES:
            return {
                ...state,
                modules: [],
            };
        case moduleConstants.SENDING_FEEDBACK:
            return {
                ...state,
                sendingFeedback: true,
            };
        case moduleConstants.SENDING_FEEDBACK_End:
            return {
                ...state,
                sendingFeedback: false,
                sendingFeedbackSuccess: true,
            };
        case moduleConstants.RESET_SENDING_FEEDBACK:
            return {
                ...state,
                sendingFeedbackSuccess: false,
            };
        case moduleConstants.MODULE_HARD_DELETE:
            return {
                ...state,
                // modules: state.modules.filter(
                //     x => x.moduleID != action.moduleId,
                // ),
            };
        case moduleConstants.SET_MODULE:
            return {
                ...state,
                module: action.module,
            };
        case moduleConstants.SET_NEW_ACTIVITY_PERCENTAGE:
            let module = state.module;
            let activities_with_sections;
            let newPercentage = action.newPercentage;
            let activityID = action.activityID;
            activities_with_sections = module?.data?.activities_with_sections?.map(
                section => {
                    section.activities = section.activities.map(activity => {
                        if (activity.activityID == activityID) {
                            activity.newPercentage = newPercentage;
                        }
                        return activity;
                    });
                    return section;
                },
            );
            if (activities_with_sections)
                module.data.activities_with_sections = activities_with_sections;
            return {
                ...state,
                module: { ...module },
            };
        case moduleConstants.MODULE_Activity_Completed:
            let activityId = action.activityId;
            let currentModule = state.module;
            currentModule.name = 'nameeee';
            currentModule.data.activities_with_sections = currentModule.data.activities_with_sections.map(
                section => {
                    section.activities = section.activities.map(activity => {
                        if (activity.activityID == action.activityId) {
                            activity.is_completed = true;
                            activity.last_tracking = {
                                ...activity.last_tracking,
                                // newPercentage: 100,
                                required: 1,
                                score: 1,
                                fullScore: 1,
                            };
                        }
                        return activity;
                    });
                    return section;
                },
            );

            return { ...state, module: { ...currentModule } };
        case moduleConstants.CLEAR_MODULE:
            return { ...state, module: {} };
        default:
            return state;
    }
};

export default modules;
