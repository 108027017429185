import React from 'react';
import {Skeleton} from 'antd'

const useSkeletonLoadingForTables = (columns) => {
    const newDataArray = Array.from({ length: 10 }, () => ({}));

    columns.forEach((column) => {
        const { dataIndex } = column;
        newDataArray.forEach((obj) => {
            obj[dataIndex] = <Skeleton.Input active />
        });
    });

    return newDataArray;
}

export default useSkeletonLoadingForTables
