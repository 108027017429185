import { notification } from 'antd';
import savedContentConstants from '../constants/savedContent.constants';
import { axiosDefaultClient } from '../../settings/axios.settings';
const client = axiosDefaultClient;
const getSaveContent = (userId, page, pageSize, role, filters, keyword) => {
    return dispatch => {
        dispatch({ type: savedContentConstants.Get_SAVE_CONTENT });
        let url =
            role === 'learner'
                ? `/api/users/${userId}/saved-content?`
                : `/api/trainer/${userId}/saved-content?`;
        if (page) url = url + `&page=${page}`;
        if (pageSize) url = url + `&per_page=${pageSize}`;
        if (filters?.type) url = url + `&type=${filters.type}`;
        if (keyword) url = url + `&keyword=${keyword}`;
        if (Number.isInteger(filters?.status))
            url = url + `&status=${filters?.status}`;
        if (filters?.groupID) url = url + `&groupID=${filters?.groupID}`;
        if (filters?.userID) url = url + `&userID=${filters?.userID}`;
        if (filters?.activityID) url = url + `&activityID=${filters?.activityID}`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: savedContentConstants?.Get_SAVE_CONTENT_SUCCESS,
                    payload: {
                        content: response.data?.payload?.data,
                        metaData: response.data?.payload?.meta,
                    },
                });
            })
            .catch(error => {
                notification.error({
                    message: 'Error!',
                    description: error?.data?.message,
                });
            });
    };
};

const trainerMarkContent = (savedContentId, score, notes) => {
    return dispatch => {
        dispatch({ type: savedContentConstants.MARK_CONTENT });
        let url = `/api/saved-content/${savedContentId}/mark`;
        client
            .post(url, { score, notes })
            .then(response => {
                dispatch({
                    type: savedContentConstants.MARK_CONTENT_SUCCESS,
                    payload: {
                        savedContentId,
                        item: response.data?.payload,
                    },
                });
            })
            .catch(error => {
                console.log(error);
                notification.error({
                    message: 'Error!',
                    description: error?.data?.message,
                });
            });
    };
};

function getTrainerActivityContentActivities(trainerID) {
    return function (dispatch) {
        dispatch({
            type: savedContentConstants.FETCH_ACTIVITY_CONTENT_ACTIVITIES_REQUEST,
        });
        client
            .get(`api/trainer/${trainerID}/saved-content/activities`)
            .then((response) => {
                dispatch({
                    type: savedContentConstants.FETCH_ACTIVITY_CONTENT_ACTIVITIES_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch((error) => {
                dispatch({
                    type: savedContentConstants.FETCH_ACTIVITY_CONTENT_ACTIVITIES_REJECTED,
                    payload: error,
                });
            });
    };
}

const savedContentActions = {
    getSaveContent,
    trainerMarkContent,
    getTrainerActivityContentActivities,
};
export default savedContentActions;
