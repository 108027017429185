import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import {Col, Row, Menu, Dropdown} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {Link, useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import faFloppyDisk from "../../../../public/svg/floppy-disk-svgrepo-com.svg"
import {SiteLayoutStyles} from '../../../styles/SiteLayout.style';
import links from './NavigationLinks';
import './Navigation.scss';
import rolesAndPermisions from "../../../redux/constants/rolesAndPermissions.constants";

const {SubMenu} = Menu;
const styles = SiteLayoutStyles;

const menu = items => {
    return (
        <Menu>
            {items.map(item => {
                if (item.items) {
                    return (
                        <SubMenu title={item.label}>
                            {item.items?.map(subItem => (
                                <Menu.Item key={subItem.label}>
                                    <Link to={subItem.url} key={subItem.index}>
                                        {subItem.label}
                                    </Link>
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    );
                } else {
                    return (
                        <Menu.Item key={item.label}>
                            <Link to={{
                                pathname: item.url,
                            }} key={item.index}>
                                {item.label}
                            </Link>
                        </Menu.Item>
                    );
                }
            })}
        </Menu>
    );
};
const NavigationLinks = ({link, key, linkClass}) => {
    const location = useLocation();
    const linkProps = link.linkProps ?? {};
    return (
        <Link
            key={key}
            to={{pathname: link.url, ...linkProps}}
            style={{
                backgroundColor: 'var(--primary-color, #5371d3)',
                display: link?.display,
            }}
            className={linkClass}
        >
                <span
                    style={{
                        padding: '10px 8px',
                    }}
                >
                    <FontAwesomeIcon
                        style={{
                            marginRight: 5,
                        }}
                        icon={link.icon}
                    />
                    {link.label}
                </span>
        </Link>
    );
};
// {
//     link.url === location.pathname
//         ? {
//               //   backgroundColor: 'var(--secondary-color, #54af38)',
//               borderBottom: '3px solid white',
//               padding: '22px 8px',
//           }
//         : {
//               backgroundColor: 'var(--primary-color, #5371d3)',
//               display: link?.display,
//           }
// }

const DropdownMenu = ({link, key, linkClass}) => {
    return (
        <Dropdown overlay={menu(link?.items)} trigger={['click']}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}

            <a
                key={key}
                className="ant-dropdown-link"
                style={linkClass === 'active' ? {
                    backgroundColor: '#FFFFFF',
                    color: 'var(--primary-color, #5371d3)',
                    fontSize: '15px',
                    textAlign: 'center',
                    border: '1px solid var(--primary-color, #5371d3)',
                    padding: '0 10px',
                    display: link.display,
                } : {
                    ...styles.navBarStyles,
                    ...styles.linkStyles,
                    display: link.display,
                }
            }
                onClick={e => e.preventDefault()}
            >
                <>
                    <FontAwesomeIcon
                        style={link.label !== 'Users' ? {marginLeft: 5, marginRight: 0} : {
                            marginRight: 5,
                            marginLeft: 0
                        }}
                        icon={link.icon}
                    />
                    {link.label} <DownOutlined/>
                </>
            </a>
        </Dropdown>
    );
};

const Navigation = ({user}) => {
    const navMenus = links(user)(
        user.permissions.map(permission => permission.name),
    )[user?.roleLevel?.slug !== 'partenaire_cloe_trainer' ? user.role : user.roleLevel.slug];

    const canAllocateContent = user?.permissions.find(
        x => x.code == rolesAndPermisions.allocateContent,
    );
    const currentPage = window.location.href;


    if (user?.site_relation?.showSearchTabForTrainer !== 1) {
        navMenus.forEach((link, index) => {
            if (link.url === '/admin/search') {
                navMenus.splice(index, 1);
            }
        })
    }

    if (user?.site_relation?.showWishlistTabForTrainer !== 1) {
        navMenus.forEach((link, index) => {
            if (link.url === '/admin/wishlist') {
                navMenus.splice(index, 1);
            }
        })
    }


    return (
        <Row
            style={{
                backgroundColor: 'var(--primary-color, #5371d3)',
                color: 'white',
                fontSize: '16px',
                textAlign: 'center',
            }}
        >
            {user && (
                <Col flex={1}>
                    <div className="navigationBar">
                        {canAllocateContent === undefined ? navMenus.filter(item => item.label !== ' Content' && item !== '')
                            .map(menuItem => {
                                const isActive = currentPage.includes(
                                    menuItem.hasOwnProperty('items') ?
                                        menuItem.items.filter(Boolean)
                                            .find(item => currentPage.includes(item.url))?.url :
                                        menuItem.url
                                );
                                const linkClass = isActive ? "active" : "navbar";
                                return menuItem.items ? (
                                    <DropdownMenu
                                        link={menuItem}
                                        key={menuItem.label}
                                        linkClass={linkClass}
                                    />
                                ) : (
                                    user.site_relation.canSeeSavedContent === 0 && menuItem.label === window.__lang('saved_content') ? null :
                                    <NavigationLinks
                                        link={menuItem}
                                        key={menuItem.label}
                                        linkClass={linkClass}
                                    />
                                )}
                            ) : (
                            navMenus
                                ?.filter(item => item != '')
                                .map(menuItem => {
                                    const isActive = currentPage.includes(
                                        menuItem.hasOwnProperty('items') ?
                                            menuItem.items.filter(Boolean)
                                                .find(item => currentPage.includes(item.url))?.url :
                                            menuItem.url
                                    );
                                    const linkClass = isActive ? "active" : "navbar";
                                    return menuItem.items ? (
                                        <DropdownMenu
                                            link={menuItem}
                                            key={menuItem.label}
                                            linkClass={linkClass}
                                        />
                                    ) : (
                                        user?.site_relation?.canSeeSavedContent === 0 && menuItem.label === window.__lang('saved_content') ? null :
                                        <NavigationLinks
                                            link={menuItem}
                                            key={menuItem.label}
                                            linkClass={linkClass}
                                        />
                                    )}
                                )
                        )}
                    </div>
                </Col>
            )}
        </Row>
    );
};

export default Navigation;

NavigationLinks.propTypes = {
    index: PropTypes.number,
    link: PropTypes.shape({
        url: PropTypes.string,
        icon: PropTypes.arrayOf(PropTypes.string),
        label: PropTypes.string,
    }).isRequired,
};

NavigationLinks.defaultProps = {
    index: null,
};

DropdownMenu.propTypes = {
    link: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})),
        label: PropTypes.string,
        icon: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
};

Navigation.propTypes = {
    user: PropTypes.shape({
        role: PropTypes.string,
    }).isRequired,
};
