const savedContentConstants = {
    Get_SAVE_CONTENT: 'Get_SAVE_CONTENT',
    Get_SAVE_CONTENT_SUCCESS: 'Get_SAVE_CONTENT_SUCCESS',
    MARK_CONTENT: 'MARK_CONTENT',
    MARK_CONTENT_SUCCESS: 'MARK_CONTENT_SUCCESS',
    FETCH_ACTIVITY_CONTENT_ACTIVITIES_REQUEST: 'FETCH_ACTIVITY_CONTENT_ACTIVITIES_REQUEST',
    FETCH_ACTIVITY_CONTENT_ACTIVITIES_SUCCESS: 'FETCH_ACTIVITY_CONTENT_ACTIVITIES_SUCCESS',
    FETCH_ACTIVITY_CONTENT_ACTIVITIES_REJECTED: 'FETCH_ACTIVITY_CONTENT_ACTIVITIES_REJECTED',
};
export default savedContentConstants;
