import siteConstants from '../constants/site.constants';

const initialState = {
    loading: false,
    updateLoading: false,
    sites: [],
    site: {},
    ssoHashKey: null,
    ssoKey: null,
    ssoReturnUrl: null,
    ssoReturnUrlText: null,
    generateSSOKeyLoading: false
};

const sites = (state = initialState, action) => {
    switch (action.type) {
        case siteConstants.FETCH_SITE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case siteConstants.FETCH_SITE_SUCCESS:
            return {
                ...state,
                site: action.payload,
                ssoHashKey: action.payload.ssoHashKey,
                ssoKey: action.payload.ssoKey,
                ssoReturnUrl: action.payload.ssoReturnUrl,
                ssoReturnUrlText: action.payload.ssoReturnUrlText,
                loading: false,
            };
        case siteConstants.FETCH_SITE_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case siteConstants.COPY_SITE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case siteConstants.COPY_SITE_SUCCESS:
            return {
                ...state,
                site: action.payload,
                loading: false,
            };
        case siteConstants.COPY_SITE_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case siteConstants.FETCH_SITES_REQUEST:
            return {
                ...state,
                loading: true,
                sites: [],
            };
        case siteConstants.SEARCH_SITE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case siteConstants.FETCH_SITES_SUCCESS:
        case siteConstants.SEARCH_SITE_SUCCESS:
            return {
                ...state,
                loading: false,
                sites: action.payload,
                site: {},
            };
        case siteConstants.FETCH_SITES_REJECTED:
        case siteConstants.SEARCH_SITE_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case siteConstants.UPDATE_SITE_REQUEST:
            return {
                ...state,
                updateLoading: true,
            };
        case siteConstants.UPDATE_SITE_SUCCESS:
            return {
                ...state,
                updateLoading: false,
            };
        case siteConstants.UPDATE_SITE_REJECTED:
            return {
                ...state,
                updateLoading: false,
            };
        case siteConstants.GET_SITE_TITLE_REQUEST:
            return {
                ...state,
                getSitesTitleLoading: true,
            };
        case siteConstants.GET_SITE_TITLE_SUCCESS:
            return {
                ...state,
                sitesTitles: action.payload,
                getSitesTitleLoading: false,
            };
        case siteConstants.GET_SITE_TITLE_REJECTED:
            return {
                ...state,
                error: action.payload,
                getSitesTitleLoading: false,
            };
        case siteConstants.MULTI_COPY_SITE_REQUEST:
            return {
                ...state,
                multiCopyLoading: true,
            };
        case siteConstants.MULTI_COPY_SITE_SUCCESS:
            return {
                ...state,
                multiCopyData: action.payload,
                multiCopyLoading: false,
            };
        case siteConstants.MULTI_COPY_SITE_REJECTED:
            return {
                ...state,
                multiCopyError: action.payload,
                multiCopyLoading: false,
            };
        case siteConstants.GET_SITE_BACKUP_STATUS_REQUEST:
            return {
                ...state,
                backUpLoading: true,
            };
        case siteConstants.GET_SITE_BACKUP_STATUS_SUCCESS:
            return {
                ...state,
                backUpData: action.payload,
            };
        case siteConstants.GET_SITE_BACKUP_STATUS_REJECTED:
            return {
                ...state,
                backUpError: action.payload,
            };
        case siteConstants.GENERATE_SSO_KEYS_FOR_SITE_REQUEST:
            return {
                ...state,
                generateSSOKeyLoading: true
            }
        case siteConstants.GENERATE_SSO_KEYS_FOR_SITE_SUCCESS:
            return {
                ...state,
                // site: action.payload,
                ssoHashKey: action.payload.ssoHashKey,
                ssoKey: action.payload.ssoKey,
                ssoReturnUrl: action.payload.ssoReturnUrl,
                ssoReturnUrlText: action.payload.ssoReturnUrlText,
                generateSSOKeyLoading: false,
            };
        case siteConstants.GENERATE_SSO_KEYS_FOR_SITE_REJECTED:
            return {
                ...state,
                generateSSOKeyLoading: false
            }
        case siteConstants.CLEAR_SSO_DATA:
            return {
                ...state,
                ssoHashKey: null,
                ssoKey: null,
                ssoReturnUrl: null,
                ssoReturnUrlText: null,
                generateSSOKeyLoading: false
            }
        default:
            return state;
    }
};

export default sites;
