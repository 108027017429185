import formatConstants from '../constants/formats.constant';

const initialState = {
    loading: false,
    formats:[]
};

const formats = (state = initialState, action) => {

    switch (action.type) {
        case formatConstants.FETCH_FORMAT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case formatConstants.FETCH_FORMAT_SUCCESS:
            return {
                ...state,
                loading: false,
                formats: action.payload,
            };
        case formatConstants.FETCH_FORMAT_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case formatConstants.FETCH_CSV_OPTIONS:
            return {
                ...state,
                csv_options: action.payload,
            };
               default:
            return state;
    }
};

export default formats;
