import learningPathwayConstants from '../constants/learningPathways.constants';
import learningPathwaysConstants from "../constants/learningPathways.constants";

const initialState = {
    loading: false,
    updateLoading: false,
    created: false,
    validationErrors: [],
    learnerPathways: [],
    learningPathway: {},
    learningPathways: [],
    otherTrainerLearningPathways: [],
    users: [],
    userGroups: [],
    assignPathwayLoading: false,
};

const learningPathways = (state = initialState, action) => {
    switch (action.type) {
        case learningPathwayConstants.FETCH_LEARNING_PATHWAYS_REQUEST:
        case learningPathwayConstants.FETCH_LEARNER_PATHWAYS_REQUEST:
            return {
                ...state,
                loading: true,
                learnerPathways: [],
            };
        case learningPathwayConstants.FETCH_LEARNER_PATHWAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                learnerPathways: action.payload,
            };
        case learningPathwayConstants.FETCH_LEARNER_PATHWAYS_REJECTED:
            return {
                ...state,
                loading: false,
                validationErrors: action.payload,
            };
        case learningPathwayConstants.FETCH_OTHER_TRAINER_PATHWAYS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case learningPathwaysConstants.FETCH_OTHER_TRAINER_PATHWAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                otherTrainerLearningPathways: action.payload
            }
        case learningPathwaysConstants.FETCH_OTHER_TRAINER_PATHWAYS_REJECTED:
            return {
                ...state,
                loading: false,
                validationErrors: action.payload
            }
        case learningPathwayConstants.SEARCH_LEARNING_PATHWAY_REQUEST:
            return {
                ...state,
                loading: true,
                learningPathways: [],
            };
        case learningPathwayConstants.FETCH_LEARNING_PATHWAY_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
                learningPathway: {},
            };
        case learningPathwayConstants.FETCH_LEARNING_PATHWAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                learningPathways: action.payload,
                learningPathway: {},
            };
        case learningPathwayConstants.CLEAR_LEARNING_PATHWAYS:
            return {
                ...state,
                learningPathways: [],
            };
        case learningPathwayConstants.SEARCH_LEARNING_PATHWAY_SUCCESS:
            return {
                ...state,
                loading: false,
                learningPathways: action.payload,
            };
        case learningPathwayConstants.FETCH_LEARNING_PATHWAYS_REJECTED:
        case learningPathwayConstants.SEARCH_LEARNING_PATHWAY_REJECTED:
            return {
                ...state,
                loading: false,
                learningPathways: [],
            };
        case learningPathwayConstants.FETCH_LEARNING_PATHWAY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learningPathwayConstants.FETCH_LEARNING_PATHWAY_SUCCESS:
            return {
                ...state,
                loading: false,
                learningPathway: action.payload,
            };
        case learningPathwayConstants.FETCH_LEARNING_PATHWAY_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case learningPathwayConstants.RESET_LEARNING_PATHWAY:
            return {
                ...state,
                learningPathway: {},
            };
        case learningPathwayConstants.SAVE_LEARNING_PATHWAY_REQUEST:
            return {
                ...state,
                updateLoading: true,
                loading: true,
                validationErrors: [],
            };
        case learningPathwayConstants.SAVE_LEARNING_PATHWAY_SUCCESS:
            return {
                ...state,
                updateLoading: false,
                validationErrors: [],
            };
        case learningPathwayConstants.SAVE_LEARNING_PATHWAY_REJECTED:
            return {
                ...state,
                updateLoading: false,
                loading: false,
                validationErrors: action.payload ? action.payload : [],
            };
        case learningPathwayConstants.SAVE_CANCELLED:
            return {
                ...state,
                validationErrors: [],
            };
        case learningPathwayConstants.UPDATE_CREATED_STATUS:
            return {
                ...state,
                created: true,
            }
        case learningPathwaysConstants.CLEAR_CREATED_STATUS:
            return {
                ...state,
                created: false
            }
        case learningPathwayConstants.CLEARDATA: {
            return {
                ...initialState,
            };
        }
        case learningPathwayConstants.FETCH_LEARNING_PATHWAY_USERS_USERGROUPS_REQUEST:
            return {
                ...state,
                loading: true,
                users: [],
                userGroups: [],
            };
        case learningPathwayConstants.FETCH_LEARNING_PATHWAY_USERS_USERGROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.users,
                userGroups: action.payload.groups,
            };
        case learningPathwayConstants.FETCH_LEARNING_PATHWAY_USERS_USERGROUPS_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case learningPathwayConstants.ASSIGN_LEARNING_PATHWAY_TO_USERS_USERGROUPS_REQUEST:
            return {
                ...state,
                assignPathwayLoading: true,
            };
        case learningPathwayConstants.ASSIGN_LEARNING_PATHWAY_TO_USERS_USERGROUPS_SUCCESS:
            return {
                ...state,
                assignPathwayLoading: false,
            };
        case learningPathwayConstants.ASSIGN_LEARNING_PATHWAY_TO_USERS_USERGROUPS_REJECTED:
            return {
                ...state,
                assignPathwayLoading: false,
            };
        default:
            return state;
    }
};

export default learningPathways;
