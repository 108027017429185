import React, { useState } from 'react';
import { Button, Col, Input, Popover, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import exportFile from '../../../../../../public/svg/Icon awesome-file-export.svg';
import './content-header.scss';
import Filters from './Filters';
const ContetHeader = ({ filters, setFilters, setKeyword, onKeyWordChange }) => {
    return (
        <Row className="content-header" justify="start">
            <Col span={8} align="start" style={{display: 'flex', flexDirection: 'row'}}>
                <h3>Filter by user/user group and/or saved content type: </h3>
                <Input
                    suffix={
                        <Filters filters={filters} setFilters={setFilters} />
                    }
                    className="search-input"
                    type={'submit'}
                />
            </Col>
        </Row>
    );
};

export default ContetHeader;
