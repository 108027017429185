import { userConstants } from '../constants/user.constants';

const initialState = {
    loading: false,
    user: {},
    users: [],
    learners: [],
    trainers: [],
    hrManagers: [],
    searchKeyword: '',
    saveLoading: false,
    updateLoading: false,
};

function users(state = initialState, action) {
    switch (action.type) {
        case userConstants.USERS_SEARCH_REQUEST:
            return {
                ...state,
                loading: true,
                searchKeyword: action.payload,
                users: [],
            };
        case userConstants.USERS_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload,
            };
        case userConstants.USERS_SEARCH_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case userConstants.COMPLETE_MODULES_SUCCESS:
            return {
                ...state,
                completeModules: action.payload,
            };
        case userConstants.COMPLETE_MODULES_REJECTED:
            return {
                ...state,
                completeModules: 0,
            };
        case userConstants.PRIMARY_SKILLS_SUCCESS:
            return {
                ...state,
                primarySkills: action.payload,
            };
        case userConstants.PRIMARY_SKILLS_REJECTED:
            return {
                ...state,
                primarySkills: [],
            };
        case userConstants.FETCH_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                users: [],
                // user:{}
            };
        case userConstants.FETCH_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload,
            };
        case userConstants.FETCH_USERS_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case userConstants.FETCH_USERS_LEARNERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.FETCH_USERS_LEARNERS_SUCCESS:
            return {
                ...state,
                loading: false,
                learners: action.payload,
            };
        case userConstants.FETCH_USERS_LEARNERS_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case userConstants.FETCH_USERS_TRAINERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.FETCH_USERS_TRAINERS_SUCCESS:
            return {
                ...state,
                loading: false,
                trainers: action.payload,
            };
        case userConstants.FETCH_USERS_TRAINERS_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case userConstants.FETCH_USERS_HRMANAGERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.FETCH_USERS_HRMANAGERS_SUCCESS:
            return {
                ...state,
                loading: false,
                hrManagers: action.payload,
            };
        case userConstants.FETCH_USERS_HRMANAGERS_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case userConstants.GET_USER_REQUEST:
            return {
                ...state,
                user: {},
                loading: true,
            };
        case userConstants.GET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false,
            };
        case userConstants.GET_USER_REJECTED:
            return {
                ...state,
                user: {},
                loading: false,
            };
        case userConstants.WELCOME_MASSAGES_SUCCESS:
            return {
                ...state,
                welcomeMassages: action.payload,
                loading: false,
            };
        case userConstants.CREATE_USER_REQUEST:
            return {
                ...state,
                saveLoading: true,
            };
        case userConstants.CREATE_USER_SUCCESS:
            return {
                ...state,
                saveLoading: false,
            };
        case userConstants.CREATE_USER_REJECTED:
            return {
                ...state,
                saveLoading: false,
            };
        case userConstants.UPDATE_USER_REQUEST:
            return {
                ...state,
                updateLoading: true,
            };
        case userConstants.UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateLoading: false,
            };
        case userConstants.UPDATE_USER_REJECTED:
            return {
                ...state,
                updateLoading: false,
            };
        case userConstants.UPDATE_TRAINER_PROFILE_REQUEST:
            return {
                ...state,
                updateLoading: true,
            };
        case userConstants.UPDATE_TRAINER_PROFILE_SUCCESS:
            return {
                ...state,
                updateLoading: false,
            };
        case userConstants.UPDATE_TRAINER_PROFILE_REJECTED:
            return {
                ...state,
                updateLoading: false,
            };
        case userConstants.CLEAR_USER:
            return {
                ...state,
                user: {},
            };
        default:
            return state;
    }
}

export default users;
