import { map, concat, remove } from 'lodash';
import { formBuilderPageConstants } from '../constants/FormBuilder/formBuilder.page.constants';
import { formBuilderFieldConstants } from '../constants/FormBuilder/formBuilder.field.constants';
import formBuilderConstants from '../constants/FormBuilder/formBuilder.constants';

const initialState = {
    currentPage: {},
    fields: {},
    pagesLoading: false,
    lastItem: {},
    fieldOptionsModalVisible: false,
    fieldActionType: 'add',
    editField: {},
    editFieldIndex: null,
    bulkActionsMode: false,
    bulkActionType: '',
    selectedFieldsForBulkActions: [],
    identifier: 'instructions',
    hasContent: false,
};

export function formBuilder(state = initialState, action) {
    switch (action.type) {
        case formBuilderPageConstants.PAGES_LOADING:
            return {
                ...state,
                pagesLoading: true,
            };
        case formBuilderPageConstants.PAGES_LOADED:
            let fields = {};
            let currentPage = {};
            map(action.fields, (value, key) => {
                if (Array.isArray(value.pages)) {
                    let pagesObject = {};
                    map(value.pages, (page, pageKey) => {
                        let questionsArray = [];
                        page.questions.map((question, questionKey) => {
                            if (question.hasOwnProperty('question')) {
                                questionsArray.push(question);
                            } else {
                                let questionLine = [];
                                map(question, (chunk, chkey) => {
                                    if (
                                        ![
                                            'created_at',
                                            'updated_at',
                                            '_id',
                                            'new_page_ids',
                                        ].includes(chkey)
                                    ) {
                                        if (chunk.length === 0) {
                                        } else {
                                            questionLine.push(chunk);
                                        }
                                    }
                                });
                                if (questionLine.length > 0) {
                                    questionsArray.push(questionLine);
                                }
                            }
                        });
                        pagesObject[pageKey + 1] = questionsArray;
                    });
                    fields[key] = {
                        pages: pagesObject,
                    };
                    currentPage[key] = 1;
                } else {
                    fields[key] = {
                        pages: value.pages,
                    };
                    currentPage[key] = 1;
                }
            });
            return {
                ...state,
                fields,
                pagesLoading: false,
                currentPage,
            };
        case formBuilderFieldConstants.FIELD_DROPPED:
            return {
                ...state,
                fieldOptionsModalVisible: true,
                editField: {},
                lastItem: action.field,
            };
        case formBuilderFieldConstants.FIELD_ADDED:
            const pages = state.fields[action.uniqueIdentifier]?.pages;
            const { values } = action;
            const newValues = values;
            if (Object.hasOwnProperty(values, 'question')) {
                newValues.question = {
                    content: values.question,
                    instructions: values.questionInstructions,
                };
            }
            const fieldInfo = { ...newValues };
            pages[state.currentPage[action.uniqueIdentifier]] = [
                ...pages[state.currentPage[action.uniqueIdentifier]],
                fieldInfo,
            ];
            const existingFields = state.fields;
            existingFields[action.uniqueIdentifier].pages = pages;
            return {
                ...state,
                fieldOptionsModalVisible: false,
                fields: existingFields,
                hasContent: true,
            };
        case formBuilderFieldConstants.FIELD_ADD_CANCELLED:
            return {
                ...state,
                fieldOptionsModalVisible: false,
                fieldActionType: 'add',
                editField: {},
                lastItem: {},
            };
        case formBuilderFieldConstants.FIELD_REMOVED:
            const arrayFrom =
                state.fields[action.uniqueIdentifier].pages[
                    state.currentPage[action.uniqueIdentifier]
                ];
            const fieldsArray = Array.from(arrayFrom);
            const newFieldsArray = fieldsArray
                .slice(0, action.index)
                .concat(
                    fieldsArray.slice(action.index + 1, fieldsArray.length),
                );
            const fieldList = state.fields[action.uniqueIdentifier].pages;
            fieldList[
                state.currentPage[action.uniqueIdentifier]
            ] = newFieldsArray;
            const existingFieldsList = state.fields;
            existingFieldsList[action.uniqueIdentifier].pages = fieldList;
            return {
                ...state,
                fields: existingFieldsList,
            };
        case formBuilderFieldConstants.FIELD_EDITING:
            const editField =
                state.fields[action.uniqueIdentifier].pages[
                    state.currentPage[action.uniqueIdentifier]
                ][action.fieldIndex];
            let lastItem;
            if (editField.field) {
                lastItem = {
                    ...editField.field,
                    uniqueIdentifier: action.uniqueIdentifier,
                };
            } else {
                lastItem = {
                    ...editField,
                    uniqueIdentifier: action.uniqueIdentifier,
                };
            }
            return {
                ...state,
                editField,
                lastItem: lastItem,
                fieldOptionsModalVisible: true,
                fieldActionType: 'edit',
                editFieldIndex: action.fieldIndex,
            };
        case formBuilderFieldConstants.FIELD_EDITED:
            const editBaseInfo = {
                field: state.editField.field,
                label: action.values.field_name,
                fieldSelected: false,
            };
            const editValues = action.values;
            if (action.values.imageSrc) {
                const base64 = action.values.imageSrc.file.xhr.responseURL;
                editValues.base64 = base64;
            }
            const newEditValues = editValues;
            newEditValues.question = {
                content: editValues.question,
                instructions: editValues.questionInstructions,
            };
            const editFieldInfo = { ...editBaseInfo, ...newEditValues };
            const editPages = state.fields[action.uniqueIdentifier].pages;
            editPages[action.pageNumber][action.editFieldIndex] = editFieldInfo;
            const fieldsListBeforeEdit = state.fields;
            fieldsListBeforeEdit[action.uniqueIdentifier].pages = editPages;
            return {
                ...state,
                fieldOptionsModalVisible: false,
                editField: {},
                fieldActionType: 'add',
                editFieldIndex: null,
                fields: fieldsListBeforeEdit,
            };
        case formBuilderPageConstants.PAGE_CHANGED:
            const currentPageBeforePageChange = state.currentPage;
            currentPageBeforePageChange[action.uniqueIdentifier] =
                action.activeKey;
            return {
                ...state,
                currentPage: currentPageBeforePageChange,
            };
        case formBuilderPageConstants.PAGE_ADDED:
            const currentPages = state.fields[action.uniqueIdentifier].pages;
            const pageIndexToAdd = Object.keys(currentPages).length + 1;
            currentPages[pageIndexToAdd] = [];
            const fieldsBeforePageAdd = state.fields;
            fieldsBeforePageAdd[action.uniqueIdentifier].pages = currentPages;
            return {
                ...state,
                fields: fieldsBeforePageAdd,
            };
        case formBuilderPageConstants.PAGE_REMOVED:
            const pagesBeforeRemove =
                state.fields[action.uniqueIdentifier].pages;
            const newPagesList = Object.keys(pagesBeforeRemove)
                .filter(key => key !== action.targetKey)
                .reduce((obj, key) => {
                    obj[key] = pagesBeforeRemove[key];
                    return obj;
                }, {});
            const reIndexedPages = {};
            Object.keys(newPagesList).forEach((page, index) => {
                reIndexedPages[index + 1] =
                    state.fields[action.uniqueIdentifier].pages[page];
            });
            const pageListBeforeRemove = state.fields;
            pageListBeforeRemove[
                action.uniqueIdentifier
            ].pages = reIndexedPages;
            return {
                ...state,
                fields: pageListBeforeRemove,
            };
        case formBuilderFieldConstants.FIELD_BULK_ACTIONS_ON:
            return {
                ...state,
                bulkActionsMode: true,
                bulkActionType: action.actionType,
            };
        case formBuilderFieldConstants.FIELD_BULK_ACTIONS_OFF:
            return {
                ...state,
                bulkActionsMode: false,
                bulkActionType: '',
            };
        case formBuilderFieldConstants.TOGGLE_FIELD_SELECT:
            const pagesBeforeSelect =
                state.fields[action.uniqueIdentifier].pages;
            const fieldsBeforeSelect =
                pagesBeforeSelect[state.currentPage[action.uniqueIdentifier]];
            const field = fieldsBeforeSelect[action.fieldKey];
            field.fieldSelected = !field.fieldSelected;
            fieldsBeforeSelect[action.fieldKey] = field;
            pagesBeforeSelect[
                state.currentPage[action.uniqueIdentifier]
            ] = fieldsBeforeSelect;
            const currentSelectedFields = state.selectedFieldsForBulkActions;
            let selectedFields;
            if (field.fieldSelected) {
                currentSelectedFields.push(action.fieldKey);
                selectedFields = currentSelectedFields;
            } else {
                selectedFields = currentSelectedFields.filter(
                    e => e !== action.fieldKey,
                );
            }
            const fieldsListBeforeToggle = state.fields;
            fieldsListBeforeToggle[
                action.uniqueIdentifier
            ].pages = pagesBeforeSelect;
            return {
                ...state,
                fields: fieldsListBeforeToggle,
                selectedFieldsForBulkActions: selectedFields,
            };
        case formBuilderFieldConstants.COPY_FIELDS:
            const copyFieldsArray = [];
            const copyFieldsTargetPage =
                state.fields[action.uniqueIdentifier].pages[action.targetPage];
            state.selectedFieldsForBulkActions.map(field => {
                copyFieldsArray.push(
                    state.fields[action.uniqueIdentifier].pages[
                        state.currentPage[action.uniqueIdentifier]
                    ][field],
                );
            });
            const copyFieldsTargetResult = [
                ...copyFieldsTargetPage,
                ...copyFieldsArray,
            ];
            const copyFieldsCurrentPages =
                state.fields[action.uniqueIdentifier].pages;
            copyFieldsCurrentPages[action.targetPage] = copyFieldsTargetResult;
            const fieldsBeforeCopyFields = state.fields;
            fieldsBeforeCopyFields[
                action.uniqueIdentifier
            ].pages = copyFieldsCurrentPages;
            return {
                ...state,
                fields: fieldsBeforeCopyFields,
                selectedFieldsForBulkActions:
                    state.selectedFieldsForBulkActions,
            };
        case formBuilderFieldConstants.DELETE_FIELDS:
            const deleteFieldsCurrentPages =
                state.fields[action.uniqueIdentifier].pages;
            const deleteFieldsArray = Array.from(
                deleteFieldsCurrentPages[
                    state.currentPage[action.uniqueIdentifier]
                ],
            );
            state.selectedFieldsForBulkActions.forEach(field => {
                delete deleteFieldsArray[field];
            });
            const filtered = deleteFieldsArray.filter(e => e !== null);
            deleteFieldsCurrentPages[
                state.currentPage[action.uniqueIdentifier]
            ] = filtered;
            const fieldsBeforeDeleteFields = state.fields;
            fieldsBeforeDeleteFields[
                action.uniqueIdentifier
            ].pages = deleteFieldsCurrentPages;
            return {
                ...state,
                fields: fieldsBeforeDeleteFields,
                selectedFieldsForBulkActions: [],
            };
        case formBuilderFieldConstants.SORT_FIELDS:
            if (
                state.fields[action.uniqueIdentifier].pages[
                    state.currentPage[action.uniqueIdentifier]
                ].length <= 1
            )
                return state;
            const { sourceIndex, destinationIndex, uniqueIdentifier } = action;
            const pageIndex = state.currentPage[action.uniqueIdentifier];
            const thePage = state.fields[uniqueIdentifier].pages[pageIndex];
            const [removed] = thePage.splice(sourceIndex, 1);
            thePage.splice(destinationIndex, 0, removed);

            // const sortPages = state.fields[action.uniqueIdentifier].pages;
            // const existingPagesBeforeSort = concat(
            //     [],
            //     sortPages[state.currentPage[action.uniqueIdentifier]],
            // );
            //
            // remove(existingPagesBeforeSort, function(n, index) {
            //     if (index === action.item.item.fieldKey) {
            //         return true;
            //     }
            //     return false;
            // });
            //
            // const itemToMove =
            //     state.fields[action.uniqueIdentifier].pages[
            //         state.currentPage[action.uniqueIdentifier]
            //     ][action.item.item.fieldKey];
            //
            // if (action.item.item.fieldKey === action.newIndex) {
            //     return;
            // }
            // existingPagesBeforeSort.splice(action.newIndex, 0, itemToMove);
            //
            // sortPages[
            //     state.currentPage[action.uniqueIdentifier]
            // ] = existingPagesBeforeSort;
            // const fieldsBeforeSort = state.fields;
            // fieldsBeforeSort[action.uniqueIdentifier].pages = sortPages;
            //
            return {
                ...state,
                //fields: fieldsBeforeSort,
            };
        case formBuilderConstants.IDENTIFIER_CHANGED: {
            return {
                ...state,
                identifier: action.identifier,
            };
        }
        default:
            return state;
    }
}

// checks if create or edit
// make a request to get the activity or a blank activity
// api/activities/{id}
// api/activities/create (blank array of panes [pages])
