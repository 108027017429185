export const sharedFilesConstants = {
    SHARED_FILES_REQUEST: 'SHARED_FILES_REQUEST',
    SHARED_FILES_SUCCESS: 'SHARED_FILES_SUCCESS',
    SHARED_FILES_REJECTED: 'SHARED_FILES_REJECTED',
    SHARED_FILES_FILE_UPLOAD_REQUEST: 'SHARED_FILES_FILE_UPLOAD_REQUEST',
    SHARED_FILES_FILE_UPLOAD_SUCCESS: 'SHARED_FILES_FILE_UPLOAD_SUCCESS',
    SHARED_FILES_FILE_UPLOAD_REJECTED: 'SHARED_FILES_FILE_UPLOAD_REJECTED',
    SHARED_FILES_FILE_DELETE_REQUEST: 'SHARED_FILES_FILE_DELETE_REQUEST',
    SHARED_FILES_FILE_DELETE_SUCCESS: 'SHARED_FILES_FILE_DELETE_SUCCESS',
    SHARED_FILES_FILE_DELETE_REJECTED: 'SHARED_FILES_FILE_DELETE_REJECTED',
    SHARED_FILES_CREATE_FOLDER_REQUEST: 'SHARED_FILES_CREATE_FOLDER_REQUEST',
    SHARED_FILES_CREATE_FOLDER_SUCCESS: 'SHARED_FILES_CREATE_FOLDER_SUCCESS',
    SHARED_FILES_CREATE_FOLDER_REJECTED: 'SHARED_FILES_CREATE_FOLDER_REJECTED',
    SHARED_FILES_DIRECTORY_NAME_MODAL_OPEN:
        'SHARED_FILES_DIRECTORY_NAME_MODAL_OPEN',
    SHARED_FILES_DIRECTORY_NAME_MODAL_CLOSE:
        'SHARED_FILES_DIRECTORY_NAME_MODAL_CLOSE',
    SHARED_FILES_FILE_OPEN: 'SHARED_FILES_FILE_OPEN',
    SHARED_FILES_FILE_CLOSE: 'SHARED_FILES_FILE_CLOSE',
    SHARED_FILES_FILE_LOADING: 'SHARED_FILES_FILE_LOADING',
    SHARED_FILES_FILE_LOADED: 'SHARED_FILES_FILE_LOADED',
    SHARED_FILES_DUPLICATE_REQUEST: 'SHARED_FILES_DUPLICATE_REQUEST',
    SHARED_FILES_DUPLICATE_SUCCESS: 'SHARED_FILES_DUPLICATE_SUCCESS',
    SHARED_FILES_DUPLICATE_REJECTED: 'SHARED_FILES_DUPLICATE_REJECTED',
    SHARED_FILES_RENAME_REQUEST: 'SHARED_FILES_RENAME_REQUEST',
    SHARED_FILES_RENAME_SUCCESS: 'SHARED_FILES_RENAME_SUCCESS',
    SHARED_FILES_RENAME_REJECTED: 'SHARED_FILES_RENAME_REJECTED',
    SHARED_FILES_EDIT_REQUEST: 'SHARED_FILES_EDIT_REQUEST',
    SHARED_FILES_EDIT_SUCCESS: 'SHARED_FILES_EDIT_SUCCESS',
    SHARED_FILES_EDIT_REJECTED: 'SHARED_FILES_EDIT_REJECTED',
    SHARED_FILES_MOVE_REQUEST: 'SHARED_FILES_MOVE_REQUEST',
    SHARED_FILES_MOVE_SUCCESS: 'SHARED_FILES_MOVE_SUCCESS',
    SHARED_FILES_MOVE_REJECTED: 'SHARED_FILES_MOVE_REJECTED',
    SHARED_FILES_DOWNLOAD_FILE_REQUEST: 'SHARED_FILES_DOWNLOAD_FILE_REQUEST',
    SHARED_FILES_DOWNLOAD_FILE_SUCCESS: 'SHARED_FILES_DOWNLOAD_FILE_SUCCESS',
    SHARED_FILES_DOWNLOAD_FILE_REJECTED: 'SHARED_FILES_DOWNLOAD_FILE_REJECTED',

    SET_TEMP_ACTIVITY_ID: 'SET_TEMP_ACTIVITY_ID',
    SET_SPECIFIC_TEMP_ID: 'SET_SPECIFIC_TEMP_ID',
    CLEAR_SHARE_FILE: 'CLEAR_SHARE_FILE',
};
