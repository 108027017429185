import { searchConstants } from '../constants/search.constants';

const initialState = {
    loading: false,
    modules: [],
    params: {
        level: [],
        skill: [],
        focus: [],
        keyword: '',
    },
};

function search(state = initialState, action) {
    switch (action.type) {
        case searchConstants.SEARCH_SEARCHING:
            return {
                ...state,
                loading: true,
                params: action.params,
            };
        case searchConstants.SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                searched: true,
                modules: action.payload,
            };
        case searchConstants.SEARCH_REJECTED:
            return {
                ...state,
                loading: false,
                searched: true,
            };
        case searchConstants.SEARCH_RESET:
            return initialState;
        default:
            return state;
    }
}

export default search;
