import { shuffle } from 'lodash';
import {questionnaireConstants} from "../../constants/Questionnaires/questionnaire.constants";
import {questionnaireBuilderConstants} from "../../constants/Questionnaires/questionnaireBuilder.constants";
import questionnaireMarkingConstants from "../../constants/Questionnaires/questionnaireMarking.constants";


const initialState = {
    loading: false,
    questionnaires: [],
    questionnaire: {},
    fields: {
        questionnaire: { pages: [] },
    },
    answers: [],
    results: [],
    currentQuestionnaire: {},
}

export function questionnaires (state = initialState, action) {
    switch (action.type) {
        case questionnaireConstants.FETCH_QUESTIONNAIRES_REQUEST:
        case questionnaireConstants.SEARCH_QUESTIONNAIRES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case questionnaireConstants.FETCH_QUESTIONNAIRES_REJECTED:
            return {
                ...state,
                loading: false,
                questionnaires: [],
            };
        case questionnaireConstants.FETCH_QUESTIONNAIRES_SUCCESS:
        case questionnaireConstants.SEARCH_QUESTIONNAIRES_SUCCESS:
            return {
                ...state,
                loading: false,
                questionnaires: action.payload,
            };
        case questionnaireConstants.FETCH_QUESTIONNAIRE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case questionnaireConstants.FETCH_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                loading: false,
                questionnaire: action.payload,
                currentQuestionnaire: action.payload,
            };
        case questionnaireConstants.QUESTION_ANSWERED:
            const { questionID, answerID } = action;
            const { answers } = state;
            answers[questionID] = answerID;
            return {
                ...state,
                answers,
            };
        case questionnaireConstants.SUBMIT_ANSWERS:
            return {
                ...state,
                results: action.results,
            };
        case questionnaireConstants.CLEAR_QUESTIONNAIRE:
            return {
                ...state,
                questionnaire: {},
            }
        case questionnaireBuilderConstants.QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_LOADED:
            return {
                ...state,
                questionnaire: action.questionnaire,
            };
        case questionnaireBuilderConstants.QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_SAVE_SUCCESS:
            return {
                ...state,
            };
        case questionnaireMarkingConstants.CLEAR_CURRENT_QUESTIONNAIRE:
            return {
                ...initialState,
            };
        case questionnaireBuilderConstants.QUESTIONNAIRE_BUILDER_CANCEL_SAVE_QUESTIONNAIRE:
            return initialState;
        case questionnaireConstants.FIELDS_LOADED:
            if (
                action.fields &&
                action.fields.questionnaire &&
                action.fields.questionnaire.pages
            ) {
                let questionnairePage = action.fields.questionnaire.pages;
                if (questionnairePage && questionnairePage.length) {
                    action.fields.questionnaire.pages = questionnairePage.map(
                        questionnaire => {
                            if (
                                questionnaire.questions &&
                                questionnaire.questions.length
                            ) {
                                questionnaire.questions = questionnaire.questions.map(
                                    q => {
                                        if (
                                            q &&
                                            q[0] &&
                                            q[0].props &&
                                            q[0].props.blanks &&
                                            q[0].type != 'selectTheBlanks'
                                        ) {
                                            let shuffleBlank = shuffle(
                                                q[0].props.blanks,
                                            );
                                            q[0].props.blanks = shuffleBlank;
                                        } else if (
                                            q &&
                                            q[0] &&
                                            q[0].props &&
                                            q[0].props.blanks &&
                                            (q[0].type == 'mixedSentence' || q[0].type == 'selectTheBlanks')
                                        ) {
                                            let shuffleAnswers = q[0].props.blanks.map(
                                                x => shuffle(x),
                                            );
                                            q[0].props.blanks = shuffleAnswers;
                                        } else if (
                                            q &&
                                            q[0] &&
                                            q[0]?.type == 'matchUp'
                                        ) {
                                            q[0].props.shuffleAnswers = shuffle(
                                                q[0].props.pairs,
                                            );
                                        }
                                        if (q && q.answers) {
                                            q.answers = q.answers.map(an => ({
                                                ...an,
                                                random: Math.random(),
                                            }));
                                        }
                                        return q;
                                    },
                                );
                            }
                            return questionnaire
                        },
                    );
                }
            }
            return  {
                ...state,
                fields: action.fields,
                currentQuestionnaire: action.currentQuestionnaire
            };

        default:
            return state
    }
}
