import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Affix } from 'antd';
import { SiteLayoutStyles } from '../../../styles/SiteLayout.style';
import links from './NavigationLinks';

const styles = SiteLayoutStyles;

const StickyLinks = ({ link, index }) => {
    return (
        <Link
            key={index}
            to={link.url}
            style={{
                ...styles.mobileNavStyle,
                ...styles.linkStyles,
                padding: 10,
                margin: '0 auto',
            }}
        >
            <FontAwesomeIcon style={{ fontSize: 25 }} icon={link.icon} />
            <div style={{ fontSize: 8 }}>{link.label}</div>
        </Link>
    );
};
const StickyNavBar = ({ user }) => {
    const stickyNavMenus = links(user)[user.role];

    return (
        <Affix offsetBottom={0}>
            <Row
                style={{
                    ...styles.mobileNavStyle,
                    justifyContent: 'center',
                }}
            >
                {stickyNavMenus.map((menuItem, index) => (
                    <StickyLinks link={menuItem} key={index} />
                ))}
            </Row>
        </Affix>
    );
};

export default StickyNavBar;
