import { onboardingPreferencesConstants } from '../constants/onboardingPreferences.constants';

const initialState = {
    error: false,
    loading: false,
    success: false,
};

export function onboardingPreferences(state = initialState, action) {
    switch (action.type) {
        case onboardingPreferencesConstants.POST_PREFERENCES_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
                success: false,
            };
        case onboardingPreferencesConstants.POST_PREFERENCES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
            };
        case onboardingPreferencesConstants.POST_PREFERENCES_REJECTED:
            return {
                ...state,
                error: true,
                loading: false,
                success: false,
            };
        default:
            return state;
    }
}
