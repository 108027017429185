// import {feedbackBuilder} from "../../constants/Feedback/FeedbackBuilder.contants";
//
//
// const initialState = {
//     questions: [],
//     listOfQuestion: [],
//     questionAnswers: [],
// }
//
// export function FeedbackBuilder(state=initialState, action) {
//     switch (action.type) {
//         case feedbackBuilder.ADD_QUESTION:
//             return {
//                 ...state,
//                 questions: [...state.questions, action.payload]
//             }
//         case feedbackBuilder.ADD_QUESTION_TO_LIST:
//             return {
//                 ...state,
//                 listOfQuestion: [...state.listOfQuestion, action.payload]
//             }
//         case feedbackBuilder.ADD_NEW_ANSWER_COMPONENT:
//             return {
//                 ...state,
//                 questionAnswers: [...state.questionAnswers, action.payload]
//             }
//         case feedbackBuilder.CLEAR_FORM:
//             return {
//                 ...state,
//                 questionAnswers: [],
//             }
//
//         default: return state
//     }
// }


import {feedbackBuilder} from "../../constants/Feedback/FeedbackBuilder.contants";


const initialState = {
    question: {},
    currentQuestion: {},
    listOfQuestion: [],
    questionAnswers: [],
}

export function FeedbackBuilder(state=initialState, action) {
    switch (action.type) {
        case feedbackBuilder.ADD_QUESTION:
            return {
                ...state,
                question: action.payload
            }
        case feedbackBuilder.CURRENT_QUESTION:
            return {
                ...state,
                currentQuestion: action.payload
            }
        case feedbackBuilder.ADD_NEW_ANSWER_COMPONENT:
            return {
                ...state,
                questionAnswers: [...state.questionAnswers, action.payload]
            }
        case feedbackBuilder.CLEAR_FORM:
            return {
                ...state,
                questionAnswers: [],
                currentQuestion: {}
            }

        default: return state
    }
}
