/* eslint-disable import/prefer-default-export */
import { learningFocusConstants } from '../constants/learningFocus.constants';

const initialState = {
    loading: false,
    learningFocuses: [],
    meta: {},
    fetchLoading:false,
    learningFocuse:{}
};

export function learningFocuses(state = initialState, action) {
    switch (action.type) {
        case learningFocusConstants.FETCH_LEARNING_FOCUS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learningFocusConstants.FETCH_LEARNING_FOCUS_SUCCESS:
            return {
                ...state,
                loading: false,
                learningFocuses: action.payload.data,
                meta: action.payload.meta,
                learningFocuse:{}
            };
            case learningFocusConstants.FETCH_FOCUS_REQUEST:
                return{
                    ...state ,
                    fetchLoading:true,
                    learningFocuse:{}
                }
            case learningFocusConstants.FETCH_FOCUS_SUCCESS:
                return{
                    ...state ,
                    fetchLoading:false,
                    learningFocuse:action.payload
                }
            case learningFocusConstants.FETCH_FOCUS_REJECTED:

        default:
            return state;
    }
}
