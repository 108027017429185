const learningTypesConstants = {
    FETCH_LEARNING_TYPES_REQUEST: 'FETCH_LEARNING_TYPES_REQUEST',
    FETCH_LEARNING_TYPES_SUCCESS: 'FETCH_LEARNING_TYPES_SUCCESS',
    FETCH_LEARNING_TYPES_REJECTED: 'FETCH_LEARNING_TYPES_REJECTED',
    FETCH_LEARNING_TYPES_PAGINATED_REQUEST:
        'FETCH_LEARNING_TYPES_PAGINATED_REQUEST',
    FETCH_LEARNING_TYPES_PAGINATED_SUCCESS:
        'FETCH_LEARNING_TYPES_PAGINATED_SUCCESS',
    FETCH_LEARNING_TYPES_PAGINATED_REJECTED:
        'FETCH_LEARNING_TYPES_PAGINATED_REJECTED',
    UPDATE_LEARNING_TYPE_REQUEST: 'UPDATE_LEARNING_TYPE_REQUEST',
    UPDATE_LEARNING_TYPE_SUCCESS: 'UPDATE_LEARNING_TYPE_SUCCESS',
    UPDATE_LEARNING_TYPE_REJECTED: 'UPDATE_LEARNING_TYPE_REJECTED',
    FETCH_LEARNING_TYPE_REQUEST:'FETCH_LEARNING_TYPE_REQUEST',
    FETCH_LEARNING_TYPE_SUCCESS:'FETCH_LEARNING_TYPE_SUCCESS',
    CLEARDATA:'CLEARDATA',
};

export default learningTypesConstants;
