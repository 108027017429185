import {contactsConstants} from "../../constants/Contacts/contacts.constants";


const initialState = {
    loading: false,
    contacts: {}
};

function contacts(state = initialState, action) {
    switch (action.type) {
        case contactsConstants.FETCH_CONTACTS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case contactsConstants.FETCH_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                contacts: action.payload
            }

        case contactsConstants.FETCH_CONTACTS_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }


        case contactsConstants.DELETE_CONTACTS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case contactsConstants.DELETE_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                contacts: {
                    ...state.feedbacks,
                    data: state.contacts.data.filter(contact => contact.contactID !== action.payload)
                },
            }

        case contactsConstants.DELETE_CONTACTS_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}

export default contacts;
