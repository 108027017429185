import React from 'react';
import { Row, Col, Dropdown, Menu, Button, Pagination } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const TableFooter = props => {
    const {
        meta,
        pageSize,
        bulkActions,
        onPageChange,
        onPageSizeChange,
        showPageSize = true,
    } = props;

    return (
        <Row className="table-pagination">
            {bulkActions && bulkActions.length > 0 && (
                <Col flex={1}>
                    <Dropdown
                        overlay={
                            <Menu>
                                {bulkActions.map((action, key) => (
                                    <Menu.Item
                                        key="key"
                                        onClick={action.handler}
                                    >
                                        {action.title}
                                    </Menu.Item>
                                ))}
                            </Menu>
                        }
                        trigger={['click']}
                        style={{ float: 'right' }}
                    >
                        <Button>
                            {window.__lang('bulk_actions')}
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </Col>
            )}
            <Col flex={1} style={{ textAlign: 'right' }}>
                <Pagination
                    current={meta ? meta.current_page : 1}
                    defaultCurrent={1}
                    pageSize={pageSize}
                    total={meta ? meta.total : 0}
                    showTotal={total => `Total ${total} items`}
                    onChange={onPageChange}
                    showQuickJumper={
                        meta && meta.last_page > 5
                            ? { goButton: <Button size="small">Go</Button> }
                            : false
                    }
                    showLessItems
                    showSizeChanger
                    onShowSizeChange={(current, size) => {
                        if (onPageSizeChange) {
                            onPageSizeChange(size);
                        }
                    }}
                />
            </Col>
        </Row>
    );
};

export default TableFooter;
