const initialState = {
    history: [],
};

const tracking = (state = initialState, action) => {
    switch (action.type) {
        case 'NAVIGATED_TO_PAGE':
            let newHistory = [
                ...state.history,
                {
                    url: action.url,
                    start: action.loadTime,
                },
            ];
            if (state.history.length > 0) {
                newHistory[newHistory.length - 2] = {
                    ...state.history[state.history.length - 1],
                    end: action.loadTime,
                };
            }
            return {
                ...state,
                history: newHistory,
            };
        default:
            return state;
    }
};

export default tracking;
