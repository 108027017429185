import React, { useState, useEffect } from 'react';
import {
    Button,
    Divider,
    Select,
    Form,
    Radio,
    DatePicker,
    notification,
    Upload,
    Col,
    Tabs,
} from 'antd';
import { axiosDefaultClient } from '../settings/axios.settings';

const client = axiosDefaultClient;
import moment from 'moment';
import { InboxOutlined } from '@ant-design/icons';
import { getResourceRealPath, retrieveDecryptedUserFromLocalStorage } from '../utils/common';

const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Option } = Select;
const { TimePicker } = DatePicker;
const AdminMigrateContnet = () => {
    const user = retrieveDecryptedUserFromLocalStorage();

    if (user && user.role === 'learner') {
        return <Redirect to="/" />;
    }

    const [eachDayFieldVisibilty, setEachDayFieldVisibilty] = useState(false);
    const [eachWeekFieldVisibilty, setEachWeekFieldVisibilty] = useState(false);
    const [default_login_image, setDefaultLoginImage] = useState('');
    const [default_logo, setDefaultLogo] = useState('');
    const [settings, setSettings] = useState(null);
    const [eachTwoWeekFieldVisibilty, setEachTwoWeekFieldVisibilty] = useState(
        false,
    );
    const [
        eachThreeWeekFieldVisibilty,
        setEachThreeWeekFieldVisibilty,
    ] = useState(false);
    const [eachMonthFieldVisibilty, setEachMonthFieldVisibilty] = useState(
        false,
    );
    const [cronJobTime, setCronJobTime] = useState('');
    // cron job time
    const [time, setTime] = useState('');
    const [dayName, setDayName] = useState('');
    const [dayNum, setDayNum] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [secondDay, setSecondDay] = useState('');
    const [status, setStatus] = useState(null);
    const [defaultSetting, setDefaultSetting] = useState();

    const onChange = e => {
        if (e.target.value === 'every_day') {
            setEachDayFieldVisibilty(true);
            setEachWeekFieldVisibilty(false);
            setEachTwoWeekFieldVisibilty(false);
            setEachThreeWeekFieldVisibilty(false);
            setEachMonthFieldVisibilty(false);
            setCronJobTime('every_day');
        }
        if (e.target.value === 'every_week') {
            setEachDayFieldVisibilty(false);
            setEachWeekFieldVisibilty(true);
            setEachTwoWeekFieldVisibilty(false);
            setEachThreeWeekFieldVisibilty(false);
            setEachMonthFieldVisibilty(false);
            setCronJobTime('every_week');
        }
        if (e.target.value === 'every_two_week') {
            setEachDayFieldVisibilty(false);
            setEachWeekFieldVisibilty(false);
            setEachTwoWeekFieldVisibilty(true);
            setEachThreeWeekFieldVisibilty(false);
            setEachMonthFieldVisibilty(false);
            setCronJobTime('every_two_week');
        }
        if (e.target.value === 'every_three_week') {
            setEachDayFieldVisibilty(false);
            setEachWeekFieldVisibilty(false);
            setEachTwoWeekFieldVisibilty(false);
            setEachThreeWeekFieldVisibilty(true);
            setEachMonthFieldVisibilty(false);
            setCronJobTime('every_three_week');
        }
        if (e.target.value === 'every_month') {
            setEachDayFieldVisibilty(false);
            setEachWeekFieldVisibilty(false);
            setEachTwoWeekFieldVisibilty(false);
            setEachThreeWeekFieldVisibilty(false);
            setEachMonthFieldVisibilty(true);
            setCronJobTime('every_month');
        }
    };

    const setDefaultOptions = payload => {
        if (payload?.every_day === 1) {
            setEachDayFieldVisibilty(true);
            setEachWeekFieldVisibilty(false);
            setEachTwoWeekFieldVisibilty(false);
            setEachThreeWeekFieldVisibilty(false);
            setEachMonthFieldVisibilty(false);
            setCronJobTime('every_day');
        }
        if (payload?.every_week === 1) {
            setEachDayFieldVisibilty(false);
            setEachWeekFieldVisibilty(true);
            setEachTwoWeekFieldVisibilty(false);
            setEachThreeWeekFieldVisibilty(false);
            setEachMonthFieldVisibilty(false);
            setCronJobTime('every_week');
        }
        if (payload?.every_two_week === 1) {
            setEachDayFieldVisibilty(false);
            setEachWeekFieldVisibilty(false);
            setEachTwoWeekFieldVisibilty(true);
            setEachThreeWeekFieldVisibilty(false);
            setEachMonthFieldVisibilty(false);
            setCronJobTime('every_two_week');
        }
        if (payload?.every_three_week === 1) {
            setEachDayFieldVisibilty(false);
            setEachWeekFieldVisibilty(false);
            setEachTwoWeekFieldVisibilty(false);
            setEachThreeWeekFieldVisibilty(true);
            setEachMonthFieldVisibilty(false);
            setCronJobTime('every_three_week');
        }
        if (payload?.every_month === 1) {
            setEachDayFieldVisibilty(false);
            setEachWeekFieldVisibilty(false);
            setEachTwoWeekFieldVisibilty(false);
            setEachThreeWeekFieldVisibilty(false);
            setEachMonthFieldVisibilty(true);
            setCronJobTime('every_month');
        }
        setDefaultSetting(payload);
        setTime(payload?.time || '');
        setDayName(payload?.week_day || '');
        setDayNum(payload?.month_day || '');
        setFirstDay(payload?.days ? payload?.days[0] : '');
        setSecondDay(payload?.days ? payload?.days[1] : '');
    };

    const timeChange = (time, timeString) => {
        setTime(timeString);
    };
    const dayChange = value => {
        setDayName(value);
    };
    const dateChange = value => {
        setDayNum(value);
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };
    const migrateNow = () => {
        client
            .get('/api/backup/content/now')
            .then(response => {
                notification.success({
                    message: response.data.message,
                });
                setStatus(0);
            })
            .catch(e => {
                notification.error({
                    message: 'Error!',
                    description: e?.statusText,
                });
            });
    };

    const sendData = data => {
        client
            .post('/api/backup/configuration', data)
            .then(response => {
                notification.success({
                    message: response.data.message,
                });
            })
            .catch(e => {
                notification.error({
                    message: 'Error!',
                    description: e?.statusText,
                });
            });
    };

    const handleSettings = () => {
        const formData = new FormData();
        if (default_login_image)
            formData.append('default_login_image', default_login_image);
        if (default_logo) formData.append('default_logo', default_logo);
        client
            .post('/api/default-settings', formData)
            .then(response => {
                notification.success({
                    message: response.data.message,
                });
            })
            .catch(e => {
                notification.error({
                    message: 'Error!',
                    description: e?.statusText,
                });
            });
    };

    const handleCronJobTime = () => {
        const formData = new FormData();
        if (
            cronJobTime === 'every_week' ||
            cronJobTime === 'every_three_week'
        ) {
            if (dayName && time) {
                formData.append('week_day', dayName);
                formData.append('time', time);
                formData.append(cronJobTime, 1);
                sendData(formData);
            } else {
                notification.error({
                    description: 'Select time and day is required',
                });
            }
        }
        if (cronJobTime === 'every_two_week') {
            if (firstDay && secondDay && time) {
                if (firstDay === secondDay) {
                    notification.error({
                        description: 'Selected days must not be equal ',
                    });
                } else {
                    formData.append(
                        'days',
                        JSON.stringify([firstDay, secondDay]),
                    );
                    formData.append('time', time);
                    formData.append(cronJobTime, 1);
                    sendData(formData);
                }
            } else {
                notification.error({
                    description: 'Select time and days is required',
                });
            }
        }
        if (cronJobTime === 'every_day') {
            if (time) {
                formData.append('time', time);
                formData.append(cronJobTime, 1);
                sendData(formData);
            } else {
                notification.error({
                    description: 'Select time is required',
                });
            }
        }
        if (cronJobTime === 'every_month') {
            if (dayNum && time) {
                formData.append('month_day', dayNum);
                formData.append('time', time);
                formData.append(cronJobTime, 1);
                sendData(formData);
            } else {
                notification.error({
                    description: 'Select time and day is required',
                });
            }
        }
        if (cronJobTime === '')
            notification.error({
                description: 'Select the cron job time',
            });
    };
    useEffect(() => {
        client
            .get('/api/backup/settings')
            .then(response => {
                setDefaultOptions(response.data.payload);
            })
            .catch(e => {
                notification.error({
                    message: 'Error!',
                    description: e?.statusText,
                });
            });
    }, []);

    useEffect(() => {
        client
            .get('/api/default-settings')
            .then(response => {
                setSettings(response.data.payload);
            })
            .catch(e => {
                notification.error({
                    message: 'Error!',
                    description: e?.statusText,
                });
            });
    }, []);

    const days = [
        { id: 'saturday', name: 'Saturday' },
        {
            id: 'sunday',
            name: 'Sunday',
        },
        {
            id: 'monday',
            name: 'Monday',
        },
        {
            id: 'tuesday',
            name: 'Tuesday',
        },
        {
            id: 'wednesday',
            name: 'Wednesday',
        },
        {
            id: 'thursday',
            name: 'Thursday',
        },
        {
            id: 'friday',
            name: 'Friday',
        },
    ];

    return (
        <div className="componentContainer">
            <Tabs tabPosition="top">
                <TabPane tab="Login Options" key={1} forceRender>
                    <Col span={8}>
                        <Form.Item label="Default Image">
                            <Dragger
                                customRequest={dummyRequest}
                                fileList={
                                    default_login_image
                                        ? [
                                              {
                                                  uid: '1',
                                                  name: 'Default Login Image',
                                                  status: 'success',
                                                  originFileObj:
                                                      getResourceRealPath(default_login_image),
                                              },
                                          ]
                                        : settings?.default_login_image
                                        ? [
                                              {
                                                  uid: '1',
                                                  name: 'Default Login Image',
                                                  status: 'success',
                                                  url:
                                                  getResourceRealPath(settings?.default_login_image),
                                              },
                                          ]
                                        : null
                                }
                                maxCount={1}
                                listType="picture"
                                onChange={e => {
                                    setDefaultLoginImage(e.file.originFileObj);
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <h2 className="ant-upload-text">Login Image</h2>
                                <p className="ant-upload-hint"></p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{ padding: 5 }}>
                        <Form.Item label="Default Logo">
                            <Dragger
                                customRequest={dummyRequest}
                                fileList={
                                    default_logo
                                        ? [
                                              {
                                                  uid: '1',
                                                  name: 'Default Logo',
                                                  status: 'success',
                                                  originFileObj: getResourceRealPath(default_logo),
                                              },
                                          ]
                                        : settings?.default_logo
                                        ? [
                                              {
                                                  uid: '1',
                                                  name: 'Default Logo',
                                                  status: 'success',
                                                  url: getResourceRealPath(settings?.default_logo),
                                              },
                                          ]
                                        : null
                                }
                                maxCount={1}
                                listType="picture"
                                onChange={e => {
                                    setDefaultLogo(e.file.originFileObj);
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <h2 className="ant-upload-text">Login Logo</h2>
                                <p className="ant-upload-hint"></p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                    <Divider />
                    <Form.Item>
                        <Button
                            onClick={handleSettings}
                            style={{ marginTop: 20 }}
                        >
                            {' '}
                            Save
                        </Button>
                    </Form.Item>
                </TabPane>
                <TabPane tab="Migrate Options" key={2} forceRender>
                    <h1>Migrate Options</h1>
                    <Button
                        onClick={migrateNow}
                        disabled={true}
                    >
                        Migrate Now{' '}
                    </Button>

                    <Divider />
                    <h3>Cronjob time to migrate content</h3>
                    <Form horizontal>
                        <Radio.Group onChange={onChange} value={cronJobTime}>
                            <Radio
                                value="every_day"
                                style={{ display: 'block' }}
                            >
                                Each Day
                            </Radio>
                            <div
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    display: eachDayFieldVisibilty
                                        ? 'block'
                                        : 'none',
                                }}
                            >
                                <TimePicker
                                    value={
                                        time && defaultSetting?.every_day
                                            ? moment(time, 'HH:mm')
                                            : undefined
                                    }
                                    format="HH:mm"
                                    placeholder="HH:mm"
                                    onChange={timeChange}
                                />
                                ;
                            </div>

                            <Radio
                                value="every_week"
                                style={{ display: 'block' }}
                            >
                                Each Week
                            </Radio>
                            <div
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    display: eachWeekFieldVisibilty
                                        ? 'block'
                                        : 'none',
                                }}
                            >
                                <Select
                                    showSearch
                                    style={{ width: 300, marginRight: '30px' }}
                                    placeholder="Select Day"
                                    optionFilterProp="children"
                                    onChange={dayChange}
                                    allowClear={true}
                                    value={dayName ? dayName : undefined}
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {days.map(day => {
                                        return (
                                            <Option value={day.id}>
                                                {day.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                <TimePicker
                                    value={
                                        time && defaultSetting?.every_week
                                            ? moment(time, 'HH:mm')
                                            : undefined
                                    }
                                    format="HH:mm"
                                    placeholder="HH:mm"
                                    onChange={timeChange}
                                />
                                ;
                            </div>
                            <Radio
                                value="every_two_week"
                                style={{ display: 'block' }}
                            >
                                Twice Monthly
                            </Radio>
                            <div
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    display: eachTwoWeekFieldVisibilty
                                        ? 'block'
                                        : 'none',
                                }}
                            >
                                <Select
                                    showSearch
                                    style={{ width: 300, marginRight: '30px' }}
                                    placeholder="Select first time"
                                    optionFilterProp="children"
                                    value={firstDay ? firstDay : undefined}
                                    onChange={value => setFirstDay(value)}
                                    allowClear={true}
                                >
                                    {[
                                        1,
                                        2,
                                        3,
                                        4,
                                        5,
                                        6,
                                        7,
                                        8,
                                        9,
                                        10,
                                        11,
                                        12,
                                        13,
                                        14,
                                        15,
                                        16,
                                        17,
                                        18,
                                        19,
                                        20,
                                        21,
                                        22,
                                        23,
                                        24,
                                        25,
                                        26,
                                        27,
                                        28,
                                        29,
                                        30,
                                        31,
                                    ].map(day => {
                                        return (
                                            <Option
                                                style={{ paddingLeft: '20px' }}
                                                value={day}
                                            >
                                                {day}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                <Select
                                    showSearch
                                    style={{ width: 300, marginRight: '30px' }}
                                    placeholder="Select second time"
                                    optionFilterProp="children"
                                    value={secondDay ? secondDay : undefined}
                                    onChange={value => setSecondDay(value)}
                                    allowClear={true}
                                >
                                    {[
                                        1,
                                        2,
                                        3,
                                        4,
                                        5,
                                        6,
                                        7,
                                        8,
                                        9,
                                        10,
                                        11,
                                        12,
                                        13,
                                        14,
                                        15,
                                        16,
                                        17,
                                        18,
                                        19,
                                        20,
                                        21,
                                        22,
                                        23,
                                        24,
                                        25,
                                        26,
                                        27,
                                        28,
                                        29,
                                        30,
                                        31,
                                    ].map(day => {
                                        return (
                                            <Option
                                                style={{ paddingLeft: '20px' }}
                                                value={day}
                                            >
                                                {day}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                <TimePicker
                                    value={
                                        time && defaultSetting?.every_two_week
                                            ? moment(time, 'HH:mm')
                                            : undefined
                                    }
                                    format="HH:mm"
                                    placeholder="HH:mm"
                                    onChange={timeChange}
                                />
                                ;
                            </div>
                            <Radio
                                value="every_month"
                                style={{ display: 'block' }}
                            >
                                Each Month{' '}
                            </Radio>
                            <div
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    display: eachMonthFieldVisibilty
                                        ? 'block'
                                        : 'none',
                                }}
                            >
                                <Select
                                    showSearch
                                    style={{ width: 300, marginRight: '30px' }}
                                    placeholder="Select Day"
                                    optionFilterProp="children"
                                    value={dayNum ? dayNum : undefined}
                                    onChange={dateChange}
                                    allowClear={true}
                                >
                                    {[
                                        1,
                                        2,
                                        3,
                                        4,
                                        5,
                                        6,
                                        7,
                                        8,
                                        9,
                                        10,
                                        11,
                                        12,
                                        13,
                                        14,
                                        15,
                                        16,
                                        17,
                                        18,
                                        19,
                                        20,
                                        21,
                                        22,
                                        23,
                                        24,
                                        25,
                                        26,
                                        27,
                                        28,
                                        29,
                                        30,
                                        31,
                                    ].map(day => {
                                        return (
                                            <Option
                                                style={{ paddingLeft: '20px' }}
                                                value={day}
                                            >
                                                {day}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                <TimePicker
                                    value={
                                        time && defaultSetting?.every_month
                                            ? moment(time, 'HH:mm')
                                            : undefined
                                    }
                                    format="HH:mm"
                                    placeholder="HH:mm"
                                    onChange={timeChange}
                                />
                                ;
                            </div>
                        </Radio.Group>
                        <Form.Item>
                            <Button
                                onClick={handleCronJobTime}
                                style={{ marginTop: 20 }}
                                disabled={true}
                            >
                                {' '}
                                Apply
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default AdminMigrateContnet;
