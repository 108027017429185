/**
 * fetch the local storage of site
 * @returns {{}}
 * @author Amr
 */
import { get } from "lodash";
import { ApiBaseURL } from '../constants/apiConstants';
import CryptoJS from 'crypto-js';

const siteLocalStorage = () => {
    let __localStorage = localStorage.getItem('site');
    return __localStorage &&
        retrieveDecryptedSiteFromLocalStorage().trim() != ''
        ? retrieveDecryptedSiteFromLocalStorage()
        : {};
};

/**
 * fetch the local storage of user
 * @returns {any}
 */
const userLocalStorage = () => {
    let __localStorage = localStorage.getItem('user');
    return __localStorage &&
        retrieveDecryptedUserFromLocalStorage().trim() != ''
        ? retrieveDecryptedUserFromLocalStorage()
        : {};
};

/**
 *  Save answers into local storage
 * @param questionId
 * @param result
 * @author Fayez
 */
export const saveAnswersToLocalStorage = (questionId, result) => {
    try {
        let localMultiAnswers = localStorage.getItem('multiAnswers')
            ? JSON.parse(localStorage.getItem('multiAnswers'))
            : {};
        let multiAnswers = localMultiAnswers || {};
        multiAnswers = {
            ...multiAnswers,
            ...{
                [questionId]: result,
            },
        };
        localStorage.setItem('multiAnswers', JSON.stringify(multiAnswers));
    } catch (error) {
        console.log('Error while save answers', error.message);
    }
};

/**
 * get the checked answers
 * @param questionId
 * @return {*[]|*}
 * @author Fayez
 */
export const getSavedAnswers = (questionId) => {
    try {
        if (!localStorage.getItem('multiAnswers')) {
            localStorage.setItem('multiAnswers', '{}');
        }
        const __checkedAnswers = JSON.parse(
            localStorage.getItem('multiAnswers'),
        );
        if (__checkedAnswers && __checkedAnswers.hasOwnProperty(questionId)) {
            return __checkedAnswers[questionId];
        }
    } catch (error) {
        console.log('Error while get old answers', error.message);
    }
    return null;
};

/**
 * get site's flags
 * @param flag
 * @returns {*}
 * @author Amr
 */
export const getUserFlag = (flag) => {
    try {

        const userObject = userLocalStorage();
        const flagValue = userObject ? get(userObject, flag) : null;

        return flagValue;
    } catch (Error) {
        return null;
    }
};

/**
 * get site's flags
 * @param flag
 * @returns {*}
 * @author Amr
 */
export const getSiteFlag = (flag) => {
    try {
        return siteLocalStorage()[flag];
    } catch (Error) {
        return null;
    }
};

export const copyStringToClipboard = (str) => {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
};

export const is_image = function (path) {
    if (!path) return false;
    return path.toLowerCase().search(/(\.)(png|jpeg|gif|raw|jpg)/g) != -1;
};

/**
 * add http protocol to the links
 * @author Amr
 */
export const printUrl = (url, protocol = 'http') => {
    if (!url || url.trim() == '' || url.includes('http')) return url;
    return protocol + '://' + url;
};
/**
 * array has the given index
 * @param index
 * @returns {boolean}
 * @author Amr
 */
Array.prototype.hasIndex = function (index) {
    return this.length >= index;
};
/**
 * update Index
 * @param index
 * @returns {boolean}
 * @author Amr
 */
Array.prototype.updateIndex = function (index, data, attr) {
    if (!this.hasIndex(index)) throw Error('Array out of bound index');
    this[index][attr] = data;
    return this;
};

/**
 * move element from index to index
 * @param newIndex
 * @param currentIndex
 * @return array
 * @author Amr
 */
Array.prototype.move = function (newIndex, currentIndex) {
    try {
        this.splice(newIndex, 0, this.splice(currentIndex, 1)[0]);
    } catch (e) {
        throw Error('Could not move the element to the new index');
    }
    return this;
};
/**
 * manage MarkedAnswers
 * @param questionId
 * @param $markedAnswers
 * @author Amr
 */
export const saveMarkedAnswers = function (questionId, $markedAnswers = {}) {
    try {
        let localMarkedAnswers = JSON.parse(
            localStorage.getItem('markedAnswers'),
        );
        localStorage.setItem(
            'markedAnswers',
            JSON.stringify({
                ...(localMarkedAnswers ?? {}),
                [questionId]: $markedAnswers,
            }),
        );
    } catch (error) {
        console.log('Error while save answers', error.message);
    }
};

export const getMarkedAnswers = function (questionId) {
    try {
        const markedAnswers = JSON.parse(localStorage.getItem('markedAnswers'));
        return markedAnswers && markedAnswers?.hasOwnProperty(questionId)
            ? markedAnswers[questionId]
            : {};
    } catch (error) {
        console.log('Error while get old answers', error.message);
    }
};

export const generateRandomKey = function (length) {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
        );
    }
    return result;
};

export const convertHMS = function (value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
};

export const convertToMS = function (value) {
    return Math.floor(value / 60) + ':' + (value % 60 ? value % 60 : '00');
};

export const secondsToHms = function (d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
};

export const convertSeconds = function (seconds) {
    var convert = function (x) {
        return x < 10 ? '0' + x : x;
    };
    return (
        convert(parseInt(seconds / (60 * 60))) +
        ':' +
        convert(parseInt((seconds / 60) % 60)) +
        ':' +
        convert(seconds % 60)
    );
};

// module.exports = {
//     saveAnswersToLocalStorage,
//     getSavedAnswers,
//     getUserFlag,
//     getSiteFlag,
//     copyStringToClipboard,
//     is_image,
//     printUrl,
//     saveMarkedAnswers,
//     getMarkedAnswers,
//     generateRandomKey,
//     convertHMS,
//     convertToMS,
//     secondsToHms,
//     convertSeconds,
//     version
// };

export const getResourceRealPath = (url) => {
    if (!url) {
        return;
    }
    if (url?.startsWith('http')) {
        const urlObject = new URL(url);
        const urlOrigin = urlObject.origin;
        return url.replace(urlOrigin, ApiBaseURL);
    }

    const newUrl = url[0] === '/' ? url : `/${url}`;
    return `${ApiBaseURL}${newUrl}`;
};

export const getSiteUrl = () => {
    const siteUrl =
        window.location.host.indexOf('localhost') > -1 ||
        localStorage.getItem('site_url')
            ? localStorage.getItem('site_url')
            : window.location.host;
    return siteUrl;
};

export const getTimeRangeAsText = (timeRange) => {
    const textMap = {1: '1-15 ', 2: '15-30 ', 3: 'more than 30 '};
    return (textMap[parseInt(timeRange, 10)] || '') + window.__lang('min');
};

const key = 'NLMy8B7glteK7EuXCrnPZ4yLIpCE0pWR';

export const storeEncryptedUserInLocalStorage = (user) => {
    const userString = JSON.stringify(user);
    const encryptedUser = CryptoJS.AES.encrypt(userString, key).toString();
    localStorage.setItem('user', encryptedUser);
};

const removeUserInfoFromLocalStorage = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('language');
    localStorage.removeItem('logo');
    localStorage.removeItem('token');
    localStorage.removeItem('token_expires');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('ssoUser');
    window.location.href = '/login';
};

export const retrieveDecryptedUserFromLocalStorage = () => {
    const storedUser = localStorage.getItem('user');
    if (!storedUser) {
        return null;
    }
    try {
        // Attempt to parse the stored user as JSON (in case it's not encrypted)
        const parsedUser = JSON.parse(storedUser);
        if (typeof parsedUser === 'object' && parsedUser !== null) {
            // If parsing was successful and returned an object,
            // encrypt it and store it back in localStorage
            storeEncryptedUserInLocalStorage(parsedUser);
            return parsedUser;
        }
    } catch (error) {
        // If parsing as JSON failed, assume the data is encrypted
        try {
            const bytes = CryptoJS.AES.decrypt(storedUser, key);
            const decryptedUserString = bytes.toString(CryptoJS.enc.Utf8);
            return JSON.parse(decryptedUserString);
        } catch (decryptError) {
            // If decryption fails, remove the corrupted data
            removeUserInfoFromLocalStorage();
        }
    }
    return null;
};

export const storeEncryptedSiteInLocalStorage = (site) => {
    const siteString = JSON.stringify(site);
    const encryptedSite = CryptoJS.AES.encrypt(siteString, key).toString();
    localStorage.setItem('site', encryptedSite);
};

export const retrieveDecryptedSiteFromLocalStorage = () => {
    const encryptedSite = localStorage.getItem('site');
    if (!encryptedSite) {
        return null;
    }
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedSite, key);
        const decryptedSiteString = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedSiteString);
    } catch (error) {
        removeUserInfoFromLocalStorage();
    }
};

export const checkFirstNestedKeyValue = (obj) => {
    const keys = Object.keys(obj);

    if (keys.length === 0) {
        return false;
    }

    for (const key of keys) {
        const value = obj[key];

        const hasValue = !(value === null
            || value === undefined
            || (Array.isArray(value) && value.length === 0)
            || (typeof value === 'object' && Object.keys(value).length === 0)
            || (typeof value === 'string' && (value.trim() === '' || value.trim() === '<p><br></p>')));

        if (hasValue) {
            return true;
        }
    }
    return false;
};
