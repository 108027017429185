import { wishlistConstants } from '../constants/wishlist.constants';

const initialState = {
    loading: false,
    fetchMoreLoading: false,
    wishlistModules: [],
    links: {},
    meta: {},
};

export function wishlist(state = initialState, action) {
    switch (action.type) {
        case wishlistConstants.WISHLIST_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case wishlistConstants.WISHLIST_DELETE_SUCCESS:
            return {
                ...state,
                wishlistModules: state.wishlistModules.filter(
                    item => item.moduleID !== action.payload,
                ),
            };
        case wishlistConstants.WISHLIST_FETCHING:
        case wishlistConstants.WISHLIST_CREATING:
            return {
                ...state,
                loading: true,
            };
        case wishlistConstants.WISHLIST_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                wishlistModules: action.payload.data,
                links: action.payload.links,
                meta: action.payload.meta
            };

        case wishlistConstants.FETCH_MORE_WISHLIST_MODULES_REQUEST:
            return {
                ...state,
                fetchMoreLoading: true,
            }

        case wishlistConstants.FETCH_MORE_WISHLIST_MODULES_SUCCESS:
            return {
                ...state,
                fetchMoreLoading: false,
                wishlistModules: [...state.wishlistModules, ...action.payload.data],
                links: action.payload.links,
                meta: action.payload.meta
            }
        case wishlistConstants.FETCH_MORE_WISHLIST_MODULES_FAILED:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
}
