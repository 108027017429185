import React from 'react';
import { Menu, Modal } from 'antd';
import './Header.scss';

const HeaderMySchoolMenu = () => {
    const info = () => {
        Modal.info({
            content: <p>This feature is going to be available soon</p>,
            onOk() {},
        });
    };
    const mySchoolMenuItems = [
        { key: 1, name: 'Shop' },
        { key: 2, name: 'School Hub' },
        { key: 3, name: 'Social Calendar' },
        { key: 4, name: 'E-portfolio' },
    ];
    return (
        <Menu
            className="headerSchoolMenu"
            style={{ textAlign: 'center', paddingBottom: 20 }}
        >
            <Menu.Item className="headerSchoolMenuItem">
                <span style={{ fontWeight: 600 }}>My School</span>
            </Menu.Item>
            <Menu.Divider />
            {mySchoolMenuItems.map(items => {
                return (
                    <Menu.Item className="headerSchoolMenuItem" key={items.key}>
                        <span onClick={info} className="menu-item">
                            {items.name}
                        </span>
                    </Menu.Item>
                );
            })}
        </Menu>
    );
};

export default HeaderMySchoolMenu;
