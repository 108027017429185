import React from 'react';
import ProtectedRoute from '../components/Layout/ProtectedRoute';

const TrainerDashboardPage = React.lazy(() =>
    import('../pages/TrainerDashboardPage'),
);
const AssignContentPage = React.lazy(() =>
    import('../pages/AssignContentPage'),
);
const TrainerUserAdminPage = React.lazy(() =>
    import('../pages/TrainerUserAdminPage'),
);
const TrainerSchoolAdminPage = React.lazy(() =>
    import('../pages/TrainerSchoolAdminPage'),
);
const TrainerGlobalReportingPage = React.lazy(() =>
    import('../pages/TrainerGlobalReportingPage'),
);
const SearchAndAssign = React.lazy(() =>
    import('../pages/SearchAndAssign'),
);
const SearchStudents = React.lazy(() =>
    import('../pages/SearchStudents'),
);
const SearchUserGroups = React.lazy(() =>
    import('../pages/SearchUserGroups'),
);
const StudentPage = React.lazy(() =>
    import('../pages/StudentPage'),
);
const LearnerContentPage = React.lazy(() =>
    import('../pages/LearnerContentPage'),
);
const UserGroupPage = React.lazy(() =>
    import('../pages/UserGroupPage'),
);
const AssignModuleWithStudentAndGroupSearchPage = React.lazy(() =>
    import('../pages/AssignModuleWithStudentAndGroupSearchPage'),
);
const SelectPathwayToCustomise = React.lazy(() =>
    import('../pages/SelectPathwayToCustomisePage'),
);
const LearningPathwayCustomisePage = React.lazy(() =>
    import('../pages/LearningPathwayCustomisePage'),
);
const TrainerUsersPage = React.lazy(() =>
    import('../pages/trainerReportingPage/ReportingPage'),
);
const ActivitiesPage = React.lazy(()=>import ('../pages/Activitiespage'))
const ActivityManagePage = React.lazy(()=>import('../pages/ManageActivityPage'))
const ModulesPage = React.lazy(()=>import ('../pages/modulesPage'))
const ModulesManagePage = React.lazy(()=>import ('../pages/ModuleManagePage'))
const CoursesListPage = React.lazy(()=>import('../pages/coursesPage'))
const CourseManagePage = React.lazy(()=>import('../pages/trainer/CourseManagePage'))
const CourseBundlesPage =  React.lazy(()=>import('../pages/courseBundlesPage'))
const LearningPathwaysPage =  React.lazy(()=>import('../pages/LearningPathwaysPage'))
const LearningPathwayManagePage =React.lazy(()=>import('../pages/trainer/LearningPathwayManagePage'))
const UsersContentPage = React.lazy(()=>import('../pages/usersContentPage'))
const UsersGroupsContentPage = React.lazy(()=>import('../pages/usersGroups/usersContentPage'))
const Messages = React.lazy(() => import('../pages/messages'))
const FeedbackDocument = React.lazy(() => import('../pages/FeedbackDocument'))
const TrainerDocumentUsers = React.lazy(() => import('../pages/TrainerDocumentUsers'))
const AssignDocument = React.lazy(() => import('../pages/AssignDocument'))
const ArchivedDocuments = React.lazy(() => import('../pages/ArchivedDocuments'))
const LearnerFeedbackReport = React.lazy(() => import('../pages/LearnerFeedbackReport'))

const TrainerRoutes = [
    <ProtectedRoute
        key="/trainer/feedback-report/:id"
        exact
        path="/trainer/feedback-report/:id"
        component={LearnerFeedbackReport}
    />,
    <ProtectedRoute
        key="/trainer/archived-documents"
        exact
        path="/trainer/archived-documents"
        component={ArchivedDocuments}
    />,
    <ProtectedRoute
        key="/trainer/document-users/:id"
        exact
        path="/trainer/document-users/:id"
        component={TrainerDocumentUsers}
    />,
    <ProtectedRoute
        key="/assign-document/:id"
        exact
        path="/assign-document/:id"
        component={AssignDocument}
    />,
    <ProtectedRoute
        key="/trainer/feedback-document"
        exact
        path="/trainer/feedback-document"
        component={FeedbackDocument}
    />,
    <ProtectedRoute
        key="/trainer/messages"
        exact
        path="/trainer/messages"
        component={Messages}
    />,
    <ProtectedRoute
        key="/trainer/assign/module/:moduleID"
        path="/trainer/assign/module/:moduleID"
        component={AssignModuleWithStudentAndGroupSearchPage}
        exact
    />,
    <ProtectedRoute
        key="/trainer/select-pathway-to-customise"
        path="/trainer/select-pathway-to-customise"
        component={SelectPathwayToCustomise}
        exact
    />,

    <ProtectedRoute
    key="/trainer/activities/manage/:activityID"
    path="/trainer/activities/manage/:activityID"
    component={ActivityManagePage}
    exact
    />,
    <ProtectedRoute
    key="/trainer/activities/manage"
    path="/trainer/activities/manage"
    component={ActivityManagePage}
    exact
    />,


    <ProtectedRoute
    key="/trainer/activities"
    path="/trainer/activities"
    component={ActivitiesPage}
    exact
    />,
    <ProtectedRoute
    key="/trainer/modules"
    path="/trainer/modules"
    component={ModulesPage}
    exact
    />,
    <ProtectedRoute
    key="/trainer/users-content"
    path="/trainer/users-content"
    component={UsersContentPage}
    exact
    />,
    <ProtectedRoute
    key="/trainer/users-groups"
    path="/trainer/users-groups"
    component={UsersGroupsContentPage}
    exact
    />,

    <ProtectedRoute
    key="/trainer/modules/manage"
    path="/trainer/modules/manage"
    component={ModulesManagePage}
    exact
    />,
    <ProtectedRoute
    key="/trainer/modules/manage/:id"
    path="/trainer/modules/manage/:id"
    component={ModulesManagePage}
    exact
    />,
    <ProtectedRoute
    key="/trainer/modules"
    path="/trainer/modules"
    component={ModulesPage}
    exact
    />,

    <ProtectedRoute
    key="/trainer/modules/manage"
    path="/trainer/modules/manage"
    component={ModulesManagePage}
    exact
    />,
    <ProtectedRoute
    key="/trainer/courses"
    path="/trainer/courses"
    component={CoursesListPage}
    exact
    />,

    <ProtectedRoute
    key="/trainer/courses/manage"
    path="/trainer/courses/manage"
    component={CourseManagePage}
    exact
    />,
    ,
    <ProtectedRoute
    key="/trainer/coursebundles"
    path="/trainer/coursebundles"
    component={CourseBundlesPage}
    exact
    />
  ,
  <ProtectedRoute
  key="/trainer/pathways"
  path="/trainer/pathways"
  component={LearningPathwaysPage}
  exact
  />

,
<ProtectedRoute
key="/trainer/learning-pathways/manage"
path="/trainer/learning-pathways/manage"
component={LearningPathwayManagePage}
exact
/>

,
<ProtectedRoute
key="/trainer/learning-pathways/:learningPathwayID/manage"
path="/trainer/learning-pathways/:learningPathwayID/manage"
component={LearningPathwayManagePage}
exact
/>,
    <ProtectedRoute
        key="/trainer/learning-pathways/:learningPathwayID/customise/user/:userID"
        path="/trainer/learning-pathways/:learningPathwayID/customise/user/:userID"
        component={LearningPathwayCustomisePage}
        exact
    />,
    <ProtectedRoute
        key="/trainer/learning-pathways/:learningPathwayID/customise/user-group/:userGroupID"
        path="/trainer/learning-pathways/:learningPathwayID/customise/user-group/:userGroupID"
        component={LearningPathwayCustomisePage}
        exact
    />,
    <ProtectedRoute
        path="/trainer/search-assign"
        component={SearchAndAssign}
        key="/trainer/search-assign"
        exact
    />,
    <ProtectedRoute
        path="/trainer/dashboard"
        component={TrainerDashboardPage}
        key="/trainer/dashboard"
        exact
    />,
    <ProtectedRoute
        path="/trainer/student/:userID/content"
        component={LearnerContentPage}
        key="/trainer/student/:userID/content"
        exact
    />,
    <ProtectedRoute
        path="/trainer/student/:userID"
        component={StudentPage}
        key="/trainer/student/:userID"
        exact
    />,
    <ProtectedRoute
        path="/trainer/search-users"
        component={SearchStudents}
        key="/trainer/search-users"
        exact
    />,
    <ProtectedRoute
        path="/trainer/user-group/:userGroupID"
        component={UserGroupPage}
        key="/trainer/user-group/:userGroupID"
        exact
    />,
    <ProtectedRoute
        path="/trainer/search-user-groups"
        component={SearchUserGroups}
        key="/trainer/search-user-groups"
    />,
    <ProtectedRoute
        path="/trainer/allocate-modules"
        component={AssignContentPage}
        key="/trainer/allocate-modules"
    />,
    <ProtectedRoute
        path="/trainer/user-admin"
        component={TrainerUserAdminPage}
        key="/trainer/user-admin"
    />,
    <ProtectedRoute
        path="/trainer/school-admin"
        component={TrainerSchoolAdminPage}
        key="/trainer/school-admin"
    />,
    <ProtectedRoute
        key="/trainer/global-reporting"
        path="/trainer/global-reporting"
        component={TrainerGlobalReportingPage}
    />,
    <ProtectedRoute
        key="/trainer/users"
        path="/trainer/users"
        component={TrainerUsersPage}
    />,
];

export default TrainerRoutes;
