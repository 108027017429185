import { shuffle } from 'lodash';
import { activityConstants } from '../constants/activity.constants';
import { activityBuilderConstants } from '../constants/activityBuilder.constants';
import activityMarkingConstants from '../constants/activityMarking.constants';

const interleave = (arr, thing) => [].concat(...arr.map(n => [n, thing])).slice(0, -1)


const initialState = {
    loading: false,
    activities: [],
    activity: {},
    fields: {
        activity: { pages: [] },
        instructions: { pages: [] },
    },
    answers: [],
    results: [],
    currentActivity: {},
};

export function activities(state = initialState, action) {
    switch (action.type) {
        case activityConstants.FETCH_ACTIVITIES_REQUEST:
        case activityConstants.SEARCH_ACTIVITIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case activityConstants.FETCH_ACTIVITIES_REJECTED:
            return {
                ...state,
                loading: false,
                activities: [],
            };
        case activityConstants.FETCH_ACTIVITIES_SUCCESS:
        case activityConstants.SEARCH_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                activities: action.payload,
            };
        case activityConstants.FETCH_ACTIVITY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case activityConstants.FETCH_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                activity: action.payload,
                currentActivity: action.payload,
            };
        case activityConstants.QUESTION_ANSWERED:
            const { questionID, answerID } = action;
            const { answers } = state;
            answers[questionID] = answerID;
            return {
                ...state,
                answers,
            };
        case activityConstants.SUBMIT_ANSWERS:
            return {
                ...state,
                results: action.results,
            };
        case activityConstants.CLEAR_ACTIVITY:
            return {
                ...state,
                activity: {},
            };
        case activityBuilderConstants.ACTIVITY_BUILDER_ACTIVITY_LOADED:
            return {
                ...state,
                activity: action.activity,
            };
        case activityBuilderConstants.ACTIVITY_BUILDER_ACTIVITY_SAVE_SUCCESS:
            return {
                ...state,
                // activity: action.payload.activity,
            };
        case activityMarkingConstants.CLEAR_CURRENT_ACTIVITY:
            return {
                ...initialState,
            };
        case activityBuilderConstants.ACTIVITY_BUILDER_CANCEL_SAVE_ACTIVITY:
            return initialState;
        case activityConstants.FIELDS_LOADED:
            if (
                action.fields &&
                action.fields.activity &&
                action.fields.activity.pages
            ) {
                let activityPages = action.fields.activity.pages;
                // let newActivity = [];
                if (activityPages && activityPages.length) {
                    action.fields.activity.pages = activityPages.map(
                        activity => {
                            if (
                                activity.questions &&
                                activity.questions.length
                            ) {
                                activity.questions = activity.questions.map(
                                    q => {
                                        if (
                                            q &&
                                            q[0] &&
                                            q[0].props &&
                                            q[0].props.blanks &&
                                            // q[0].type != 'mixedSentence' &&
                                            q[0].type != 'selectTheBlanks'
                                        ) {
                                            let shuffleBlank = shuffle(
                                                q[0].props.blanks,
                                            );
                                            q[0].props.blanks = shuffleBlank;
                                        } else if (
                                            q &&
                                            q[0] &&
                                            q[0].props &&
                                            q[0].props.blanks &&
                                            q[0].type == 'mixedSentence'
                                        ) {
                                            let shuffleAnswers = q[0].props.blanks.map(
                                                x => shuffle(x),
                                            );
                                            q[0].props.blanks = shuffleAnswers;
                                        } else if (q &&
                                            q[0] &&
                                            q[0].props &&
                                            q[0].props.blanks &&
                                            q[0].type == 'selectTheBlanks'
                                        ) {
                                            let test = [...q[0].props.blanks] // [['1#2#3'],['a#b#c'],['x#y#z']]
                                            let filteredArr = []
                                            for (let i = 0; i < test.length; i++) {
                                                let testItemStr = String(test[i]) // index: 0: '1#2#3'
                                                let shuffleAnswer =  interleave(shuffle(testItemStr.split("#")), '#')
                                                filteredArr.push([shuffleAnswer.join('')])

                                            }

                                            q[0].props.blanks = filteredArr;
                                        }
                                        else if (
                                            q &&
                                            q[0] &&
                                            q[0]?.type == 'matchUp'
                                        ) {
                                            q[0].props.shuffleAnswers = shuffle(
                                                q[0].props.pairs,
                                            );
                                            q.answers = q.answers.map(an => ({
                                                ...an,
                                                shuffleAnswers: shuffle(
                                                    an?.pairs,
                                                ),
                                                random: Math.random(),
                                            }));
                                        }
                                        if (q && q.answers) {
                                            q.answers = q.answers.map(an => ({
                                                ...an,
                                                random: Math.random(),
                                            }));
                                        }

                                        return q;
                                    },
                                );
                            }
                            return activity;
                        },
                    );
                }
            }
            return {
                ...state,
                fields: action.fields,
                currentActivity: action.currentActivity,
            };
        default:
            return state;
    }
}
