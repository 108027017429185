import React from 'react';
import { Modal, Button } from 'antd';

const ConfirmLogOutModal = ({ handleOk, handleCancel, visible }) => {
    return (
        <Modal
            destroyOnClose
            title={window.__lang('log_out')}
            open={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="1" onClick={handleCancel}>
                    {window.__lang('cancel')}
                </Button>,
                <Button key="2" type="primary" onClick={handleOk}>
                    {window.__lang('confirm_action')}
                </Button>,
            ]}
        >
            <p>{window.__lang('please_confirm_you_wish_to_log_out')}.</p>
        </Modal>
    );
};
export default ConfirmLogOutModal;
