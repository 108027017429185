import { axiosDefaultClient } from '../../settings/axios.settings';
import { retrieveDecryptedUserFromLocalStorage } from '../../utils/common';
import notificationConstants from '../constants/notification.constants';

const client = axiosDefaultClient;

const fetchNotifications = (page = 1, pageSize = 10, filters = {}) => {
    return dispatch => {
        const user = retrieveDecryptedUserFromLocalStorage();
        dispatch({
            type: notificationConstants.FETCH_NOTIFICATIONS_REQUEST,
        });
        const url = `/api/notifications/${
            user.userID
        }?page=${page.toString()}&per_page=${pageSize}`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: notificationConstants.FETCH_NOTIFICATIONS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: notificationConstants.FETCH_NOTIFICATIONS_REJECTED,
                    payload: error,
                });
            });
    };
};

const fetchLearnerNotifications = () => {
    return dispatch => {
        const user = retrieveDecryptedUserFromLocalStorage();
        dispatch({
            type: notificationConstants.FETCH_LEARNER_NOTIFICATIONS_REQUEST,
        });
        const url = `/api/learner/notifications_list`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: notificationConstants.FETCH_LEARNER_NOTIFICATIONS_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: notificationConstants.FETCH_LEARNER_NOTIFICATIONS_REJECTED,
                    payload: error,
                });
            });
    };
};

const markLearnerNotificationRead = (notificationID) => {
    return dispatch => {
        dispatch({type: notificationConstants.MARK_LEARNER_NOTIFICATION_READ_REQUEST})

        let url = `/api/learner/${notificationID}/mark-notification-as-read`

        client
            .post(url)
            .then(response => {
                dispatch({
                    type: notificationConstants.MARK_LEARNER_NOTIFICATION_READ_SUCCESS
                })
            })
            .catch(error => {
                dispatch({
                    type: notificationConstants.MARK_LEARNER_NOTIFICATION_READ_REJECTED
                })
            })
    }
}

const notificationActions = {
    fetchNotifications,
    fetchLearnerNotifications,
    markLearnerNotificationRead,
};

export default notificationActions;
