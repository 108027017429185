import axios from 'axios';
// moment import
import moment from 'moment';
// import { userService } from '../services/user.service';
import { ApiBaseURL } from '../constants/apiConstants';
import { storeEncryptedUserInLocalStorage } from '../utils/common';

const BASE_URL = ApiBaseURL;
const cancelToken = axios.CancelToken.source();
const axiosClient = axios.create({
    baseURL: BASE_URL,
    cancelToken: cancelToken.token,
    headers: {
        'Content-Type': 'application/json',
    },
});

let refreshTokenPromise;
let newToken;
let refereshTokenError;
const requestHandler = async request => {
    // if (localStorage.getItem('user') && localStorage.getItem('token')) {
    if (localStorage.getItem('user')) {
        request.headers.Authorization = `Bearer ${localStorage.getItem(
            'token',
        )}`;

        const tokenExpiresAt = moment(localStorage.getItem('token_expires'));
        if (tokenExpiresAt.isSameOrBefore(moment())) {
            if (!refreshTokenPromise) {
                // check for an existing in-progress request
                // if nothing is in-progress, start a new refresh token request
                refreshTokenPromise = refreshToken()
                    .then(token => {
                        newToken = token;
                        refreshTokenPromise = null; // clear state
                        return token; // resolve with the new token
                    })
                    .catch(e => {
                        refereshTokenError = e;
                        throw new axios.Cancel('invalid refresh token');
                    });
            }

            await refreshTokenPromise;
            request.headers.Authorization = `Bearer ${newToken}`;

            // return refreshToken()
            //     .then(() => {
            //         request.headers.Authorization = `Bearer ${localStorage.getItem(
            //             'token',
            //         )}`;
            //         return Promise.resolve(request);
            //     })
            //     .catch(error => {
            //         return Promise.reject(error);
            //     });
        }
        return request;
    } else {
        // cancelToken.cancel('Refresh Token Error');
        // localStorage.removeItem('user');
        // localStorage.removeItem('language');
        // localStorage.removeItem('token');
        // localStorage.removeItem('token_expires');
        // localStorage.removeItem('refresh_token');
        // window.location.href = '/login';
    }
    return request;
};

const responseHandler = response => {
    // TODO test for unauthenticated response / denied response
    // if (response.status === 401) {

    //     // not authenticated - call logout code ???
    // }
    // if(response.)
    return response;
};
const errorHandler = error => {
    if (error.response?.statusText === 'Unauthenticated.') {
        // userService.logout().then(()=>)
        cancelToken.cancel('Refresh Token Error');
        localStorage.removeItem('user');
        localStorage.removeItem('language');
        localStorage.removeItem('token');
        localStorage.removeItem('token_expires');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('ssoUser');
        window.location.href = '/login';
    }
    if (error.response) {
        // Handle known errors
        if (error.response.status === 503) {
            window.location.href = '/maintenance';
        }
    } 
    // else {
    //     // Handle network errors or when the server is down
    //     if (error.message === 'Network Error') {
    //         window.location.href = '/maintenance';
    //     }
    // }
    return Promise.reject(error.response);
};

axiosClient.interceptors.request.use(request => requestHandler(request));

axiosClient.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error),
    // error => {
    //     console.log('errorrrrr', error.response);
    //     if (error.config && error.response && error.response.status === 401) {
    //         if (!refreshTokenPromise) {
    //             // check for an existing in-progress request
    //             // if nothing is in-progress, start a new refresh token request
    //             refreshTokenPromise = refreshToken().then(token => {
    //                 refreshTokenPromise = null; // clear state
    //                 return token; // resolve with the new token
    //             });
    //         }

    //         return refreshTokenPromise.then(token => {
    //             error.config.headers['Authorization'] = `bearer ${token}`;
    //             return axiosClient.request(error.config);
    //         });
    //     }
    //     return Promise.reject(error);
    // },
);

const refreshToken = () => {
    return new Promise((resolve, reject) => {
        const newClient = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'refresh-token': localStorage.getItem('refresh_token'),
            },
        });

        newClient.interceptors.response.use(
            response => {
                return response;
            },
            // error => {
            //     cancelToken.cancel('Refresh Token Error');
            //     refereshTokenError = true;
            //     if (error.response.status === 401) {
            //         const logoutClient = axios.create({
            //             headers: {
            //                 Authorization: `Bearer ${localStorage.getItem(
            //                     'token',
            //                 )}`,
            //             },
            //         });
            //         logoutClient.post('/logout').then(() => {
            //             localStorage.removeItem('user');
            //             localStorage.removeItem('language');
            //             localStorage.removeItem('token');
            //             localStorage.removeItem('token_expires');
            //             localStorage.removeItem('refresh_token');
            //             window.location.href = '/login';
            //         });
            //     }
            // },
        );
        newClient
            .post('/api/refreshtok', {})
            .then(response => {
                localStorage.setItem('token', response?.data?.access_token);
                localStorage.setItem(
                    'refresh_token',
                    response?.data?.refresh_token,
                );
                localStorage.setItem(
                    'token_expires',
                    response?.data?.expires_in,
                );
                storeEncryptedUserInLocalStorage(response?.data?.user);
                resolve(response?.data?.access_token);
                // return response.data.access_token;
            })
            .catch(error => {
                refereshTokenError = true;
                cancelToken.cancel('Refresh Token Error');
                const logoutClient = axios.create({
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token',
                        )}`,
                    },
                });
                // logoutClient.post('/logout').then(() => {
                localStorage.removeItem('user');
                localStorage.removeItem('language');
                localStorage.removeItem('token');
                localStorage.removeItem('token_expires');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('ssoUser');
                window.location.href = '/login';
                // });
                reject(error);
            });
    });
};

export { axiosClient as axiosDefaultClient, refereshTokenError, cancelToken };
