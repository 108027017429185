// export const feedbackBuilder = {
//     ADD_QUESTION: 'ADD_QUESTION',
//     ADD_QUESTION_TO_LIST: 'ADD_QUESTION_TO_LIST',
//     ADD_NEW_ANSWER_COMPONENT: 'ADD_NEW_ANSWER_COMPONENT',
//     CLEAR_FORM: "CLEAR_FORM",
// }


export const feedbackBuilder = {
    ADD_QUESTION: 'ADD_QUESTION',
    CURRENT_QUESTION: 'CURRENT_QUESTION',
    ADD_NEW_ANSWER_COMPONENT: 'ADD_NEW_ANSWER_COMPONENT',
    CLEAR_FORM: "CLEAR_FORM",
}
