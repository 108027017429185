const initialState = {
    loading: false,
    auditLogs: [],
};

const auditLogs = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_AUDIT_LOGS_REQUEST':
        case 'SEARCH_AUDIT_LOGS_REQUEST':
            return {
                ...state,
                loading: true,
                auditLogs: [],
            };
        case 'FETCH_AUDIT_LOGS_SUCCESS':
        case 'SEARCH_AUDIT_LOGS_SUCCESS':
            return {
                ...state,
                loading: false,
                auditLogs: action.payload,
            };
        case 'FETCH_AUDIT_LOGS_REJECTED':
        case 'SEARCH_AUDIT_LOGS_REJECTED':
            return {
                ...state,
                loading: false,
                auditLogs: [],
            };
        default:
            return state;
    }
};

export default auditLogs;
