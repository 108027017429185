import React from 'react';
import { Card, Col, Row } from 'antd';
import './detailes.scss';
const Detailes = ({ selectedContent, user }) => {
    return (
        <Row className="detailes">
            <Col span={24} className="title">
                {window.__lang('DETAILS')}
            </Col>
            <Col span={24}>
                <Card>
                    <Row justify="space-between">
                        <Col>
                            <b> {window.__lang('Activity_Title')}:</b>
                            <span>{selectedContent?.activity}</span>
                        </Col>
                        <Col>
                            <b>{window.__lang('User_Group')}:</b>
                            <span> {selectedContent?.group}</span>
                            <span>
                                {selectedContent?.groups?.length > 0
                                    ? selectedContent?.groups?.map(
                                          (group, index) => (
                                              <>{group.userGroup + ', '}</>
                                          ),
                                      )
                                    : '-'}
                            </span>
                        </Col>
                        {user.role === 'learner' && (
                            <Col>
                                <b>{window.__lang('Trainer_Name')}:</b>{' '}
                                <span>
                                    {selectedContent?.trainers?.length > 0
                                        ? selectedContent?.trainers?.map(
                                              (trainer, index) => (
                                                  <>{trainer.name + ', '}</>
                                              ),
                                          )
                                        : '-'}
                                </span>
                            </Col>
                        )}
                        <Col>
                            <b>{window.__lang('Mark')}:</b>{' '}
                            <span>
                                {selectedContent?.score
                                    ? selectedContent.score
                                    : '-'}
                            </span>
                        </Col>
                        <Col>
                            <b>{window.__lang('Mark_Date')}:</b>
                            <span>
                                {selectedContent?.markDate
                                    ? selectedContent?.markDate.split(' ')[0]
                                    : '-'}
                            </span>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};

export default Detailes;
