import courseBundleConstants from '../constants/courseBundle.constants';

const initialState = {
    loading: false,
    updateLoading: false,
    courseBundles: [],
    bundles:[],
    courseBundle:{}
};

const courseBundles = (state = initialState, action) => {
    switch (action.type) {
        case courseBundleConstants.FETCH_COURSE_BUNDLES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case courseBundleConstants.FETCH_COURSE_BUNDLES_SUCCESS:
            return {
                ...state,
                loading: false,
                courseBundles: action.payload,
                courseBundle:{}

            };
        case courseBundleConstants.FETCH_COURSE_BUNDLES_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case courseBundleConstants.FETCH_COURSE_BUNDLE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case courseBundleConstants.FETCH_COURSE_BUNDLE_SUCCESS:
            return {
                ...state,
                loading: false,
                courseBundle: action.payload,
            };
        case courseBundleConstants.FETCH_COURSE_BUNDLE_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case courseBundleConstants.FETCH_BUNDLES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case courseBundleConstants.FETCH_BUNDLES_SUCCESS:
            return {
                ...state,
                loading: false,
                bundles: action.payload,
            };
        case courseBundleConstants.FETCH_BUNDLES_REJECTED:
            return {
                ...state,
                loading: false,
            };
        case courseBundleConstants.SAVE_COURSE_BUNDLE_REQUEST:
            return {
                ...state,
                updateLoading: true,
               
            };
        case courseBundleConstants.SAVE_COURSE_BUNDLE_SUCCESS:
            return {
                ...state,
                updateLoading: false,
                courseBundles: [],
                bundles:[],
                courseBundle:{}
            };
        case courseBundleConstants.SAVE_COURSE_BUNDLE_REJECTED:
            return {
                ...state,
                updateLoading: false,
            };
        case courseBundleConstants.SEARCH_COURSE_BUNDLES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case courseBundleConstants.SEARCH_COURSE_BUNDLES_SUCCESS:
            return {
                ...state,
                loading: false,
                courseBundles: action.payload,
            };
        case courseBundleConstants.SEARCH_COURSE_BUNDLES_REJECTED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default courseBundles;
