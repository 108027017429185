const learnerProfile = {
    FETCH_ALL_COUNTRIES_REQUEST: 'FETCH_ALL_COUNTRIES_REQUEST',
    FETCH_ALL_COUNTRIES_SUCCESS: 'FETCH_ALL_COUNTRIES_SUCCESS',
    FETCH_ALL_COUNTRIES_FAIL: 'FETCH_ALL_COUNTRIES_FAIL',
    FETCH_ALL_SETTINGS_REQUEST: 'FETCH_ALL_SETTINGS_REQUEST',
    FETCH_ALL_SETTINGS_SUCCESS: 'FETCH_ALL_SETTINGS_SUCCESS',
    FETCH_ALL_SETTINGS_FAIL: 'FETCH_ALL_SETTINGS_FAIL',
    FETCH_ALL_AVATARS_REQUEST: 'FETCH_ALL_AVATARS_REQUEST',
    FETCH_ALL_AVATARS_SUCCESS: 'FETCH_ALL_AVATARS_SUCCESS',
    FETCH_ALL_AVATARS_FAIL: 'FETCH_ALL_AVATARS_FAIL',
    FETCH_INTERFACE_LANGUAGES_REQUEST: 'FETCH_INTERFACE_LANGUAGES_REQUEST',
    FETCH_INTERFACE_LANGUAGES_SUCCESS: 'FETCH_INTERFACE_LANGUAGES_SUCCESS',
    FETCH_INTERFACE_LANGUAGES_FAIL: 'FETCH_INTERFACE_LANGUAGES_FAIL',
    FETCH_PROFILE_INFO_REQUEST: 'FETCH_PROFILE_INFO_REQUEST',
    FETCH_PROFILE_INFO_SUCCESS: 'FETCH_PROFILE_INFO_SUCCESS',
    FETCH_PROFILE_INFO_FAIL: 'FETCH_PROFILE_INFO_FAIL',
    UPDATE_PROFILE_INFO_REQUEST: 'UPDATE_PROFILE_INFO_REQUEST',
    UPDATE_PROFILE_INFO_SUCCESS: 'UPDATE_PROFILE_INFO_SUCCESS',
    UPDATE_PROFILE_INFO_FAIL: 'UPDATE_PROFILE_INFO_FAIL',
    UPDATE_PROFILE_IMAGE_REQUEST: 'UPDATE_PROFILE_IMAGE_REQUEST',
    UPDATE_PROFILE_IMAGE_SUCCESS: 'UPDATE_PROFILE_IMAGE_SUCCESS',
    UPDATE_PROFILE_IMAGE_FAIL: 'UPDATE_PROFILE_IMAGE_FAIL',
    UPDATE_SETTINGS_TARGET_REQUEST: 'UPDATE_SETTINGS_TARGET_REQUEST',
    UPDATE_SETTINGS_TARGET_SUCCESS: 'UPDATE_SETTINGS_TARGET_SUCCESS',
    UPDATE_SETTINGS_TARGET_FAIL: 'UPDATE_SETTINGS_TARGET_FAIL',
    UPDATE_SETTINGS_SKILLS_REQUEST: 'UPDATE_SETTINGS_SKILLS_REQUEST',
    UPDATE_SETTINGS_SKILLS_SUCCESS: 'UPDATE_SETTINGS_SKILLS_SUCCESS',
    UPDATE_SETTINGS_SKILLS_FAIL: 'UPDATE_SETTINGS_SKILLS_FAIL',
    UPDATE_SETTINGS_LANGUAGE_REQUEST: 'UPDATE_SETTINGS_LANGUAGE_REQUEST',
    UPDATE_SETTINGS_LANGUAGE_SUCCESS: 'UPDATE_SETTINGS_LANGUAGE_SUCCESS',
    UPDATE_SETTINGS_LANGUAGE_FAIL: 'UPDATE_SETTINGS_LANGUAGE_FAIL',
    UPDATE_SETTINGS_PASSWORD_REQUEST: 'UPDATE_SETTINGS_PASSWORD_REQUEST',
    UPDATE_SETTINGS_PASSWORD_SUCCESS: 'UPDATE_SETTINGS_PASSWORD_SUCCESS',
    UPDATE_SETTINGS_PASSWORD_FAIL: 'UPDATE_SETTINGS_PASSWORD_FAIL',
    WEEKLY_TARGET:"WEEKLY_TARGET",
    WEEKLY_TARGET_SUCCESS:"WEEKLY_TARGET_SUCCESS",
    WEEKLY_TARGET_REJECTED:"WEEKLY_TARGET_REJECTED",
    UPDATE_WEEKLY_TARGET:"UPDATE_WEEKLY_TARGET",
    UPDATE_WEEKLY_TARGET_SUCCESS:"UPDATE_WEEKLY_TARGET_SUCCESS",
    UPDATE_WEEKLY_TARGET_FAIL:"UPDATE_WEEKLY_TARGET_FAIL",
     
    
};

export default learnerProfile;
