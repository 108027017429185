import learnerFeedbackConstants from "../constants/learnerFeedback.constants";

const initialState = {
    // GET Copies For Learner
    learnerCopiesLoading: false,
    learnerCopies: [],
    learnerCopiesError: null,
    // GET Copy Details For Learner
    learnerCopyFeedbackDetailsLoading: false,
    learnerCopyFeedbackDetails: {
        currentUser: {},
        questions: [],
        feedbackDetails: {}
    },
    addLearnerCommentsLoading: false,
    addLearnerCommentsError: null,
    generatePDFLoading: false,
    generatePDFData: {},
    generatePDFError: null,
    learnerCopyFeedbackDetailsError: null,
}

export function learnerFeedbackReducer(state = initialState, action) {
    switch (action.type) {
        // GET Copies For Learner
        case learnerFeedbackConstants.GET_COPIES_FOR_LEARNER_REQUEST:
            return {
                ...state,
                learnerCopiesLoading: true,
            }

        case learnerFeedbackConstants.GET_COPIES_FOR_LEARNER_SUCCESS:
            return {
                ...state,
                learnerCopiesLoading: false,
                learnerCopies: action.payload
            }

        case learnerFeedbackConstants.GET_COPIES_FOR_LEARNER_REJECTED:
            return {
                ...state,
                learnerCopiesLoading: false,
                learnerCopiesError: action.payload
            }


        // GET Copy Details For Learner
        case learnerFeedbackConstants.GET_COPY_DETAILS_FOR_LEARNER_REQUEST:
            return {
                ...state,
                learnerCopyFeedbackDetailsLoading: true,
            }

        case learnerFeedbackConstants.GET_COPY_DETAILS_FOR_LEARNER_SUCCESS:
            return {
                ...state,
                learnerCopyFeedbackDetailsLoading: false,
                learnerCopyFeedbackDetails: {
                    // ...state.learnerCopyFeedbackDetails,
                    currentUser: action.payload.current_user,
                    questions: action.payload.questions,
                    feedbackDetails: action.payload.feedback_details
                }
            }

        case learnerFeedbackConstants.GET_COPY_DETAILS_FOR_LEARNER_REJECTED:
            return {
                ...state,
                learnerCopyFeedbackDetailsLoading: false,
                learnerCopyFeedbackDetailsError: action.payload
            }


            // POST Add Learner Comments
        case learnerFeedbackConstants.ADD_LEARNER_COMMENTS_REQUEST:
            return {
                ...state,
                addLearnerCommentsLoading: true,
            }

        case learnerFeedbackConstants.ADD_LEARNER_COMMENTS_SUCCESS:
            return {
                ...state,
                addLearnerCommentsLoading: false,
            }

        case learnerFeedbackConstants.ADD_LEARNER_COMMENTS_REJECTED:
            return {
                ...state,
                addLearnerCommentsLoading: false,
                addLearnerCommentsError: action.payload
            }

            // POST Generate PDF Report
        case learnerFeedbackConstants.GENERATE_PDF_REPORT_REQUEST:
            return {
                ...state,
                generatePDFLoading: true,
            }

        case learnerFeedbackConstants.GENERATE_PDF_REPORT_SUCCESS:
            return {
                ...state,
                generatePDFLoading: false,
                generatePDFData: action.payload
            }

        case learnerFeedbackConstants.GENERATE_PDF_REPORT_REJECTED:
            return {
                ...state,
                generatePDFLoading: false,
                generatePDFError: action.payload
            }

        default: return state
    }
}
