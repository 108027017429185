const notificationConstants = {
    FETCH_NOTIFICATIONS_REQUEST: 'FETCH_NOTIFICATIONS_REQUEST',
    FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
    FETCH_NOTIFICATIONS_REJECTED: 'FETCH_NOTIFICATIONS_REJECTED',

    // GET Learner Notifications
    FETCH_LEARNER_NOTIFICATIONS_REQUEST: 'FETCH_LEARNER_NOTIFICATIONS_REQUEST',
    FETCH_LEARNER_NOTIFICATIONS_SUCCESS: 'FETCH_LEARNER_NOTIFICATIONS_SUCCESS',
    FETCH_LEARNER_NOTIFICATIONS_REJECTED: 'FETCH_LEARNER_NOTIFICATIONS_REJECTED',

    //POST: Mark Learner Notification Read
    MARK_LEARNER_NOTIFICATION_READ_REQUEST: 'MARK_LEARNER_NOTIFICATION_READ_REQUEST',
    MARK_LEARNER_NOTIFICATION_READ_SUCCESS: 'MARK_LEARNER_NOTIFICATION_READ_SUCCESS',
    MARK_LEARNER_NOTIFICATION_READ_REJECTED: 'MARK_LEARNER_NOTIFICATION_READ_REJECTED',
};

export default notificationConstants;
