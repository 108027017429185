import learnerDashboardConstant from '../constants/learnerDashboard.constants';

const initialState = {};

function learnerDashboardPathways(state = initialState, action) {
    switch (action.type) {
        case learnerDashboardConstant.FETCH_PATHWAYS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerDashboardConstant.FETCH_PATHWAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                pathways: action.payload,
            };
        case learnerDashboardConstant.ClEAR_PATHWAYS_REQUEST:
            return {
                ...state,
                loading: false,
                pathways: [],
            };
        case learnerDashboardConstant.FETCH_PATHWAYS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function learnerDashboardModules(state = initialState, action) {
    switch (action.type) {
        case learnerDashboardConstant.FETCH_MODULES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerDashboardConstant.FETCH_MODULES_SUCCESS:
            return {
                ...state,
                loading: false,
                modules: action.payload,
            };
        case learnerDashboardConstant.CLEAR_MODULES:
            return {
                ...state,
                loading: false,
                modules: [],
            };
        case learnerDashboardConstant.FETCH_MODULES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function learnerDashboardLibrary(state = initialState, action) {
    switch (action.type) {
        case learnerDashboardConstant.FETCH_Library_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerDashboardConstant.FETCH_Library_SUCCESS:
            return {
                ...state,
                loading: false,
                librarySections: action.payload,
            };
        case learnerDashboardConstant.FETCH_Library_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

function learnerDashboardCourses(state = initialState, action) {
    switch (action.type) {
        case learnerDashboardConstant.FETCH_COURSES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case learnerDashboardConstant.FETCH_COURSES_SUCCESS:
            return {
                ...state,
                loading: false,
                courses: action.payload,
            };
        case learnerDashboardConstant.FETCH_COURSES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export {
    learnerDashboardPathways,
    learnerDashboardModules,
    learnerDashboardLibrary,
    learnerDashboardCourses,
};
