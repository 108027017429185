
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Modal } from 'antd';
import { userActions } from '../../../redux/actions/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { retrieveDecryptedUserFromLocalStorage } from '../../../utils/common';


const ModalUsernameAndPassword = props => {
    const dispatch = useDispatch();
    const [username, setusername] = useState();
    const [userpassword, setuserpassword] = useState();
    const [confirmuserpassword, setconfirmuserpassword] = useState();
    const userWelcomeMassages = useSelector(state => state.users.welcomeMassages);
    const history = useHistory();

    const submit = () => {
        const user = retrieveDecryptedUserFromLocalStorage();
        const data = {
            "id": user.userID,
            "name": username,
            "password": userpassword,
            "confirmpassword": confirmuserpassword,
        }
        dispatch(userActions.updateFirstLoginUser(data));
        dispatch(userActions.welcomMassages(user.userID));
    }
    const logout = () => {
        userActions.logout(history);
    };
    useEffect(() => {
        const user = retrieveDecryptedUserFromLocalStorage();
        dispatch(userActions.welcomMassages(user.userID));
    }, []);

    return (
        <div>
            <Modal
                title={window.__lang("Complete_Profile")}
                visible={userWelcomeMassages?.showPasswordField || userWelcomeMassages?.showUsernameField}
                onOk={submit}
                onCancel={logout}
                closable={false}
                okText={'Update'}
                cancelText={'Decline'}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    /*  onFinish={onFinish}
                     onFinishFailed={onFinishFailed} */
                    autoComplete="off"
                >
                    {(userWelcomeMassages?.showUsernameField) ?
                        <Form.Item
                            label={window.__lang("username")}
                            name="username"
                            value={username}
                            onChange={e => setusername(e.target.value)}
                            rules={[
                                {
                                    required: true,
                                    message: window.__lang("Please_input_your_username"),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        : ''}
                    {(userWelcomeMassages?.showPasswordField) ?
                    <Form.Item
                        label={window.__lang("Password")}
                        name="password"
                        value={userpassword}
                        onChange={e => setuserpassword(e.target.value)}
                        rules={[
                            {
                                required: true,
                                message: window.__lang("Please_input_your_password"),
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    : ''}
                    {(userWelcomeMassages?.showPasswordField) ?
                    <Form.Item
                        name="confirm"
                        label={window.__lang("Confirm_Password")}
                        value={confirmuserpassword}
                        onChange={e => setconfirmuserpassword(e.target.value)}
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: window.__lang("Please_confirm_your_password"),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(window.__lang("The_two_passwords_that_you_entered_do_not_match")));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                        : ''}
                </Form>
            </Modal>
        </div>
    );

}
export default ModalUsernameAndPassword;