const initialState = {
    loading: false,
    usersReport: [],
    filteredBundleName: null,
    filteredCourseName: null,
    filteredModuleName: null,
};

const userGroupReporting = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_REPORT_FOR_USERS_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_REPORT_FOR_USERS_SUCCESS':
            return {
                ...state,
                loading: false,
                usersReport: action.payload.report,
                filteredBundleName: action.payload.courseBundle,
                filteredCourseName: action.payload.course,
                filteredModuleName: action.payload.module,
            };
        default:
            return state;
    }
};

export default userGroupReporting;
