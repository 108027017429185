import userGroupConstants from '../constants/userGroup.constants';

const initialState = {
    loading: false,
    updateLoading: false,
    userGroups: [],
    userGroup: {},
    userGroupTrainers: [],
    loadingUserGroupTrainers: false,
    userGroupLearners: [],
    loadingUserGroupLearners: false,
    userGroupHRManagers: [],
    loadingUserGroupHRManagers: false,
    users: [],
};

const userGroups = (state = initialState, action) => {
    switch (action.type) {
        case userGroupConstants.FETCH_USER_GROUPS_REQUEST:
            return {
                ...state,
                loading: true,
                userGroup: [],
                userGroups: [],
            };
        case userGroupConstants.SEARCH_USER_GROUPS_REQUEST:
            return {
                ...state,
                loading: true,
                userGroup: [],
            };
        case userGroupConstants.FETCH_USER_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                userGroups: action.payload,
            };
        case userGroupConstants.SEARCH_USER_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                userGroups: action.payload,
            };
        case userGroupConstants.FETCH_USER_GROUPS_REJECTED:
        case userGroupConstants.SEARCH_USER_GROUPS_REJECTED:
            return {
                ...state,
                loading: false,
                userGroups: [],
            };
        case userGroupConstants.SAVE_USER_GROUP_REQUEST:
            return {
                ...state,
                updateLoading: true,
            };
        case userGroupConstants.SAVE_USER_GROUP_SUCCESS:
            return {
                ...state,
                updateLoading: false,
            };
        case userGroupConstants.SAVE_USER_GROUP_REJECTED:
            return {
                ...state,
                updateLoading: false,
            };
        case userGroupConstants.GET_USER_GROUP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userGroupConstants.GET_USER_GROUP_SUCCESS:
            return {
                ...state,
                userGroup: action.payload,
                loading: false,
            };
        case userGroupConstants.GET_USER_GROUP_REJECTED:
            return {
                ...state,
                userGroup: {},
                loading: false,
            };
        case userGroupConstants.Add_TRAINERS_TO_USERS_GROUPs_SUCCESS:
            return {
                ...state,
                userGroupTrainers: {
                    ...state.userGroupTrainers,
                    data: [...action.payload, ...state.userGroupTrainers?.data],
                },
                loadingUserGroupTrainers: false,
            };
        case userGroupConstants.Remove_TRAINERS_FROM_USERS_GROUPs_SUCCESS:
            return {
                ...state,
                userGroupTrainers: {
                    ...state.userGroupTrainers,
                    data: state.userGroupTrainers?.data.filter(
                        group =>
                            action.payload.findIndex(
                                x => x.userID === group.userID,
                            ) === -1,
                    ),
                },
                loadingUserGroupTrainers: false,
            };
        case userGroupConstants.REMOVE_ALL_TRAINERS_FROM_USERS_GROUP_REQUEST:
            return {
                ...state,
                loadingUserGroupTrainers: true,
            };
        case userGroupConstants.REMOVE_ALL_TRAINERS_FROM_USERS_GROUP_SUCCESS:
            return {
                ...state,
                userGroupTrainers: {
                    ...state.userGroupTrainers,
                    data: [],
                },
                loadingUserGroupTrainers: false,
            };
        case userGroupConstants.REMOVE_ALL_TRAINERS_FROM_USERS_GROUP_REJECTED:
            return {
                ...state,
                loadingUserGroupTrainers: false,
            };
        case userGroupConstants.FETCH_USERS_GROUPs_TRAINERS_SUCCESS:
            return {
                ...state,
                userGroupTrainers: action.payload,
                loadingUserGroupTrainers: false,
            };
        case userGroupConstants.FETCH_USERS_GROUPs_TRAINERS:
            return {
                ...state,
                loadingUserGroupTrainers: true,
            };
        case userGroupConstants.Add_LEARNERS_TO_USERS_GROUPS_SUCCESS:
            return {
                ...state,
                userGroupLearners: {
                    ...state.userGroupLearners,
                    data: [...state.userGroupLearners?.data,...action.payload],
                },
                loadingUserGroupLearners: false,
            };
        case userGroupConstants.Remove_LEARNERS_FROM_USERS_GROUPs_SUCCESS:
            return {
                ...state,
                userGroupLearners: {
                    ...state.userGroupLearners,
                    data: state.userGroupLearners?.data.filter(
                        group =>
                            action.payload.findIndex(
                                x => x.userID === group.userID,
                            ) === -1,
                    ),
                },
                loadingUserGroupLearners: false,
            };
        case userGroupConstants.REMOVE_ALL_LEARNERS_FROM_USERS_GROUP_REQUEST:
            return {
                ...state,
                loadingUserGroupLearners: true,
            };
        case userGroupConstants.REMOVE_ALL_LEARNERS_FROM_USERS_GROUP_SUCCESS:
            return {
                ...state,
                userGroupLearners: {
                    ...state.userGroupLearners,
                    data: [],
                },
                loadingUserGroupLearners: false,
            };
        case userGroupConstants.REMOVE_ALL_LEARNERS_FROM_USERS_GROUP_REJECTED:
            return {
                ...state,
                loadingUserGroupLearners: false,
            };
        case userGroupConstants.FETCH_USERS_GROUPs_LEARNERS_SUCCESS:
            return {
                ...state,
                userGroupLearners: action.payload,
                loadingUserGroupLearners: false,
            };
        case userGroupConstants.FETCH_USERS_GROUPs_LEARNERS:
            return {
                ...state,
                loadingUserGroupLearners: true,
            };
        case userGroupConstants.Add_HR_MANAGER_TO_GROUPS_SUCCESS:
            return {
                ...state,
                userGroupHRManagers: {
                    ...state.userGroupHRManagers,
                    data: [
                        ...action.payload,
                        ...state.userGroupHRManagers?.data,
                    ],
                },
                loadingUserGroupHRManagers: false,
            };
        case userGroupConstants.Remove_HR_FROM_USERS_GROUPs_SUCCESS:
            return {
                ...state,
                userGroupHRManagers: {
                    ...state.userGroupHRManagers,
                    data: state.userGroupHRManagers?.data.filter(
                        group =>
                            action.payload.findIndex(
                                x => x.userID === group.userID,
                            ) === -1,
                    ),
                },
                loadingUserGroupHRManagers: false,
            };
        case userGroupConstants.FETCH_USERS_GROUPs_HR_MANAGERS_SUCCESS:
            return {
                ...state,
                userGroupHRManagers: action.payload,
                loadingUserGroupHRManagers: false,
            };
        case userGroupConstants.FETCH_USERS_GROUPs_HR_MANAGERS:
            return {
                ...state,
                loadingUserGroupHRManagers: true,
            };
        case 'FETCH_USERS_BY_GROUP_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_USERS_BY_GROUP_SUCCESS':
            return {
                ...state,
                loading: false,
                userGroup: action.payload.group,
            };
        default:
            return state;
    }
};

export default userGroups;
