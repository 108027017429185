import React, {useEffect, useRef, useState} from 'react';
import {Table, Tag} from 'antd';
import viewIcon from '../../../../../../public/svg/black-veiw.svg';
import fileIcon from '../../../../../../public/svg/Icon feather-file.svg';
import {useSelector} from 'react-redux';
import TableFooter from '../../../../Shared/TableFooter';
import jsPDF from 'jspdf';
import {renderToString, renderToStaticMarkup} from 'react-dom/server';
import PdfContentPopUp from '../PdfContentPopUp/PdfContentPopUp';
import html2canvas from 'html2canvas';
import './contet-table.scss';
import { retrieveDecryptedUserFromLocalStorage } from '../../../../../utils/common';

const ContetnTable = ({
                          setOpenContent,
                          content,
                          setSelectedContent,
                          setPage,
                          pageSize,
                          setPageSize,
                      }) => {
    const [pdfContent, setPdfContent] = useState();
    const [pdfUser, setPdfUser] = useState();
    const user = retrieveDecryptedUserFromLocalStorage();
    const loading = useSelector(state => state.savedContent.loading);
    const metaData = useSelector(state => state.savedContent.metaData);
    const pdfRef = useRef();

    useEffect(() => {
        if (pdfContent) {
            exportToPdf();
        }

        return () => {
            setPdfContent(null);
        };
    }, [pdfContent]);

    const exportToPdf = () => {
        // setPdfContent(content);
        // const string = renderToString(
        //     <PdfContentPopUp user={user} selectedContent={content} />,
        // );
        // const pdf = new jsPDF('p', 'mm', 'a4');
        // pdf.html(string).then(() => {
        //     pdf.save('pdf');
        // });
        let div = document.getElementById('pdf-div');
        // let div = document.getElementById(id);
        div.style.display = 'block';
        const pdf = new jsPDF({orientation: 'landscape'});
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        html2canvas(div)
            .then(canvas => {
                div.style.display = 'none';
                const divImage = canvas.toDataURL('image/png');
                pdf.addImage(divImage, 'PNG', 0, 0, width, 0);
                let name =
                    user.role == 'learner' ? user.name : pdfContent.user?.name;
                pdf.save(`${name}-content.pdf`);
                setPdfContent(null);
            })
            .catch(e => {
                console.log('eeeee', e);
                setPdfContent(null);
            });
    };

    const viewContent = record => {
        setSelectedContent(record);
        setOpenContent(true);
    };

    const onPageChange = page => {
        setPage(page);
        // getContent(page, pageSize);
    };

    const onPageSizeChange = size => {
        setPageSize(size);
        // getContent(page, size);
    };

    const savedContentColuns = {
        learner: [
            {
                title: window.__lang('module'),
                dataIndex: 'module',
                align: 'center',
            },
            {
                title: window.__lang('activity'),
                dataIndex: 'activity',
                align: 'center',
            },
            // {
            //     title: window.__lang('question'),
            //     dataIndex: 'question',
            //     align: 'center',
            // },
            {
                title: window.__lang('content_type'),
                dataIndex: '_type',
                align: 'center',
            },
            {
                title: window.__lang('trainer'),
                // dataIndex: 'trainer',
                align: 'center',
                width: '200px',
                render: (_, record) => (
                    <div>
                        {record.trainers?.length > 0
                            ? record.trainers?.map((trainer, index) => (
                                  <>{trainer.name + ', '}</>
                              ))
                            : '-'}
                    </div>
                ),
            },
            {
                title: window.__lang('group'),
                // dataIndex: 'group',
                align: 'center',
                width: '200px',
                render: (_, record) => (
                    <div>
                        {record.groups?.length > 0
                            ? record.groups?.map((group, index) => (
                                  <>{group.userGroup + ', '}</>
                              ))
                            : '-'}
                    </div>
                ),
            },
            {
                title: window.__lang('mark'),
                align: 'center',
                render: (_, record) => {
                    return record.score ? record.score : '-';
                },
            },
            {
                title: window.__lang('status'),
                align: 'center',
                render: (_, record) => {
                    return (
                        <Tag
                            className={`tag status ${
                                record.status
                                    ? 'enable-status'
                                    : 'disable-status'
                            }`}
                        >
                            {record.status ? 'Marked' : 'Unmarked'}
                        </Tag>
                    );
                },
            },
            {
                title: window.__lang('submitted_date'),
                align: 'center',
                dataIndex: "created"
            },
            {
                title: window.__lang('actions'),
                align: 'center',
                render: (_, record, index) => (
                    <div className="actions-div">
                        <Tag
                            onClick={() => viewContent(record)}
                            className="icons-tag tag"
                        >
                            <img className="image-icon" src={viewIcon} />
                        </Tag>
                        <Tag
                            // onClick={() => exportToPdf(record)}
                            onClick={() => {
                                setPdfContent(record);
                                // exportToPdf(record, `pdf-div-${index}`);
                            }}
                            className="tag icons-tag"
                        >
                            <img className="image-icon" src={fileIcon} />
                        </Tag>
                    </div>
                ),
            },
        ],
        trainer: [
            {
                title: window.__lang('module'),
                dataIndex: 'module',
                align: 'center',
            },
            {
                title: window.__lang('learner_name'),
                // dataIndex: 'learnerName',
                align: 'center',
                render: (_, record) => record.user?.name,
            },
            {
                title: window.__lang('activity'),
                dataIndex: 'activity',
                align: 'center',
            },
            {
                title: window.__lang('content_type'),
                dataIndex: 'type',
                align: 'center',
            },
            {
                title: window.__lang('group'),
                // dataIndex: 'group',
                align: 'center',
                width: '200px',
                render: (_, record) => (
                    <div>
                        {record.groups?.length > 0
                            ? record.groups?.map((group, index) => (
                                  <>{group.userGroup}</>
                              ))
                            : '-'}
                    </div>
                ),
            },
            {
                title: window.__lang('mark'),
                align: 'center',
                render: (_, record) => {
                    return record.score ? record.score : '-';
                },
            },
            {
                title: window.__lang('status'),
                align: 'center',
                render: (_, record) => {
                    return (
                        <Tag
                            className={`tag status ${
                                record.status
                                    ? 'enable-status'
                                    : 'disable-status'
                            }`}
                        >
                            {record.status ? 'Marked' : 'Unmarked'}
                        </Tag>
                    );
                },
            },
            {
                title: window.__lang('submitted_date'),
                align: 'center',
                dataIndex: "created"
            },
            {
                title: window.__lang('actions'),
                align: 'center',
                render: (_, record, index) => (
                    <div className="actions-div">
                        <Tag
                            onClick={() => viewContent(record)}
                            className="icons-tag tag"
                        >
                            <img className="image-icon" src={viewIcon} />
                        </Tag>

                        <Tag
                            onClick={() => {
                                setPdfContent(record);
                                // exportToPdf(record, `pdf-div-${index}`);
                            }}
                            className="tag icons-tag"
                        >
                            <img className="image-icon" src={fileIcon} />
                        </Tag>
                        {/* <div
                            style={{
                                display: 'none',
                                position: 'fixed',
                                width: '90%',
                            }}
                            id={`pdf-div-${index}`}
                            ref={pdfRef}
                        >
                            <div style={{ display: 'block' }}>
                                <PdfContentPopUp
                                    selectedContent={record}
                                    user={user}
                                />
                            </div>
                        </div> */}
                    </div>
                ),
            },
        ],
    };
    const columns = savedContentColuns[user.role];

    return (
        content?.length > 0 && (
            <div className="content-table-container">
                <Table
                    className="content-table"
                    columns={columns}
                    dataSource={content}
                    loading={loading}
                    pagination={false}
                />
                <TableFooter
                    onPageChange={onPageChange}
                    pageSize={pageSize}
                    meta={metaData}
                    showPageSize
                />
                <div
                    style={{
                        // display: 'none',
                        position: 'fixed',
                        width: '90%',
                        padding: '20px',
                        // position: 'absolute',
                        left: '5000px',
                    }}
                    id="pdf-div"
                    ref={pdfRef}
                >
                    <div
                        style={{
                            display: 'block',
                        }}
                    >
                        <PdfContentPopUp selectedContent={pdfContent} user={user}/>
                    </div>
                </div>
            </div>
        )
    );
};

export default ContetnTable;
