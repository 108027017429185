const userGroupConstants = {
    FETCH_USER_GROUPS_REQUEST: 'FETCH_USER_GROUPS_REQUEST',
    FETCH_USER_GROUPS_SUCCESS: 'FETCH_USER_GROUPS_SUCCESS',
    FETCH_USER_GROUPS_REJECTED: 'FETCH_USER_GROUPS_REJECTED',
    SAVE_USER_GROUP_REQUEST: 'SAVE_USER_GROUP_REQUEST',
    SAVE_USER_GROUP_SUCCESS: 'SAVE_USER_GROUP_SUCCESS',
    SAVE_USER_GROUP_REJECTED: 'SAVE_USER_GROUP_REJECTED',
    SEARCH_USER_GROUPS_REQUEST: 'SEARCH_USER_GROUPS_REQUEST',
    SEARCH_USER_GROUPS_SUCCESS: 'SEARCH_USER_GROUPS_SUCCESS',
    SEARCH_USER_GROUPS_REJECTED: 'SEARCH_USER_GROUPS_REJECTED',
    GET_USER_GROUP_REQUEST: 'GET_USER_GROUP_REQUEST',
    GET_USER_GROUP_SUCCESS: 'GET_USER_GROUP_SUCCESS',
    GET_USER_GROUP_REJECTED: 'GET_USER_GROUP_REJECTED',
    Add_TRAINERS_TO_USERS_GROUPs_SUCCESS:
        'Add_TRAINERS_TO_USERS_GROUPS_SUCCESS',
    Add_TRAINERS_TO_USERS_GROUPS: 'Add_TRAINERS_TO_USERS_GROUPS',
    Add_LEARNERS_TO_USERS_GROUPS: 'Add_LEARNERS_TO_USERS_GROUPS',
    Add_LEARNERS_TO_USERS_GROUPS_SUCCESS:
        'Add_LEARNERS_TO_USERS_GROUPS_SUCCESS',
    Add_HR_MANAGER_TO_GROUPS: 'Add_HR_MANAGER_TO_GROUPS',
    Add_HR_MANAGER_TO_GROUPS_SUCCESS: 'Add_HR_MANAGER_TO_GROUPS_SUCCESS',
    FETCH_USERS_GROUPs_TRAINERS_SUCCESS: 'FETCH_USERS_GROUPs_TRAINERS_SUCCESS',
    FETCH_USERS_GROUPs_TRAINERS: 'FETCH_USERS_GROUPs_TRAINERS',
    FETCH_USERS_GROUPs_LEARNERS_SUCCESS: 'FETCH_USERS_GROUPs_LEARNERS_SUCCESS',
    FETCH_USERS_GROUPs_LEARNERS: 'FETCH_USERS_GROUPs_LEARNERS',
    FETCH_USERS_GROUPs_HR_MANAGERS_SUCCESS:
        'FETCH_USERS_GROUPs_HR_MANAGERS_SUCCESS',
    FETCH_USERS_GROUPs_HR_MANAGERS: 'FETCH_USERS_GROUPs_HR_MANAGERS',
    Remove_TRAINERS_FROM_USERS_GROUPs_SUCCESS:
        'Remove_TRAINERS_FROM_USERS_GROUPs_SUCCESS',
    Remove_HR_FROM_USERS_GROUPs_SUCCESS: 'Remove_HR_FROM_USERS_GROUPs_SUCCESS',
    Remove_LEARNERS_FROM_USERS_GROUPs_SUCCESS:
        'Remove_LEARNERS_FROM_USERS_GROUPs_SUCCESS',
    REMOVE_ALL_LEARNERS_FROM_USERS_GROUP_REQUEST:
        'REMOVE_ALL_LEARNERS_FROM_USERS_GROUP_REQUEST',
    REMOVE_ALL_LEARNERS_FROM_USERS_GROUP_SUCCESS:
        'REMOVE_ALL_LEARNERS_FROM_USERS_GROUP_SUCCESS',
    REMOVE_ALL_LEARNERS_FROM_USERS_GROUP_REJECTED:
        'REMOVE_ALL_LEARNERS_FROM_USERS_GROUP_REJECTED',
    REMOVE_ALL_TRAINERS_FROM_USERS_GROUP_REQUEST:
        'REMOVE_ALL_TRAINERS_FROM_USERS_GROUP_REQUEST',
    REMOVE_ALL_TRAINERS_FROM_USERS_GROUP_SUCCESS:
        'REMOVE_ALL_TRAINERS_FROM_USERS_GROUP_SUCCESS',
    REMOVE_ALL_TRAINERS_FROM_USERS_GROUP_REJECTED:
        'REMOVE_ALL_LEARNERS_FROM_USERS_GROUP_REJECTED',
};

export default userGroupConstants;
