/* eslint-disable import/prefer-default-export */
const learnerDashboard = {
    FETCH_PATHWAYS_REQUEST: 'FETCH_PATHWAYS_REQUEST',
    FETCH_PATHWAYS_SUCCESS: 'FETCH_PATHWAYS_SUCCESS',
    FETCH_PATHWAYS_FAIL: 'FETCH_PATHWAYS_FAIL',
    FETCH_MODULES_REQUEST: 'FETCH_MODULES_REQUEST',
    FETCH_MODULES_SUCCESS: 'FETCH_MODULES_SUCCESS',
    FETCH_MODULES_FAIL: 'FETCH_MODULES_FAIL',
    FETCH_Library_REQUEST: 'FETCH_Library_REQUEST',
    FETCH_Library_SUCCESS: 'FETCH_Library_SUCCESS',
    FETCH_Library_FAIL: 'FETCH_Library_FAIL',
    FETCH_COURSES_REQUEST: 'FETCH_COURSES_REQUEST',
    FETCH_COURSES_SUCCESS: 'FETCH_COURSES_SUCCESS',
    FETCH_COURSES_FAIL: 'FETCH_COURSES_FAIL',
    ClEAR_PATHWAYS_REQUEST: 'ClEAR_PATHWAYS_REQUEST',
    CLEAR_MODULES: 'CLEAR_MODULES',
};

export default learnerDashboard;
