import React from 'react';
import { Card, Col, Row, Typography } from 'antd';
import Detailes from './Detailes/Detailes';
import LearnerAnswersContainer from './LearnerAnswersContainer';
import TrainerAnswersContainer from './TrainerAnswersContainer';
import './content-popup.scss';
const PdfContentPopUp = ({ selectedContent, user }) => {
    return (
        <Row className="content-popup">
            <Col span={24} className="feedback">
                <Typography>
                    {/* style={{ fontSize: '12px', fontWeight: '600' }} */}
                    {window.__lang('Feedback')}
                </Typography>
            </Col>
            <Col span={24}>
                <Card>
                    <Detailes user={user} selectedContent={selectedContent} />
                    {/* {user.role === 'learner' ? ( */}
                    <LearnerAnswersContainer
                        selectedContent={selectedContent}
                    />
                    {/* ) : (
                        <TrainerAnswersContainer
                            cancel={cancel}
                            selectedContent={selectedContent}
                        />
                    )} */}
                </Card>
            </Col>
        </Row>
    );
};

export default PdfContentPopUp;
