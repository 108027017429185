import { axiosDefaultClient } from '../../settings/axios.settings';
import { learningFocusConstants } from '../constants/learningFocus.constants';
import { notification } from 'antd';

const client = axiosDefaultClient;

function fetchLearningFocus(siteId,forSite, page= 1, pageSize= 50, langID, sort) {
    return function(dispatch) {
        dispatch({ type: learningFocusConstants.FETCH_LEARNING_FOCUS_REQUEST });
        let url = `/api/learning-focuses?page=${page?.toString()}&per_page=${pageSize}`;
        if (langID) url += `&langID=${langID}`
        if (sort) url += `&sort=${sort}`
        if (siteId) url = url + `&siteID=${siteId}`;
        if (forSite) url = url + `&forSite=${forSite}`;

        client
            .get(url)
            .then(response => {
                dispatch({
                    type: learningFocusConstants.FETCH_LEARNING_FOCUS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: learningFocusConstants.FETCH_LEARNING_FOCUS_REJECTED,
                    payload: error,
                });
            });
    };
}
const fetchLearningFocuse = id => {
    return function(dispatch) {
        dispatch({ type: learningFocusConstants.FETCH_FOCUS_REQUEST });
        client
            .get(`/api/learning-focuses/${id}`)
            .then(response => {
                dispatch({
                    type: learningFocusConstants.FETCH_FOCUS_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch(error => {
                dispatch({
                    type: learningFocusConstants.FETCH_FOCUS_REJECTED,
                    payload: error,
                });
            });
    };
};
const saveLearningFouces = (data, history) => {
    return function(dispatch) {
        dispatch({
            type: learningFocusConstants.UPDATE_LEARNING_FOCUS_REQUEST,
        });
        client
            .post('/api/learning-focuses', data)
            .then(response => {
                dispatch({
                    type: learningFocusConstants.UPDATE_LEARNING_FOCUS_SUCCESS,
                    payload: response.data,
                });

                notification.success({
                    message: 'Success',
                });
                history.push('/admin/learning-focuses');
            })
            .catch(error => {
                dispatch({
                    type: learningFocusConstants.UPDATE_LEARNING_FOCUS_REJECTED,
                    payload: error,
                });
                notification.error({
                    message: 'Error!',
                    description: error?.data?.message,
                });
            });
    };
};
const updateLearningFouces = (id, data, history) => {
    return function(dispatch) {
        dispatch({
            type: learningFocusConstants.UPDATE_LEARNING_FOCUS_REQUEST,
        });
        client
            .put(`/api/learning-focuses/${id}`, data)
            .then(response => {
                dispatch({
                    type: learningFocusConstants.UPDATE_LEARNING_FOCUS_SUCCESS,
                    payload: response.data,
                });

                notification.success({
                    message: 'Success',
                });
                history.push(`/admin/learning-focuses`);
            })
            .catch(error => {
                dispatch({
                    type: learningFocusConstants.UPDATE_LEARNING_FOCUS_REJECTED,
                    payload: error,
                });
                notification.error({
                    message: 'Error!',
                    description: error?.data?.message,
                });
            });
    };
};
export const learningFocusActions = {
    fetchLearningFocus,
    saveLearningFouces,
    fetchLearningFocuse,
    updateLearningFouces,
};
