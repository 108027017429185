import React from 'react';
import { Form, Input, Switch } from 'antd';

const CustomersForm = ({ form, editedCustomer }) => {
    return (
        <Form form={form}>
            <Form.Item required label="Name" name="name">
                <Input />
            </Form.Item>
            <Form.Item required label="Path" name="path">
                <Input />
            </Form.Item>
            <Form.Item valuePropName="checked" label="Status" name="status">
                <Switch />
            </Form.Item>
        </Form>
    );
};

export default CustomersForm;
