import formatConstants from '../constants/formats.constant';

const initialState = {
    loading: false,
    csv_options:[]
};
/**
 * redux gate
 * @param state
 * @param action
 * @returns {{csv_options: [], loading: boolean}|*}
 * @author Amr
 */
const formats = (state = initialState, action) => {
    switch (action.type) {
        case formatConstants.FETCH_CSV_REQUEST:
            return {}
        case formatConstants.FETCH_CSV_OPTIONS:
            return  action.payload;
        default:
            return state;
    }
};

export default formats;
