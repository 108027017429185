import React from 'react';
import {LockOutlined, MailOutlined, UserOutlined} from '@ant-design/icons';
import {Col, Input, Row, Form, Button} from 'antd';
import {useMediaQuery} from 'react-responsive';
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router';

import './ProfileForm.scss';
import {userActions} from "../redux/actions/user.actions";
import { retrieveDecryptedUserFromLocalStorage } from '../utils/common';

const ProfileForm = props => {
    const isMobile = useMediaQuery({maxWidth: 767});
    const dispatch = useDispatch()
    const history = useHistory()

    const { updateLoading } = useSelector(state => state.users)

    const user = retrieveDecryptedUserFromLocalStorage();
    const {
        showUsernameField,
        showEmailField,
        showPasswordField,
        helpContent,
        canChangeName,
        canChangeUsername,
        canChangeEmail,
        canChangePassword,
        firstLogin,
    } = props;

    const [form] = Form.useForm()

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 15},
    };

    const existingPassword = (
        <Form.Item
            name="current_password"
            label="Current Password"
            rules={[
                {
                    required: true,
                    message: 'Please input your current password',
                },
            ]}
        >
            {!isMobile ? (
                <Input
                    placeholder="Please input your current password"
                    prefix={
                        <LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>
                    }
                    type="password"
                />
            ) : (
                <>
                    <Input
                        prefix={
                            <LockOutlined
                                style={{
                                    color: 'rgba(0,0,0,.25)',
                                }}
                            />
                        }
                        type="password"
                    />
                    <p className="help-text">
                        Please input your current password
                    </p>
                </>
            )}
        </Form.Item>
    );

    const submitForm = (value) => {
        let formData = new FormData();
        for (const property in value) {
            let val = value[property]
            if (val) {
                formData.append(property, val);
            }
        }

        dispatch(userActions.updateTrainerProfile(formData, history, user.userID))

    };

    return (
        <div>
            <Form
                name="basic"
                form={form}
                initialValues={{
                    showUsernameField,
                    showEmailField,
                    showPasswordField,
                }}
                {...layout}
                onFinish={submitForm}
                className="profileForm"
            >
                <Row gutter={16} justify="center">
                    <Col lg={14} md={16} sm={18}>
                        {canChangeName && (
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your full name',
                                    },
                                ]}
                                initialValue={user.name}
                            >
                                {!isMobile ? (
                                    <Input
                                        prefix={
                                            <UserOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.25)',
                                                }}
                                            />
                                        }
                                        placeholder="Please input your full name"
                                        autoComplete="new-name"
                                        disabled
                                    />
                                ) : (
                                    <>
                                        <Input
                                            prefix={
                                                <UserOutlined
                                                    style={{
                                                        color:
                                                            'rgba(0,0,0,.25)',
                                                    }}
                                                />
                                            }
                                            autoComplete="new-name"
                                        />
                                        <p className="help-text">
                                            Please input your full name
                                        </p>
                                    </>
                                )}
                            </Form.Item>
                        )}

                        {(canChangeUsername || showUsernameField) && (
                            <Form.Item
                                name="username"
                                label="Username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username',
                                    },
                                ]}
                                initialValue={user.username}
                            >
                                {!isMobile ? (
                                    <Input
                                        prefix={
                                            <UserOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.25)',
                                                }}
                                            />
                                        }
                                        placeholder="Please input your username"
                                        autoComplete="user-name"
                                    />
                                ) : (
                                    <>
                                        <Input
                                            prefix={
                                                <UserOutlined
                                                    style={{
                                                        color:
                                                            'rgba(0,0,0,.25)',
                                                    }}
                                                />
                                            }
                                            autoComplete="user-name"
                                        />
                                        <p className="help-text">
                                            Please input your username
                                        </p>
                                    </>
                                )}
                            </Form.Item>
                        )}

                        {canChangeEmail || showEmailField ? (
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email',
                                    },
                                ]}
                                initialValue={user.email}
                            >
                                {!isMobile ? (
                                    <Input
                                        prefix={
                                            <MailOutlined
                                                style={{
                                                    color: 'rgba(0,0,0,.25)',
                                                }}
                                            />
                                        }
                                        type="email"
                                        placeholder="Please input your email"
                                        autoComplete="new-email"
                                    />
                                ) : (
                                    <>
                                        <Input
                                            prefix={
                                                <MailOutlined
                                                    style={{
                                                        color:
                                                            'rgba(0,0,0,.25)',
                                                    }}
                                                />
                                            }
                                            type="email"
                                            autoComplete="new-email"
                                        />
                                        <p className="help-text">
                                            Please input your email
                                        </p>
                                    </>
                                )}
                            </Form.Item>
                        ) : ''}
                        {canChangePassword || showPasswordField ? (
                            <>
                                <>{!firstLogin && existingPassword}</>

                                <Form.Item
                                    name="new_password"
                                    label="New Password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your new password',
                                        },
                                    ]}
                                >
                                    {!isMobile ? (
                                        <Input
                                            placeholder="Please input your new password"
                                            prefix={
                                                <LockOutlined
                                                    style={{
                                                        color:
                                                            'rgba(0,0,0,.25)',
                                                    }}
                                                />
                                            }
                                            type="password"
                                            autoComplete="new-password"
                                        />
                                    ) : (
                                        <>
                                            <Input
                                                prefix={
                                                    <LockOutlined
                                                        style={{
                                                            color:
                                                                'rgba(0,0,0,.25)',
                                                        }}
                                                    />
                                                }
                                                type="password"
                                                autoComplete="new-password"
                                            />
                                            <p className="help-text">
                                                Please input your new password
                                            </p>
                                        </>
                                    )}
                                </Form.Item>
                            </>
                        ) : ''}

                        {canChangePassword || showPasswordField ? (
                            <Form.Item
                                name="confirm_new_password"
                                label="Retype Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password again',
                                    },
                                ]}
                            >
                                {!isMobile ? (
                                    <Input
                                        placeholder="Please input your password again"
                                        prefix={
                                            <LockOutlined
                                                style={{color: 'rgba(0,0,0,.25)'}}
                                            />
                                        }
                                        type="Password"
                                        autoComplete="new-password"
                                    />
                                ) : (
                                    <>
                                        <Input
                                            prefix={
                                                <LockOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.25)',
                                                    }}
                                                />
                                            }
                                            type="password"
                                            autoComplete="new-password"
                                        />
                                        <p className="help-text">
                                            Please input your password again
                                        </p>
                                    </>
                                )}
                            </Form.Item>) : ''}
                    </Col>

                    <Col
                        lg={10}
                        md={8}
                        sm={6}
                        style={{
                            borderLeft: isMobile ? '' : '1px solid #c1c1c1',
                        }}
                    >
                        <div style={{padding: 20}}>{helpContent}</div>
                    </Col>
                </Row>
                <Row align="end">
                    <Form.Item>
                        <Button type="primary" htmlType='submit'>
                            Save
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    );
};

ProfileForm.defaultProps = {
    firstLogin: true,
};

export default ProfileForm;
