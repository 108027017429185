import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PreferenceSettings.scss';
import useLearningLevels from '../../redux/Hooks/useLearningLevels';

const LearningLevelsPreference = ({ selectedLevelID, setSelectedLevelID }) => {
    const learningLevels = useLearningLevels();

    const getItemColour = index => {
        const colors = {
            1: '#5bc489',
            2: '#3fb78d',
            3: '#23aa8f',
            4: '#009c8f',
            5: '#008d8c',
        };
        const { length } = learningLevels;
        return colors[index];
        // return `rgb(13, 161, 13, ${(0.1 * length + index) / length})`;
    };

    return (
        <div>
            <p className="languageLevelTitle">What is your level of English?</p>
            <Row gutter={[16, 16]} justify="center" align="center">
                {learningLevels.map((level, i) => {
                    return level.learningLevelID !== 1 ? (
                        <Col sm={8} lg={4}>
                            <div
                                className="selectionItems"
                                style={{
                                    backgroundColor:
                                        level.learningLevelID ===
                                        selectedLevelID
                                            ? '#fcf9d4'
                                            : getItemColour(i),
                                }}
                                key={level.learningLevelID}
                                onClick={() =>
                                    setSelectedLevelID(
                                        selectedLevelID !==
                                            level.learningLevelID
                                            ? level.learningLevelID
                                            : 0,
                                    )
                                }
                                onKeyDown={setSelectedLevelID}
                            >
                                <div>
                                    <FontAwesomeIcon
                                        icon={
                                            // eslint-disable-next-line no-nested-ternary
                                            level.learningLevelID === 1 ||
                                            level.learningLevelID === 2
                                                ? ['fad', 'signal-alt-1']
                                                : level.learningLevelID === 3 ||
                                                  level.learningLevelID === 4
                                                ? ['fad', 'signal-alt-2']
                                                : ['fad', 'signal-alt-3']
                                        }
                                        style={{
                                            color:
                                                level.learningLevelID ===
                                                selectedLevelID
                                                    ? '#22ad00'
                                                    : '#F5F5F5',
                                        }}
                                    />
                                    <span
                                        style={{
                                            marginLeft: 5,
                                            color:
                                                level.learningLevelID ===
                                                selectedLevelID
                                                    ? '#22ad00'
                                                    : '#F5F5F5',
                                        }}
                                    >
                                        {level.learningLevel.split(' - ')[0]}
                                    </span>

                                    <p
                                        style={{
                                            color:
                                                level.learningLevelID ===
                                                selectedLevelID
                                                    ? '#22ad00'
                                                    : '#ffffff',
                                        }}
                                    >
                                        {level.learningLevel.split(' - ')[1]}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    ) : (
                        ''
                    );
                })}
            </Row>
        </div>
    );
};
LearningLevelsPreference.propTypes = {
    selectedLevelID: PropTypes.number,
    setSelectedLevelID: PropTypes.object,
};
export default LearningLevelsPreference;
