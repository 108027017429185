const courseBundleConstants = {
    FETCH_COURSE_BUNDLES_REQUEST: 'FETCH_COURSE_BUNDLES_REQUEST',
    FETCH_COURSE_BUNDLES_SUCCESS: 'FETCH_COURSE_BUNDLES_SUCCESS',
    FETCH_COURSE_BUNDLES_REJECTED: 'FETCH_COURSE_BUNDLES_REJECTED',
    SAVE_COURSE_BUNDLE_REQUEST: 'SAVE_COURSE_BUNDLE_REQUEST',
    SAVE_COURSE_BUNDLE_SUCCESS: 'SAVE_COURSE_BUNDLE_SUCCESS',
    SAVE_COURSE_BUNDLE_REJECTED: 'SAVE_COURSE_BUNDLE_REJECTED',
    SEARCH_COURSE_BUNDLES_REQUEST: 'SEARCH_COURSE_BUNDLES_REQUEST',
    SEARCH_COURSE_BUNDLES_SUCCESS: 'SEARCH_COURSE_BUNDLES_SUCCESS',
    SEARCH_COURSE_BUNDLES_REJECTED: 'SEARCH_COURSE_BUNDLES_REJECTED',
    FETCH_COURSE_BUNDLE_REQUEST: 'FETCH_COURSE_BUNDLE_REQUEST',
    FETCH_COURSE_BUNDLE_SUCCESS: 'FETCH_COURSE_BUNDLE_SUCCESS',
    FETCH_COURSE_BUNDLE_REJECTED: 'FETCH_COURSE_BUNDLE_REJECTED',
    FETCH_BUNDLES_REQUEST: 'FETCH_BUNDLES_REQUEST',
    FETCH_BUNDLES_SUCCESS: 'FETCH_BUNDLES_SUCCESS',
    FETCH_BUNDLES_REJECTED: 'FETCH_BUNDLES_REJECTED',
    SET_STATE_BUNDLES_REQUEST: 'SET_STATE_BUNDLES_REQUEST',
    SET_STATE_BUNDLES_SUCCESS: 'SET_STATE_BUNDLES_SUCCESS',
    SET_STATE_BUNDLES_REJECTED: 'SET_STATE_BUNDLES_REJECTED',
};

export default courseBundleConstants;
