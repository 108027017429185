const activityMarkingConstants = {
    ACTIVITY_QUESTION_ANSWER_GIVEN: 'ACTIVITY_QUESTION_ANSWERED',
    ACTIVITY_QUESTION_MULTIPLE_ANSWER_GIVEN:
        'ACTIVITY_QUESTION_MULTIPLE_ANSWER_GIVEN',
    ACTIVITY_QUESTION_MULTIPLE_ANSWER_REMOVED:
        'ACTIVITY_QUESTION_MULTIPLE_ANSWER_REMOVED',
    ACTIVITY_QUESTION_MIXED_SENTENCE_ANSWER_GIVEN:
        'ACTIVITY_QUESTION_MIXED_SENTENCE_ANSWER_GIVEN',
    ACTIVITY_QUESTION_WHAT_IS_THIS_ANSWER_GIVEN:
        'ACTIVITY_QUESTION_WHAT_IS_THIS_ANSWER_GIVEN',
    ACTIVITY_QUESTION_REORDER: 'ACTIVITY_QUESTION_REORDER',
    ACTIVITY_QUESTION_PAIRED_ANSWER_GIVEN: 'ACTIVITY_QUESTION_PAIRED_ANSWERED',
    ACTIVITY_BLANK_ANSWER_GIVEN: 'ACTIVITY_BLANK_ANSWERED',
    ACTIVITY_CHECK_ANSWERS: 'ACTIVITY_CHECK_ANSWERS',
    ACTIVITY_ANSWERS_CHECKED: 'ACTIVITY_ANSWERS_CHECKED',
    ACTIVITY_ANSWERS_SHOW_CORRECT: 'ACTIVITY_ANSWERS_SHOW_CORRECT',
    ACTIVITY_SHOW_ANSWERS: 'ACTIVITY_SHOW_ANSWERS',
    ACTIVITY_ANSWERS_SHOWN: 'ACTIVITY_ANSWERS_SHOWN',
    ACTIVITY_TRY_AGAIN: 'ACTIVITY_TRY_AGAIN',
    ACTIVITY_QUESTION_COUNT: 'ACTIVITY_QUESTION_COUNT',
    CLEAR_CURRENT_ACTIVITY: 'CLEAR_CURRENT_ACTIVITY',
};

export default activityMarkingConstants;
