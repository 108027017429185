import { activityBuilderConstants } from '../constants/activityBuilder.constants';

const initialState = {
    activityName: 'Activity Name',
    activity: {},
    activityActionType: 'add',
    saveActivityRequestLoading: false,
    validationErrors: [],
};

export function activityBuilder(state = initialState, action) {
    switch (action.type) {
        case activityBuilderConstants.ACTIVITY_NAME_CHANGED:
            return {
                ...state,
                activityName: action.value,
            };
        case activityBuilderConstants.ACTIVITY_BUILDER_ACTIVITY_SUBMITTED:
            return {
                ...state,
                saveActivityRequestLoading: true,
                validationErrors: [],
            };
        case activityBuilderConstants.ACTIVITY_BUILDER_ACTIVITY_SAVE_SUCCESS:
            return {
                ...state,
                saveActivityRequestLoading: false,
                validationErrors: [],
                activity: action.payload.activity,
                activityActionType: 'edit',
            };
        case activityBuilderConstants.ACTIVITY_BUILDER_ACTIVITY_SAVE_FAILURE:
            return {
                ...state,
                saveActivityRequestLoading: false,
                validationErrors: action.payload ? action.payload : [],
            };
        case activityBuilderConstants.ACTIVITY_BUILDER_TYPE_CHANGED:
            return {
                ...state,
                activityActionType: action.activityActionType,
            };
        case activityBuilderConstants.ACTIVITY_BUILDER_CLEAR_PAGE:
            const { pageNumber } = action;
            const fieldsBeforeClear = state.questions;
            fieldsBeforeClear.pages[pageNumber] = [];
            return {
                ...state,
                questions: fieldsBeforeClear,
            };
        case activityBuilderConstants.ACTIVITY_BUILDER_PAGE_ITEMS_COPIED:
            const sourcePageItems = state.questions.pages[action.currentPage];
            const targetPageItems = [
                ...state.questions.pages[action.targetPage],
                ...sourcePageItems,
            ];
            const fieldsBeforeCopy = state.questions;
            fieldsBeforeCopy.pages[action.targetPage] = targetPageItems;
            return {
                ...state,
                questions: fieldsBeforeCopy,
                questionsCurrentPage: action.targetPage,
            };
        case activityBuilderConstants.ACTIVITY_BUILDER_CANCEL_SAVE_ACTIVITY:
            return initialState;
        case activityBuilderConstants.ACTIVITY_BUILDER_ACTIVITY_LOADED:
            return {
                ...state,
                activityID: action.activity.activityID,
            };
        default:
            return state;
    }
}
