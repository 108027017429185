/* eslint-disable import/prefer-default-export */
const picturesDb = {
    FETCH_DB_PICTURES_REQUEST: 'FETCH_DB_PICTURES_REQUEST',
    FETCH_DB_PICTURES_SUCCESS: 'FETCH_DB_PICTURES_SUCCESS',
    FETCH_DB_PICTURES_FAIL: 'FETCH_DB_PICTURES_FAIL',
    DELETE_DB_PICTURES_REQUEST: 'DELETE_DB_PICTURES_REQUEST',
    DELETE_DB_PICTURES_SUCCESS: 'DELETE_DB_PICTURES_SUCCESS',
    DELETE_DB_PICTURES_FAIL: 'DELETE_DB_PICTURES_FAIL',
    UPLOAD_DB_PICTURES_REQUEST: 'UPLOAD_DB_PICTURES_REQUEST',
    UPLOAD_DB_PICTURES_SUCCESS: 'UPLOAD_DB_PICTURES_SUCCESS',
    UPLOAD_DB_PICTURES_FAIL: 'UPLOAD_DB_PICTURES_FAIL',
    SELECT_DB_PICTURES_REQUEST: 'SELECT_DB_PICTURES_REQUEST',
    SELECT_DB_PICTURES_SUCCESS: 'SELECT_DB_PICTURES_SUCCESS',
    SELECT_DB_PICTURES_FAIL: 'SELECT_DB_PICTURES_FAIL',
    MOVE_DB_PICTURES_REQUEST: 'MOVE_DB_PICTURES_REQUEST',
};

export default picturesDb;
