import axios from 'axios';
import { notification } from 'antd';
import { axiosDefaultClient } from '../../settings/axios.settings';
import learnerProfile from '../constants/learnerProfile.constants';
import { retrieveDecryptedSiteFromLocalStorage, retrieveDecryptedUserFromLocalStorage } from '../../utils/common';

const client = axiosDefaultClient;

const fetchAllCountries = () => {
    return dispatch => {
        dispatch({
            type: learnerProfile.FETCH_ALL_COUNTRIES_REQUEST,
        });
        const url = `https://restcountries.com/v2/all`;
        axios
            .get(url)
            .then(response => {
                dispatch({
                    type: learnerProfile.FETCH_ALL_COUNTRIES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.FETCH_ALL_COUNTRIES_FAIL,
                    payload: error,
                }),
            );
    };
};

const fetchAllSettings = userId => {
    return dispatch => {
        dispatch({
            type: learnerProfile.FETCH_ALL_SETTINGS_REQUEST,
        });
        const url = `/api/learner/${userId}/profile/settings`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: learnerProfile.FETCH_ALL_SETTINGS_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.FETCH_ALL_SETTINGS_FAIL,
                    payload: error,
                }),
            );
    };
};

const fetchAllAvatars = () => {
    return async dispatch => {
        await dispatch({
            type: learnerProfile.FETCH_ALL_AVATARS_REQUEST,
        });
        const url = `/api/learner/profile/get-default-images`;
        await client
            .get(url)
            .then(response => {
                dispatch({
                    type: learnerProfile.FETCH_ALL_AVATARS_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.FETCH_ALL_AVATARS_FAIL,
                    payload: error,
                }),
            );
    };
};

const fetchInterfaceLanguages = () => {
    return dispatch => {
        dispatch({
            type: learnerProfile.FETCH_INTERFACE_LANGUAGES_REQUEST,
        });
        const url = `/api/get-interface-languages`;
        client
            .get(url)
            .then(response => {
                dispatch({
                    type: learnerProfile.FETCH_INTERFACE_LANGUAGES_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.FETCH_INTERFACE_LANGUAGES_FAIL,
                    payload: error,
                }),
            );
    };
};

const fetchProfileInfo = userId => {
    return async dispatch => {
        await dispatch({
            type: learnerProfile.FETCH_PROFILE_INFO_REQUEST,
        });
        const url = `/api/learner/${userId}/profile/get-user-info`;
        await client
            .get(url)
            .then(response => {
                dispatch({
                    type: learnerProfile.FETCH_PROFILE_INFO_SUCCESS,
                    payload: response.data.payload,
                });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.FETCH_PROFILE_INFO_FAIL,
                    payload: error,
                }),
            );
    };
};

const updateProfileInfo = (userId, data) => {
    return async dispatch => {
        await dispatch({
            type: learnerProfile.UPDATE_PROFILE_INFO_REQUEST,
        });
        const url = `/api/learner/${userId}/profile/update`;
        await client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: learnerProfile.UPDATE_PROFILE_INFO_SUCCESS,
                    payload: response.data,
                });
                dispatch(fetchProfileInfo(userId));
                notification.success({
                    message: 'Profile Updated',
                    description:
                        'Your Profile Information Is Updated Successfully ..!',
                    placement: 'topRight',
                    duration: 2,
                });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.UPDATE_PROFILE_INFO_FAIL,
                    payload: error,
                }),
            );
    };
};

const updateLanguageInterface = (
    userId,
    data,
    callback,
    isNotification = true,
    selectedLanguage,
) => {
    return dispatch => {
        dispatch({
            type: learnerProfile.UPDATE_SETTINGS_LANGUAGE_REQUEST,
        });
        const url = `/api/learner/${userId}/profile/update-lang`;
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: learnerProfile.UPDATE_SETTINGS_LANGUAGE_SUCCESS,
                    payload: response.data,
                });
                // localStorage.setItem(
                //     'lang',
                //     JSON.stringify(selectedLanguage ?? {}),
                // );
                callback && callback();
            })
            .then(() => {
                isNotification &&
                    notification.success({
                        message: 'Language Updated',
                        description:
                            'Your Language Interface Is Updated Successfully ..!',
                        placement: 'topRight',
                        duration: 2,
                    });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.UPDATE_SETTINGS_LANGUAGE_FAIL,
                    payload: error,
                }),
            );
    };
};

const updateLearnerSkills = (userId, data) => {
    return dispatch => {
        dispatch({
            type: learnerProfile.UPDATE_SETTINGS_SKILLS_REQUEST,
        });
        const url = `/api/learner/${userId}/profile/update-skills`;
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: learnerProfile.UPDATE_SETTINGS_SKILLS_SUCCESS,
                    payload: response.data,
                });
                notification.success({
                    message: 'Skills Updated',
                    description:
                        'Your Skills Interface Is Updated Successfully ..!',
                    placement: 'topRight',
                    duration: 2,
                });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.UPDATE_SETTINGS_SKILLS_FAIL,
                    payload: error,
                }),
            );
    };
};

const updateLearnerTarget = (userId, data) => {
    return dispatch => {
        dispatch({
            type: learnerProfile.UPDATE_SETTINGS_TARGET_REQUEST,
        });
        const url = `/api/learner/${userId}/profile/update-target`;
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: learnerProfile.UPDATE_SETTINGS_TARGET_SUCCESS,
                    payload: response.data,
                });
                notification.success({
                    message: 'Target Updated',
                    description:
                        'Your Target Interface Is Updated Successfully ..!',
                    placement: 'topRight',
                    duration: 2,
                });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.UPDATE_SETTINGS_TARGET_FAIL,
                    payload: error,
                }),
            );
    };
};

const updateLearnerPassword = (userId, data, handle = null) => {
    return dispatch => {
        dispatch({
            type: learnerProfile.UPDATE_SETTINGS_PASSWORD_REQUEST,
        });

        const url = `/api/users/${userId}/change-password`;
        client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: learnerProfile.UPDATE_SETTINGS_PASSWORD_SUCCESS,
                    payload: response.data,
                });
                if (handle) handle(response);
                notification.success({
                    message: 'Password Updated',
                    description:
                        'Your Password Interface Is Updated Successfully ..!',
                    placement: 'topRight',
                    duration: 2,
                });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.UPDATE_SETTINGS_PASSWORD_FAIL,
                    payload: error.data,
                }),
            );
    };
};

const updateProfileImage = (userId, data) => {
    return async dispatch => {
        await dispatch({
            type: learnerProfile.UPDATE_PROFILE_IMAGE_REQUEST,
        });
        const url = `/api/learner/${userId}/profile/chang-image`;
        await client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: learnerProfile.UPDATE_PROFILE_IMAGE_SUCCESS,
                    payload: response.data,
                });
                dispatch(fetchProfileInfo(userId));
                notification.success({
                    message: 'Profile Picture',
                    description:
                        'Your Profile Picture Is Updated Successfully ..!',
                    placement: 'topRight',
                    duration: 2,
                });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.UPDATE_PROFILE_IMAGE_FAIL,
                    payload: error.data,
                }),
            );
    };
};
function weeklyTarget(userID) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({ type: learnerProfile.WEEKLY_TARGET });
            const url = `/api/users/weekly-target/${userID}`;
            client
                .get(url)
                .then(response => {
                    dispatch({
                        type: learnerProfile.WEEKLY_TARGET_SUCCESS,
                        payload: response.data,
                    });
                    resolve();
                })
                .catch(() => {
                    dispatch({ type: learnerProfile.WEEKLY_TARGET_REJECTED });
                    reject();
                });
        });
    };
}
function changeLanguageInterface(langCode, langId) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const url = `/api/users/get-language-interface/${langCode}`;
            client
                .get(url)
                .then(async (response) => {
                    response.data?.lang &&
                        localStorage.setItem(
                            'language',
                            JSON.stringify(response.data.lang),
                        );

                    const user = retrieveDecryptedUserFromLocalStorage();
                    if (user?.role === 'learner') {
                        let pathwaysUrl = `/api/v2/user/${user.userID}/pathways`;
                        if (langId) pathwaysUrl = pathwaysUrl + `/${langId}`;
                        const pathwaysRes = await client.get(pathwaysUrl);

                        if (
                            location.pathname === '/learner/dashboard' &&
                            pathwaysRes?.data?.payload?.pathway &&
                            pathwaysRes?.data?.payload?.pathway?.length === 0
                        ) {
                            let libraryUrl = `/api/user/${user.userID}/library`;
                            if (langId) libraryUrl = libraryUrl + `/${langId}`;
                            const libraryRes = await client.get(libraryUrl);
                            const site =
                                retrieveDecryptedSiteFromLocalStorage();
                            if (
                                site?.showLibraryTab &&
                                libraryRes?.data?.data?.length > 0
                            ) {
                                window.location.assign('/learner/library');
                            } else if (site?.showSearchTab) {
                                window.location.assign('/learner/search');
                            } else {
                                location.reload();
                            }
                        } else {
                            location.reload();
                        }
                    } else {
                        location.reload();
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    };
}
const updateWeeklyTarget = (userId, data) => {
    return async dispatch => {
        await dispatch({
            type: learnerProfile.UPDATE_WEEKLY_TARGET,
        });
        const url = `/api/users/weekly-target/${userId}`;
        await client
            .post(url, data)
            .then(response => {
                dispatch({
                    type: learnerProfile.UPDATE_WEEKLY_TARGET_SUCCESS,
                    payload: response.data,
                });
                dispatch(fetchProfileInfo(userId));
                notification.success({
                    message: 'Weekly Target',
                    description: 'Weekly Target Is Updated Successfully ..!',
                    placement: 'topRight',
                    duration: 2,
                });
            })
            .catch(error =>
                dispatch({
                    type: learnerProfile.UPDATE_WEEKLY_TARGET_FAIL,
                    payload: error.data,
                }),
            );
    };
};

const learnerProfileApis = {
    fetchAllCountries,
    updateProfileInfo,
    updateLanguageInterface,
    updateLearnerSkills,
    updateLearnerTarget,
    updateLearnerPassword,
    fetchInterfaceLanguages,
    fetchAllSettings,
    fetchAllAvatars,
    updateProfileImage,
    fetchProfileInfo,
    updateWeeklyTarget,
    weeklyTarget,
    changeLanguageInterface,
};

export default learnerProfileApis;
