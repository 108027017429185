import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Drawer, Row } from 'antd';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';

import { SiteLayoutStyles } from '../../styles/SiteLayout.style';
import HeaderProfileMenu from './HeaderProfileMenu';
import HeaderNotificationMenu from './HeaderNotificationMenu';
import HeaderMySchoolMenu from './HeaderMySchoolMenu';
import NotificationsBell from './NotificationsBell';
import './Header.scss';
import { retrieveDecryptedUserFromLocalStorage, retrieveDecryptedSiteFromLocalStorage } from '../../utils/common';
import SsoButton from '../SsoButton';

const styles = SiteLayoutStyles;

const HeaderMenuMobile = props => {
    const { user, notifications, unreadCount } = props;
    const [profileIsVisible, setProfileIsVisible] = useState(false);
    const [notificationsIsVisible, setNotificationsIsVisible] = useState(false);
    const [logoFromParentUserGroup, setLogoFromParentUserGroup] = useState(null)
    const [mySchoolIsVisible, setMySchoolIsVisible] = useState(false);
    const loggedUser = retrieveDecryptedUserFromLocalStorage()
    const site = retrieveDecryptedSiteFromLocalStorage()
    // const logo =loggedUser ?`${loggedUser.site_logo}` :JSON.parse(localStorage.getItem('logo'))
    useEffect(() => {
        const loggedUser = retrieveDecryptedUserFromLocalStorage()
        const site = retrieveDecryptedSiteFromLocalStorage()
        if (loggedUser.role === 'trainer') {
            if (loggedUser?.trainer_groups.length > 0) {
                let logos = [];
                for (let userGroup of loggedUser.trainer_groups) {
                    if (userGroup.displayLogoForTrainers === 1) {
                        if (userGroup.logo_s3) {
                            logos.push(userGroup.logo_s3);
                        } else if (userGroup.parent_id) {
                            const parent = loggedUser?.trainer_groups.filter(
                                (obj) =>
                                    obj.userGroupID === userGroup.parent_id,
                            );
                            if (parent.logo) logos.push(parent.logo);
                        }
                    }
                }
                setLogoFromParentUserGroup([...new Set(logos)]);
            } else {
                setLogoFromParentUserGroup([site.largeLogo_s3]);
            }
        }
    }, []);

    return (
        <div className="headerMenuMobile">
            <Row>
                <Col
                    span={10}
                    style={{
                        display: 'flex',
                    }}
                >
                    {loggedUser.role === 'trainer' ? (
                        logoFromParentUserGroup?.length > 0 &&
                        logoFromParentUserGroup?.map((logo, i) => (
                            <Link
                                to="/"
                                style={{
                                    alignItems: 'center',
                                    marginRight: 20,
                                }}
                                key={i}
                            >
                                <img
                                    className="logoImage"
                                    src={logo}
                                    alt="logo-image"
                                />
                            </Link>
                        ))
                    ) : (
                        <Link to="/">
                            <img
                                className="logoImage"
                                src={site.largeLogo_s3}
                                alt="logo-image"
                            />
                        </Link>
                    )}
                </Col>
                <Col flex={1} span={14}>
                    <>
                        <span
                            style={{
                                ...styles.userMenuStyles,
                                ...styles.userMenuBorder,
                            }}
                            onClick={() =>
                                setProfileIsVisible(!profileIsVisible)
                            }
                        >
                            <Avatar
                                color={Avatar.getRandomColor('sitebase', [
                                    'pink',
                                    'purple',
                                    'green',
                                    'orange',
                                ])}
                                name={user.name}
                                size={36}
                                round="50%"
                                style={{
                                    float: 'right',
                                }}
                            />
                        </span>
                        <span
                            onClick={() =>
                                setMySchoolIsVisible(!mySchoolIsVisible)
                            }
                            style={{
                                ...styles.userMenuStyles,
                                ...styles.userMenuBorder,
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fal', 'map-marker']}
                                style={{
                                    ...styles.userMenuIcon,
                                    position: 'relative',
                                    color: '#c1c1c1',
                                }}
                            />
                            <FontAwesomeIcon
                                icon={['fas', 'graduation-cap']}
                                style={{
                                    color: '#c1c1c1',
                                    margin: 8,
                                    position: 'absolute',
                                    fontSize: '10px',
                                }}
                            />
                        </span>
                        <span
                            style={{
                                ...styles.userMenuStyles,
                            }}
                            onClick={() =>
                                setNotificationsIsVisible(
                                    !notificationsIsVisible,
                                )
                            }
                        >
                            <NotificationsBell
                                notifications={notifications}
                                unreadCount={unreadCount}
                            />
                        </span>
                        <span>
                            <SsoButton></SsoButton>
                        </span>
                        <Drawer
                            className="headerMenuDrawer"
                            placement="right"
                            closable
                            onClose={() => {
                                setMySchoolIsVisible(false);
                                setNotificationsIsVisible(false);
                                setProfileIsVisible(false);
                            }}
                            visible={
                                profileIsVisible ||
                                notificationsIsVisible ||
                                mySchoolIsVisible
                            }
                        >
                            {profileIsVisible && (
                                <HeaderProfileMenu user={user} history={props.history}/>
                            )}
                            {notificationsIsVisible && (
                                <HeaderNotificationMenu
                                    notifications={notifications}
                                    unreadCount={unreadCount}
                                />
                            )}
                            {mySchoolIsVisible && <HeaderMySchoolMenu />}
                        </Drawer>
                    </>
                </Col>
            </Row>
        </div>
    );
};

export default HeaderMenuMobile;
