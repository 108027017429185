export const questionnaireBuilderConstants = {
    QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_SUBMITTED: "QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_SUBMITTED",
    QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_SAVE_SUCCESS: "QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_SAVE_SUCCESS",
    QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_SAVE_FAILURE: 'QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_SAVE_FAILURE',
    QUESTIONNAIRE_BUILDER_CANCEL_SAVE_QUESTIONNAIRE: "ACTIVITY_BUILDER_CANCEL_SAVE_QUESTIONNAIRE",
    QUESTIONNAIRE_NAME_CHANGED: "QUESTIONNAIRE_NAME_CHANGED",
    QUESTIONNAIRE_BUILDER_TYPE_CHANGED: "QUESTIONNAIRE_BUILDER_TYPE_CHANGED",
    QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_LOADING: "QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_LOADING",
    QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_LOADED: "QUESTIONNAIRE_BUILDER_QUESTIONNAIRE_LOADED",
};
