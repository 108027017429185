import React from 'react';
import './SsoButton.scss';
import { retrieveDecryptedSiteFromLocalStorage } from '../../utils/common';

const SsoButton = () => {
  const site = retrieveDecryptedSiteFromLocalStorage();

  const handleClick = () => {
    const url = site?.ssoReturnUrl; 
    // Check if the URL starts with "http://" or "https://"
    const fullUrl = url.startsWith('http://') || url.startsWith('https://') 
        ? url 
        : `https://${url}`;

    // Redirect to the full URL
    window.location.href = fullUrl;
  };
  return (
    <button className="shiny-button" onClick={handleClick}>
        {site?.ssoReturnUrlText}
    </button>
  );
};

export default SsoButton;
