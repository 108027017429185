import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'antd';
import { SiteLayoutStyles } from '../../styles/SiteLayout.style';

const styles = SiteLayoutStyles;

const NotificationsBell = props => {
    const { notifications, unreadCount } = props;

    if (unreadCount > 0) {
        return (
            <Badge count={unreadCount}>
                <FontAwesomeIcon
                    icon={['fal', 'bell']}
                    style={{
                        // float: 'right',
                        marginBottom: 4,
                        fontSize: '36px',
                        color: '#c1c1c1',
                    }}
                />
            </Badge>
        );
    }

    return (
        <FontAwesomeIcon
            icon={['fal', 'bell']}
            style={{
                // float: 'right',
                marginBottom: 4,
                fontSize: '36px',
                color: '#c1c1c1',
            }}
        />
    );
};

export default NotificationsBell;
