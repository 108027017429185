const responsiveDefaults = {
    xs: {
        span: 24,
        offset: 0,
    },

    sm: {
        span: 24,
        offset: 0,
    },

    md: {
        span: 24,
        offset: 0,
    },

    lg: {
        span: 18,
        offset: 3,
    },

    xl: {
        span: 16,
        offset: 4,
    },
};

export { responsiveDefaults };
