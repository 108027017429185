import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Spin } from 'antd';
import ContetnTable from './ContetTable/ContetTable';
import ContetHeader from './ContetHeader/ContetHeader';
import NoContent from '../NoContent/NoContent';
import ContentPopUp from './ContentPopUp/ContentPopUp';
import savedContentActions from '../../../../redux/actions/saveContent.actions';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { retrieveDecryptedUserFromLocalStorage } from '../../../../utils/common';

const ContentList = () => {
    const dispatch = useDispatch();
    const metaData = useSelector(state => state.savedContent.metaData);
    const [openContent, setOpenContent] = useState();
    const [selectedContent, setSelectedContent] = useState();
    const [page, setPage] = useState(metaData.current_page || 1);
    const [pageSize, setPageSize] = useState(metaData.per_page || 15);
    const [keyword, setKeyword] = useState('');
    const [filters, setFilters] = useState({
        userID: null,
        groupID: null,
        activityID: null,
        status: null,
        type: 'all',
    });
    const content = useSelector(state => state.savedContent.content);
    const loading = useSelector(state => state.savedContent.loading);
    const user = retrieveDecryptedUserFromLocalStorage();
    // useEffect(() => {
    //     getContent();
    // }, [page, pageSize, filters]);

    const getContent = () => {
        dispatch(
            savedContentActions.getSaveContent(
                user.userID,
                page,
                pageSize,
                user?.role,
                filters,
                keyword,
            ),
        );
    };
    // const onKeyWordChange = debounce(getContent, 500, true);
    const onKeyWordChange = debounce(word => setKeyword(word), 500, true);

    useEffect(() => {
        // onKeyWordChange();
        getContent();
    }, [keyword, page, pageSize, filters]);

    const cancel = () => {
        setOpenContent(false);
        setSelectedContent(null);
    };

    return (
        // <Card style={user.role == 'learner' ? { width: 'max-content' } : {}}>
        <Card>
            {loading ? <Spin /> : (
            <Row>
                <Col span={24}>
                    <ContetHeader
                        setKeyword={setKeyword}
                        filters={filters}
                        setFilters={setFilters}
                        onKeyWordChange={onKeyWordChange}
                    />
                </Col>
                {content.length > 0 ? (
                    <Col span={24}>
                        <ContetnTable
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            content={content}
                            setOpenContent={setOpenContent}
                            setSelectedContent={setSelectedContent}
                        />
                    </Col>
                ) : (
                    <Col span={24}>
                        <NoContent />
                    </Col>
                )}
                </Row>
            )}
            <Modal
                width={'90%'}
                onCancel={cancel}
                visible={openContent}
                footer={null}
                destroyOnClose
            >
                <ContentPopUp
                    cancel={cancel}
                    selectedContent={selectedContent}
                />
            </Modal>
        </Card>

                );
};

export default ContentList;
