import notificationConstants from '../constants/notification.constants';

const initialState = {
    loading: false,
    notifications: [],
    learnerNotifications: [],
    learnerNotificationsLoading: false,
    learnerNotificationClicked: false,
};

const notifications = (state = initialState, action) => {
    switch (action.type) {
        case notificationConstants.FETCH_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case notificationConstants.FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                notifications: action.payload,
            };
        case notificationConstants.FETCH_NOTIFICATIONS_REJECTED:
            return {
                ...state,
                loading: false,
            };


        case notificationConstants.FETCH_LEARNER_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                learnerNotificationsLoading: true,
            };
        case notificationConstants.FETCH_LEARNER_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                learnerNotificationsLoading: false,
                learnerNotifications: action.payload,
            };
        case notificationConstants.FETCH_LEARNER_NOTIFICATIONS_REJECTED:
            return {
                ...state,
                learnerNotificationsLoading: false,
            };


        case notificationConstants.MARK_LEARNER_NOTIFICATION_READ_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case notificationConstants.MARK_LEARNER_NOTIFICATION_READ_SUCCESS:
            return {
                ...state,
                loading: false,
                learnerNotificationClicked: true
            }
        case notificationConstants.MARK_LEARNER_NOTIFICATION_READ_REJECTED:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
};

export default notifications;
